import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    maintainAspectRatio: false
  },
};

const labels = ["green", "none"];
const colors = {yellow: "#FBBC05", grey: "#E0E4EB"}

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Battery",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: Object.values(colors),
      weight: 0.5
    }
  ],
};

function ProductionChart() {
  return (
    <Doughnut width={250} height={250} options={options} data={data} />
  )
}

export default ProductionChart;
