import { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useLightingService } from "../../../services/lightingService";
import { languages } from "../../../utils/languages/languages";
import { Text } from "../../../styles/shared-styles/text-sizes";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import { useTranslation } from "react-i18next";

interface StatusCardProps {
  selectedLamp: any;
  onLamp?: string;
  onlineLamp?: string;
}

export const StatusCard = ({
  selectedLamp,
  onLamp,
  onlineLamp,
}: StatusCardProps) => {
  const { sendCommandToLuminaire } = useLightingService();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [iconOpacity, setIconOpacity] = useState(0.5);

  const handleClick = async (order: number) => {
    try {
      let result: any = await sendCommandToLuminaire([selectedLamp], order, 0);
    } catch (error) {
      alert(error);
    }
  };
  const { t } = useTranslation();

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        paddingTop: "7px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        flexGrow: 1,
        marginBottom: "24px",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="#A3AED0">{t(txt.status)}</Text>
        </div>
        {/* <div style={{ textAlign: "right", marginTop: "-2px", cursor: "pointer" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} />
        </div> */}
      </div>
      <div className="col-12" style={{ marginBottom: "16px" }}>
        {onLamp === "1" && onlineLamp === "1" ? (
          <>
            <button
              onClick={() => handleClick(2)}
              style={{
                width: "50%",
                background: "transparent",
                color: "#979797",
                boxShadow: "0px 4px 8px 3px rgb(0 0 0 / 15%)",
                border: "none",
                padding: "8px 0px",
                fontSize: "11px",
                borderRadius: "7px 0 0 7px",
              }}
            >
              {t("OFF")}
            </button>
            <button
              onClick={() => handleClick(1)}
              style={{
                width: "50%",
                color: "#ffffff",
                background: "#56C568",
                boxShadow: "0px 4px 8px 3px rgb(0 0 0 / 15%)",
                border: "none",
                padding: "8px 0px",
                fontSize: "11px",
                borderRadius: "0 7px 7px 0",
                transform: "scale(1.03)",
              }}
            >
              {t("ON")}
            </button>
          </>
        ) : onLamp === "0" && onlineLamp === "1" ? (
          <>
            <button
              onClick={() => handleClick(2)}
              style={{
                width: "50%",
                color: "#ffffff",
                background: "#525252",
                boxShadow: "0px 4px 8px 3px rgb(0 0 0 / 15%)",
                border: "none",
                padding: "8px 0px",
                fontSize: "11px",
                borderRadius: "7px 0 0 7px",
                transform: "scale(1.03)",
              }}
            >
              {t("OFF")}
            </button>
            <button
              onClick={() => handleClick(1)}
              style={{
                width: "50%",
                color: "#979797",
                background: "transparent",
                boxShadow: "0px 4px 8px 3px rgb(0 0 0 / 15%)",
                border: "none",
                padding: "8px 0px",
                fontSize: "11px",
                borderRadius: "0 7px 7px 0",
              }}
            >
              {t("ON")}
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => handleClick(2)}
              style={{
                width: "50%",
                color: "rgb(171, 171, 171)",
                background: "rgb(229, 229, 229)",
                boxShadow: "3px 8px 4px 0px rgb(0 0 0 / 15%)",
                border: "none",
                padding: "8px 0px",
                fontSize: "11px",
                borderRadius: "7px 0 0 7px",
                cursor: "not-allowed",
                transform: "scale(1.01)",
                borderLeft: "1px solid rgb(171, 171, 171, 0.5)",
                borderRight: "0.5px solid rgb(171, 171, 171, 0.5)",
              }}
            >
              {t("OFF")}
            </button>
            <button
              onClick={() => handleClick(1)}
              style={{
                width: "50%",
                color: "rgb(171, 171, 171)",
                background: "rgb(229, 229, 229)",
                boxShadow: "3px 8px 4px 0px rgb(0 0 0 / 15%)",
                border: "none",
                padding: "8px 0px",
                fontSize: "11px",
                borderRadius: "0 7px 7px 0",
                cursor: "not-allowed",
                transform: "scale(1.01)",
                borderLeft: "0.5px solid rgb(171, 171, 171, 0.5)",
                borderRight: "1px solid rgb(171, 171, 171, 0.5)",
              }}
            >
              {t("ON")}
            </button>
          </>
        )}
      </div>
    </div>
  );
};
