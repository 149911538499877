import { useNavigate, useParams } from "react-router-dom";
import { IconStyled } from "../Header/HeaderStyled";
import { TextSpan } from "../../styles/shared-styles/text-sizes";
import { useAppSelector } from "../../redux/hooks";
import { languages } from "../../utils/languages/languages";
import narrowLeft from "../../assets/back.svg";
import SwitchBoardDetail from "./SwitchBoard-Details/SwitchBoardDetail";
import useSwitchBoard from "../../hooks/useSwitchBoard/useSwitchBoard";
import { useEffect, useState } from "react";
import SwitchTabs from "./Tab/SwitchTabs";
import useFeeder from "../../hooks/useFeeders/useFeeder";
import { CardContainer } from "./SwitchBoardPageStyled";
import { StatusCard } from "./StatusCar/StatusCard";
import LineGraph from "../../shared/components/LineGraph/LineGraph";
import { AlarmsCard } from "../LuminairePage/AlarmsCard/AlarmsCard";
import { LocalWeather } from "../LocalWeather/LocalWeather";
import { MiniMap } from "../CustomMaps/CustomMap/MiniMap";
import { LatLng } from "leaflet";
import { SwitchBoardMiniMap } from "./SwitchBoardMiniMap/SwitchBoardMiniMap";
import SwitchBoardModal from "./SwitchBoardModal/SwitchBoardModal";
import plusIcon from "../../assets/plus.svg";
import CustomModal from "../../shared/Modal/CustomModal";
import FeederNodesModal from "./FeederNodesModal/FeederNodesModal";
import { Spin } from "antd";

const dataConsumption = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Feeder 1",
      borderColor: "#00C7F2",
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      data: [1, 2, 4, 3, 5, 6], // Replace with your data
      tension: 0.4,
    },
  ],
};
const optionConsumption = {
  plugins: {
    legend: {
      display: false,
      labels: {
        usePointStyle: true, // This makes the legend items circular
      },
      borderColor: "transparent", // Remove the legend border
      borderWidth: 0, // Set the border width to 0
      marginBottom: 200,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

// Chart options
const optionsCurrentConsumption: any = {
  scales: {
    x: {
      type: "category", // Use a "category" scale for time values
      position: "bottom", // x-axis should be at the bottom
      title: {
        display: false,
      },
    },
    y: {
      type: "linear",
      position: "left", // y-axis should be on the left
      title: {
        display: false,
        text: "Numbers",
      },
      ticks: {
        callback: (value: any) => (Number.isInteger(value) ? value : ""), // Display only integer values
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        usePointStyle: true, // This makes the legend items circular
      },
      borderColor: "transparent", // Remove the legend border
      borderWidth: 0, // Set the border width to 0
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

const dateItems = [
  {
    title: "Last 6 Months",
    value: 6,
  },
  {
    title: "Last 12 Months",
    value: 12,
  },
];
const menuItems = [
  {
    title: "Today",
    value: 1,
  },
  {
    title: "7-days",
    value: 7,
  },
  {
    title: "14-days",
    value: 14,
  },
  {
    title: "30-days",
    value: 30,
  },
];

const Switchboard = () => {
  const navigate = useNavigate();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { switchBoardData } = useSwitchBoard();
  const [selectedTab, setSelectedTab] = useState<string>("General");
  const [miniMapModal, setMiniMapModal] = useState(false);
  const mapHalfSize = { height: "100%" };
  const [invalidate, setInvalidate] = useState(false);

  const {
    feedersNav,
    feederConsumption,
    allNodes,
    singleFeederDetails,
    isFeederLoading,
  } = useFeeder(selectedTab, invalidate);
  return (
    <div className="container-fluid mt-4">
      <div
        style={{
          marginBottom: "2rem", // Equivalent to mb-8
          marginTop: "2rem", // Equivalent to mt-8
          cursor: "pointer",
          display: "inline-flex",
          alignItems: "center",
        }}
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        <IconStyled
          src={narrowLeft}
          height={12}
          style={{ marginRight: "8px" }}
        />
        <TextSpan className="text-slate-500">{txt.back} to Overview</TextSpan>
      </div>
      <SwitchBoardDetail
        switchData={switchBoardData}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <SwitchTabs
        selected={selectedTab}
        setSelected={setSelectedTab}
        tabs={feedersNav}
      />
      {isFeederLoading ? (
        <div
          style={{
            height: "50vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin tip="Loading" size="large" />
        </div>
      ) : (
        <div className="row mt-4">
          <div className="col-3">
            {/* Map Location And Temporary Events Cards */}
            <div>
              {" "}
              <div>
                <CardContainer className="h-8">
                  <StatusCard
                    selectedTab={selectedTab}
                    switchBoardData={switchBoardData}
                    // onLamp={Number(foundNode?.is_on)}
                    onlineLamp={1}
                  />
                  {/* <DimmingCard dimmingLamp={foundNode} /> */}
                </CardContainer>
              </div>
              <div className="mb-4">
                <CardContainer>
                  {/* <CurrentStrategy nodeId={foundNode?.node_id} />
                   */}
                  <LineGraph
                    data={dataConsumption}
                    // data={dataCurrentConsumption}
                    options={optionConsumption}
                    title={txt.consumption}
                    // menuItems={menuItems}
                    menuItems={dateItems}
                  />
                </CardContainer>
              </div>
              <div>
                <CardContainer>
                  <AlarmsCard />
                </CardContainer>
              </div>
            </div>
          </div>
          <div className="col-9">
            <div>
              <CardContainer>
                {feederConsumption ? (
                  <LineGraph
                    data={feederConsumption}
                    options={optionsCurrentConsumption}
                    // options={optionsCurrentConsumption}
                    title={txt.currentConsumption}
                    menuItems={menuItems}
                  />
                ) : (
                  <Spin
                    style={{
                      height: "250px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Spin>
                )}
              </CardContainer>
            </div>
            <div
              style={{
                height: "381px",
                position: "relative",
                marginTop: "20px",
              }}
            >
              {selectedTab === "General" && switchBoardData && (
                <SwitchBoardMiniMap
                  center={
                    new LatLng(
                      Number.parseFloat(switchBoardData.latitude),
                      Number.parseFloat(switchBoardData.longitude)
                    )
                  }
                  mapSize={mapHalfSize}
                  // unAssignedNode={mapNodes}
                  switchBoardData={switchBoardData}
                  generalPageNodes={allNodes}
                />
              )}
              {selectedTab !== "General" &&
                switchBoardData &&
                singleFeederDetails && (
                  <SwitchBoardMiniMap
                    center={
                      new LatLng(
                        Number.parseFloat(switchBoardData.latitude),
                        Number.parseFloat(switchBoardData.longitude)
                      )
                    }
                    mapSize={mapHalfSize}
                    feederColor={singleFeederDetails?.feeder?.color}
                    feederNodes={singleFeederDetails.nodes}
                    switchBoardData={switchBoardData}
                  />
                )}
              <div className="absolute bottom-0 left-0 z-2 transform -translate-x-1/2 -translate-y-1/2">
                <LocalWeather />
              </div>
              {selectedTab !== "General" && singleFeederDetails && (
                <div
                  className="absolute bottom-8 right-4 z-3 transform -translate-x-4 -translate-y-4 h-8 w-8 shadow-2xl"
                  style={{
                    position: "absolute",
                    bottom: "30px",
                    right: "20px",
                    zIndex: 1,
                  }}
                >
                  <div
                    onClick={() => setMiniMapModal(true)}
                    className="rounded-full bg-[#FBBC05] h-12 w-12  flex justify-center items-center shadow-inner"
                    style={{
                      background: "#FBBC05",
                      height: "50px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "30px",
                    }}
                  >
                    <IconStyled src={plusIcon} width={18} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <SwitchBoardModal
        selectedTab={selectedTab}
        switchBoardData={switchBoardData}
        setSelectedTab={setSelectedTab}
        feederId={singleFeederDetails?.feeder.feeder_id}
        feederName={singleFeederDetails?.feeder.feeder_name}
      />
      {singleFeederDetails && !isFeederLoading && (
        <FeederNodesModal
          handleOpen={() => setMiniMapModal((prev) => !prev)}
          open={miniMapModal}
          selectedTab={selectedTab}
          feederData={singleFeederDetails}
          feeders={allNodes}
          switchBoardData={switchBoardData}
          isSingleFeederDataLoading={false}
          setInvalidate={setInvalidate}
        />
      )}
    </div>
  );
};

export default Switchboard;
