import { useState, useEffect } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveStrategyInfoActionCreator } from "../../../../redux/slices/strategiesSlice/strategiesSlice";
import { Dimming } from "../Step2Dimming/Dimming";
import { languages } from "../../../../utils/languages/languages";
import {
  InputField,
  TextAreaField,
  CardContainer,
  CancelButton,
  NextButton,
} from "../StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import ellipseGreen from "../../../../assets/new_icons/ellipse_green.svg";
import ellipseRed from "../../../../assets/new_icons/ellipse_red.svg";
import ellipseYellow from "../../../../assets/new_icons/ellipse_yellow.svg";
import ellipseBlack from "../../../../assets/new_icons/ellipse_black.svg";
import ellipseBlue from "../../../../assets/new_icons/ellipse_blue.svg";
import { useTranslation } from "react-i18next";

interface NameProps {
  onHide?: any;
  showDimming?: any;
  goToDimming?: any;
  errorMessage?: boolean;
}

export const Details = ({
  onHide,
  showDimming,
  goToDimming,
  errorMessage,
}: NameProps) => {
  const strategyInfo = useAppSelector((state) => state.strategies);
  const [name, setName] = useState(strategyInfo.name);
  const [selectedOption, setSelectedOption]: any = useState(strategyInfo.color);
  const [description, setDescription] = useState(strategyInfo.description);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  // cuando tengamos esto definido, poner traducciones e iconos
  const options = [
    { label: t(txt.selectColor) },
    { value: "#56C568", label: t("Green"), icon: ellipseGreen },
    { value: "#D51A52", label: t("Red"), icon: ellipseRed },
    { value: "#FBBC05", label: t("Yellow"), icon: ellipseYellow },
    { value: "#1C1B1F", label: t("Black"), icon: ellipseBlack },
    { value: "#87CBE8", label: t("Blue"), icon: ellipseBlue },
  ];

  const formatOptionLabel = ({ value, label, icon }: any) => (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <img src={icon} alt="" style={{ marginRight: "8px", marginTop: "1px" }} />
      <Text
        textType="caption"
        color="#959595"
        marginBottom="0px"
        style={{ fontSize: "12px" }}
      >
        {label}
      </Text>
    </div>
  );

  useEffect(() => {
    dispatch(
      saveStrategyInfoActionCreator({
        name: name,
        color: selectedOption,
        description: description,
      })
    );
  }, [name, selectedOption, description]);

  return (
    <>
      <div className="col-12">
        <CardContainer>
          <div
            className="col-12"
            style={{ marginBottom: "32px", textAlign: "left" }}
          >
            <Text fontWeight="600" marginBottom="0px">
              {t(txt.strategyDetailsTitle)}
            </Text>
            <Text textType="caption" fontWeight="400">
              {t(txt.strategyDetailsSubtitle)}
            </Text>
          </div>
          <div
            className="row"
            style={{ marginBottom: "16px", textAlign: "left" }}
          >
            <div className="col-6">
              <Text textType="caption" color="#475569" marginBottom="0px">
                {" "}
                {t(txt.name)}
              </Text>
              <InputField
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t(txt.name)}
              />
            </div>
            <div className="col-6">
              <Text textType="caption" color="#475569" marginBottom="0px">
                {t(txt.color)}
              </Text>
              <Select
                value={selectedOption ? selectedOption : options[0]}
                onChange={setSelectedOption}
                options={options}
                formatOptionLabel={formatOptionLabel}
                placeholder={t(txt.selectColor)}
                styles={{
                  control: (baseStyles, state) => {
                    state.theme.colors.primary = "none";
                    return {
                      ...baseStyles,
                      cursor: "pointer",
                      backgroundColor: "#fff",
                      color: "#212529",
                      maxHeight: "36px",
                      minHeight: "36px",
                      fontSize: "12px",
                      fontWeight: "400",
                      borderRadius: "0.375rem",
                      border: state.isFocused
                        ? "2px solid"
                        : "1px solid #ced4da",
                      "&:hover": {
                        borderColor: "none",
                      },
                    };
                  },
                }}
              />
            </div>
          </div>
          <div
            className="col-12"
            style={{
              marginBottom: errorMessage ? "11px" : "32px",
              textAlign: "left",
            }}
          >
            <Text textType="caption" color="#475569" marginBottom="0px">
              {t(txt.description)}
            </Text>
            <TextAreaField
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={t(txt.description)}
            />
          </div>
          {errorMessage ? (
            <Text
              marginBottom="0px"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t(txt.errorBlankInput)}
            </Text>
          ) : (
            <></>
          )}
        </CardContainer>
      </div>
      <div className="row">
        <div className="col-6" style={{ textAlign: "left" }}>
          <CancelButton
            onClick={() => {
              onHide();
            }}
          >
            {t(txt.cancel)}
          </CancelButton>
        </div>
        <div className="col-6" style={{ textAlign: "right" }}>
          <NextButton
            onClick={() => {
              goToDimming();
            }}
          >
            {t(txt.nextDimming)}
          </NextButton>
          {showDimming && <Dimming />}
        </div>
      </div>
    </>
  );
};
