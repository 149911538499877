import { DataCardStyled, IconsDataCardStyled, TextDataCardStyled } from "./DataCardStyled";
import { TextSpan } from "../../styles/shared-styles/text-sizes";
import ON from "../../assets/new_icons/ON.svg";
import OFF from "../../assets/new_icons/OFF.svg";

interface DataCardProps {
  mainIcon: string;
  title: string;
  content: string;
  moveIcon?: string;
  isAlert?: boolean;
  onClickFn?: any;
  notPositioned: boolean;
}

export const DataCard = ({
  mainIcon,
  content,
  moveIcon = "arrow_right_alt",
  title,
  isAlert = false,
  onClickFn,
  notPositioned
}: DataCardProps) => {
  return (
    <>
    
    <DataCardStyled>
      <IconsDataCardStyled>
        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "center", width: "50px", height: "50px", borderRadius: "50%", background: "#F3F4F6" }} onClick={()=>onClickFn(notPositioned)}>
          <img src={mainIcon} style={{ width: "20px", height: "20px", marginTop: mainIcon === ON || mainIcon === OFF ? "-4px": "" }} />  
        </div>
        {/* <span
          className={
            isAlert
              ? "material-symbols-rounded data-card__main-icon data-card__main-icon--alert"
              : "material-symbols-rounded data-card__main-icon"
          }
        >
          {!isAlert ? (
            mainIcon
          ) : (
            <span className="material-symbols-rounded data-card__alert-icon">
              priority_high
            </span>
          )}
        </span> */}
      </IconsDataCardStyled>
      <TextDataCardStyled>  
        <TextSpan color="#A3AED0">
          {title}
        </TextSpan>      
        <TextSpan textType="title" fontWeight="700">
          {content}
        </TextSpan>    
      </TextDataCardStyled>
    </DataCardStyled>
    </>
  );
};

// flechas pendientes de implementar
/*
  <ArrowDataCardStyled>
    <span className="material-symbols-rounded">
      {moveIcon}
    </span>
  </ArrowDataCardStyled>
*/
