import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InitialState {
  showNotPositionedNodes: boolean;
  selectedNodeToAddLocation: string | null;
  addLocationMarkerLatLng: any;
}

const initialState: InitialState = {
  showNotPositionedNodes: true,
  selectedNodeToAddLocation: null,
  addLocationMarkerLatLng: null,
};

const sharedSlice = createSlice({
  name: "sharedSlice",
  initialState,
  reducers: {
    toggleShowNotPositionedNodes: (state, action) => {
      state.showNotPositionedNodes = action.payload;
    },
    setSelectedNodeToAddLocation: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedNodeToAddLocation = action.payload;
    },
    setAddLocationMarkerLatLng: (
      state,
      action: PayloadAction<any>
    ) => {
      state.addLocationMarkerLatLng = action.payload;
    },
  },
});

export const { toggleShowNotPositionedNodes, setAddLocationMarkerLatLng, setSelectedNodeToAddLocation } = sharedSlice.actions;

export default sharedSlice.reducer;
