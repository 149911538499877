import axios from "axios";

class UpdateLanguageService {
  private webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;

  public async updateLanguage(
    token: string,
    languageName: string
  ): Promise<void> {
    try {
      const response = await fetch(
        `${this.webAddressPrefix}/profile/change-language`,
        {
          method: "PATCH",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            language_name: languageName,
          }),
        }
      );

      if (!response) {
        throw new Error("There is no response data.");
      }
    } catch (error) {
      throw new Error("There was an error. Please try again later.");
    }
  }
}

const updateLanguageService = new UpdateLanguageService();
export default updateLanguageService;
