import styled from "styled-components";

export const CustomMapStyled = styled.section`
  height: 102%;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #FBFCFE;
  position: relative;
  gap: 20px;
  

  .leaflet-container {
    background: #FFFFFF;
    min-height: 100.1%;
    min-width: 100%;
    z-index: 0;
    border-radius: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1),
      0px 1px 3px 1px rgba(0, 0, 0, 0.1);
  }

  .my-custom-map .leaflet-tile {
    filter: grayscale(100%) opacity(50%) contrast(110%)
  }

  .div {
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 5px;
    background: ${(props) => props.theme.mainBackgroundColor};
    position: absolute;
    bottom: 0;
  }
`;
