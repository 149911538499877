// useGetManagerUsersService.tsx
import { GetManagerUsers } from "../types/interfaces";
import { loadGetManagerUsersActionCreator } from "../redux/slices/managerUsers/getManagerUsersSlice";
import { useAppDispatch } from "../redux/hooks";
import Cookies from "js-cookie";

export const useGetManagerUsersService = () => {
  const webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;
  const dispatch = useAppDispatch();

  const saveTokenToStorage = (token: string) => {
    Cookies.set("token", token, { expires: 7 });
  };

  const getManagerUsers = async (token: string): Promise<boolean> => {
    try {
      const response = await fetch(`${webAddressPrefix}/users/manage-users`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const getManagerUsersData = await response.json();
        if (
          Array.isArray(getManagerUsersData) &&
          getManagerUsersData.length > 0
        ) {
          const usersList: GetManagerUsers[] = getManagerUsersData.map(
            (userData) => ({
              id: userData.id || "",
              name: userData.name || "",
              surname: userData.surname || "",
              email: userData.email || "",
              role: userData.role || "",
              active: userData.active || "",
              organizations: userData.organizations || [],
            })
          );
          dispatch(loadGetManagerUsersActionCreator(usersList));
          return true;
        } else {
          console.log("No user data found in response.");
          return false;
        }
      } else {
        console.log("Failed to fetch user profile.", response.status);
        return false;
      }
    } catch (error) {
      console.log("Error while fetching user profile:", error);
      return false;
    }
  };

  return {
    getManagerUsers,
    saveTokenToStorage,
  };
};
