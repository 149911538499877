import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GetManagerOrganisationState {
  id: string;
  organization: string;
  type: string;
  city: string;
  country: string;
  organizationActive: string;
  selected: boolean;
}

const initialGetManagerOrganisationState: GetManagerOrganisationState[] = [];

const getManagerUsersOrganisationSlice = createSlice({
  name: "managerUsersOrganisation",
  initialState: initialGetManagerOrganisationState,
  reducers: {
    loadGetManagerUsersOrganisationActionCreator: (
      state,
      action: PayloadAction<GetManagerOrganisationState[]>
    ) => {
      return action.payload;
    },
  },
});

export const { loadGetManagerUsersOrganisationActionCreator } =
  getManagerUsersOrganisationSlice.actions;

export default getManagerUsersOrganisationSlice.reducer;
