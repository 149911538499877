import styled from "styled-components";
import { Switch } from "antd";

export const ErrorPopUp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13px;
  width: 380px;
  height: fit-content;
  border-radius: 14px;
  background-color: #F3F4F6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
  0px 1px 3px 1px rgba(0, 0, 0, 0.15);

  //revisar esto si al final lo dejamos así
  .errorpopup {
    &__button-container {
      display: flex;
      align-self: flex-end;

      &__icon {
        font-size: 1.2rem;
        color: #686b6f;
        cursor: pointer;
      }
    }

    &__body-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 14px 18px;
      padding-top: 2px;

      &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 13px;
        width: 310px;
        height: fit-content;
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

        &__title {
          display: flex;
          justify-content: space-between;
          width: 286px;
          margin-bottom: 10px;

          &--icon {
            font-size: 1.2rem;
            color: #A3AED0;
          }  
        }

        &__progress-bar {
          width: 280px;
          height: 16px;
          margin-top: 6px;
          margin-bottom: 6px;
          -webkit-appearance: none;
          border-radius: 10px;
          position: relative;
          cursor: pointer;
          border: 1px solid #A3AED0;

          ::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 24px;
            height: 24px;
            background-color: #ffc107;
            cursor: pointer;
            border-radius: 50%;
            border: 1px solid #A3AED0;
          }
        }

        &__percentages {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 286px;
          height: 14px;
          margin-bottom: 4px;
        }
      }

      &__button {
        cursor: pointer;
        display: flex;        
        margin-top: 20px;
        border-radius: 8px;
        align-items: center;
        padding: 8px 18px 8px 18px;
        gap: 8px;
        height: 40px;
        font-weight: 600;
        background: #ffc107;
        color: #1b2559;
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
          0px 1px 3px rgba(0, 0, 0, 0.3);
      }
    }
  }
`;

export const Scroll = styled.div`
  overflow: auto;
  max-height: 643px;
  margin-top: 0px;
  padding-left: 2px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    /*background: #f1f1f1;*/
    background: transparent;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }*/
`;

export const Space = styled.div`
  height: 10px;
`;

export const IconStyled = styled.img`
  height: 12px;
  margin-right: 8px;
`;

export const ListPopUp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  border-radius: 4px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);

  .listpopup {
    &__block {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-top: 9px;
      padding-bottom: 8px;
      height: 50px;

      &__text {
        margin-top: 2px;
        font-size: 14px;
        font-weight: 400
      }
    }
  }

  .listpopup__block:hover {
    background-color: #1c1b1f11;
  }
`;

export const SwitchComponent = styled(Switch)`
  && {
    background-color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};
  }
`;

export const GroupText = styled.span`
  padding: 6px 12px;
  color: #1a141f;
  background: #f5f3f7;
  font-weight: 600;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 12px;
`;
