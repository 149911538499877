import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  language_name: string;
  role_name: string;
  user_active: string;
}

const initialUserState: UserState = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  language_name: "",
  role_name: "",
  user_active: "",
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    loadUserActionCreator: (state, action: PayloadAction<UserState>) => {
      return { ...state, ...action.payload };
    },
    // Other reducers for user if any
  },
});

export const { loadUserActionCreator } = userSlice.actions;

export default userSlice.reducer;
