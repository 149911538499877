import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Strategies } from "../../../types/interfaces";

const initialState: Strategies = {
  strategies: "",
  labels: "",
  data: "",
  name: "",
  color: "",
  description: "",
  saving: 0,
  sunset: false,
  checkedSunset: false,
  sunrise: false,
  checkedSunrise: false,
  count: 0
};

const strategiesSlice = createSlice({
  name: "actualFilter",
  initialState: initialState,
  reducers: {
    getStrategies: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      strategies: action.payload
    }),
    
    changeStrategyChart: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      labels: action.payload.labels,
      data: action.payload.data
    }),

    saveStrategyInfo: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      name: action.payload.name,
      color: action.payload.color,
      description: action.payload.description
    }),

    saveEnergyInfo: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      saving: action.payload.saving
    }),

    changeSunset: (previousData) => ({
      ...previousData,
      sunset: !previousData.sunset
    }),

    turnSunsetTrue: (previousData) => ({
      ...previousData,
      sunset: true
    }),

    changeSunrise: (previousData) => ({
      ...previousData,
      sunrise: !previousData.sunrise
    }),

    turnSunriseTrue: (previousData) => ({
      ...previousData,
      sunrise: true
    }),

    sumCount: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      count: action.payload.count,
    }),

    resetStrategy: (previousData) => ({
      ...previousData,
      labels: "",
      data: "",
      name: "",
      color: "",
      description: "",
      saving: 0,
      sunset: false,
      sunrise: false,
      count: 0
    }),
  },
});

export const strategiesReducer = strategiesSlice.reducer;

export const {
  getStrategies: getStrategiesActionCreator,
  changeStrategyChart: changeStrategyChartActionCreator,
  saveStrategyInfo: saveStrategyInfoActionCreator,
  saveEnergyInfo: saveEnergyInfoActionCreator,
  changeSunset: changeSunsetActionCreator,
  turnSunsetTrue: turnSunsetTrueActionCreator,
  changeSunrise: changeSunriseActionCreator,
  turnSunriseTrue: turnSunriseTrueActionCreator,
  sumCount: sumCountActionCreator,
  resetStrategy: resetStrategyActionCreator
} = strategiesSlice.actions;
