import { useEffect, useState } from "react";
import { /* useParams, */ useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LatLng } from "leaflet";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  setIsFullViewActionCreator,
  setIsHybridViewActionCreator,
  setIsListViewActionCreator,
  setFirstRenderMapToTrueActionCreator,
} from "../../redux/slices/uiSlice/uiSlice";
import { AlarmsCard } from "./AlarmsCard/AlarmsCard";
import { Battery } from "./SolarLuminaire/Battery/Battery";
import { CurrentStrategy } from "./CurrentStrategy/CurrentStrategy";
import { DimmingCard } from "./Dimming/DimmingCard";
import { ElectricalParametersCard } from "./ElectricalParametersCard/ElectricalParametersCard";
import { EnergyGeneration } from "./SolarLuminaire/EnergyGeneration/EnergyGeneration";
import { LocalWeather } from "../LocalWeather/LocalWeather";
import { MiniMap } from "../CustomMaps/CustomMap/MiniMap";
import { Production } from "./SolarLuminaire/Production/Production";
import { GroupsCard } from "./GroupsCard/GroupsCard";
import { Savings } from "./SolarLuminaire/Savings/Savings";
import { StatusCard } from "./Status/StatusCard";
import { TemporaryEventsCard } from "./TemporaryEventsCard/TemporaryEventsCard";
import { ViewCamera } from "./ViewCamera/ViewCamera";
import { languages } from "../../utils/languages/languages";
import { CardContainer, CardSimple, IconStyled } from "./LuminairePageStyled";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import back from "../../assets/new_icons/back.svg";
import { useTranslation } from "react-i18next";

interface LuminairePageProps {}

export const LuminairePage = ({}: LuminairePageProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const viewState = location.state.viewState;
  const nodeFromRedux = useAppSelector((state) => state.manageNode);
  const [node, setNode]: any = useState(nodeFromRedux);
  const [creationDate, setCreationDate]: any = useState();
  const [updateDate, setUpdateDate]: any = useState();
  const devices = useAppSelector((state) => state.filteredDevices);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { t } = useTranslation();

  const mapHalfSize = { height: "100%" };

  // manage going back through the button stacked in the navigator
  window.addEventListener("popstate", () => {
    if (viewState === "full") {
      dispatch(setIsFullViewActionCreator());
    } else if (viewState === "hybrid") {
      dispatch(setIsHybridViewActionCreator());
    } else if (viewState === "list") {
      dispatch(setIsListViewActionCreator());
    }
    navigate("/dashboard");
  });

  // manage going back through the button "back to overview""
  const goBackToMap = () => {
    if (viewState === "full") {
      dispatch(setIsFullViewActionCreator());
    } else if (viewState === "hybrid") {
      dispatch(setIsHybridViewActionCreator());
    } else if (viewState === "list") {
      dispatch(setIsListViewActionCreator());
    }
    dispatch(setFirstRenderMapToTrueActionCreator());
    navigate("/dashboard");
  };

  useEffect(() => {
    setNode(nodeFromRedux);

    const parsedCreationDate = async () => {
      const creationDate = new Date(node.creation_date);
      let day: any = creationDate.getUTCDate();
      let month: any = creationDate.getUTCMonth() + 1;
      let year = creationDate.getUTCFullYear();

      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;

      let parsedDate = day + "/" + month + "/" + year;
      await setCreationDate(parsedDate);
    };

    const parsedUpdateDate = async () => {
      const updateDate = new Date(node.update_date);

      let day: any = updateDate.getUTCDate();
      let month: any = updateDate.getUTCMonth() + 1;
      let year = updateDate.getUTCFullYear();

      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;

      let parsedDate = day + "/" + month + "/" + year;
      await setUpdateDate(parsedDate);
    };

    parsedCreationDate();
    parsedUpdateDate();
  }, [devices, nodeFromRedux]);

  return (
    <div
      className="container-fluid"
      style={
        node.solar_id !== null
          ? {
              marginTop: "8px",
              paddingBottom: "20px",
              paddingRight: "0px",
              paddingLeft: "0px",
              zIndex: 2,
            }
          : {
              marginTop: "8px",
              paddingBottom: "0px",
              paddingRight: "0px",
              paddingLeft: "0px",
              zIndex: 2,
            }
      }
    >
      <div
        style={{
          marginBottom: "20px",
          cursor: "pointer",
          display: "inline-flex",
          alignItems: "center",
        }}
        onClick={() => {
          goBackToMap();
        }}
      >
        <IconStyled
          src={back}
          height={12}
          style={{ marginRight: "8px", marginTop: "1px" }}
        />
        <TextSpan color="black">{t(txt.back)}</TextSpan>
      </div>
      <ViewCamera selectedLamp={node} />
      {node.solar_id && (
        <div
          className="row"
          style={{ marginBottom: "24px", display: "flex", flexWrap: "wrap" }}
        >
          <div className="col-6" style={{ maxWidth: "12.5%" }}>
            <CardContainer>
              <Battery />
            </CardContainer>
          </div>
          <div className="col-6" style={{ maxWidth: "12.5%" }}>
            <CardContainer>
              <Production />
            </CardContainer>
          </div>
          <div className="col-6" style={{ maxWidth: "50%" }}>
            <CardContainer>
              <EnergyGeneration />
            </CardContainer>
          </div>
          <div className="col-6" style={{ maxWidth: "25%" }}>
            <CardContainer>
              <Savings />
            </CardContainer>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-6">
          {/* Status ,Dimming, and Current Strategy cards */}
          <div
            className="row"
            style={{ marginBottom: "24px", display: "flex", flexWrap: "wrap" }}
          >
            <div className="col-6" style={{ maxWidth: "50%" }}>
              <CardContainer>
                <StatusCard
                  selectedLamp={node}
                  onLamp={node.on}
                  onlineLamp={node.online}
                />
                <DimmingCard selectedLamp={node} dimmingValue={node.bri} />
              </CardContainer>
            </div>
            <div className="col-6" style={{ maxWidth: "50%" }}>
              <CardContainer>
                <CurrentStrategy
                  selectedLampId={node.node_id}
                  viewState={viewState}
                />
              </CardContainer>
            </div>
          </div>
          {/* Map Location And Temporary Events Cards */}
          <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
            <div
              style={{
                flex: " 0 0 50%",
                maxWidth: "50%",
                position: "relative",
                height: "340px",
              }}
            >
              <CardContainer>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "86.8%",
                  }}
                >
                  {node?.latitude && (
                    <MiniMap
                      center={
                        new LatLng(
                          Number(node.latitude),
                          Number(node.longitude)
                        )
                      }
                      mapSize={mapHalfSize}
                      selectedLamp={node}
                    />
                  )}
                </div>
                <div
                  style={{
                    alignSelf: "center",
                    position: "absolute",
                    bottom: 0,
                    zIndex: 2,
                  }}
                >
                  <LocalWeather />
                </div>
              </CardContainer>
            </div>
            <div
              className="col-6"
              style={{ flex: " 0 0 50%", maxWidth: "50%" }}
            >
              <CardContainer>
                <TemporaryEventsCard />
              </CardContainer>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div
            className="row"
            style={{ marginBottom: "24px", display: "flex", flexWrap: "wrap" }}
          >
            <div
              className="col-6"
              style={{ flex: " 0 0 50%", maxWidth: "50%" }}
            >
              <CardContainer>
                <GroupsCard />
              </CardContainer>
            </div>
            <div
              className="col-6"
              style={{ flex: " 0 0 50%", maxWidth: "50%" }}
            >
              <CardContainer>
                <AlarmsCard />
              </CardContainer>
            </div>
          </div>
          <div className="col-12">
            <CardContainer>
              <ElectricalParametersCard />
            </CardContainer>
          </div>
        </div>
        {/* Implementar los 8 widgets que faltan */}
        <div
          className="col-12"
          style={{
            marginBottom: "24px",
            marginTop: "24px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div className="col-6" style={{ maxWidth: "12.6%" }}>
            <CardContainer style={{ height: "85%" }}>
              <CardSimple>
                <Text color="#A3AED0">{t(txt.manufacturer)}</Text>
                <Text>{node.manufacturer_name}</Text>
              </CardSimple>
            </CardContainer>
          </div>
          <div className="col-6" style={{ maxWidth: "12.6%" }}>
            <CardContainer style={{ height: "85%" }}>
              <CardSimple>
                <Text color="#A3AED0">{t(txt.protocol)}</Text>
                <Text>{node.protocol_name}</Text>
              </CardSimple>
            </CardContainer>
          </div>
          <div className="col-6" style={{ maxWidth: "12.6%" }}>
            <CardContainer style={{ height: "85%" }}>
              <CardSimple>
                <Text color="#A3AED0">{t(txt.case)}</Text>
                <Text>{node.case_name}</Text>
              </CardSimple>
            </CardContainer>
          </div>
          <div className="col-6" style={{ maxWidth: "12.6%" }}>
            <CardContainer style={{ height: "85%" }}>
              <CardSimple>
                <Text color="#A3AED0">{t(txt.poleNumber)}</Text>
                {node.pole ? <Text>{node.pole}</Text> : <Text>-</Text>}
              </CardSimple>
            </CardContainer>
          </div>
          <div className="col-6" style={{ maxWidth: "12.6%" }}>
            <CardContainer style={{ height: "85%" }}>
              <CardSimple>
                <Text color="#A3AED0">{t(txt.creationDate)}</Text>
                <Text>{creationDate}</Text>
              </CardSimple>
            </CardContainer>
          </div>
          <div className="col-6" style={{ maxWidth: "12.6%" }}>
            <CardContainer style={{ height: "85%" }}>
              <CardSimple>
                <Text color="#A3AED0">{t(txt.updateDate)}</Text>
                <Text>{updateDate}</Text>
              </CardSimple>
            </CardContainer>
          </div>
          <div className="col-6" style={{ maxWidth: "12.6%" }}>
            <CardContainer style={{ height: "85%" }}>
              <CardSimple>
                <Text color="#A3AED0">{t(txt.active)}</Text>
                {node.active === "Y" ? (
                  <Text>{t(txt.yes)}</Text>
                ) : (
                  <Text>{t(txt.no)}</Text>
                )}
              </CardSimple>
            </CardContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
