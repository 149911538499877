import axios from "axios";

class UpdatePersonalInformationService {
  private webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;

  public async updatePersonalInformation(
    token: string,
    firstName: string,
    lastName: string,
    phoneNumber: string
  ): Promise<void> {
    try {
      const response = await fetch(
        `${this.webAddressPrefix}/profile/personal-information`,
        {
          method: "PATCH",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
          }),
        }
      );

      if (!response) {
        throw new Error("There is no response data.");
      }
    } catch (error) {
      throw new Error("There was an error. Please try again later.");
    }
  }
}

const updatePersonalInformationService = new UpdatePersonalInformationService();
export default updatePersonalInformationService;
