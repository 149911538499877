import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    fontColor: "white",
    maintainAspectRatio: false,
  },
  scales: {
    x: {
      border: {
        display: true
      },
      grid: {
        display: false,
        drawOnChartArea: true,
        drawTicks: true,
      }
    },
    y: {
      border: {
        display: false
      },
      grid: {
        color: 'white',
        lineWidth: 0.3,
      },
    }
  }
};

const labels = ["0", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: "white",
      borderWidth: 5,
      lineTension: 0.4,
      pointRadius: 0,
    },
  ],
};

function SavingsChart() {
  return (
    <Line width={200} height={110} options={options} data={data} />
  )
}

export default SavingsChart;
