import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import blackProfile from "../../assets/black-profile.svg";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import envelope from "../../assets/mail.svg";
import userIcon from "../../assets/user.svg";
import globeBold from "../../assets/globe-bold.svg";
import { ListItem, IconStyled, CardStyled } from "./ProfilePageStyled";
import { ButtonOutlinedDisabled } from "../../styles/shared-styles/buttons-sizes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useUserService } from "../../services/userService";
import { RootState } from "../../redux/store";
import Cookies from "js-cookie";
import { PersonalInformation } from "./Personal_Information";
import { PasswordInformation } from "./PasswordInformation";
import { LanguageInformation } from "./LanguageInformation";
import { useTranslation } from "react-i18next";

interface ProfilePageProps {}

export const ProfilePage = ({}: ProfilePageProps) => {
  const dispatch = useAppDispatch();
  const { getUserProfile } = useUserService();
  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      getUserProfile(token);
      if (user) {
        console.log("User Payload ProfilePage Gladys", user);
      }
    } else {
      // Redirect the user to the login page
      navigate("/");
    }
  }, []);
  const { t } = useTranslation();
  return (
    <div
      className="container-fluid"
      style={{
        marginTop: "30px",
        paddingRight: "0px",
        paddingLeft: "0px",
        zIndex: 2,
      }}
    >
      <div className="row" style={{ marginBottom: "32px" }}>
        <div className="col-1">
          <div
            className="profile-picture-container"
            style={{
              borderRadius: "50%",
              width: "72px",
              height: "72px",
              overflow: "hidden",
            }}
          >
            <img
              src={blackProfile}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt="Profile Picture"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="col-4">
          <div className="row" style={{ marginBottom: "0px" }}>
            <div className="col-12">
              <Text textType="subTitle" fontWeight="700">
                {user.first_name} {user.last_name}
              </Text>
            </div>
          </div>

          <div className="row">
            <ul style={{ display: " inline-flex" }}>
              <ListItem>
                <IconStyled src={userIcon} height={12} />{" "}
                <TextSpan>{user.role_name}</TextSpan>
              </ListItem>

              <ListItem>
                <IconStyled src={envelope} height={16} />{" "}
                <TextSpan>{user.email}</TextSpan>
              </ListItem>

              {/* <ListItem>
                <IconStyled src={globeBold} height={14} />{" "}
                <TextSpan>{user.language_name}</TextSpan>
              </ListItem> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="col-12" style={{ marginBottom: "32px" }}>
            <PersonalInformation user={user} />
          </div>
          {/* <div className="col-12" style={{ marginBottom: "32px" }}>
            <CardStyled>
              <Text
                textType="subTitle"
                fontWeight="700"
                color="background: #1B2559;
"
              >
                Email
              </Text>
              <div className="row" style={{ marginBottom: "16px" }}>
                <div className="col-6">
                  <TextSpan>
                    {t("Your email address is")} <b>{user.email}</b>
                  </TextSpan>
                </div>
                <div className="col-6" style={{ textAlign: "right" }}>
                  <ButtonOutlinedDisabled>{t("Change email")}</ButtonOutlinedDisabled>
                </div>
              </div>
            </CardStyled>
          </div> */}
          <div className="col-12" style={{ marginBottom: "32px" }}>
            <PasswordInformation />
          </div>
        </div>
        <div className="col-6">
          {/* <div className="col-12" style={{ marginBottom: "32px" }}>
            <LanguageInformation user={user} />
          </div> */}
          {/* <div className="col-12" style={{ marginBottom: "32px" }}>
            <CardStyled>
              <Text
                textType="subTitle"
                fontWeight="700"
                color="background: #1B2559"
              >
                {t("Role")}
              </Text>
              <div className="row" style={{ marginBottom: "16px" }}>
                <div className="col-6">
                  <TextSpan>
                    {t("Your role is")} <b>{user.role_name}</b>
                  </TextSpan>
                </div>
                <div className="col-6" style={{ textAlign: "right" }}>
                  <ButtonOutlinedDisabled>{t("Change Role")}</ButtonOutlinedDisabled>
                </div>
              </div>
            </CardStyled>
          </div>
          <div className="col-12" style={{ marginBottom: "32px" }}>
            <CardStyled>
              <Text
                textType="subTitle"
                fontWeight="700"
                color="background: #1B2559;
"
              >
                {t("Account")}
              </Text>
              <div className="row" style={{ marginBottom: "16px" }}>
                <div className="col-6">
                  <TextSpan>
                    {t("Your account is")}
                    <b> {user.user_active === "Y" ? "active" : "not active"}</b>
                  </TextSpan>
                </div>
                <div className="col-6" style={{ textAlign: "right" }}>
                  <ButtonOutlinedDisabled>
                   {t("Change Account")} 
                  </ButtonOutlinedDisabled>
                </div>
              </div>
            </CardStyled>
          </div>  */}
        </div>
      </div>
    </div>
  );
};
