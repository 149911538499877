import { useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setFiltersActionCreator } from "../../redux/slices/actualFilterSlice/actualFilterSlice";
import {
  filterAlarmDevicesActionCreator,
  filterDisconnectedDevicesActionCreator,
  filteredAllDevicesActionCreator,
  filterOffDevicesActionCreator,
  filterOnDevicesActionCreator,
} from "../../redux/slices/devices/filteredDevicesSlice";
import { changeBackgroundActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { errorToInitialStateActionCreator } from "../../redux/slices/mqttSlice/mqttSlice";
import {
  allowFlyModeActionCreator,
  setIsOpenedDetailActionCreator,
  setIsOpenedGroupActionCreator,
  setIsStaticViewActionCreator,
} from "../../redux/slices/uiSlice/uiSlice";
import { useLightingService } from "../../services/lightingService";
import { DevicesInfo } from "../../types/interfaces";
import { initialLamp, initialLampsGroup } from "../../utils/data";
import { googleIcons } from "../../utils/googleFontsIcons/icons";
import { languages } from "../../utils/languages/languages";
import {
  GroupSelectionPopupStyled,
  GroupPopupHybridViewStyled,
} from "./GroupSelectionPopupStyled";
import { useTranslation } from "react-i18next";

interface GroupSelectionPopupProps {
  devicesInfo: DevicesInfo;
  setSelectedMarker: React.Dispatch<React.SetStateAction<string>>;
  setSelectedGroup: React.Dispatch<React.SetStateAction<string[]>>;
}

type DevicesSelector = "nodes" | "gateways";

export const GroupSelectionPopup = ({
  devicesInfo,
  setSelectedGroup,
  setSelectedMarker,
}: GroupSelectionPopupProps) => {
  const fatherHeight = document
    .getElementById("map-container")
    ?.getBoundingClientRect().height!;
  const fatherWidth = document
    .getElementById("map-container")
    ?.getBoundingClientRect().width!;
  const nodesSelector = document
    .getElementById("nodes-selector")
    ?.getBoundingClientRect().width!;
  const gatewaysSelector = document
    .getElementById("gateways-selector")
    ?.getBoundingClientRect().width!;
  const selectorContainer = document
    .getElementById("selector-container")
    ?.getBoundingClientRect().width!;

  const dispatch = useAppDispatch();
  const { sendCommandToLuminaire } = useLightingService();
  const { actualLanguage } = useAppSelector((state) => state.ui);
  const txt = languages[actualLanguage];
  const devices = useAppSelector((state) => state.devices);
  const filteredDevices = useAppSelector((state) => state.filteredDevices);
  const actualFilter = useAppSelector((state) => state.actualFilter);
  const fullView = useAppSelector((state) => state.ui.isFullView);
  const { close, move, error, lightBulb, connection } = googleIcons;

  const customWidth = fullView ? 420 : 340;
  const customHeight = fullView ? 427 : 50;

  const initialPosition = {
    x: fullView
      ? fatherWidth! - customWidth - 45 ?? 50
      : fatherWidth! - customWidth - 35 ?? 50,
    y: fullView
      ? fatherHeight! / 2 - customHeight / 2 ?? 50
      : fatherHeight! / 16.8 - customHeight / 10 ?? 100,
  };

  /*
  const initialPosition = {
    x: fatherWidth! - customWidth - 45 ?? 50,
    y: fatherHeight! / 2 - customHeight / 2 ?? 50,
  };
  */

  const [newPosition, setNewPosition] = useState(initialPosition);
  const [origin, setOrigin] = useState({ x: 0, y: 0 });
  const [actualDevices, setActualDevices] = useState<DevicesSelector>("nodes");
  const [bulkActions, setBulkActions] = useState(false);

  setSelectedMarker(initialLamp.node_id);

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.setProperty("display", "none");
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const startX = event.clientX - newPosition.x;
    const startY = event.clientY - newPosition.y;
    setOrigin({ x: startX, y: startY });
  };

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    let x = event.clientX - origin.x;
    const y = event.clientY - origin.y;
    event.currentTarget.style.setProperty("display", "block");
    if (
      x > fatherWidth - customWidth ||
      y > fatherHeight - customHeight ||
      x < 0 ||
      y < 0
    ) {
      alert("Out of the map");
      //setNewPosition(initialPosition);
      return;
    }

    setNewPosition({ x, y });
  };

  const startLineWidth =
    actualDevices === "nodes"
      ? nodesSelector / 2
      : nodesSelector + 20 + gatewaysSelector / 2;
  const endLineWidth = selectorContainer - startLineWidth;

  const initialLine = startLineWidth.toString() + "px";
  const endLine = endLineWidth.toString() + "px";

  const action = actualFilter.on
    ? filterOnDevicesActionCreator(devices)
    : actualFilter.off
    ? filterOffDevicesActionCreator(devices)
    : actualFilter.alarmas
    ? filterAlarmDevicesActionCreator(devices)
    : actualFilter.disconnected
    ? filterDisconnectedDevicesActionCreator(devices)
    : actualFilter.all
    ? filteredAllDevicesActionCreator(devices)
    : null;

  const handleClose = useCallback(() => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(setIsStaticViewActionCreator(true));
    dispatch(errorToInitialStateActionCreator());
    setSelectedGroup(initialLampsGroup.group);
    if (action) dispatch(action);
    dispatch(
      setFiltersActionCreator({
        all: false,
        area: false,
        on: false,
        off: false,
        disconnected: false,
        alarmas: false,
      })
    );
    dispatch(allowFlyModeActionCreator(true));
  }, [action, dispatch, setSelectedGroup]);

  const handleBulkAction = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    /*if (event.currentTarget.id === "dimming") {
      setRangeInput(true);
      return;
    }*/

    const order = event.currentTarget.id === "on" ? 1 : 2;

    const nodesToSwitch = filteredDevices.nodes.filter(
      (node) => node.online && (order === 1 ? !node.on : node.on)
    );

    //    switchNodeGroupStatus(order, nodesToSwitch);
    sendCommandToLuminaire(nodesToSwitch, order, 0);
    setBulkActions(false);
  };

  const changeBackground = () => {
    dispatch(changeBackgroundActionCreator());
  };

  const { t } = useTranslation();

  return fullView ? (
    <GroupSelectionPopupStyled
      id="detail-popup"
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      draggable={true}
      style={{
        height: actualDevices === "nodes" ? customHeight : "336px",
        width: customWidth,
        left: `${newPosition.x}px`,
        top: `${newPosition.y}px`,
      }}
    >
      <div className="popup__button-container">
        <span className="material-symbols-rounded popup__button-container__icon move">
          {move}
        </span>
        <span
          onClick={handleClose}
          className="material-symbols-rounded popup__button-container__icon"
        >
          {close}
        </span>
      </div>
      <div className="popup__body-container">
        <h1 className="popup__title">{t(txt.devicesSelected)}</h1>
        <div
          id="selector-container"
          className="popup__devices-selector-container"
        >
          <div
            id="nodes-selector"
            className={`devices-selector-container__device-selector ${
              actualDevices === "nodes"
                ? "devices-selector-container__device-selector--selected"
                : ""
            } `}
            onClick={() => setActualDevices("nodes")}
          >
            <span className="material-symbols-rounded">light</span>
            <span>{devicesInfo.totalNodes}</span>
          </div>
          <div
            id="gateways-selector"
            className={`devices-selector-container__device-selector ${
              actualDevices === "gateways"
                ? "devices-selector-container__device-selector--selected"
                : ""
            } `}
            onClick={() => setActualDevices("gateways")}
          >
            <span className="material-symbols-rounded">router</span>
            <span>{devicesInfo.totalGateways}</span>
          </div>
          {bulkActions && (
            <article className="popup__bulk-actions-container">
              <span
                onClick={handleBulkAction}
                id="on"
                className="bulk-actions-container__action"
              >
                {t(txt.ONAllLuminaires)}
              </span>
              <span
                onClick={handleBulkAction}
                id="off"
                className="bulk-actions-container__action"
              >
                {t(txt.OFFAllLuminaires)}
              </span>
              <span
                onClick={changeBackground}
                //onClick={handleBulkAction}
                id="dimming"
                className="bulk-actions-container__action"
              >
                {t(txt.dimmingAllLuminaires)}
              </span>
            </article>
          )}
        </div>
        <div className="popup__separator-container">
          <span
            className="separator-container__start"
            style={{ width: initialLine }}
          ></span>
          <span className="material-symbols-rounded separator-container__icon">
            arrow_forward_ios
          </span>
          <span
            className="separator-container__end"
            style={{ width: endLine }}
          ></span>
        </div>
        {actualDevices === "nodes" && (
          <>
            <div className="popup__status-container">
              <span className="material-symbols-rounded status-container__icon">
                {connection}
              </span>

              <div className="status-container__details">
                <span>{t(txt.connection)}</span>
                <span>{`${devicesInfo.connectedNodes}/${
                  devicesInfo.totalNodes
                } ${t(txt.luminaires)}`}</span>
              </div>
            </div>

            <span className="popup__separator"></span>

            <div className="popup__status-container">
              <span className="material-symbols-rounded status-container__icon">
                {lightBulb}
              </span>

              <div className="status-container__details">
                <span>{t("ON")}</span>
                <span>{`${devicesInfo.onNodes}/${devicesInfo.totalNodes} ${t(
                  txt.luminaires
                )}`}</span>
              </div>
            </div>

            <span className="popup__separator"></span>

            <div className="popup__status-container">
              <span className="material-symbols-rounded status-container__icon status-container__icon--alarm ">
                {error}
              </span>

              <div className="status-container__details">
                <span>{t(txt.alarmsCapitalLetter)}</span>
                <span className="status-container__details">{`${
                  devicesInfo.alarmedNodes
                } ${t(txt.alarms)}`}</span>
              </div>
            </div>
          </>
        )}
        {actualDevices === "gateways" && (
          <>
            <div className="popup__status-container">
              <span className="material-symbols-rounded status-container__icon">
                {connection}
              </span>

              <div className="status-container__details">
                <span>{t(txt.connection)}</span>
                <span>{`${devicesInfo.connectedGateways}/${
                  devicesInfo.totalGateways
                } ${t(txt.gateways)}`}</span>
              </div>
            </div>

            <span className="popup__separator"></span>

            <div className="popup__status-container">
              <span className="material-symbols-rounded status-container__icon status-container__icon--alarm ">
                {error}
              </span>

              <div className="status-container__details">
                <span>{t(txt.alarmsCapitalLetter)}</span>
                <span className="status-container__details">{`${
                  devicesInfo.alarmedGateways
                } ${t(txt.gateways)}`}</span>
              </div>
            </div>
          </>
        )}
        <div
          className="popup__link-container"
          onClick={() => setBulkActions(!bulkActions)}
        >
          <span className="material-symbols-rounded">{googleIcons.add}</span>
          <span className="link-container__link">{t(txt.bulkActions)}</span>
        </div>
        {bulkActions && (
          <article className="popup__bulk-actions-container">
            <span
              onClick={handleBulkAction}
              id="on"
              className="bulk-actions-container__action"
            >
              {t(txt.ONAllLuminaires)}
            </span>
            <span
              onClick={handleBulkAction}
              id="off"
              className="bulk-actions-container__action"
            >
              {txt.OFFAllLuminaires}
            </span>
            <span
              onClick={changeBackground}
              //onClick={handleBulkAction}
              id="dimming"
              className="bulk-actions-container__action"
            >
              {t(txt.dimmingAllLuminaires)}
            </span>
          </article>
        )}
      </div>
    </GroupSelectionPopupStyled>
  ) : (
    <GroupPopupHybridViewStyled
      style={{
        height: actualDevices === "nodes" ? customHeight : "336px",
        width: customWidth,
        left: `${newPosition.x}px`,
        top: `${newPosition.y}px`,
      }}
    >
      <div className="hybridpopup__block">
        <h1 className="hybridpopup__block__title">{t(txt.devicesSelected)}</h1>
        <div className="hybridpopup__block__nodes">
          <span className="material-symbols-rounded hybridpopup__block__nodes--icon">
            light
          </span>
          <span className="hybridpopup__block__nodes--text">
            {devicesInfo.totalNodes}
          </span>
        </div>
        <span
          onClick={handleClose}
          className="material-symbols-rounded hybridpopup__block__close"
        >
          {close}
        </span>
      </div>
      <div
        className="hybridpopup__bulk"
        onClick={() => setBulkActions(!bulkActions)}
      >
        <span className="material-symbols-rounded hybridpopup__bulk__icon">
          {googleIcons.add}
        </span>
      </div>
      {bulkActions && (
        <article className="hybridpopup__bulk__container">
          <span
            onClick={handleBulkAction}
            id="on"
            className="hybridpopup__bulk__container--action"
          >
            {t(txt.ONAllLuminaires)}
          </span>
          <span
            onClick={handleBulkAction}
            id="off"
            className="hybridpopup__bulk__container--action"
          >
            {txt.OFFAllLuminaires}
          </span>
          <span
            onClick={changeBackground}
            id="dimming"
            className="hybridpopup__bulk__container--action"
          >
            {t(txt.dimmingAllLuminaires)}
          </span>
        </article>
      )}
    </GroupPopupHybridViewStyled>
  );
};
