import { connectToMqtt } from "./mqttService";

class AuthService {
  private webAddressPrefix: string = process.env
    .REACT_APP_LOCAL_BASE_URL as string;

  public async smartecLogin(
    email: string,
    password: string
  ): Promise<any | null> {
    try {
      const response = await fetch(`${this.webAddressPrefix}/auth/login`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const setCookieHeader = response.headers.get("Set-Cookie");
        const data = await response.json();
        console.log("data", data);
        let organizationsArray = data.orgs;
        console.log("las orgs", organizationsArray);
        let userId = data.user_id.toString();
        connectToMqtt(organizationsArray, userId); //Inside the array we have the orgIds
        // Extract cookies from the setCookieHeader if needed
        //return "success";
        console.log("Before return");
        return { result: "success", organizationsArray };
      } else {
        return null; // Invalid credentials or error
      }
    } catch (error) {
      console.error("Login error:", error);
      return null;
    }
  }

  public async smartecForgotPassword(email: string): Promise<any | null> {
    try {
      const response = await fetch(
        `${this.webAddressPrefix}/auth/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        const data = await response.json(); // Parse the response data
        return {
          data,
          error: null,
        };
      } else {
        return {
          data: null,
          error: await response.json(),
        };
      }
    } catch (error: any) {
      throw error;
    }
  }

  public async smartecResetPassword(
    password: string,
    confirmPassword: string,
    resetToken: string | null
  ): Promise<any | null> {
    try {
      const response = await fetch(
        `${this.webAddressPrefix}/auth/reset-password?token=${resetToken}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password, confirmPassword }),
        }
      );

      if (response.ok) {
        const data = await response.json(); // Parse the response data
        return {
          data,
          error: null,
        };
      } else {
        return {
          data: null,
          error: await response.json(),
        };
      }
    } catch (error: any) {
      throw error;
    }
  }
}

const authService = new AuthService();
export default authService;
