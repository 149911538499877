import { useAppSelector } from "../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import { languages } from "../../utils/languages/languages";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface StrategiesChartProps {
  chartData: any;
  borderColor: string;
  backgroundColor: string | string[];
}

function StrategiesChart({
  chartData,
  borderColor,
  backgroundColor,
}: StrategiesChartProps) {
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { t } = useTranslation();

  const times = [
    chartData[7],
    chartData[8],
    chartData[9],
    chartData[10],
    chartData[11],
    chartData[12],
    chartData[13],
  ];
  let labels = [];

  for (let i = 0; i < times.length; i++) {
    if (times[i] === "254:254") {
      if (times[i - 1] === "99:99") {
        labels[labels.length - 1] = t(txt.sunrise);
        break;
      }
      break;
    }

    if (times[i] === "99:99") {
      if (i === times.length - 1) {
        labels.push(t(txt.sunrise));
      } else {
        labels.push(t(txt.sunset));
      }
    } else {
      labels.push(times[i]);
    }
  }

  let dimmings = [
    chartData[0],
    chartData[1],
    chartData[2],
    chartData[3],
    chartData[4],
    chartData[5],
    chartData[6],
  ];
  let newData = dimmings.slice(0, labels.length);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Standard",
        data: newData,
        borderColor: borderColor,
        backgroundColor: backgroundColor + "80",
        fill: true,
        stepped: true,
        pointRadius: 3,
        pointBackgroundColor: "white",
        showLine: true,
        spanGaps: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        display: true,
        max: 100,
        min: 0,
        ticks: {
          stepSize: 25,
        },
      },
      x: {
        display: true,
        ticks: {
          stepSize: 25,
          maxTicks: 3,
        },
      },
    },
    dragData: false,
  };

  return (
    <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
      <Line options={options} data={data} />
    </div>
  );
}

export default StrategiesChart;
