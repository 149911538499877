// useUserService.tsx
import { User } from "../types/interfaces";
import { loadUserActionCreator } from "../redux/slices/user/userSlice";
import { useAppDispatch } from "../redux/hooks";
import Cookies from "js-cookie";

export const useUserService = () => {
  const webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;
  const dispatch = useAppDispatch();

  const saveTokenToStorage = (token: string) => {
    Cookies.set("token", token, { expires: 7 });
  };

  const getUserProfile = async (token: string): Promise<boolean> => {
    try {
      const response = await fetch(`${webAddressPrefix}/profile/`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const [userData] = await response.json(); // Parse JSON response once

        const user: User = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          phone_number: userData.phone_number,
          email: userData.email,
          language_name: userData.language_name,
          role_name: userData.role_name,
          user_active: userData.user_active,
        };

        dispatch(loadUserActionCreator(user));

        return true;
      } else {
        console.log("Failed to fetch user profile.", response.status);
        return false;
      }
    } catch (error) {
      console.log("Error while fetching user profile:", error);
      return false;
    }
  };

  return {
    getUserProfile,
    saveTokenToStorage,
  };
};
