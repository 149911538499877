import { useTranslation } from "react-i18next";
import { Dropdown, Menu } from "antd";
import { useState } from "react";
import { IconStyled } from "../Header/HeaderStyled";
import { TextSpan } from "../../styles/shared-styles/text-sizes";
import languageIcon from "../../assets/new_icons/Language.svg";

interface LanguageSelectorProps {
  handleMenuClick: (item: any) => void;
  items: any[];
}
const LanguageSelector = ({
  handleMenuClick,
  items,
}: LanguageSelectorProps) => {
  const { t } = useTranslation();
  const [opacityLanguage, setOpacityLanguage] = useState(0.5);

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu onClick={handleMenuClick} style={{ padding: "0px" }} >
          {items.map((item) => (
            <Menu.Item key={item.key}>
              <img
                src={item.icon}
                style={{
                  height: "16px",
                  width: "16px",
                  borderRadius: "50%",
                  marginRight: "8px",
                  cursor: "pointer"
                }}
              />
              <TextSpan textType="" color="#a3aed0">
                {t(item.label)}
              </TextSpan>
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <a onClick={(e) => e.preventDefault()}>
        <IconStyled
          src={languageIcon}
          style={{ opacity: opacityLanguage, marginTop: "-2px" }}
          onMouseEnter={() => setOpacityLanguage(1)}
          onMouseLeave={() => setOpacityLanguage(0.5)}
          alt="Language Icon"
        />
      </a>
    </Dropdown>
  );
};

export default LanguageSelector;
