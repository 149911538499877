import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GetManagerGroupState {
  orgId: string;
  organization_name: string;
  servId: string;
  servName: string;
  groups: Array<[]>;
}

const initialGetManagerGroupState: GetManagerGroupState[] = [];

const getManagerGroupSlice = createSlice({
  name: "groupService",
  initialState: initialGetManagerGroupState,
  reducers: {
    loadGetManagerGroupActionCreator: (
      state,
      action: PayloadAction<GetManagerGroupState[]>
    ) => {
      return action.payload;
    },
  },
});

export const { loadGetManagerGroupActionCreator } =
  getManagerGroupSlice.actions;

export default getManagerGroupSlice.reducer;
