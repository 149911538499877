import { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  Marker,
  useMapEvents,
} from "react-leaflet";
import bulbOff from "../../../assets/icons/icon-bulb-off.svg";
import { Icon, LatLng, LeafletMouseEvent } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setFiltersActionCreator,
  setManageAreaActionCreator,
} from "../../../redux/slices/actualFilterSlice/actualFilterSlice";
import { filteredAllDevicesActionCreator } from "../../../redux/slices/devices/filteredDevicesSlice";
import { turnAllFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import {
  setBoundsToFlyActionCreator,
  setIsStaticViewActionCreator,
  setIsOpenedDetailActionCreator,
  setIsOpenedGroupActionCreator,
  setHomeVisibleActionCreator,
  setFirstRenderMapToFalseActionCreator,
} from "../../../redux/slices/uiSlice/uiSlice";
import { ISwitchboard, Lamp } from "../../../types/interfaces";
import CustomMarker from "../CustomMarker/CustomMarker";
import { DetailPopup } from "../../DetailPopUp/DetailPopUp";
import FilterByProject from "../../FilterByProject/FilterByProject";
import selectedIcon from "../../../assets/icons/icon-selected.svg";
import { GroupSelectionPopup } from "../../GroupSelectionPopup/GroupSelectionPopup";
import { MapEventHandler } from "../../MapEventHandler/MapEventHandler";
import { CustomMapStyled } from "./CustomMapStyled";
import { FilterBarStyled } from "../../FilterBar/FilterBarStyled";
import { FilterElement } from "../../FilterBar/FilterElement/FilterElement";
import "../../../styles/index.css";
import {
  calculateDevicesInfo,
  getAllpositions,
  getAllProjects,
} from "../../../utils/auxiliaryFunctions";
import { gateway, initialLamp, initialLampsGroup } from "../../../utils/data";

import { languages } from "../../../utils/languages/languages";
import alarm from "../../../assets/new_icons/Alarm.svg";
import disconnected from "../../../assets/new_icons/Node disconnected.svg";
import OFF from "../../../assets/new_icons/OFF.svg";
import ON from "../../../assets/new_icons/ON.svg";
import selectArea from "../../../assets/new_icons/select_area.svg";
import sun from "../../../assets/new_icons/Solar.svg";
import { useTranslation } from "react-i18next";
import useSwitchBoard from "../../../hooks/useSwitchBoard/useSwitchBoard";
import SwitchBoardMarker from "../../SwitchBoard/SwitchBoardMarker/SwitchBoardMarker";
import { RootState } from "../../../redux/store";
import { setAddLocationMarkerLatLng } from "../../../redux/slices/shared-slice";
import { Spin } from "antd";

//esto es necesario?
/*interface MapBounds {
  xOriginPixel: number;
  xOriginBounds: number;
  widthPixel: number;
  widthBounds: number;
  yOriginPixel: number;
  yOriginBounds: number;
  heightPixel: number;
  heightBounds: number;
}*/

interface CustomMapProps {
  center: LatLng;
  mapSize: React.CSSProperties;
  showFilters?: boolean;
  lamps: any;
}

const markerIcon = new Icon({
  iconUrl: bulbOff,
  iconSize: [25, 25],
  shadowUrl: selectedIcon,
  shadowSize: [30, 30],
});

export const CustomMap = ({
  center,
  mapSize,
  showFilters = true,
  lamps,
}: CustomMapProps) => {
  //const { currentLanguage } = useLanguage();
  const allDevices = useAppSelector((state) => state.devices);
  const devices = useAppSelector((state) => state.filteredDevices);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const areaState = useAppSelector((state) => state.actualFilter.area);
  const { selectedNodeToAddLocation, addLocationMarkerLatLng } = useAppSelector(
    (state: RootState) => state.shared
  );
  const detailPopUp = useAppSelector((state) => state.ui.isOpenedDetail);
  const groupPopUp = useAppSelector((state) => state.ui.isOpenedGroup);
  const firstRender = useAppSelector((state) => state.ui.firstRenderMap);

  const dispatch = useAppDispatch();

  const [map, setMap] = useState(0);
  const [selectedLamp, setSelectedLamp] = useState<string>(initialLamp.node_id);
  const [nodesGroup, setNodesGroup] = useState<Array<string>>(
    initialLampsGroup.group
  );
  const [selectedSwitchBoard, setSelectedSwitchboard] =
    useState<ISwitchboard | null>(null);
  const [draw, setDraw] = useState(false);
  const [actualProject, setActualProject] = useState<Array<string>>([]);
  const { t } = useTranslation();

  let filteredLamps: Lamp[] = lamps?.filter((lamp: any) => {
    if (!actualProject.length) {
      return lamp;
    } else if (actualProject.includes(lamp.prjName)) return lamp;
  });

  const { allSwitchBoards } = useSwitchBoard();

  // useEffect(() => {
  //   if (filteredLamps?.length < 1) {
  //     //alert("No devices found");
  //     dispatch(filteredAllDevicesActionCreator(allDevices));
  //     dispatch(filteredAllDevicesActionCreator(devices));
  //     dispatch(
  //       setFiltersActionCreator({
  //         all: false,
  //         area: false,
  //         on: false,
  //         off: false,
  //         disconnected: false,
  //         alarmas: false,
  //       })
  //     );
  //     dispatch(setIsStaticViewActionCreator(true));
  //   } else {
  //     dispatch(setBoundsToFlyActionCreator(filteredLamps));
  //   }
  // }, [filteredLamps]);

  /*
  <FilterElement
    type="SelectArea"
    customStyle="none"
    style={{
      fontSize: "14px",
      backgroundColor: draw ? "beige" : "",
    }}
    icon={googleIcons.sunny}
    title={filters.SelectArea}
  />
  */

  /*<FilterElementStyled>
    <span
      style={{
        backgroundColor: draw ? "beige" : "",
      }}
      className="selectArea"
      
      title={filters.SelectArea}
      onClick={async () => {
        setDraw(!draw);
      }}
    >
      <img src={select_area} height={24} alt="select area" />
    </span>
  </FilterElementStyled>*/

  //Location add functionality
  const addMarker = (e: LeafletMouseEvent) => {
    if (!selectedNodeToAddLocation) return;
    dispatch(setAddLocationMarkerLatLng(e.latlng));
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: addMarker,
    });
    return null;
  };

  const newDrawSetState = (newValue: boolean) => {
    setDraw(newValue);
  };

  window.addEventListener("beforeunload", () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(turnAllFalseActionCreator());
    dispatch(
      setManageAreaActionCreator({
        all: false,
        area: false,
        on: false,
        off: false,
        disconnected: false,
        alarmas: false,
      })
    );
  });

  useEffect(() => {
    if (!detailPopUp && !groupPopUp) {
      setSelectedLamp(initialLamp.node_id);
      setNodesGroup(initialLampsGroup.group);
    }

    if (firstRender) {
      dispatch(setIsStaticViewActionCreator(false));
      //dispatch(setFiltersActionCreator({ all: false, area: false, on: false, off: false, disconnected: false, alarmas: false }));
      dispatch(setFirstRenderMapToFalseActionCreator());
    } else if (!detailPopUp || !groupPopUp) {
      dispatch(setIsStaticViewActionCreator(true));
    }

    if (areaState) {
      if (detailPopUp || !groupPopUp) {
        dispatch(
          setFiltersActionCreator({
            all: true,
            area: false,
            on: false,
            off: false,
            disconnected: false,
            alarmas: false,
          })
        );
        /* dispatch(filteredAllDevicesActionCreator(allDevices)) */
      }
    }

    dispatch(setHomeVisibleActionCreator());
  }, [detailPopUp, groupPopUp, dispatch, firstRender]);

  // este código me marca una zona limitada pero no puedo hacer zoom y encima se carga el tope de zoom que he puesto

  /*const southWest: LatLngExpression = [24.812, -77.627]; // Coordenadas del límite suroeste
  const northEast: LatLngExpression = [24.974, -77.625]; // Coordenadas del límite noreste
  const bounds = new LatLngBounds(southWest, northEast); // Crea el objeto de límites

  function MapBounds() {
    const ew = useMap();

    ew.fitBounds(bounds); // Establece los límites en el objeto de mapa

    return null;
  }*/
  /*<MapBounds />*/
  if (center.lat === 0 && center.lng === 0) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return (
    <CustomMapStyled id="map-container" style={mapSize}>
      <MapContainer
        className="my-custom-map"
        center={center}
        zoom={10}
        scrollWheelZoom={true}
        maxZoom={18}
        minZoom={2}
        zoomControl={false}
        /*maxBounds={bounds}*/
      >
        <TileLayer
          key={"map layer"}
          //url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="bottomright" />
        <MapClickHandler />
        {addLocationMarkerLatLng && selectedNodeToAddLocation && (
          <Marker icon={markerIcon} position={addLocationMarkerLatLng}></Marker>
        )}

        {allSwitchBoards?.map((switchboard) => (
          <div key={switchboard.id}>
            <SwitchBoardMarker
              setSelectedLamp={setSelectedLamp}
              switchboard={switchboard}
              setSelectedSwitchboard={setSelectedSwitchboard}
              selected={selectedLamp === switchboard.switchboard_id}
            ></SwitchBoardMarker>
          </div>
        ))}
        {filteredLamps && (
          <>
            <MapEventHandler
              positions={getAllpositions(filteredLamps)}
              setNodesGroup={setNodesGroup}
              lamps={filteredLamps}
              setDraw={setDraw}
              draw={draw}
              key={"map events handler"}
              setMapBounds={setMap}
            ></MapEventHandler>
          </>
        )}

        {filteredLamps && (
          <MarkerClusterGroup
            chunkedLoading
            onClick={() => {}}
            maxClusterRadius={50}
            removeOutsideVisibleBounds
            disableClusteringAtZoom={15}
            spiderfyOnMaxZoom={false}
            zoom={5}
          >
            {filteredLamps.map((lamp) => {
              return (
                <>
                  {lamp?.longitude !== "0.000000" && (
                    <CustomMarker
                      setNodesGroup={setNodesGroup}
                      key={lamp.node_id + Math.random().toString()}
                      lamp={lamp}
                      setMarker={setSelectedLamp}
                      selected={selectedLamp === lamp.node_id}
                      editable={false}
                      center={
                        new LatLng(
                          Number.parseFloat(lamp.latitude),
                          Number.parseFloat(lamp.longitude)
                        )
                      }
                    />
                  )}
                </>
              );
            })}
          </MarkerClusterGroup>
        )}
      </MapContainer>

      {detailPopUp && devices.nodes.length && (
        <DetailPopup
          setSelectedMarker={setSelectedLamp}
          setNodesGroup={setNodesGroup}
          node={
            filteredLamps.filter((lamp) => lamp.node_id === selectedLamp)[0]
          }
          switchboard={allSwitchBoards?.find(
            (switchboard) => switchboard.switchboard_id === selectedLamp
          )}
        />
      )}

      {groupPopUp && devices.nodes.length && nodesGroup.length > 0 && (
        <GroupSelectionPopup
          setSelectedMarker={setSelectedLamp}
          setSelectedGroup={setNodesGroup}
          devicesInfo={calculateDevicesInfo(
            filteredLamps.filter((node) => nodesGroup.includes(node.node_id)),
            [gateway]
          )}
        />
      )}

      {showFilters && (
        <>
          {/* <FilterByProject
            projects={getAllProjects(lamps)}
            selectedProjects={actualProject}
            setSelectedProjects={setActualProject}
          ></FilterByProject> */}

          <FilterBarStyled>
            <FilterElement
              type="selectArea"
              icon={selectArea}
              title={t("Select an area")}
              newDrawSetState={newDrawSetState}
            />
            <FilterElement type="All" icon={sun} title={t("All")} />
            <FilterElement type="ON" icon={ON} title={t("ON")} />
            <FilterElement type="OFF" icon={OFF} title={t("OFF")} />
            <FilterElement
              type="Disconnected"
              icon={disconnected}
              title={t("Disconnected")}
            />
            {/* <FilterElement
              type="Alarmed"
              icon={alarm}
              title={t(txt.alarmsCapitalLetter)}
            /> */}
          </FilterBarStyled>
        </>
      )}
    </CustomMapStyled>
  );
};
