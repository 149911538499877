import Cookies from "js-cookie";
import { GetManagerServicePayload } from "../types/interfaces";
import { useAppDispatch } from "../redux/hooks";
import { loadGetManagerServiceActionCreator } from "../redux/slices/managerUsers/getManagedUserServiceSlice";

export interface GetManagerServiceState {
  orgId: string;
  organization_name: string;
  services: Array<[]>;
}

export const useGetManagedServiceService = () => {
  const webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;
  const dispatch = useAppDispatch();

  const saveTokenToStorage = (token: string) => {
    Cookies.set("token", token, { expires: 7 });
  };

  const getManagedService = async (
    token: string,
    userId: string
  ): Promise<GetManagerServiceState[] | null> => {
    try {
      const response = await fetch(
        `${webAddressPrefix}/users/user-serv?userId=${userId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const getManagedServiceData: GetManagerServicePayload[] =
          await response.json();
        if (
          Array.isArray(getManagedServiceData) &&
          getManagedServiceData.length > 0
        ) {
          const serviceList: GetManagerServiceState[] =
            getManagedServiceData.map((orgData) => ({
              orgId: orgData.orgId || "",
              organization_name: orgData.organization_name || "",
              services: orgData.services || "",
            }));

          dispatch(loadGetManagerServiceActionCreator(serviceList));

          return serviceList;
        } else {
          console.log("No data found or unexpected response format.");
          return null;
        }
      } else {
        console.log("Failed to fetch user by ID.", response.status);

        return null;
      }
    } catch (error) {
      console.log("Error while fetching user by ID:", error);

      return null;
    }
  };

  return {
    getManagedService,
    saveTokenToStorage,
  };
};
