import { Skeleton } from "antd";
import { PersonalInformation } from "../PersonalInformation/PersonalInformation";
import leftHand from "../../../assets/left-hand.svg";
import { IconStyled, ListItem } from "../../ProfilePage/ProfilePageStyled";
import userImage from "../../../assets/user.svg";
import globeBold from "../../../assets/globe-bold.svg";
import envelope from "../../../assets/envelope.svg";
import placeholder from "../../../assets/ashley.svg";
import { GetManagedUserPayload } from "../../../types/interfaces";
import {
  CardContainer,
  TabLink,
  ProfileContainer,
  ImageProfile,
  EmptyCardContainer,
} from "../UsersStyled";
import { TextSpan, Text } from "../../../styles/shared-styles/text-sizes";
import {
  GetManagerOrganisationState,
  useGetManagedUserOrganisationService,
} from "../../../services/getManagedUserOrganisationService";
import {
  GetManagerServiceState,
  useGetManagedServiceService,
} from "../../../services/getManagedServiceService";
import {
  GetManagerGroupState,
  useGetManagedGroupService,
} from "../../../services/getManagedGroupService";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { useGetManagedUserByIdService } from "../../../services/getManagedUserByIdService";
import { RootState } from "../../../redux/store";
import { useAppSelector } from "../../../redux/hooks";
import { Services } from "../Services_Users/Services";
import { Organizations } from "../Organizations/Organizations";
import { Groups } from "../Groups/Groups";
import { useTranslation } from "react-i18next";

interface ActiveUsersDeatilsProps {
  userRole: string;
}
interface UserAvatarProps {
  firstName: string;
}

export const ActiveUsersDeatils = ({ userRole }: ActiveUsersDeatilsProps) => {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const { getManagedUserById } = useGetManagedUserByIdService();
  const { getManagedUserOrganisation } = useGetManagedUserOrganisationService();
  const { getManagedService } = useGetManagedServiceService();
  const { getManagedGroup } = useGetManagedGroupService();
  const { t } = useTranslation();

  const activeUser = useAppSelector(
    (state: RootState) => state.getManagedUserById
  );

  const org = useAppSelector(
    (state: RootState) => state.getManagerUsersOrganisation
  );

  const grp = useAppSelector((state: RootState) => state.getManagerGroup);

  const serv = useAppSelector((state: RootState) => state.getManagerService);

  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const token = Cookies.get("token");

  useEffect(() => {
    if (token && id) {
      setIsLoading(true);
      getManagedUserById(token, id)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    const token = Cookies.get("token");

    if (token && id) {
      getManagedUserOrganisation(token, id)
        .then((data: any) => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    const token = Cookies.get("token");

    if (token && id) {
      getManagedService(token, id)
        .then((data: any) => {
          setIsLoading(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    const token = Cookies.get("token");

    if (token && id) {
      getManagedGroup(token, id)
        .then(() => {})
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  const user = activeUser[0];
  const getRandomColor = (): string => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const UserAvatar: React.FC<UserAvatarProps> = ({ firstName }) => {
    const avatarStyle: React.CSSProperties = {
      backgroundColor: getRandomColor(),
      width: "72px",
      height: "72px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",
      fontWeight: "bold",
      color: "#ffffff",
    };
    const firstLetter: string = firstName.charAt(0).toUpperCase();

    return <div style={avatarStyle}>{firstLetter}</div>;
  };

  return (
    <>
      {isLoading ? (
        <CardContainer className="d-flex flex-column h-100">
          <Skeleton active />
        </CardContainer>
      ) : (
        <>
          {id ? (
            <CardContainer className="d-flex flex-column h-100">
              <>
                <div className="row" style={{ marginBottom: "32px" }}>
                  <div className="col-1">
                    <UserAvatar firstName={user?.first_name} />
                    {/* <ImageProfile src={placeholder} alt="Profile Picture" />{" "} */}
                  </div>
                  <div className="col-4">
                    <div className="row" style={{ marginBottom: "0px" }}>
                      <div className="col-12">
                        <Text textType="subTitle" fontWeight="700">
                          {user.first_name} {user.last_name}
                        </Text>
                      </div>
                    </div>

                    <div className="row">
                      <ul style={{ display: " inline-flex" }}>
                        <ListItem>
                          <IconStyled src={userImage} height={12} />{" "}
                          <TextSpan>{user.role_name}</TextSpan>
                        </ListItem>

                        <ListItem>
                          <IconStyled src={envelope} height={16} />{" "}
                          <TextSpan>{user.email}</TextSpan>
                        </ListItem>

                        <ListItem>
                          <IconStyled src={globeBold} height={14} />{" "}
                          <TextSpan>{user.language_name}</TextSpan>
                        </ListItem>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <ul
                      className="nav nav-pills"
                      style={{
                        marginBottom: "16px",
                        borderBottom: "1px solid #e7e0ec",
                        width: "100%",
                      }}
                    >
                      <li className="nav-item">
                        <TabLink
                          className="nav-link active"
                          id="personal-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#personal"
                          type="button"
                          role="tab"
                          aria-controls="personal"
                          aria-selected="true"
                        >
                          {t("Personal Information")}
                        </TabLink>
                      </li>
                      <li className="nav-item">
                        <TabLink
                          className="nav-link"
                          id="organizations-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#organizations"
                          type="button"
                          role="tab"
                          aria-controls="organizations"
                          aria-selected="false"
                        >
                          {t("Organizations")}
                        </TabLink>
                      </li>
                      <li className="nav-item">
                        <TabLink
                          className="nav-link"
                          id="services-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#services"
                          type="button"
                          role="tab"
                          aria-controls="services"
                          aria-selected="false"
                        >
                          {t("Services")}
                        </TabLink>
                      </li>
                      <li className="nav-item">
                        <TabLink
                          className="nav-link"
                          id="groups-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#groups"
                          type="button"
                          role="tab"
                          aria-controls="groups"
                          aria-selected="false"
                        >
                          {t("Groups")}
                        </TabLink>
                      </li>
                      {/* <li className="nav-item">
                        <TabLink
                          className="nav-link"
                          id="users-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#users"
                          type="button"
                          role="tab"
                          aria-controls="users"
                          aria-selected="false"
                        >
                          {t("Users")}
                        </TabLink>
                      </li> */}
                    </ul>
                    <div
                      className="tab-content"
                      id="myTabContent"
                      style={{ marginBottom: "32px" }}
                    >
                      <div
                        className="tab-pane fade show active"
                        id="personal"
                        role="tabpanel"
                        aria-labelledby="personal-tab"
                      >
                        <PersonalInformation
                          userDataInfo={user}
                          userRole={userRole}
                        />
                      </div>
                      <div
                        className="tab-pane fade show"
                        id="organizations"
                        role="tabpanel"
                        aria-labelledby="organizations-tab"
                      >
                        <Organizations OrganizationData={org} />
                      </div>
                      <div
                        className="tab-pane fade show"
                        id="services"
                        role="tabpanel"
                        aria-labelledby="services-tab"
                      >
                        <Services serviceData={serv} />
                      </div>
                      <div
                        className="tab-pane fade show"
                        id="groups"
                        role="tabpanel"
                        aria-labelledby="groups-tab"
                      >
                        <Groups groupsData={grp} />
                      </div>
                    </div>
                    {/* <div className="row" style={{ float: "right" }}>
                      <div className="col-3">
                        <ButtonOutlined>{t("Update")}</ButtonOutlined>
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            </CardContainer>
          ) : (
            <EmptyCardContainer>
              <div className="row">
                <div className="col-2">
                  <img src={leftHand} />
                </div>
                <div className="col-10">
                  <Text textType="title" fontWeight="700" marginBottom="0">
                    {t("Select a user to see the information")}
                  </Text>
                  <Text textType="caption" color="#A3AED0">
                    {t(
                      "You can check the information of the users you are managing and changing their permissions."
                    )}
                  </Text>
                </div>
              </div>
            </EmptyCardContainer>
          )}
        </>
      )}
    </>
  );
};

function getManagedUserById(token: string, selectedUserId: any) {
  throw new Error("Function not implemented.");
}
function getManagedUserOrganisation(token: string, selectedUserId: any) {
  throw new Error("Function not implemented.");
}
