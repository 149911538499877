import styled from "styled-components";

export const DimmingInputStyled = styled.section`
  .dimming {
    &__progress-bar {
        width: 100%;
        height: 12px;
        margin-top: 6px;
        margin-bottom: 6px;
        -webkit-appearance: none;
        border-radius: 10px;
        position: relative;
        cursor: pointer;

        ::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 20px;
            height: 20px;
            background-color: #ffc107;
            cursor: pointer;
            border-radius: 50%;
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
                0px 1px 3px rgba(0, 0, 0, 0.3);
        }
    }
  }
`;
