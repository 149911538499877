import { useEffect } from "react";
import { useMap } from "react-leaflet";
import "leaflet-draw"; // Import the Leaflet Draw library
import "leaflet-draw/dist/leaflet.draw.css"; // Import the CSS for Leaflef
import "leaflet-draw/dist/leaflet.draw-src";
import L from "leaflet";

interface IProps {
  markers: any;
  setNodes: any;
  nodes: any;
}
const DrawTool = ({ markers, setNodes, nodes }: IProps) => {
  const map = useMap();
  const handleShapeDrawn = (e: any) => {
    const drawnBounds = e.layer.getBounds();

    // Set an array containing all the markers within the drawn bounds
    const includedMarkers = markers.filter((marker: any) => {
      const markerPosition = L.latLng(marker.latitude, marker.longitude);
      // if (drawnBounds.contains({ markerPosition })) {
      //   return markerPosition;
      // }
      return drawnBounds.contains(markerPosition);
    });
    setNodes((prev: any[]) => [...includedMarkers, ...prev]);
    // queryClient.invalidateQueries("getSingleFeeder");
  };

  useEffect(() => {
    const drawOptions: any = {
      draw: {
        marker: false,
        polygon: false,
        polyline: false,
        rectangle: { showArea: false },
        circle: false,
        circlemarker: false,
      },
      edit: false,
    };

    const drawControl = new L.Control.Draw(drawOptions);
    map.addControl(drawControl);

    // L.Rectangle.include({
    //   contains: function (markersToCheck: any) {
    //     if (!Array.isArray(markersToCheck)) {
    //       markersToCheck = [markersToCheck];
    //     }

    //     let markersContained: any = [];
    //     markersToCheck.forEach((marker: any) => {
    //       markersContained.push(this.getBounds().contains(marker));
    //     });
    //     console.log("here");
    //     return markersContained;
    //   },
    // }); //seems not needed

    map.on(L.Draw.Event.CREATED, handleShapeDrawn);

    return () => {
      map.removeControl(drawControl);
      map.off(L.Draw.Event.CREATED, handleShapeDrawn);
    };
  }, [map, markers]);

  return null;
};

export default DrawTool;
