import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Devices, Lamp } from "../../../types/interfaces";

const initialDevices: Devices = {
  gateways: [],
  nodes: [],
  unpositionedNodes: [],
  organizations: [],
  selectedOrganizationId: 0,
  services: [],
  selectedServiceId: 0
};

const filteredDevicesSlice = createSlice({
  name: "filteredDevices",
  initialState: initialDevices,
  reducers: {
    filteredAllDevices: (previousState, action: PayloadAction<Devices>) => ({
      ...action.payload,
    }),

    filteredNewOrderDevices: (previousState, action) => ({
      ...previousState,
      nodes: action.payload
    }),

    filterOnDevices: (previousState, action: PayloadAction<Devices>) => ({
      ...previousState,
      nodes: action.payload.nodes.filter((node) => node.online && node.on),
    }),

    filterOffDevices: (previousState, action: PayloadAction<Devices>) => ({
      ...previousState,
      nodes: action.payload.nodes.filter((node) => node.online && !node.on),
    }),

    filterConnectedDevices: (previousState, action: PayloadAction<Devices>) => ({
      ...previousState,
      nodes: action.payload.nodes.filter((node: any) => node.online)
    }),
    
    /*filterConnectedDevices: (previousState, action: PayloadAction<Devices>) => {
      console.log('filterDisconnectedDevices called');
      console.log('previousState:', previousState);
      console.log('action.payload:', action.payload);
      console.log('action.payload.nodes:', action.payload.nodes);
    
      return {
      ...previousState,
      nodes: action.payload.nodes.filter((node: any) => node.online) 
      };
    },*/

    filterDisconnectedDevices: (previousState, action: PayloadAction<Devices>) => ({
      ...previousState,
      nodes: action.payload.nodes.filter((node: any) => !node.online)
    }),
    
    filterAlarmDevices: (previousState, action: PayloadAction<Devices>) => ({
      ...previousState,
      nodes: action.payload.nodes.filter((node) => !node.alarm_status),
      //nodes: action.payload.nodes.filter((node) => node.alarm_status),
    }),

    filterByProjectName: (previousState, action: PayloadAction<string>) => ({
      ...previousState,
      /* nodes: previousState.nodes.filter((node) =>
        action.payload === "All" ? node : node.prjName === action.payload
      ), */
    }),

    filterByArea: (previousState, action: PayloadAction<Lamp[]>) => ({
      ...previousState,
      nodes: action.payload,
    }),

    updateNodeDeviceStatusRedux: (previousState, action) => {
      const nodeToUpdate = previousState.nodes.find(
        (node) => node.node_id === action.payload.node_id
      );

      console.log("payload redux")
      console.log(action.payload)

      previousState.nodes.forEach((node) => {
        if (nodeToUpdate !== undefined) {
          if (node.node_id === nodeToUpdate.node_id) {
            if(action.payload.msg_type === 2) {
              node.on = 0;
              node.online = 1;
            }
            else if(action.payload.msg_type === 1) {
              node.on = 1;
              node.online = 1;
            }
            else if(action.payload.msg_type === 3) {
              node.on = 1;
              node.bri = action.payload.value;
              node.online = 1;
            }
            else if(action.payload.msg_type === 4) {
              node.on = 0;
              node.online = 0;
            }
          }
        }  
      });
    },

    updateNodeDeviceStatus: (previousState, action: PayloadAction<Lamp>) => {
      const nodeToUpdate = previousState.nodes.findIndex(
        (node) => node.node_id === action.payload.node_id
      );
      
      console.log("payload")
      console.log(action.payload.selected)
      console.log("previo")
      console.log(previousState.nodes[0].selected)

      previousState.nodes.forEach((node, index) => {
        /*if (index === nodeToUpdate) {
          let manageNode = node.on === 0 ? 1 : 0;
          node.on = manageNode;

          //let manageSelect = node.selected === false ? true : false
          //node.selected = manageSelect
        }*/

        if (index === nodeToUpdate) {
          node.on = action.payload.on;
          node.selected = action.payload.selected;
          node.bri = action.payload.bri;
        }
      });
    },
  },
});

export const filteredDevicesReducer = filteredDevicesSlice.reducer;

export const {
  filteredAllDevices: filteredAllDevicesActionCreator,
  filteredNewOrderDevices: filteredNewOrderDevicesActionCreator,
  filterOnDevices: filterOnDevicesActionCreator,
  filterOffDevices: filterOffDevicesActionCreator,
  filterConnectedDevices: filterConnectedDevicesActionCreator,
  filterDisconnectedDevices: filterDisconnectedDevicesActionCreator,
  filterAlarmDevices: filterAlarmDevicesActionCreator,
  filterByProjectName: filterByProjectNameActionCreator,
  filterByArea: filterByAreaActionCreator,
  updateNodeDeviceStatus: updateNodeDeviceStatusActionCreator,
  updateNodeDeviceStatusRedux: updateNodeDeviceStatusReduxActionCreator
} = filteredDevicesSlice.actions;
