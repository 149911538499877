import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { ISwitchboard } from "../../../types/interfaces";
import switchboardSvg from "../../../assets/icons/switchboard.svg";
import selectedIcon from "../../../assets/icons/icon-selected.svg";
import { Icon, LatLng } from "leaflet";
import { useAppDispatch } from "../../../redux/hooks";
import { turnAllFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import {
  setIsOpenedDetailActionCreator,
  setIsOpenedGroupActionCreator,
} from "../../../redux/slices/uiSlice/uiSlice";
import { Marker } from "react-leaflet";
interface IProps {
  setSelectedSwitchboard: Dispatch<SetStateAction<ISwitchboard | null>>;
  setSelectedLamp: Dispatch<SetStateAction<string>>;
  switchboard: ISwitchboard;
  selected: boolean;
}
const SwitchBoardMarker = ({
  setSelectedSwitchboard,
  switchboard,
  setSelectedLamp,
  selected,
}: IProps) => {
  const selectedSwitchboardIcon = new Icon({
    iconUrl: switchboardSvg,
    iconSize: [20, 20],
    shadowUrl: selectedIcon,
    shadowSize: [40, 40],
  });
  const switchboardIcon = new Icon({
    iconUrl: switchboardSvg,
    iconSize: [20, 20],
  });
  const dispatch = useAppDispatch();
  const switchboardEventHandler = useMemo(
    () => ({
      click() {
        // setSelectedLamp("");
        setSelectedLamp(switchboard.switchboard_id);
        dispatch(setIsOpenedDetailActionCreator(true));
        dispatch(setIsOpenedGroupActionCreator(false));
        dispatch(turnAllFalseActionCreator());
      },
    }),
    []
  );
  return (
    <Marker
      eventHandlers={switchboardEventHandler}
      icon={selected ? selectedSwitchboardIcon : switchboardIcon}
      position={new LatLng(+switchboard.latitude, +switchboard.longitude)}
    ></Marker>
  );
};

export default SwitchBoardMarker;
