import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import { filterReducer } from "./slices/actualFilterSlice/actualFilterSlice";
import { devicesReducer } from "./slices/devices/devicesSlice";
import { filteredDevicesReducer } from "./slices/devices/filteredDevicesSlice";
import { graphicsReducer } from "./slices/graphicsSlice/graphicsSlice";
import { manageNodeReducer } from "./slices/node/manageNodeSlice";
import { strategiesReducer } from "./slices/strategiesSlice/strategiesSlice";
import { uiDataReducer } from "./slices/uiSlice/uiSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { mqttReducer } from "./slices/mqttSlice/mqttSlice";
import userReducer from "./slices/user/userSlice";
import thunk, { ThunkMiddleware } from "redux-thunk";
import passwordReducer from "./slices/profile/passwordSlices";
import updatePersonalInfoReducer from "./slices/profile/updatePersonalInformationSlices";
import updateLanguageReducer from "./slices/profile/updateLanguageSlice";
import logoutReducer from "./slices/logout/logoutSlice";
import languageReducer from "./slices/languageSlice/languageSlice";
import getManagerUsersReducer from "./slices/managerUsers/getManagerUsersSlice";
import getManagedUserByIdReducer from "./slices/managerUsers/getManagedUserByIdSlice";
import getManagerUsersOrganisationReducer from "./slices/managerUsers/getManagerUsersOrgSlice";
import getManagerServiceReducer from "./slices/managerUsers/getManagedUserServiceSlice";
import getManagerGroupReducer from "./slices/managerUsers/getManagedUserGroupSlice";
import updateManagedUserPersoInfoReducer from "./slices/managerUsers/updateManagedUserPersoInfoSlice";
import sharedSlice from "./slices/shared-slice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  actualFilter: filterReducer,
  devices: devicesReducer,
  filteredDevices: filteredDevicesReducer,
  ui: uiDataReducer,
  graphics: graphicsReducer,
  mqtt: mqttReducer,
  user: userReducer,
  strategies: strategiesReducer,
  password: passwordReducer,
  updatePersonalInfo: updatePersonalInfoReducer,
  updateLanguage: updateLanguageReducer,
  logout: logoutReducer,
  language: languageReducer,
  getManagerUser: getManagerUsersReducer,
  getManagedUserById: getManagedUserByIdReducer,
  manageNode: manageNodeReducer,
  getManagerUsersOrganisation: getManagerUsersOrganisationReducer,
  getManagerService: getManagerServiceReducer,
  getManagerGroup: getManagerGroupReducer,
  updateManagedUserPersoInfo: updateManagedUserPersoInfoReducer,
  shared: sharedSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // middleware: [thunk as ThunkMiddleware],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// In the updated configuration I provided, the thunk middleware is used
// with the correct type (ThunkMiddleware), and we can remove the AppThunk
//  type definition because it's already included in the redux-thunk package.
// The ThunkMiddleware type handles the correct typing for thunks,
//  and we no longer need to define it separately.

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// CÓDIGO CON DATA QUE NO PERSISTE
/*import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { filterReducer } from "./slices/actualFilterSlice/actualFilterSlice";
import { devicesReducer } from "./slices/devices/devicesSlice";
import { filteredDevicesReducer } from "./slices/devices/filteredDevicesSlice";
import { uiDataReducer } from "./slices/uiSlice/uiSlice";

export const store = configureStore({
  reducer: {
    devices: devicesReducer,
    filteredDevices: filteredDevicesReducer,
    ui: uiDataReducer,
    actualFilter: filterReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;*/
