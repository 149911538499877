import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { AppThunk } from "../../store";
import logoutService from "../../../services/logoutService";

interface LogoutState {
  isLoading: boolean;
  error: string | null;
}

const initialState: LogoutState = {
  isLoading: false,
  error: null,
};

const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    logoutFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { logoutStart, logoutSuccess, logoutFailure } =
  logoutSlice.actions;

export const logout = (): AppThunk => async (dispatch) => {
  try {
    dispatch(logoutStart());

    // Make the API call to log out
    const token = Cookies.get("token");

    if (!token) {
      throw new Error("Token not found.");
    }

    // Call the logoutService to perform the logout
    await logoutService.logout(token);

    // Clear the token from the cookies
    Cookies.remove("token");

    dispatch(logoutSuccess());
  } catch (error: any) {
    dispatch(logoutFailure(error.message as string));
  }
};

export default logoutSlice.reducer;
