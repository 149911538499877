import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import updatePasswordService from "../../../services/updatePasswordService";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface PasswordState {
  isLoading: boolean;
  error: string | null;
}

const initialState: PasswordState = {
  isLoading: false,
  error: null,
};

const passwordSlice = createSlice({
  name: "password",
  initialState,
  reducers: {
    updatePasswordStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    updatePasswordSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    updatePasswordFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordFailure,
} = passwordSlice.actions;

export const updatePassword =
  (
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(updatePasswordStart());

      // Validate the new password and confirm password
      if (newPassword !== confirmPassword) {
        throw new Error("New password and confirm password do not match.");
      }

      // Check if the current password input is empty
      if (!currentPassword || currentPassword.trim() === "") {
        throw new Error("Please enter your current password.");
      }

      // Fetch the token from cookies
      const token = Cookies.get("token");

      // If the token is undefined, handle the error
      if (!token) {
        throw new Error("Token not found.");
      }

      // Call the updatePasswordService to update the password
      await updatePasswordService.updatePasswordWithToken(
        token,
        currentPassword,
        newPassword,
        confirmPassword
      );

      dispatch(updatePasswordSuccess());
      return "Password updated successfully!";
    } catch (error: any) {
      dispatch(updatePasswordFailure(error.message as string));

      throw error;
    }
  };

export default passwordSlice.reducer;
