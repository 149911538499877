import { useState } from "react";
import { Text } from "../../../styles/shared-styles/text-sizes";
import { GetManagedUserPayload } from "../../../types/interfaces";
import { SelectOption, OptionSelect } from "../UsersStyled";
import {
  ButtonLoading,
  ButtonOutlined,
} from "../../../styles/shared-styles/buttons-sizes";
import Cookies from "js-cookie";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

interface PersonalInformationProps {
  userDataInfo: GetManagedUserPayload;
  userRole: string;
}

export const PersonalInformation = ({
  userDataInfo,
  userRole,
}: PersonalInformationProps) => {
  const [name, setName] = useState(userDataInfo.first_name);
  const [surname, setSurname] = useState(userDataInfo.last_name);
  const [email, setEmail] = useState(userDataInfo.email);
  const [role, setRole] = useState(userDataInfo.role_name);
  const [language, setLanguage] = useState(userDataInfo.language_name);
  const statusList = [
    { value: userDataInfo.user_active },
    { value: userDataInfo.user_active === "Y" ? "N" : "Y" },
  ];

  const [activeStatus, setActiveStatus] = useState(statusList[0]);
  const [isLoading, setIsLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleNameChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setName(e.target.value);
  };
  const handleSurnameChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSurname(e.target.value);
  };
  const handleEmailChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
  };

  const handleActiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setActiveStatus({ value: e.target.value });
  };
  const handleRoleChange = (value: string) => {
    // Handle the select change event here
    setRole(value);
    console.log({ value });
  };

  const handleUpdateClick = async (userId: any) => {
    setIsLoading(true);
    const token = Cookies.get("token");
    setError(null);
    setSuccessMessage(null);
    const updatedUserData = {
      first_name: name,
      last_name: surname,
      phone_number: "+25078000000",
      language_name: "English",
      role_name: role,
      user_active: activeStatus.value,
    };

    const mainAPIURL = process.env.REACT_APP_API_LOCAL_BASE_URL!;

    try {
      fetch(`${mainAPIURL}/users/user-pi?userId=${userId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer  ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedUserData),
        credentials: "include",
      });
      setIsLoading(false);
      setSuccessMessage(`Updated successfully ${updatedUserData.first_name}`);
    } catch (error: any) {
      setError("An error occurred while updating.");
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="row" style={{ marginBottom: "32px" }}>
        <div className="col-12">
          <Text textType="subTitle" fontWeight="700" marginBottom="8px">
            {t("Personal Information")}
          </Text>
        </div>
        <div className="col-4">
          <Text marginBottom="8px" fontWeight="400">
            {t("First name")}
          </Text>
          <input
            className="form-control"
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div className="col-4">
          <Text marginBottom="8px" fontWeight="400">
            {t("Last name")}
          </Text>
          <input
            className="form-control"
            value={surname}
            onChange={handleSurnameChange}
          />
        </div>
        <div className="col-4">
          <Text marginBottom="8px" fontWeight="400">
            {t("Email")}
          </Text>
          <input className="form-control" value={email} disabled />
        </div>
      </div>
      <div className="row" style={{ marginBottom: "32px" }}>
        <div className="col-12">
          <Text textType="subTitle" fontWeight="700" marginBottom="8px">
            Role & Language
          </Text>
        </div>
        <div className="col-4">
          <Text marginBottom="8px" fontWeight="400">
            {t("Role")}
          </Text>
          <Select
            defaultValue={role}
            style={{ width: "100%" }}
            onChange={handleRoleChange}
            disabled={userRole === "observer" || userRole === "mantainance"}
            options={[
              {
                value: "owner",
                label: "Owner",
                disabled:
                  role === "owner" ||
                  userRole === "admin" ||
                  userRole === "observer" ||
                  userRole === "mantainance",
              },
              {
                value: "admin",
                label: "Admin",
                disabled:
                  role === "admin" ||
                  userRole === "observer" ||
                  userRole === "mantainance",
              },
              {
                value: "mantainance",
                label: "Mantainance",
                disabled: role === "mantainance",
              },
              {
                value: "observer",
                label: "Observer",
                disabled: role === "observer",
              },
            ]}
          ></Select>
        </div>
        <div className="col-4">
          <Text marginBottom="8px" fontWeight="400">
            Default Language
          </Text>
          <input className="form-control" value={language} disabled />
          {/* <SelectOption className="form-select" aria-label="Default example">
            <OptionSelect selected label="English">
              English
            </OptionSelect>
            <OptionSelect value="1" label="OptionSelect 1">
              OptionSelect 1
            </OptionSelect>
            <OptionSelect value="2" label="OptionSelect 2">
              OptionSelect 2
            </OptionSelect>
            <OptionSelect value="3" label="OptionSelect 3">
              OptionSelect 3
            </OptionSelect>
          </SelectOption> */}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Text textType="subTitle" fontWeight="700" marginBottom="8px">
            {t("Account")}
          </Text>
        </div>
        <div className="col-4">
          <Text marginBottom="8px" fontWeight="400">
            Active
          </Text>

          <SelectOption
            className="form-select"
            aria-label="Default select example"
            value={activeStatus.value}
            onChange={handleActiveChange}
          >
            {statusList?.map((status) => (
              <OptionSelect
                value={status.value}
                label={status.value === "Y" ? "Yes" : "No"}
              >
                {status.value}
              </OptionSelect>
            ))}
          </SelectOption>
        </div>
      </div>
      <div className="row" style={{ margin: "16px 0", float: "right" }}>
        <div className="col-3">
          {isLoading ? (
            <ButtonLoading type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading
            </ButtonLoading>
          ) : (
            <ButtonOutlined onClick={() => handleUpdateClick(userDataInfo.id)}>
              Update
            </ButtonOutlined>
          )}
          {successMessage && (
            <Text color="green" textType="">
              {successMessage}
            </Text>
          )}
          {error && <Text color="red">{error}</Text>}
        </div>
      </div>
    </>
  );
};
