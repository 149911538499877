import styled from "styled-components";
import { styledMainTheme } from "../../../styles/shared-styles/styleMainTheme";

export const TextOption = styled.option`
  color: "#959595";
  font-size: 12px;
  cursor: pointer;
`;

export const InputField = styled.input`
  display: block;
  width: 100%;
  height: 36px;
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const SelectField = styled.select`
  display: block;
  width: 100%;
  padding: 8px;
  height: 36px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #959595;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
`;

export const TextAreaField = styled.textarea`
  display: block;
  width: 100%;
  padding: 8px;
  height: 96px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #959595;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const CardContainer = styled.div`
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
    0px 1px 3px 1px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  margin-bottom: 16px;
`;

export const CancelButton = styled.button`
  padding: 8px 32px;
  background-color: transparent;
  color: #a3aed0;
  font-size: 14px;
  border: none;
  font-weight: 500;
  border: 1px solid #a3aed0;
  border-radius: 4px;
`;

export const NextButton = styled.button`
  padding: 8px 32px;
  background-color: #1B2559;
  color: #ffffff;
  font-size: 14px;
  border: 1px solid #1B2559;
  font-weight: 500;
  border-radius: 4px;
`;
export const ButtonStrategy = styled.button`
  padding: 8px 48px;
  border-radius: 4px;
  background-color: ${(props: {
    buttonType: "primary" | "secondary" | "link" | "";
  }) =>
    props.buttonType === "primary"
      ? styledMainTheme.light.buttonMainColor
      : props.buttonType === "secondary"
      ? styledMainTheme?.light?.mainBackgroundColor
      : "red"};
  color: #1b2559;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  border: none;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
`;
