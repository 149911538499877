import { AxiosResponse } from "axios";
import { T_Response } from "../types/api.types";
import { ISwitchboard, SwitchBoardPayload } from "../types/interfaces";
import axiosInstance from "../shared/axios";

export const getSwitchBoards = async (payload: SwitchBoardPayload) => {
  try {
    const { organizationId, serviceId } = payload;
    const response: AxiosResponse<T_Response<ISwitchboard[]>> =
      await axiosInstance.get("/api/switchboards", {
        params: {
          organizationId,
          serviceId,
        },
      });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSingleSwitchBoard = async (id: string) => {
  const response: AxiosResponse<ISwitchboard> = await axiosInstance.get(
    `/api/switchboards/${id}`
  );
  return response.data;
};
