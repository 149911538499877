import { useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { CardStyled } from "./ProfilePageStyled";
import { Text } from "../../styles/shared-styles/text-sizes";
import { ButtonOutlined } from "../../styles/shared-styles/buttons-sizes";
import {
  updatePersonalInformation,
  updatePersonalInformationStart,
  updatePersonalInformationFailure,
} from "../../redux/slices/profile/updatePersonalInformationSlices";
import { useTranslation } from "react-i18next";

interface UserProps {
  first_name: string;
  last_name: string;
  phone_number: string;
}
interface PersonalInformationProps {
  user: UserProps;
}
export const PersonalInformation = ({ user }: PersonalInformationProps) => {
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [phoneNumber, setPhoneNumber] = useState(
    user.phone_number || "+34 000 000"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const handleFirstNameChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLastName(e.target.value);
  };

  const handlePhoneNumberChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPhoneNumber(e.target.value);
  };

  // const handleUpdateNameClick = () => {
  //   console.log("start");
  //   console.log("all", firstName, lastName, phoneNumber);
  //   dispatch(updatePersonalInformation(firstName, lastName, phoneNumber));
  //   console.log("done");
  // };
  const handleUpdateNameClick = async () => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      // Dispatch the updatePersonalInformation action and await its completion
      await dispatch(
        updatePersonalInformation(firstName, lastName, phoneNumber)
      );
      setIsLoading(false);
      setSuccessMessage("Updated successfully");
    } catch (error) {
      setIsLoading(false);
      setError("An error occurred while updating.");
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <CardStyled>
        <Text
          textType="subTitle"
          fontWeight="700"
          color="background: #1B2559;
"
        >
          {t("Personal Information")}
        </Text>
        <div className="row" style={{ marginBottom: "16px" }}>
          <div className="col-6">
            <Text marginBottom="8px">{t("First name")}</Text>
            <input
              className="form-control"
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </div>
          <div className="col-6">
            <Text marginBottom="8px">{t("Last name")}</Text>
            <input
              className="form-control"
              value={lastName}
              onChange={handleLastNameChange}
            />
          </div>
        </div>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-6">
            <Text marginBottom="8px">{t("Phone Number")}</Text>
            <input
              className="form-control"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
          </div>
          <div
            className="col-6"
            style={{ textAlign: "right", alignSelf: "end" }}
          >
            {/* {!isLoading ? (
              <>
                <ButtonOutlined onClick={handleUpdateNameClick}>
                 {t("Update Name")}
                </ButtonOutlined>
              </>
            ) : (
              <Text color="#1B2559">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </Text>
            )} */}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {" "}
            {successMessage && (
              <Text color="green" textType="">
                {successMessage}
              </Text>
            )}
            {error && <Text color="red">{error}</Text>}
          </div>
        </div>
      </CardStyled>
    </>
  );
};
