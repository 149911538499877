export const initialLamp = {
  alarm_status: 0,
  bri: 0,
  on: 0,
  latitude: "",
  longitude: "",
  active: "Y",
  node_id: "",
  name: "",
  orgName: "",
  organizationId: "",
  selected: false,
  service: "",
  solar_id: null,
  online: 0,
  //SHUNCOM
  addr: "",
  alarm_id: "",
  gateway_addr: "",
  pEnergy: "",
  prjName: "",
  projectId: "",
  uid: ""
};

export const initialLampsGroup = {
  group: []
};

export const gateway = {
  addDate: "2022-11-24 11:10:33",
  addUser: "Smartecadmin",
  addr: "0xfcfcbd19",
  addrStr: "FCFCBD19",
  advName: "Salvi Lighting",
  alarm_id: "",
  alarm_status: 0,
  box_code: "DC4122D409",
  dblControlZigbeeLamp: false,
  divisionId: "5d36c6dec0303209bd1dad97",
  enable_alarm: "0",
  encryption: 1,
  formula: "1",
  isGateway4: true,
  isZigBeeCloud: 0,
  latitudeMap: "",
  longitudeMap: "",
  modifyDate: "2022-11-28 11:40:13",
  modifyUser: "Smartecadmin",
  name: "GW_KGL_smartec",
  offline_interval: 360,
  online: 0,
  orgName: "Smartec",
  organizationId: "5d36c6e6c0303209bd1dad98",
  poll_interval: 120,
  prjName: "Salvi Lighting",
  projectId: "5d36c6f7c0303209bd1dad99",
  register_pkg: "DC4122D409",
  section_count: 4,
  sections: [],
  type: 2,
  typeName: "M26网关",
  uid: "637f3509181e9f0adec52c57",
};

//localStorage.setItem("lamps", JSON.stringify(lamps));
