import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { resetStrategyActionCreator } from "../../../../redux/slices/strategiesSlice/strategiesSlice";
import { useStrategiesService } from "../../../../services/strategiesService";
import { Dimming } from "../Step2Dimming/Dimming";
import DimmingChart from "../Step2Dimming/DimmingChart";
import {
  parseLabelsAndData,
  getHoursAndValues,
  calculateEnergySaving,
} from "../../StrategiesLogic";
import { languages } from "../../../../utils/languages/languages";
import {
  CardContainer,
  CancelButton,
  ButtonStrategy,
} from "../StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import { useTranslation } from "react-i18next";

interface ReviewProps {
  onHide?: any;
  setShowDetails?: any;
  setShowDimming?: any;
  showDimming?: any;
  setShowReview?: any;
  setNewChartData?: any;
  newChartData?: any;
}

export const Review = ({
  onHide,
  setShowDetails,
  setShowDimming,
  showDimming,
  setShowReview,
  setNewChartData,
  newChartData,
}: ReviewProps) => {
  const dispatch = useAppDispatch();
  const { postStrategy, getStrategy } = useStrategiesService();
  const selectedOrgId: any = useAppSelector(
    (state) => state.devices.selectedOrganizationId
  );
  const selectedServiceId: any = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const newStrategy = useAppSelector((state) => state.strategies);
  const name = useAppSelector((state) => state.strategies.name);
  const description = useAppSelector((state) => state.strategies.description);
  const color = useAppSelector((state) => state.strategies.color);
  const value = useAppSelector((state) => state.strategies.saving);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const sendInfo = async () => {
    await postStrategy(newStrategy.labels, newStrategy.data);
    await getStrategy(selectedOrgId, selectedServiceId);
    dispatch(resetStrategyActionCreator());
    onHide();
  };

  useEffect(() => {
    if (newChartData) {
      let parsedLabelsAndData = parseLabelsAndData(
        newChartData.labels,
        newChartData.datasets[0].data
      );
      let hoursWithValues = getHoursAndValues(
        parsedLabelsAndData[0],
        parsedLabelsAndData[1]
      );
      calculateEnergySaving(hoursWithValues);
    }
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <div className="col-12">
        <CardContainer>
          <div
            className="col-12"
            style={{ marginBottom: "16px", textAlign: "left" }}
          >
            <Text fontWeight="600" marginBottom="0px">
              {t(txt.reviewStrategyTitle)}
            </Text>
            <Text textType="caption" fontWeight="400">
              {t(txt.reviewStrategySubtitle)}
            </Text>
          </div>
          <DimmingChart
            setNewChartData={setNewChartData}
            newChartData={newChartData}
          />
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "space-between",
              paddingLeft: "35px",
              paddingRight: "10px",
            }}
          >
            <div>
              <Text marginBottom="0px" color={color.value} fontWeight="500">
                {name}
              </Text>
              <Text textType="caption">{description}</Text>
            </div>
            <div>
              <Text marginBottom="0px" color={color.value} fontWeight="500">
                {newChartData ? value : 0}
                {t(txt.percentageEnergySaved)}{" "}
              </Text>
            </div>
          </div>
        </CardContainer>
      </div>
      <div className="row">
        <div className="col-6" style={{ textAlign: "left" }}>
          <CancelButton
            onClick={() => {
              setShowDetails(false);
              setShowDimming(true);
              setShowReview(false);
            }}
          >
            {t("Back to Dimming")}
          </CancelButton>
          {showDimming && <Dimming />}
        </div>
        <div className="col-6" style={{ textAlign: "right" }}>
          <ButtonStrategy
            buttonType="primary"
            onClick={() => {
              sendInfo();
            }}
          >
            {t("Create Strategy")}
          </ButtonStrategy>
        </div>
      </div>
    </>
  );
};
