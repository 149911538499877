import { useEffect, useState, useCallback } from "react";
import { useMapEvents } from "react-leaflet";
import { LatLng, LatLngBounds, polygon, Polygon } from "leaflet";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { filteredAllDevicesActionCreator } from "../../redux/slices/devices/filteredDevicesSlice";
import { turnAllFalseActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { allowFlyModeActionCreator, setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator, setIsStaticViewActionCreator } from "../../redux/slices/uiSlice/uiSlice";
import { Lamp } from "../../types/interfaces";
import { isMarkerInsidePolygon, Position } from "../../utils/auxiliaryFunctions";
import "../../styles/index.css";

interface MapEventHandlerProps {
  setMapBounds: React.Dispatch<React.SetStateAction<number>>;
  lamps: Array<Lamp>;
  draw: boolean;
  setDraw: React.Dispatch<React.SetStateAction<boolean>>;
  setNodesGroup: React.Dispatch<React.SetStateAction<string[]>>;
  positions: Array<Position>;
}

export const MapEventHandler = ({
  setMapBounds,
  draw,
  setDraw,
  lamps,
  positions,
  setNodesGroup
}: MapEventHandlerProps) => {
  const [drawPolygon, setDrawPolygon] = useState(false);
  const polygonCoordinates: LatLng[] = [];
  let polygonSelection: Polygon = new Polygon(polygonCoordinates);

  const areaState = useAppSelector((state) => state.actualFilter.area)
  
  const groupNodes: Array<string> = [];
  const dispatch = useAppDispatch();
  
  const { allowFlyMode, selectedBoundsToFly: positionsToFly } = useAppSelector(
    (state) => state.ui
  );
  const detailPopUp = useAppSelector((state) => state.ui.isOpenedDetail);
  const staticView = useAppSelector((state) => state.ui.isStaticView);

  const setZoom = () => {
    dispatch(setIsOpenedDetailActionCreator(false))
    dispatch(setIsOpenedGroupActionCreator(true))
    dispatch(turnAllFalseActionCreator());
  }

  const map = useMapEvents({
    load: () => {
      // setMapBounds({
      //   xOriginPixel: map.getContainer().getBoundingClientRect().left,
      //   widthPixel: map.getContainer().getBoundingClientRect().width,
      //   xOriginBounds: map.getBounds().getNorthWest().lat,
      //   widthBounds: map.getBounds().getEast() - map.getBounds().getWest(),
      //   yOriginPixel: map.getContainer().getBoundingClientRect().top,
      //   heightPixel: map.getContainer().getBoundingClientRect().height,
      //   yOriginBounds: map.getBounds().getNorthWest().lng,
      //   heightBounds: map.getBounds().getNorth() - map.getBounds().getSouth(),
      // });
    },
    locationfound: (event) => map.flyTo(event.latlng),

    click: () => {
      dispatch(setIsOpenedDetailActionCreator(false))
      dispatch(setIsOpenedGroupActionCreator(false))
      dispatch(turnAllFalseActionCreator());
    },
    /* zoomstart: () => {
      dispatch(setIsStaticViewActionCreator(true));
    }, */

    zoomend: () => {
      /* dispatch(setIsStaticViewActionCreator(false)); */
      setMapBounds(map.getZoom());
      dispatch(allowFlyModeActionCreator(false));
      if (map.getZoom() !== 2) {
        map.dragging.enable()
      } else {
        map.dragging.disable()
      } 
    },

    mousedown: () => {
      if (draw) {
        setDrawPolygon(true);
      }

      if (drawPolygon && lamps.length > 0) {
        setDrawPolygon(false);
        setDraw(false);
        map.removeLayer(polygonSelection);

        positions.forEach((position) => {
          if (isMarkerInsidePolygon(position, polygonSelection)) {
            groupNodes.push(position.id);
          }
        });

        const newLamps = lamps.filter((lamp) => groupNodes.includes(lamp.node_id));

        setNodesGroup(groupNodes);
        dispatch(
          filteredAllDevicesActionCreator({ gateways: [], nodes: newLamps, unpositionedNodes: [] })
        );

        const positionToCalculate: Position[] = positions.filter((position) =>
          groupNodes.includes(position.id)
        );

        const southWest = new LatLng(
          positionToCalculate[0].latitude,
          positionToCalculate[0].longitude
        );

        const northEast = new LatLng(
          positionToCalculate[positionToCalculate.length - 1].latitude,
          positionToCalculate[positionToCalculate.length - 1].longitude
        );

        if (draw) {
          map.flyToBounds(new LatLngBounds(southWest, northEast), {
            animate: true,
            maxZoom: 18,
            padding: [50, 50],
          });
        }

        map.getZoom() > 7
          ? setZoom()
          : setTimeout(() => {
              setZoom()
            }, 2000);
      }
    },
    mousemove: (event) => {
      if (drawPolygon && draw) {
        map.removeLayer(polygonSelection);
        polygonCoordinates.push(event.latlng);
        polygonSelection = polygon(polygonCoordinates, {
          color: "darkblue",
          fill: true,
          fillColor: "blue",
          fillOpacity: 0.25,
          weight: 5,
        }).addTo(map);
      }
    },
  });

  const flyToPosition = useCallback(async () => {
    if (positionsToFly) {
      const southWest = new LatLng(
        positionsToFly.southWest.latitude,
        positionsToFly.southWest.longitude
      );
      const northEast = new LatLng(
        positionsToFly.northEast.latitude,
        positionsToFly.northEast.longitude
      );
      map.flyToBounds(new LatLngBounds(southWest, northEast), {
        animate: true,
        maxZoom: 18,
        padding: [50, 50],
      });
    }
  }, [positionsToFly, map]);

  
  useEffect(() => {
    dispatch(allowFlyModeActionCreator(true))
    allowFlyMode && !areaState && !detailPopUp && !staticView && flyToPosition();
    
    /* if (staticView) {
      const southWest = new LatLng(
        positionsToFly.southWest.latitude,
        positionsToFly.southWest.longitude
      );
      const northEast = new LatLng(
        positionsToFly.northEast.latitude,
        positionsToFly.northEast.longitude
      );
  
      map.flyToBounds(new LatLngBounds(southWest, northEast), {
        animate: false,
        maxZoom: 18,
        padding: [50, 50],
      });
    } */
  }, [flyToPosition]);

  return null;
};
