import { store } from "../../redux/store";
import { setActualFilterWithAreaActionCreator } from "../../redux/slices/actualFilterSlice/actualFilterSlice";
import {
  filterConnectedDevicesActionCreator,
  filterDisconnectedDevicesActionCreator,
  filterOffDevicesActionCreator,
  filterAlarmDevicesActionCreator,
  filterOnDevicesActionCreator,
  filteredNewOrderDevicesActionCreator,
  updateNodeDeviceStatusActionCreator,
} from "../../redux/slices/devices/filteredDevicesSlice";
import { DevicesFilter } from "../../types/interfaces";

// IMPLEMENT THIS ON A NEXT STEP, FILE TO REFACTOR
export const checkAll = async (
  e: React.ChangeEvent<HTMLInputElement>,
  list: any
) => {
  let tempList: any = JSON.parse(JSON.stringify(list));

  await tempList.forEach((device: any) => {
    device.selected = e.target.checked;
    store.dispatch(updateNodeDeviceStatusActionCreator(device));
  });

  //Update State

  /*setList(tempList);
    console.log("lista post")
    console.log(list)*/

  //dispatch(updateNodeDeviceStatusActionCreator(list));

  // esta línea cambia cuando Alicia cambie el objeto nodes
  //setSelectedList(list.filter((e: any) => e.selected));
};

export const onMasterCheck = (array: any, setMasterChecked: Function) => {
  console.log("dentro", array);
  if (array.length !== 0 && array.every((obj: any) => obj.selected === true)) {
    setMasterChecked(true);
  } else {
    setMasterChecked(false);
  }
};

export const onItemCheck = async (
  e: React.ChangeEvent<HTMLInputElement>,
  item: any,
  list: any
) => {
  let tempList: any = JSON.parse(JSON.stringify(list));

  tempList.forEach((device: any) => {
    if (device.node_id === item.node_id) {
      device.selected = e.target.checked;
      store.dispatch(updateNodeDeviceStatusActionCreator(device));
    }
  });

  //To Control Master Checkbox State
  // ?? const totalItems = list.length;
  // esta línea cambia cuando Alicia cambie el objeto nodes
  // ?? const totalCheckedItems = tempList.filter((e) => e.selected).length;

  // Update State
  // esta línea cambia cuando Alicia cambie el objeto nodes
  // ?? setMasterChecked(totalItems === totalCheckedItems);
  // ?? setList(tempList);
  // esta línea cambia cuando Alicia cambie el objeto nodes
  // ?? setSelectedList(list.filter((e: any) => e.selected));
};

export const makeMenusVisible = (
  expression: string,
  setNameOrder: Function,
  setConnectionOrder: Function,
  setStatusOrder: Function,
  setAlarmOrder: Function,
  setGroupsOrder: Function,
  setDeviceOrder?: Function
) => {
  switch (expression) {
    case "name":
      if (setDeviceOrder) {
        setDeviceOrder(false);
      }
      setConnectionOrder(false);
      setStatusOrder(false);
      setAlarmOrder(false);
      setGroupsOrder(false);
      break;

    case "device":
      setNameOrder(false);
      setConnectionOrder(false);
      setStatusOrder(false);
      setAlarmOrder(false);
      setGroupsOrder(false);
      break;

    case "connection":
      if (setDeviceOrder) {
        setDeviceOrder(false);
      }
      setNameOrder(false);
      setStatusOrder(false);
      setAlarmOrder(false);
      setGroupsOrder(false);
      break;

    case "status":
      if (setDeviceOrder) {
        setDeviceOrder(false);
      }
      setNameOrder(false);
      setConnectionOrder(false);
      setAlarmOrder(false);
      setGroupsOrder(false);
      break;

    case "alarm":
      if (setDeviceOrder) {
        setDeviceOrder(false);
      }
      setNameOrder(false);
      setConnectionOrder(false);
      setStatusOrder(false);
      setGroupsOrder(false);
      break;

    case "groups":
      if (setDeviceOrder) {
        setDeviceOrder(false);
      }
      setNameOrder(false);
      setConnectionOrder(false);
      setStatusOrder(false);
      setAlarmOrder(false);
      break;

    default:
      if (setDeviceOrder) {
        setDeviceOrder(false);
      }
      setNameOrder(false);
      setConnectionOrder(false);
      setStatusOrder(false);
      setAlarmOrder(false);
      setGroupsOrder(false);
  }
};

export const makeIconsVisible = (
  expression: string,
  setNameAZ: Function,
  setNameZA: Function,
  setConnected: Function,
  setDisconnected: Function,
  setOn: Function,
  setOff: Function,
  setClear: Function,
  setCritical: Function,
  setModerate: Function,
  setGroupsAZ: Function,
  setGroupsZA: Function,
  setGroups: Function,
  setLuminaire?: Function,
  setGateway?: Function
) => {
  switch (expression) {
    case "nameAZ":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(true);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "nameZA":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(true);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "luminaire":
      if (setLuminaire && setGateway) {
        setLuminaire(true);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "gateway":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(true);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "connected":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(true);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "disconnected":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(true);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "on":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(true);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "off":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(true);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "clear":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(true);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "critical":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(true);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "moderate":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(true);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "groupsAZ":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(true);
      setGroupsZA(false);
      setGroups(false);
      break;

    case "groupsZA":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(true);
      setGroups(false);
      break;

    case "groups":
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(true);
      break;

    default:
      if (setLuminaire && setGateway) {
        setLuminaire(false);
        setGateway(false);
      }
      setNameAZ(false);
      setNameZA(false);
      setConnected(false);
      setDisconnected(false);
      setOn(false);
      setOff(false);
      setClear(false);
      setCritical(false);
      setModerate(false);
      setGroupsAZ(false);
      setGroupsZA(false);
      setGroups(false);
  }
};

export const changeOrder = (
  list: any,
  number: number,
  nameOrder: boolean,
  groupsOrder: boolean
) => {
  let tempList: any = JSON.parse(JSON.stringify(list));
  let firstItem: any;
  let secondItem: any;

  //ALPHABETIC ORDER
  if (number === 1) {
    let result = tempList.sort((first: any, second: any, third: any) => {
      if (nameOrder) {
        firstItem = first.name.toUpperCase();
        secondItem = second.name.toUpperCase();
      } else if (groupsOrder) {
        //no es orgName, está falseado porque groups aun no existe
        firstItem = first.orgName.toUpperCase();
        secondItem = second.orgName.toUpperCase();
      }

      if (firstItem < secondItem) {
        return -1;
      }
      if (firstItem > secondItem) {
        return 1;
      }
      return 0;
    });

    store.dispatch(filteredNewOrderDevicesActionCreator(result));
  }

  //INVERSE ALPHABETIC ORDER
  if (number === 2) {
    let result = tempList.sort((first: any, second: any, third: any) => {
      if (nameOrder) {
        firstItem = first.name.toUpperCase();
        secondItem = second.name.toUpperCase();
      } else if (groupsOrder) {
        //no es orgName, está falseado porque groups aun no existe
        firstItem = first.orgName.toUpperCase();
        secondItem = second.orgName.toUpperCase();
      }

      if (firstItem > secondItem) {
        return -1;
      }
      if (firstItem < secondItem) {
        return 1;
      }
      return 0;
    });

    store.dispatch(filteredNewOrderDevicesActionCreator(result));
  }
};

// we'll have to add device's type
export const changeState = async (command: string, devices: any) => {
  let tempList: any = JSON.parse(JSON.stringify(devices));
  const filter: DevicesFilter = {
    area: command === "selectArea" ? true : false,
    all: command === "all" ? true : false,
    on: command === "on" ? true : false,
    off: command === "off" ? true : false,
    disconnected: command === "disconnected" ? true : false,
    alarmas: command === "alarm" ? true : false,
  };

  if (command === "connected") {
    store.dispatch(filterConnectedDevicesActionCreator(tempList));
  } else if (command === "disconnected") {
    store.dispatch(filterDisconnectedDevicesActionCreator(tempList));
  } else if (command === "on") {
    store.dispatch(filterOnDevicesActionCreator(tempList));
  } else if (command === "off") {
    store.dispatch(filterOffDevicesActionCreator(tempList));
  } else if (command === "alarm") {
    store.dispatch(filterAlarmDevicesActionCreator(tempList));
  }

  store.dispatch(setActualFilterWithAreaActionCreator(filter));
};
