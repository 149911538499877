import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GetManagerServiceState {
  orgId: string;
  organization_name: string;
  services: Array<[]>;
}

const initialGetManagerServiceState: GetManagerServiceState[] = [];

const getManagerServiceSlice = createSlice({
  name: "managerService",
  initialState: initialGetManagerServiceState,
  reducers: {
    loadGetManagerServiceActionCreator: (
      state,
      action: PayloadAction<GetManagerServiceState[]>
    ) => {
      return action.payload;
    },
  },
});

export const { loadGetManagerServiceActionCreator } =
  getManagerServiceSlice.actions;

export default getManagerServiceSlice.reducer;
