import Cookies from "js-cookie";
import { GetManagerGroupPayload } from "../types/interfaces";
import { useAppDispatch } from "../redux/hooks";
import { loadGetManagerGroupActionCreator } from "../redux/slices/managerUsers/getManagedUserGroupSlice";

export interface GetManagerGroupState {
  orgId: string;
  organization_name: string;
  servId: string;
  servName: string;
  groups: Array<[]>;
}

export const useGetManagedGroupService = () => {
  const webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;
  const dispatch = useAppDispatch();

  const saveTokenToStorage = (token: string) => {
    Cookies.set("token", token, { expires: 7 });
  };

  const getManagedGroup = async (
    token: string,
    userId: string
  ): Promise<GetManagerGroupState[] | null> => {
    try {
      const response = await fetch(
        `${webAddressPrefix}/users/user-groups?userId=${userId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const getManagedGroupData: GetManagerGroupPayload[] =
          await response.json();
        if (
          Array.isArray(getManagedGroupData) &&
          getManagedGroupData.length > 0
        ) {
          const serviceList: GetManagerGroupState[] = getManagedGroupData.map(
            (groupData) => ({
              orgId: groupData.orgId || "",
              organization_name: groupData.organization_name || "",
              servId: groupData.servId || "",
              servName: groupData.servName || "",
              groups: groupData.groups || "",
            })
          );

          dispatch(loadGetManagerGroupActionCreator(serviceList));

          return serviceList;
        } else {
          console.log("No data found or unexpected response format.");
          return null;
        }
      } else {
        console.log("Failed to fetch user by ID.", response.status);

        return null;
      }
    } catch (error) {
      console.log("Error while fetching user by ID:", error);

      return null;
    }
  };

  return {
    getManagedGroup,
    saveTokenToStorage,
  };
};
