import { useState, useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";
import { formatAMPM } from "../../utils/dateutils";
import { LocalWeatherStyled } from "./LocalWeatherStyled";
import sun from "../../assets/new_icons/Solar.svg";

export const LocalWeather = () => {
  const [location, setLocation] = useState("");
  const [time, setTime] = useState(formatAMPM(new Date()));

  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);

  const actualTime = time.hours + ":" + time.formatedMinutes;
  const amPm = time.amPm.toUpperCase();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`
      );
      const data = await response.json();
      setLocation(data.address.city + ", " + data.address.country);
    });
  }, []);

  setInterval(() => {
    setTime(formatAMPM(new Date()));
  }, 60000);

  return (
    <LocalWeatherStyled
      style={
        !fullView && !hybridView
          ? {
              position: "relative",
              height: "90px",
              bottom: "30px",
              left: "0px",
            }
          : { position: "absolute" }
      }
    >
      <img src={sun} width={44} />
      <div className="weather-card__content-container">
        {/* <h1 className="weather-card__title">
          {location ? location : "unknown location"}
        </h1> */}
        <div className="weather-card__time-container">
          <h2 className="weather-card__content">{actualTime}</h2>
          <p className="weather-card__am-pm">{amPm}</p>
        </div>
      </div>
    </LocalWeatherStyled>
  );
};
