import { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { languages } from "../../../utils/languages/languages";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import favourite from "../../../assets/new_icons/Favourite filled.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import { useTranslation } from "react-i18next";

interface GroupsCardProps {}

export const GroupsCard = ({}: GroupsCardProps) => {
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="#A3AED0">{t("Groups")}</Text>
        </div>
        {/* <div style={{ textAlign: "right", marginTop: "-2px", cursor: "pointer" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} />
        </div> */}
      </div>
      <div
        style={{ display: " flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <div
          style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            margin: "8px 4px",
            padding: "4px 12px",
            background: "#FFFFFF",
            borderRadius: "30px",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
          }}
        >
          {/* <img src={favourite} style={{ height: "12px" }} /> */}
          <TextSpan>TRF-01</TextSpan>
        </div>
        <div
          style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            margin: "8px 4px",
            padding: "4px 12px",
            background: "#FFFFFF",
            borderRadius: "30px",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
          }}
        >
          {/* <img src={favourite} style={{ height: "12px" }} /> */}
          <TextSpan>KN 34 STREET</TextSpan>
        </div>
        <div
          style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            margin: "8px 4px",
            padding: "4px 12px",
            background: "#FFFFFF",
            borderRadius: "30px",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
          }}
        >
          {/* <img src={favourite} style={{ height: "12px" }} /> */}
          <TextSpan>Kigali</TextSpan>
        </div>
        <div
          style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            margin: "8px 4px",
            padding: "4px 12px",
            background: "#FFFFFF",
            borderRadius: "30px",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
          }}
        >
          {/* <img src={favourite} style={{ height: "12px" }} /> */}
          <TextSpan>TRF-01</TextSpan>
        </div>
        <div
          style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            margin: "8px 4px",
            padding: "4px 12px",
            background: "#FFFFFF",
            borderRadius: "30px",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
          }}
        >
          {/* <img src={favourite} style={{ height: "12px" }} /> */}
          <TextSpan>KN 34 STREET</TextSpan>
        </div>
        <div
          style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            margin: "8px 4px",
            padding: "4px 12px",
            background: "#FFFFFF",
            borderRadius: "30px",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
          }}
        >
          {/* <img src={favourite} style={{ height: "12px" }} /> */}
          <TextSpan>Kigali</TextSpan>
        </div>
        <div
          style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            margin: "8px 4px",
            padding: "4px 12px",
            background: "#FFFFFF",
            borderRadius: "30px",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
          }}
        >
          {/* <img src={favourite} style={{ height: "12px" }} /> */}
          <TextSpan>TRF-01</TextSpan>
        </div>
      </div>
    </div>
  );
};
