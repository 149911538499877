import styled from "styled-components";

export const UsersContainer = styled.div`
  margin-top: 36px;
`;

export const InputContainer = styled.input`
  width: 100%;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    border: 1px solid #ccc;
    border-radius: 4px !important;
  }
  ::placeholder {
    color: #a3aed0 !important;
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 0;
  right: 4px;
  bottom: 0;
  background-color: transparent;
  padding: 0;
  border: none;
  z-index: 10;
`;
export const NavLink = styled.a`
  font-size: 14px !important;
  border-radius: 0 !important;
  border-bottom: 2px solid #e7e0ec !important;
  color: #a3aed0 !important;
  padding: 16px 32px 16px 16px !important;
  &.active {
    color: #475569 !important;
    background-color: transparent !important;
    font-weight: 700 !important;
    border-bottom: 2px solid #1b2559 !important;
    border-radius: 0 !important;
  }
`;

export const SelectOptionUsers = styled.select`
  border-radius: 8px;
  border: 2px solid #fff !important;
  box-shadow: 0px 4px 8px 3px rgba(125, 125, 125, 0.103);
  color: #475569;
  font-size: 14px;
  font-weight: 700;
`;

export const SelectOption = styled.select`
  color: #475569;
  font-size: 14px;
`;

// export const OptionSelect = styled.option`
//   font-size: 12px;
// `;
export const EmptyCardContainer = styled.div`
  background-color: #ffffff;
  padding: 32px;
  border: 2px solid #fff !important;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 3px rgba(125, 125, 125, 0.103);
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CardContainer = styled.div`
  background-color: #ffffff;
  padding: 24px;
  border: 2px solid #fff !important;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 3px rgba(125, 125, 125, 0.103);
  height: calc(100vh - 196px);
`;

export const TabContent = styled.div`
  height: calc(100vh - 360px);
  overflow: auto;
  margin-bottom: 32px;
`;

export const TabLink = styled.a`
  font-size: 14px !important;
  border-radius: 0 !important;
  border-bottom: 1px solid transparent !important;
  color: #a3aed0 !important;
  padding: 16px 32px 16px 16px !important;
  &.active {
    color: #475569 !important;
    background-color: transparent !important;
    font-weight: 700 !important;
    border-bottom: 1px solid #1b2559 !important;
    border-radius: 0 !important;
  }
`;
export const ProfileContainer = styled.div`
  border-radius: 50%;
  width: 72px;
  height: 72px;
  overflow: hidden;
`;
export const ImageProfile = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ListOrganization = styled.li`
  display: inline-flex;
  align-items: left;
  margin-right: 0px;
  vertical-align: middle;
`;
export const OrganizationCard = styled.div`
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 5px 0px #00000026;
  background-color: #ffffff;
  padding: 0 16px;
  margin-bottom: 24px;
`;
export const Table = styled.table`
  margin-bottom: 4px;
`;
export const OrganizationProfile = styled.img`
  /* border-radius: 50%; */
  height: 56px;
  /* width: 56px; */
`;

export const DropdownUsersStyled = styled.section`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.contentFontColor};
  align-items: left;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px 0px #00000026;
  padding: 0px 16px 8px 16px;
  z-index: 1;
  cursor: default;

  .filter {
    &__title-container {
      padding: 8px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: inherit;
    }
  }

  .title-container {
    &__title {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #475569;
    }

    &__icon {
      color: inherit;
      font-weight: 400;
    }
  }

  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.08)
      ),
      #ffffff;
  }

  ul::-webkit-scrollbar {
    position: absolute;
    right: 0;
    width: 3px;
    height: 50%;
  }

  ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #a3aed034;
    border-radius: 5px;
  }

  ul::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0.377);
    border-radius: 5px;
  }

  li {
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0px 5px;
  }

  li:hover {
    background-color: #f0f0f0;
  }

  .option-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
`;

export const GroupCardStyled = styled.div`
  max-width: fit-content;
  display: flex;
  gap: 6px;
  align-items: center;
  margin: 8px 4px;
  padding: 4px 12px;
  background: rgb(255, 255, 255);
  border-radius: 30px;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 3px 1px;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 1px 2px);
`;

export const UsersListCard = styled.div`
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 5px 0px #00000026;
  background-color: #ffffff;
  padding: 8px;
  margin-bottom: 24px;
`;

export const ListItem = styled.li`
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  bottom: 50vh;
  left: 150vh;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px;
  z-index: 10;
  width: 45%;

  .row {
    font-size: 14px;
    text-align: left;
  }
  @media (min-width: 1768px) {
    bottom: 60vh;
  }
`;

export const DropdownItem = styled.div`
  padding: 8px 16px;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const InputFormFiltering = styled.input`
  width: 150px;
  margin: 0 16px;
  border-radius: 16px;
  height: 36px;
`;

export const ButtonFilter = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #1b2559;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  border: none;
`;

export const SelectFormFiltering = styled.select`
  width: 150px;
  margin: 0 16px;
  border-radius: 16px;
  height: 36px;
`;
export const SelectFormFilteringSmall = styled.select`
  width: 100px;
  margin: 0 16px;
  border-radius: 16px;
  height: 36px;
`;

export const OptionSelect = styled.option`
  font-size: 14px !important;
  font-weight: 400;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const UserRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

export const ServiceCard = styled.div`
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 5px 0px #00000026;
  background-color: #ffffff;
  padding: 16px;
  margin-bottom: 24px;
`;
export const ServiceProfile = styled.img`
  /* border-radius: 50%; */
  height: 42px;
  /* width: 36px; */
`;
export const ServiceList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;
export const GroupList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
`;

export const TableRow = styled.tr`
  &:hover {
    background-color: #f1f1f1;
  }
`;
