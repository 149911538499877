import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import CurrentStrategyChart from "./CurrentStrategyChart";
import { languages } from "../../../utils/languages/languages";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import { ReactComponent as EnergyIcon } from "../../../assets/new_icons/Energy.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import sunrise from "../../../assets/new_icons/Sunrise - Filled.svg";
import sunset from "../../../assets/new_icons/Sunset - Filled.svg";
import { useTranslation } from "react-i18next";

interface CurrentStrategyProps {
  selectedLampId: string | undefined;
  viewState: any;
}

export const CurrentStrategy = ({
  selectedLampId,
  viewState,
}: CurrentStrategyProps) => {
  const navigate = useNavigate();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        height: "100%",
      }}
    >
      <div className="row">
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Text color="#A3AED0">{t(txt.currentStrategy)}</Text>
          </div>
          {/* <div style={{ textAlign: "right", marginTop: "-2px", cursor: "pointer" }}>
            <img src={plusCircle} height={16} onClick={() => {navigate("/strategies", {state: {selectedLampId, viewState}})}} style={{ opacity: iconOpacity }}
              onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)}
            />
          </div> */}
        </div>
        <div style={{ marginTop: "-8px" }}>
          <Text fontWeight="700" marginBottom="4px">
            {t(txt.standard)}
          </Text>
          <Text color="#A3AED0" style={{ fontSize: "12px" }}>
            {t(txt.shortDescription)}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div style={{ textAlign: "right", marginTop: "-2px" }}>
            <img src={sunset} height={16} />
          </div>
          <div style={{ textAlign: "right", marginTop: "-2px" }}>
            <img src={sunrise} height={16} />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CurrentStrategyChart />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "8px",
          height: "20px",
        }}
      >
        <EnergyIcon height={14} style={{ color: "rgb(135, 203, 232)" }} />
        <TextSpan color="rgb(135, 203, 232)" fontWeight="500">
          {t(txt.energySaved)}
        </TextSpan>
      </div>
    </div>
  );
};
