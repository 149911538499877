import Cookies from "js-cookie";
import { GetManagedUserPayload } from "../types/interfaces";
import { useAppDispatch } from "../redux/hooks";
import { loadGetManagerUsersByIdActionCreator } from "../redux/slices/managerUsers/getManagedUserByIdSlice";

export const useGetManagedUserByIdService = () => {
  const webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;
  const dispatch = useAppDispatch();

  const saveTokenToStorage = (token: string) => {
    Cookies.set("token", token, { expires: 7 });
  };

  const getManagedUserById = async (
    token: string,
    userId: string
  ): Promise<GetManagedUserPayload | null> => {
    try {
      const response = await fetch(
        `${webAddressPrefix}/users/user-pi?userId=${userId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        console.log("service manager by id", userId);
        const getManagerUsersData = await response.json();
        console.log("Cristina response json", response.json);
        console.log("Cristina 1", getManagerUsersData);
        if (
          Array.isArray(getManagerUsersData) &&
          getManagerUsersData.length > 0
        ) {
          const userData = getManagerUsersData[0];

          const userPayload: GetManagedUserPayload = {
            id: userData.id || "",
            first_name: userData.first_name || "",
            last_name: userData.last_name || "",
            phone_number: userData.phone_number || null,
            email: userData.email || "",
            language_name: userData.language_name || "",
            role_name: userData.role_name || "",
            user_active: userData.user_active || "",
          };

          dispatch(loadGetManagerUsersByIdActionCreator(getManagerUsersData));

          return userPayload;
        } else {
          console.log("No data found or unexpected response format.");
          return null;
        }
      } else {
        console.log("Failed to fetch user by ID.", response.status);
        // Handle other error cases here
        return null;
      }
    } catch (error) {
      console.log("Error while fetching user by ID:", error);
      // Handle error cases here
      return null;
    }
  };

  return {
    getManagedUserById,
    saveTokenToStorage,
  };
};
