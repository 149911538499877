import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NodeLumimairePage } from "../../../types/interfaces";

const initialState: NodeLumimairePage = {
  case_name: "",
  creation_date: "",
  device_type: "",
  bri: 0,
  gateway_id: "",
  hops: 0,
  on: "",
  latitude: "",
  longitude: "",
  manufacturer_name: "",
  active: "",
  node_id: "",
  node_name: "",
  num_neighbours: 0,
  protocol_name: "",
  rssi: 0,
  solar_id: null,
  traveltime: 0,
  update_date: "",
  online: ""
};

const manageNodeSlice = createSlice({
  name: "node",
  initialState: initialState,
  reducers: {
    saveNode: (previousData, action: PayloadAction<NodeLumimairePage>) => ({
      ...action.payload,
    }),

    updateNodeStatusRedux: (previousState, action) => {

      if (action.payload !== undefined) {
        if (previousState.node_id === action.payload.node_id) {
          if(action.payload.msg_type === 2) {
            previousState.on = "0";
            previousState.online = "1";
          }
          else if(action.payload.msg_type === 1) {
            previousState.on = "1";
            previousState.online = "1";
          }
          else if(action.payload.msg_type === 3) {
            previousState.on = "1";
            previousState.bri = action.payload.value;
            previousState.online = "1";
          }
          else if(action.payload.msg_type === 4) {
            previousState.on = "0";
            previousState.online = "0";
          }
        }
      }
    },

    // Creo que esto no lo necesito pero lo dejo aquí por si acaso

    /* updateNodeStatus: (previousState, action) => {
      const nodeToUpdate = previousState.nodes.findIndex(
        (node) => node.node_id === action.payload.node_id
      );
      
      console.log("payload")
      
      console.log(action.payload.selected)
      console.log("previo")
      console.log(previousState.nodes[0].selected)

      console.log("payload para dim")
      console.log(action.payload, action)

      previousState.nodes.forEach((node, index) => {
        /*if (index === nodeToUpdate) {
          let manageNode = node.on === 0 ? 1 : 0;
          node.on = manageNode;

          //let manageSelect = node.selected === false ? true : false
          //node.selected = manageSelect
        }

        if (index === nodeToUpdate) {
          node.on = action.payload.on;
          node.selected = action.payload.selected;
          node.bri = action.payload.bri;
        }
      });
    }, */
  },
});

export const manageNodeReducer = manageNodeSlice.reducer;

export const { 
  saveNode: saveNodeActionCreator,
  updateNodeStatusRedux: updateNodeStatusReduxActionCreator
} =
  manageNodeSlice.actions;
