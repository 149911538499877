import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NodeDevice, UiData } from "../../../types/interfaces";
import { getAllpositions } from "../../../utils/auxiliaryFunctions";
import { Language } from "../../../utils/languages/languages";

const uiDataInitialState: UiData = {
  isLoading: false,
  actualLanguage: "english",
  selectedBoundsToFly: {
    northEast: { latitude: 0, longitude: 0 },
    southWest: { latitude: 0, longitude: 0 },
  },
  allowFlyMode: true,
  firstRenderMap: true,
  isOpenedDetail: false,
  isOpenedGroup: false,
  isStaticView: false,
  isFullView: true,
  isListView: false,
  isHybridView: false,
  isSideMenuVisible: true,
  isHomeVisible: true,
  isAlarmsVisible: false,
  isGroupsVisible: false,
  isStrategiesVisible: false,
  isEventsVisible: false,
  isSwitchBoardModalVisible: false,
};

const uiDataSlice = createSlice({
  name: "uiData",
  initialState: uiDataInitialState,
  reducers: {
    openLoadingModal: (previousUiData: UiData) => ({
      ...previousUiData,
      isLoading: true,
    }),

    closeLoadingModal: (previousUiData: UiData) => ({
      ...previousUiData,
      isLoading: false,
    }),

    setActualLanguage: (previousUiData, action: PayloadAction<Language>) => ({
      ...previousUiData,
      actualLanguage: action.payload,
    }),

    setBoundsToFly: (
      previousData,
      action: PayloadAction<Array<NodeDevice>>
    ) => {
      const positions = getAllpositions(action.payload);

      return {
        ...previousData,
        selectedBoundsToFly: {
          northEast: {
            latitude: positions[positions.length - 1].latitude,
            longitude: positions[positions.length - 1].longitude,
          },
          southWest: {
            latitude: positions[0].latitude,
            longitude: positions[0].longitude,
          },
        },
      };
    },

    allowFlyMode: (previousUiData, action: PayloadAction<boolean>) => ({
      ...previousUiData,
      allowFlyMode: action.payload,
    }),

    setIsOpenedDetail: (previousUiData, action: PayloadAction<boolean>) => ({
      ...previousUiData,
      isOpenedDetail: action.payload,
    }),

    setIsOpenedGroup: (previousUiData, action: PayloadAction<boolean>) => ({
      ...previousUiData,
      isOpenedGroup: action.payload,
    }),

    setIsStaticView: (previousUiData, action: PayloadAction<boolean>) => ({
      ...previousUiData,
      isStaticView: action.payload,
    }),

    setIsFullView: (previousUiData) => ({
      ...previousUiData,
      isFullView: true,
      isListView: false,
      isHybridView: false,
    }),

    setIsListView: (previousUiData) => ({
      ...previousUiData,
      isFullView: false,
      isListView: true,
      isHybridView: false,
    }),

    setIsHybridView: (previousUiData) => ({
      ...previousUiData,
      isFullView: false,
      isListView: false,
      isHybridView: true,
    }),

    setViewsToFalse: (previousUiData) => ({
      ...previousUiData,
      isFullView: false,
      isListView: false,
      isHybridView: false,
    }),

    changeSideMenu: (previousUiData) => ({
      ...previousUiData,
      isSideMenuVisible: !previousUiData.isSideMenuVisible,
    }),

    setHomeVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: true,
      isAlarmsVisible: false,
      isGroupsVisible: false,
      isStrategiesVisible: false,
      isEventsVisible: false,
    }),

    setAlarmsVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: false,
      isAlarmsVisible: true,
      isGroupsVisible: false,
      isStrategiesVisible: false,
      isEventsVisible: false,
    }),

    setGroupsVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: false,
      isAlarmsVisible: false,
      isGroupsVisible: true,
      isStrategiesVisible: false,
      isEventsVisible: false,
    }),

    setStrategiesVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: false,
      isAlarmsVisible: false,
      isGroupsVisible: false,
      isStrategiesVisible: true,
      isEventsVisible: false,
    }),

    setEventsVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: false,
      isAlarmsVisible: false,
      isGroupsVisible: false,
      isStrategiesVisible: false,
      isEventsVisible: true,
    }),

    setFirstRenderMapToTrue: (previousUiData) => ({
      ...previousUiData,
      firstRenderMap: true,
    }),

    setFirstRenderMapToFalse: (previousUiData) => ({
      ...previousUiData,
      firstRenderMap: false,
    }),
    setSwitchBoardModalState: (previousUiData, action) => ({
      ...previousUiData,
      isSwitchBoardModalVisible: action.payload,
    }),
  },
});

export const uiDataReducer = uiDataSlice.reducer;

export const {
  openLoadingModal: openLoadingModalActionCreator,
  closeLoadingModal: closeLoadingModalActionCreator,
  setActualLanguage: setActualLanguageActionCreator,
  setBoundsToFly: setBoundsToFlyActionCreator,
  allowFlyMode: allowFlyModeActionCreator,
  setIsOpenedDetail: setIsOpenedDetailActionCreator,
  setIsOpenedGroup: setIsOpenedGroupActionCreator,
  setIsStaticView: setIsStaticViewActionCreator,
  setIsFullView: setIsFullViewActionCreator,
  setIsListView: setIsListViewActionCreator,
  setIsHybridView: setIsHybridViewActionCreator,
  setViewsToFalse: setViewsToFalseActionCreator,
  changeSideMenu: changeSideMenuActionCreator,
  setHomeVisible: setHomeVisibleActionCreator,
  setAlarmsVisible: setAlarmsVisibleActionCreator,
  setGroupsVisible: setGroupsVisibleActionCreator,
  setStrategiesVisible: setStrategiesVisibleActionCreator,
  setEventsVisible: setEventsVisibleActionCreator,
  setFirstRenderMapToTrue: setFirstRenderMapToTrueActionCreator,
  setFirstRenderMapToFalse: setFirstRenderMapToFalseActionCreator,
  setSwitchBoardModalState,
} = uiDataSlice.actions;
