import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
    },
  },
};

const labels = ["0", "5", "10", "15", "20", "25", "30"];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: "rgb(231, 29, 126)",
      backgroundColor: "#e71d7f75",
      lineTension: 0.4,
      pointRadius: 0,
    },
  ],
};

function ElectricalParametersChart() {
  return (//294
    <Line width={900} height={245} options={options} data={data} />
  )
}

export default ElectricalParametersChart;
