import styled from "styled-components";

export const ContainerMain = styled.div`
  padding: 0px;
  background-color: #fbfcfe;
`;

export const Sidemenu = styled.div`
  width: 60px;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  order: 2;
  z-index: 1;
  overflow-x: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 0px 16px 16px 0px;
  align-items: center;
  justify-content: center;
`;

export const SidemenuLogo = styled.img`
  height: 32px;
  margin: 26px 10px 94px 15px;
`;

export const SidemenuTopIcon = styled.div`
  padding-top: 5px;
`;

export const SidemenuIcon = styled.img`
  height: 16px;
  margin: 48px 22px;
  display: block;
`;

export const SidemenuDownIcon = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
`;

export const Headertest = styled.div`
  background-color: transparent;
  height: 96px;
  color: #fff;
  text-align: center;
  //position: fixed;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding-left: 90px;
`;

export const HeadertestFullView = styled.div`
  background-color: transparent;
  height: 96px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 40px;
  padding-left: 15px;
`;

export const ContainerChildren = styled.div`
  margin-top: 70px;
  padding-left: 90px;
`;

export const ContainerChildrenFullView = styled.div`
  margin-top: 70px;
  padding-left: 55px;
`;

export const ChildrenComponent = styled.div`
  //The height to show the Google Map without scrolling is calculated by taking 100vh the maximum height of the screen minus the header
  height: calc(101.2vh - 112px);
  padding-right: 30px;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  color: ${(props) => props.theme.mainTitleColor};
  font-family: ${(props) => props.theme.mainFont};
  display: flex;
  flex-wrap: wrap;
`;
