import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import InputForm from "../Input/Input";
import locker_icon from "../../assets/lock.svg";
import { languages } from "../../utils/languages/languages";
import {
    ButtonLogin,
    Container,
    ImageLogo,
    LoginForm,
    LoginOverlay,
    LoginOverlayWhite,
} from "../../pages/Login/LoginStyled";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import chevronLeft from "../../assets/chevron-left.svg";
import envelope_icon from "../../assets/mail.svg";
import logo from "../../assets/smartec-logo.svg";
import { useTranslation } from "react-i18next";
import authService from "../../services/authService";
import { useEffect, useState } from "react";
import { ButtonLoading } from "../../styles/shared-styles/buttons-sizes";
import { useSearchParams } from 'react-router-dom';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();

    const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
    const txt = languages[currentLanguage];
    const [resetToken, setResetToken] = useState<string | null>(null);
    const [invalid, setInvalid] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
    });

    const [formErrors, setFormErrors] = useState({
        password: "",
        confirmPassword: "",
    });
    const { t } = useTranslation();
    const navigate = useNavigate();


    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === "confirmPassword") {
            const field = 'Confirm Password';
            if (formData.password !== value) {
                setFormErrors({ ...formErrors, confirmPassword: "Passwords do not match" });
            } else {
                setFormErrors({ ...formErrors, confirmPassword: "" });
            }
            if (!value.trim()) {
                setFormErrors({ ...formErrors, confirmPassword: `${field} is required` });
            } else {
                setFormErrors({ ...formErrors, confirmPassword: "" });
            }
        } else {
            const field = 'Password';
            if (!value.trim()) {
                setFormErrors({ ...formErrors, [name]: `${field} is required` });
            } else {
                setFormErrors({ ...formErrors, [name]: "" });
            }
        }
    };

    const handleSubmit = async () => {
        const { password, confirmPassword } = formData;
        const newErrors = { ...formErrors };

        if (!password || !confirmPassword) {
            newErrors.password = !password ? "Password is required" : "";
            newErrors.confirmPassword = !confirmPassword ? "Confirm Password is required" : "";
        } else {
            newErrors.password = "";
            newErrors.confirmPassword = "";
        }

        if (password !== confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match";
        }
        setFormErrors(newErrors);

        if (!newErrors.password && !newErrors.confirmPassword) {
            setLoading(true);
            const res = await authService.smartecResetPassword(password, confirmPassword, searchParams.get('token'));
            if (res?.data) {
                setFormErrors({
                    password: '',
                    confirmPassword: ''
                });
                navigate('/');
                setLoading(false);
            } else {
                setFormErrors({
                    password: res?.error?.error,
                    confirmPassword: res?.error?.error
                });
                setLoading(false);
            }
        }
    };





    return (
        <>
            <Container className="login_container">
                <div className="row">
                    <div className="col-6">
                        <LoginOverlay className="overlay" />
                    </div>
                    <div className="col-6">
                        <Container>
                            <LoginOverlayWhite className="overlay" />
                            <LoginForm padding="large" className="card" borderRadius="large">
                                <div
                                    className="col-12"
                                    style={{
                                        display: "flex",
                                        marginTop: "-100px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <img
                                        src={chevronLeft}
                                        style={{
                                            position: "absolute",
                                            top: "-29vh",
                                            bottom: "10vh",
                                            left: "-21vh",
                                            width: "50px",
                                            height: "50px",
                                            cursor: "pointer",
                                            borderRadius: "50%",
                                            background: "white",
                                            padding: "17px",
                                            boxShadow:
                                                "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
                                        }}
                                        onClick={() => {
                                            navigate("/");
                                        }}
                                    />
                                    <ImageLogo src={logo} />
                                </div>

                                <>
                                    <div className="col-span-12 text-left flex flex-col items-center  justify-center h-[calc(100vh-12rem)] w-full">
                                        <Text textType="title" fontWeight="700" marginBottom="10px">
                                            {t(txt.resetPassword)}
                                        </Text>
                                        <InputForm
                                            preffix_icon={locker_icon}
                                            suffix_icon={envelope_icon}
                                            inputType="password"
                                            placeholder={t(txt.password)}
                                            marginBottom="small"
                                            name="password"
                                            onChange={handleInputChange}
                                            isError={Boolean(formErrors?.password)}
                                        />
                                        {formErrors.password &&
                                            <TextSpan
                                                style={{ marginTop: "-40px", width: "200px" }}
                                                color="red"
                                            >
                                                {formErrors.password}
                                            </TextSpan>
                                        }
                                        <InputForm
                                            preffix_icon={locker_icon}
                                            suffix_icon={envelope_icon}
                                            inputType="password"
                                            placeholder={t(txt.confirmPassword)}
                                            marginBottom="small"
                                            name="confirmPassword"
                                            onChange={handleInputChange}
                                            isError={Boolean(formErrors?.confirmPassword)}
                                        />

                                        {formErrors.confirmPassword &&
                                            <TextSpan
                                                style={{ marginTop: "-40px", width: "200px" }}
                                                color="red"
                                            >
                                                {formErrors.confirmPassword}
                                            </TextSpan>
                                        }
                                    </div>
                                    <div className="col-12 text-left">
                                        {isLoading ? (
                                            <ButtonLoading
                                                style={{ marginTop: "16px" }}
                                                type="button"
                                                disabled
                                            >
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                {t(txt.resetPassword)}
                                            </ButtonLoading>
                                        ) : (
                                            <ButtonLogin disabled={isLoading} buttonType="primary" onClick={handleSubmit}>
                                                {t(txt.resetPassword)}
                                            </ButtonLogin>
                                        )}
                                    </div>
                                </>
                            </LoginForm>
                        </Container>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default ResetPassword;