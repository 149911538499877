import { LatLng } from "leaflet";
import { useMemo, useRef } from "react";
import { Marker } from "react-leaflet";
import { Marker as IMarker } from "leaflet";
import { useAppDispatch } from "../../../redux/hooks";
import { turnAllFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { errorToInitialStateActionCreator } from "../../../redux/slices/mqttSlice/mqttSlice";
import { setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator } from "../../../redux/slices/uiSlice/uiSlice";
import { Lamp } from "../../../types/interfaces";
import { getIcon } from "../../../utils/icons";


interface CustomMarkerProps {
  lamp: Lamp;
  selected: boolean;
  editable: boolean;
  center: LatLng;
  setMarker?: React.Dispatch<React.SetStateAction<string>>;
  setNodesGroup: React.Dispatch<React.SetStateAction<string[]>>;
}

const CustomMarker = ({
  lamp,
  setMarker,
  selected,
  editable,
  center,
  setNodesGroup,
}: CustomMarkerProps) => {
  // lamp.coordenates = lamp.coordenates ? lamp.coordenates : center;
  const lampRef = useRef<IMarker>(new IMarker(center));

  const lampStatus = lamp.online ? (lamp.on ? "on" : "off") : "disconnected";

  const icon = getIcon(lampStatus, selected);
  const positioned = lamp.latitude ? true : false;
  const dispatch = useAppDispatch();

  // if (selected) icon.createShadow();
  const eventHandlers = useMemo(
    () => ({
      click() {
        if (setMarker && positioned) {
          setMarker(lamp.node_id);
          dispatch(setIsOpenedDetailActionCreator(true))
          dispatch(setIsOpenedGroupActionCreator(false))
          dispatch(turnAllFalseActionCreator());
          dispatch(errorToInitialStateActionCreator());
        }
        setNodesGroup([]);
      },
      drag() {
        if (lampRef.current && positioned) {
          lamp.latitude = lampRef.current.getLatLng().lat.toString();
          lamp.longitude = lampRef.current.getLatLng().lng.toString();
        }
      },
    }),
    [lamp, setMarker, positioned, setNodesGroup, dispatch]
  );

  return (
    <>
      <Marker
        key={lamp.node_id}
        position={center}
        draggable={editable}
        riseOnHover
        eventHandlers={eventHandlers}
        riseOffset={100}
        zIndexOffset={selected || !positioned ? 150 : 0}
        icon={icon}
      ></Marker>
    </>
  );
};

export default CustomMarker;
