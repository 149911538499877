export const languages = {
  english: {
    accept: "Accept",
    actions: "Actions",
    activateAstroDIM: "Would you like to activate the Astro DIM?",
    activatedAstroDIMTitle: "Great, Astroclock is activated!",
    activatedAstroDIMSubtitle: "You can give an offset to the strategy",
    activatedAstroDIMSubtitleBold: " in minutes",
    active: "Active",
    addTime: "Add new time",
    alarm: "Alarm",
    alarms: "alarms",
    alarmsCapitalLetter: "Alarms",
    all: "All",
    back: "Back",
    backToDimmingConfiguration: "Back to Dimming configuration",
    backToGeneralDetails: "Back to General Details",
    backToOverview: "Back to Overview",
    battery: "Battery",
    bulkActions: "Bulk actions",
    cancel: "Cancel",
    case: "Case",
    charging: "Charging",
    clear: "Clear",
    clearAllFilters: "Clear all filters",
    clearFilter: "Clear filter",
    closeStrategyPopupTitle:
      "Do you want to close this form? All the information will be lost",
    color: "Color",
    confirmPassword: "Confirm Password",
    connected: "Connected",
    connectedLuminaires: "Connected luminaires",
    connection: "Connection",
    consumption: "Consumption",
    continue: "Continue",
    creationDate: "Creation date",
    critical: "Critical",
    currentStrategy: "Current strategy",
    darkLight: "Dark mode/Light mode",
    dashboard: "Dashboard",
    delete: "Delete",
    deletePoint: "Delete point",
    deleteStrategyPopupTitle:
      "Do you want to delete this strategy? Please write the name of the strategy to delete it",
    description: "Description",
    device: "Device",
    devicesSelected: "Devices selected",
    dimming: "Dimming",
    dimming1: "1%",
    dimming50: "50%",
    dimming100: "100%",
    dimmingAll: "Dimming all",
    dimmingAllLuminaires: "Dimming all luminaires",
    dimmingConfigurationTitle: "Dimming configuration",
    dimmingConfigurationSideMenu: "Configure your strategy",
    dimmingConfigurationSubtitle:
      "Give a strategy the configuration you prefer. The energy saving will be calculated on the next step.",
    dimmingConfigurationBoxes:
      "You can use the boxes to configure the time and dimming or the graph.",
    dimmingPopUpSubtitle:
      "Select the dimming you want to apply to the luminaires",
    dimmingPopUpTitle: "Dimming luminaires",
    disconnected: "Disconnected",
    editInfo: "Edit information",
    editChart: "Edit graphic",
    editStrategy: "Edit your strategy",
    electricParams: "Electrical parameters",
    email: "Email",
    energyGeneration: "Energy Generation (kWh)",
    energySaved: "25% energy saving",
    errorBlankInput: "Please provide valid data",
    errorConnection: "Ops! Not possible, please try again later",
    errorDimmingHour:
      "You have entered an invalid parameter. Please, provide a valid hour",
    errorDimmingNoHour:
      "The selected hour doesn't exist. Please, provide a valid hour",
    errorDimmingPoint:
      "The selected hour has no dimming point. Please, provide a valid hour",
    errorLogin: "Incorrect email or password",
    errorStrategyLimit:
      "You have reached the dimming points' limit on a strategy",
    errorWrongStrategy: "The strategy's name is not correct",
    events: "Events",
    expandDecrease: "Expand/Decrease",
    followSteps: "Follow the steps to create a new strategy",
    forgotPassword: "Forgot password?",
    forgotPasswordQ: "Forgot password?",
    forgotPasswordText:
      "Enter the email address that you used when you joined and we'll send you instructions to reset your password.",
    fromAToZ: "From A to Z",
    fromZToA: "From Z to A",
    gateway: "Gateway",
    gateways: "gateways",
    generalDetails: "General details",
    generalDetailsTitle: "General details",
    generalDetailsSideMenu: "Provide the general details of the strategy",
    generalDetailsSubtitle:
      "Give a strategy the name you prefer. This will help you identify it for future events.",
    groups: "Groups",
    hour: "Hour",
    id: "ID",
    lightStrategies: "Your lighting strategies",
    loading: "Loading...",
    logIn: "Log in",
    logInSmartec: "Log in to Smartec",
    luminaire: "Luminaire",
    luminaires: "luminaires",
    manufacturer: "Manufacturer",
    max60: "max 60 min",
    minute: "Minute",
    moderate: "Moderate",
    name: "Name",
    newGroup: "New group",
    newStrategy: "New strategy",
    nextReviewCreate: "Next: Review & Create",
    no: "No",
    none: "None",
    notPositioned: "Not positioned",
    OFF: "OFF",
    OFFAllLuminaires: "OFF all luminaires",
    ON: "ON",
    ONAllLuminaires: "ON all luminaires",
    OFFluminaires: "OFF luminaires",
    ONluminaires: "ON luminaires",
    optional: "(Optional)",
    password: "Password",
    past: "Past",
    percentageEnergySaved: "% of energy saving",
    poleNumber: "Pole number",
    power: "power",
    production: "Production",
    protocol: "Protocol",
    refresh: "Refresh",
    resetInstructions: "Send reset instructions",
    resetPassword: "Reset Password",
    review: "Review & Create",
    reviewStrategyTitle: "Review strategy configuration",
    reviewStrategySideMenu: "Verify all the information",
    reviewStrategySubtitle:
      "Review your strategy configuration before creation.",
    savings: "Savings",
    selectArea: "Select an area",
    selectColor: "Select color...",
    send: "Send",
    sendDimming: "Send dimming",
    setAutomaticSunrise: "Automatic dimming with the local sunrise's hour",
    setAutomaticSunset: "Automatic dimming with the local sunset's hour",
    shortDescription: "Short description of the strategy",
    shortLongDescription:
      "Short description about what is lighting strategies and how can help with the energy savings",
    solar: "Solar",
    standard: "Standard",
    start: "Start",
    status: "Status",
    step1: "STEP 1",
    step2: "STEP 2",
    step3: "STEP 3",
    strategies: "Strategies",
    strategiesLimit: "You have reached the strategies' limit",
    strategyConfiguration: "Strategy configuration",
    sunrise: "Sunrise",
    sunriseOffset: "SUNRISE OFFSET",
    sunset: "Sunset",
    sunsetOffset: "SUNSET OFFSET",
    switchOFF: "Switch OFF",
    temporaryEvents: "Temporary events",
    upcoming: "Upcoming",
    updateDate: "Update date",
    switchON: "Switch ON",
    times: "times",
    totalLuminaires: "Total luminaires",
    viewAll: "View all",
    viewDetails: "View details",
    viewHistoric: "View historic",
    welcomeTo: "Welcome to ",
    yes: "Yes",
    createStrategy: "Create new strategy",
    step3Title: "Review & Create",
    addPoint: "Add point",
    backToName: "Back to Name",
    nextDimming: "Next: Dimming",
    strategyDetailsTitle: "General details",
    strategyDetailsSubtitle:
      "Give a strategy the name you prefer. This will help you identify it for future events.",
    currentConsumption: "Current Consumption (Amps)",
  },

  catala: {
    cardInfo: {
      "Total luminaires": "Totes les lluminàries",
      "ON luminaires": "Lluminàries enceses",
      "Connected luminaires": "Lluminàries conectades",
      Alarms: "Alarmes",
      Consumption: "Consum",
    },
    filters: {
      SelectArea: "Selecciona una àrea",
      All: "Totes",
      Connected: "Conectat",
      Disconnected: "Desconectat",
      Alarms: "Alarmes",
      ON: "ON",
      OFF: "OFF",
    },
  },
};

export type Language = "english";

//una vez añadidas las traducciones es ir añadiendo al type Language el lenguaje añadido, y usar el hook useLanguage que lo que hará es setear el nuevo idioma. Por defecto inglés.

/*un ejemplo de uso seriá:
 <button onClick={()=>useLanguage("english")}>English</button>
 
 */
