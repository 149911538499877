import { Button } from "../../../styles/shared-styles/buttons-sizes";
import plusIcon from "../../../assets/plus.svg";
import { languages } from "../../../utils/languages/languages";
import { useAppSelector } from "../../../redux/hooks";
import { TextSpan } from "../../../styles/shared-styles/text-sizes";
import { useEffect, useRef, useState } from "react";
import { DropDownLabels, IconStyled } from "../SwitchBoardPageStyled";
import { useDispatch } from "react-redux";
interface IProps {
  dropDownData: { label: string; function: Function }[];
}
const ActionButton = ({ dropDownData }: IProps) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      // Clicked outside the dropdown, close it
      setDropDownOpen(false);
    }
  };

  const toggleDropdown = () => {
    setDropDownOpen(!dropDownOpen);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const dispatch = useDispatch();
  const txt = languages[currentLanguage];

  return (
    <div className="position-relative" ref={dropdownRef}>
      <Button
        buttonType="primary"
        style={{ width: "110px" }}
        onClick={toggleDropdown}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
            position: "relative",
          }}
        >
          <IconStyled src={plusIcon} height={18} />
          <TextSpan
            color="black"
            fontWeight="500"
            style={{ marginRight: "6px" }}
          >
            {txt.actions}
          </TextSpan>
        </div>
      </Button>

      {dropDownOpen && (
        <div
          className="position-absolute bg-white border rounded w-48 py-2 cursor-pointer text-start p-2 "
          style={{ right: "5px", width: "175px", cursor: "pointer" }}
        >
          {dropDownData.map((data: any) => (
            <DropDownLabels>
              <div key={data.label} className="" onClick={data.function}>
                {data.label}
              </div>
            </DropDownLabels>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActionButton;
