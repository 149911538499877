import { useState, useEffect } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  saveStrategyInfoActionCreator,
  changeSunsetActionCreator,
  changeSunriseActionCreator,
} from "../../../redux/slices/strategiesSlice/strategiesSlice";
import { useStrategiesService } from "../../../services/strategiesService";
import { Modal } from "react-bootstrap";
import StrategiesChart from "../StrategiesChart";
import EditChart from "./EditChart";
import { languages } from "../../../utils/languages/languages";
import { CenteredTitle } from "../NewStrategyModal/NewStrategyModalStyled";
import {
  InputField,
  TextAreaField,
  CardContainer,
  NextButton,
} from "../StrategiesSteps/StrategiesStepsStyled";
import styled from "styled-components";
import { Text } from "../../../styles/shared-styles/text-sizes";
import ellipseGreen from "../../../assets/new_icons/ellipse_green.svg";
import ellipseRed from "../../../assets/new_icons/ellipse_red.svg";
import ellipseYellow from "../../../assets/new_icons/ellipse_yellow.svg";
import ellipseBlack from "../../../assets/new_icons/ellipse_black.svg";
import ellipseBlue from "../../../assets/new_icons/ellipse_blue.svg";
import { useTranslation } from "react-i18next";

interface NewStrategyModalProps {
  item: any;
  showEditModal: boolean;
  handleShowEditModal: () => void;
  editDetails: boolean;
  setEditDetails: any;
  editChart: boolean;
  setEditChart: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 800px;
  }

  .modal-content {
    padding-bottom: 0px;
    background: rgba(236, 236, 236, 1);
    border: 0px;
  }
`;

export const EditStrategyModal = ({
  item,
  showEditModal,
  handleShowEditModal,
  editDetails,
  setEditDetails,
  editChart,
  setEditChart,
}: NewStrategyModalProps) => {
  const strategyInfo = useAppSelector((state) => state.strategies);
  const { getStrategy, editStrategyText, editStrategyChart } =
    useStrategiesService();
  const dispatch = useAppDispatch();
  const selectedOrgId: any = useAppSelector(
    (state) => state.devices.selectedOrganizationId
  );
  const selectedServiceId: any = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const [nameInput, setNameInput] = useState(item.strgy_name);
  const [selectedOptionInput, setSelectedOptionInput]: any = useState();
  const [selectedOption, setSelectedOption]: any = useState();
  const [descriptionInput, setDescriptionInput] = useState(item.description);
  const [errorMessage, setErrorMessage] = useState(false);
  const { t } = useTranslation();

  const options = [
    { value: "#56C568", label: t("Green"), icon: ellipseGreen },
    { value: "#D51A52", label: t("Red"), icon: ellipseRed },
    { value: "#FBBC05", label: t("Yellow"), icon: ellipseYellow },
    { value: "#1C1B1F", label: t("Black"), icon: ellipseBlack },
    { value: "#87CBE8", label: t("Blue"), icon: ellipseBlue },
  ];

  const formatOptionLabel = ({ value, label, icon }: any) => (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <img src={icon} alt="" style={{ marginRight: "8px", marginTop: "1px" }} />
      <Text
        textType="caption"
        color="#959595"
        marginBottom="0px"
        style={{ fontSize: "12px" }}
      >
        {label}
      </Text>
    </div>
  );

  const changeDetails = async () => {
    if (nameInput !== "" && selectedOptionInput !== undefined) {
      if (!selectedOption) {
        await editStrategyText(
          nameInput,
          selectedOptionInput.value,
          descriptionInput,
          item.id
        );
      } else {
        await editStrategyText(
          nameInput,
          selectedOption.value,
          descriptionInput,
          item.id
        );
      }
      await getStrategy(selectedOrgId, selectedServiceId);
      handleShowEditModal();
    } else {
      setErrorMessage(true);
    }
  };

  const changeChart = async () => {
    await editStrategyChart(strategyInfo.labels, strategyInfo.data, item.id);
    handleShowEditModal();
  };

  useEffect(() => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === item.color) {
        setSelectedOptionInput(options[i]);
        break;
      }
    }
    dispatch(
      saveStrategyInfoActionCreator({
        name: nameInput,
        color: selectedOption,
        description: descriptionInput,
      })
    );
  }, [dispatch, nameInput, selectedOption, descriptionInput]);

  return (
    <CustomModal show={showEditModal} onHide={handleShowEditModal} centered>
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          padding: "20px 20px 0 20px",
          background: "rgba(236, 236, 236, 0.92)",
          zIndex: 2,
        }}
      ></Modal.Header>
      <Modal.Body
        style={{
          background: "rgba(236, 236, 236, 0.92)",
          marginBottom: "10px",
          paddingTop: "0px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        {editDetails && (
          <>
            <CenteredTitle>
              <Text
                fontWeight="700"
                color="#475569"
                style={{ fontSize: "22px" }}
              >
                {t(txt.editStrategy)}
              </Text>
            </CenteredTitle>
            <div className="col-12">
              <CardContainer>
                <div
                  className="row"
                  style={{ marginBottom: "16px", textAlign: "left" }}
                >
                  <div className="col-6">
                    <Text textType="caption" color="#475569" marginBottom="0px">
                      {" "}
                      {t(txt.name)}
                    </Text>
                    <InputField
                      value={nameInput}
                      onChange={(e) => setNameInput(e.target.value)}
                      placeholder={t(txt.name)}
                    />
                  </div>
                  <div className="col-6">
                    <Text textType="caption" color="#475569" marginBottom="0px">
                      {t(txt.color)}
                    </Text>
                    <Select
                      value={
                        selectedOption ? selectedOption : selectedOptionInput
                      }
                      onChange={setSelectedOption}
                      options={options}
                      formatOptionLabel={formatOptionLabel}
                      placeholder={t(txt.selectColor)}
                      styles={{
                        control: (baseStyles, state) => {
                          state.theme.colors.primary = "none";
                          return {
                            ...baseStyles,
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            color: "#212529",
                            maxHeight: "36px",
                            minHeight: "36px",
                            fontSize: "12px",
                            fontWeight: "400",
                            borderRadius: "0.375rem",
                            border: state.isFocused
                              ? "2px solid"
                              : "1px solid #ced4da",
                            "&:hover": {
                              borderColor: "none",
                            },
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{
                    marginBottom: errorMessage ? "11px" : "32px",
                    textAlign: "left",
                  }}
                >
                  <Text textType="caption" color="#475569" marginBottom="0px">
                    {t(txt.description)}
                  </Text>
                  <TextAreaField
                    value={descriptionInput}
                    onChange={(e) => setDescriptionInput(e.target.value)}
                    placeholder={t(txt.description)}
                  />
                </div>
                {errorMessage ? (
                  <Text
                    marginBottom="0px"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t(txt.errorBlankInput)}
                  </Text>
                ) : (
                  <></>
                )}
              </CardContainer>
            </div>
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <NextButton onClick={() => {changeDetails()}}>{t(txt.send)}</NextButton>
              </div> */}
          </>
        )}
        {editChart && (
          <>
            <CenteredTitle>
              <Text
                fontWeight="700"
                color="#475569"
                style={{ fontSize: "22px" }}
              >
                {t(txt.editStrategy)}
              </Text>
            </CenteredTitle>
            <div className="col-12">
              <CardContainer>
                <div
                  className="row"
                  style={{ marginBottom: "16px", textAlign: "left" }}
                >
                  <EditChart item={item} />
                  {/* <StrategiesChart
                      chartData={[item.dim1, item.dim2, item.dim3, item.dim4, item.dim5, item.dim6, item.dim7, item.time1, item.time2, item.time3, item.time4, item.time5, item.time6, item.time7]}
                      borderColor={item.color}
                      backgroundColor={item.color}
                      show={showEditModal}
                    />    */}
                </div>
                {errorMessage ? (
                  <Text
                    marginBottom="0px"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {t(txt.errorBlankInput)}
                  </Text>
                ) : (
                  <></>
                )}
              </CardContainer>
            </div>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <NextButton
            onClick={() => {
              editDetails ? changeDetails() : changeChart();
            }}
          >
            {t(txt.send)}
          </NextButton>
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
