import { AxiosResponse } from "axios";
import axiosInstance from "../shared/axios";
import {
  FeederConsumptionResponse,
  MonthlyConsumptionResponse,
} from "../types/interfaces";

interface IAddNodeToFeeder {
  feeder_id: string;
  node_ids: string[];
}
interface IgetFeederPayload {
  switchboardId: string;
  page?: string;
  page_size?: string;
}

export interface CreateFeederPayload {
  name: string;
  color: string;
  switchboard_id: string;
  is_active: boolean;
}

export const getFeeders = async ({ switchboardId }: IgetFeederPayload) => {
  const response = await axiosInstance.get(
    `/api/feeders/?switchboardId=${switchboardId}&page=${"1"}&page_size=${"1000"}&with_nodes=true`
  );
  return response.data;
};
export const createFeeders = async (payload: CreateFeederPayload) => {
  const response = await axiosInstance.post(`/api/feeders`, payload);
  return response;
};
export const changeFeederStatus = async (
  payload: { is_active: boolean },
  feederId: string
) => {
  const response = await axiosInstance.put(`/api/feeders/${feederId}`, payload);
  return response;
};
export const getSingleFeeder = async (id: string) => {
  const response = await axiosInstance.get(`/api/feeders/${id}`);
  return response.data.data;
};
export const addNodeToFeeder = async (payload: IAddNodeToFeeder) => {
  const response = await axiosInstance.put(`/api/feeders/addNode`, payload);
  return response;
};
export const getUnassignedNodes = async (switchBoardId: string) => {
  const response = await axiosInstance.get(
    `/api/feeders/${switchBoardId}/unassigned/nodes`
  );
  return response.data.data;
};
export const getFeederConsumption = async (
  switchBoardId: string,
  feeder_id: string
) => {
  const response: AxiosResponse<FeederConsumptionResponse> =
    await axiosInstance.get(
      `/api/consumption/feeders/${switchBoardId}${
        feeder_id !== "General" ? `?feeder_id=${feeder_id}` : ""
      }`
    );
  return response.data;
};
export const getFeederConsumptionMonthly = async (
  switchBoardId: string,
  feeder_id: string
) => {
  const response: AxiosResponse<MonthlyConsumptionResponse> =
    await axiosInstance.get(
      `/api/consumption/feeders/${switchBoardId}/monthly${
        feeder_id !== "General" ? `?feeder_id=${feeder_id}` : ""
      }`
    );
  return response.data;
};

export const deleteFeeder = async (feeder_id: string) => {
  const response: any = await axiosInstance.delete(`/api/feeders/${feeder_id}`);
  return response;
};

export const updateFeeder = async ({ feeder_id, payload }: any) => {
  const response: any = await axiosInstance.put(
    `/api/feeders/${feeder_id}`,
    payload
  );
  return response;
};
