import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { errorToInitialStateActionCreator } from "../../redux/slices/mqttSlice/mqttSlice";
import {
  setIsOpenedDetailActionCreator,
  setIsOpenedGroupActionCreator,
  setIsStaticViewActionCreator,
  setViewsToFalseActionCreator,
} from "../../redux/slices/uiSlice/uiSlice";
import { ISwitchboard, Lamp as NodeDevice } from "../../types/interfaces";
import { useLightingService } from "../../services/lightingService";
import { initialLamp, initialLampsGroup } from "../../utils/data";
import { googleIcons } from "../../utils/googleFontsIcons/icons";
import { languages } from "../../utils/languages/languages";
import {
  DetailPopupStyled,
  BatteryLevel,
  DetailPopupHybridViewStyled,
} from "./DetailPopUpStyled";
import { TextBox, TextSpan } from "../../styles/shared-styles/text-sizes";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/new_icons/arrow_right_full.svg";
import Location from "../../assets/new_icons/Location.svg";
import bulbOff from "../../assets/icons/icon-bulb-off.svg";

interface DetailPopupProps {
  node: NodeDevice;
  setSelectedMarker: React.Dispatch<React.SetStateAction<string>>;
  setNodesGroup: React.Dispatch<React.SetStateAction<string[]>>;
  switchboard?: ISwitchboard;
}

export const DetailPopup = ({
  node,
  setSelectedMarker,
  setNodesGroup,
  switchboard,
}: DetailPopupProps) => {
  const fatherHeight = document
    .getElementById("map-container")
    ?.getBoundingClientRect().height!;
  const fatherWidth = document
    .getElementById("map-container")
    ?.getBoundingClientRect().width!;

  const dispatch = useAppDispatch();
  const { sendCommandToLuminaire, getNodeInfo } = useLightingService();
  const navigate = useNavigate();
  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);
  const mqttError = useAppSelector((state) => state.mqtt.error);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const showNotPositionedNodes = useAppSelector(
    (state) => state.shared.showNotPositionedNodes
  );

  const {
    close,
    move,
    error,
    lightBulb,
    connection,
    disconnection,
    arrowForward,
    location,
    sadFace,
  } = googleIcons;

  const customWidth = fullView ? 420 : 380;
  const customHeight = fullView ? 228 : 50;

  const initialPosition = {
    x: fullView
      ? fatherWidth! - customWidth - 45 ?? 50
      : fatherWidth! - customWidth - 35 ?? 50,
    y: fullView
      ? fatherHeight! / 2 - customHeight / 2 ?? 50
      : fatherHeight! / 16.8 - customHeight / 10 ?? 100,
  };

  const position = ` ${node?.latitude}, 
    ${node?.longitude}`;

  /*const initialPosition = {
    x: fatherWidth! - customWidth - 45 ?? 50,
    y: fatherHeight! / 2 - customHeight / 2 ?? 50
  };*/
  const [newPosition, setNewPosition] = useState(initialPosition);
  const [origin, setOrigin] = useState({ x: 0, y: 0 });
  const [value, setValue] = useState(73);
  const [barColor, setBarColor] = useState(
    `linear-gradient(to right, #56C568 ${value}%, #d2d4d9 ${value}%)`
  );
  const [bulkActions, setBulkActions] = useState(false);

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.setProperty("display", "none");
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const startX = event.clientX - newPosition.x;
    const startY = event.clientY - newPosition.y;
    setOrigin({ x: startX, y: startY });
  };

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    let x = event.clientX - origin.x;
    const y = event.clientY - origin.y;
    event.currentTarget.style.setProperty("display", "block");
    if (
      x > fatherWidth - customWidth ||
      y > fatherHeight - customHeight ||
      x < 0 ||
      y < 0
    ) {
      alert("Out of the map");

      return;
    }

    setNewPosition({ x, y });
  };

  const handleClick = async (order: number) => {
    //0 to off 1 to on
    try {
      let result: any = sendCommandToLuminaire([node], order, 0);
      //      console.log("e")
      //      console.log(result[0][0])
      //      setStatus(result[0][0].result);
    } catch (error) {
      alert(error);
    }
  };

  const handleClose = () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(setIsStaticViewActionCreator(true));
    dispatch(errorToInitialStateActionCreator());
    setSelectedMarker(initialLamp.node_id);
    setNodesGroup(initialLampsGroup.group);
  };

  const handleClickViewDetails = async () => {
    handleClose();
    let viewState = "";
    if (fullView) {
      viewState = "full";
    } else if (hybridView) {
      viewState = "hybrid";
    }
    await getNodeInfo(node.node_id);
    dispatch(setViewsToFalseActionCreator());
    navigate(`/luminaire/${node.node_id}`, { state: { viewState } });
  };

  const { t } = useTranslation();
  if (switchboard) {
    return fullView ? (
      <DetailPopupStyled
        id="detail-popup"
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        draggable={true}
        style={{
          position: "absolute",
          height: "160px",
          //height: mqttError ? "238px" : node.bri !== null ? "301px" : customHeight,
          width: customWidth,
          left: `${newPosition.x}px`,
          top: `${newPosition.y}px`,
        }}
      >
        <div className="popup__button-container">
          {/* <span className="material-symbols-rounded popup__button-container__icon move">
          {move}
        </span> */}
          <span className="material-symbols-rounded popup__button-container__icon move">
            {move}
          </span>
          <span
            onClick={handleClose}
            className="material-symbols-rounded popup__button-container__icon"
          >
            {close}
          </span>
        </div>
        <h1 className="popup__title">{switchboard.switchboard_name}</h1>
        <div className="popup__details-container">
          <div className="details-container__details"></div>
          <div className="details-container__details">
            <span
              className={`material-symbols-rounded details-container__icon ${
                switchboard.is_active ? "details-container__icon--on" : ""
              }`}
            >
              {switchboard.is_active ? connection : disconnection}
            </span>{" "}
            <span>
              {switchboard.is_active ? t("Connected") : t("Disconnected")}
            </span>
          </div>
          <div className="details-container__details">
            <span className="material-symbols-rounded details-container__icon">
              {location}
            </span>{" "}
            <span>{`${switchboard.longitude}, ${switchboard.latitude}`}</span>
          </div>
          {/* <div className="popup__status-container">
            <span className="material-symbols-rounded status-container__icon">
              {lightBulb}
            </span>
            <span className="status-container__content">{t("Status")}</span>
          </div> */}
        </div>

        {/* <div className="popup__status-container mb-4">
          <img src={bulbOff} alt="bulb" />
          <span className="status-container__content">{txt.status}</span>
        </div> */}
        <div style={{ paddingRight: "15px", paddingLeft: "18px" }}></div>
        <div className="popup__link-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.75rem",
            }}
            onClick={() => {
              navigate(`/switchBoard/${switchboard.switchboard_id}`);
            }}
          >
            {txt.viewDetails}
            <span className="material-symbols-rounded">{arrowForward}</span>
          </div>
        </div>
      </DetailPopupStyled>
    ) : (
      <DetailPopupHybridViewStyled
        style={{
          position: "absolute",
          height: mqttError ? "238px" : customHeight,
          width: customWidth,
          // left: `${newPosition.x}px`,
          // top: `${newPosition.y}px`,
          right: "2vw",
          top: "30px",
        }}
      >
        <div className="hybridpopup__block">
          <h1 className="hybridpopup__block__title">
            {switchboard?.switchboard_name}
          </h1>
          <div className="hybridpopup__block__position">
            <span className="material-symbols-rounded hybridpopup__block__position--icon">
              {location}
            </span>
            <span className="hybridpopup__block__position--text">
              {`${switchboard.longitude}, ${switchboard.latitude}`}
            </span>
          </div>
          <span
            onClick={handleClose}
            className="material-symbols-rounded hybridpopup__block__close"
          >
            {" "}
            close
          </span>
        </div>
        <div
          className="hybridpopup__bulk"
          onClick={() => {
            navigate(`/switchBoard/${switchboard.switchboard_id}`);
          }}
        >
          <span className="material-symbols-rounded hybridpopup__bulk__icon">
            {arrowForward}
          </span>
        </div>

        {/* 
          // <div className="hybridpopup__detail">
          //   <span
          //     className="material-symbols-rounded"
          //     onClick={() => {
          //       navigate(`/switchBoard/${switchboard.switchboard_id}`);
          //     }}
          //     style={{
          //       display: "flex",
          //       background: "rgb(255, 203, 33)",
          //       justifyContent: "center",
          //       alignItems: "center",
          //       width: "30px",
          //       height: "30px",
          //       borderRadius: "50%",
          //     }}
          //   >
          //     {arrowForward}
          //   </span>
          // </div> */}
      </DetailPopupHybridViewStyled>
    );
  }
  if (node) {
    return fullView ? (
      <>
        <DetailPopupStyled
          id="detail-popup"
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          draggable={true}
          style={{
            position: "absolute",
            height: mqttError
              ? node.solar_id !== null
                ? "312px"
                : "238px"
              : node.solar_id !== null
              ? "301px"
              : "238px",
            //height: mqttError ? "238px" : node.bri !== null ? "301px" : customHeight,
            width: customWidth,
            left: `${newPosition.x}px`,
            top: `${newPosition.y}px`,
          }}
        >
          <div className="popup__button-container">
            <span className="material-symbols-rounded popup__button-container__icon move">
              {move}
            </span>
            <span
              onClick={handleClose}
              className="material-symbols-rounded popup__button-container__icon"
            >
              {close}
            </span>
          </div>
          <h1 className="popup__title">{node.name}</h1>
          <div className="popup__details-container">
            <div className="details-container__details">
              <span
                className={`material-symbols-rounded details-container__icon ${
                  node.online ? "details-container__icon--on" : ""
                }`}
              >
                {node.online ? connection : disconnection}
              </span>{" "}
              <span>{node.online ? t("Connected") : t("Disconnected")}</span>
            </div>
            <div className="details-container__details">
              <span className="material-symbols-rounded details-container__icon">
                {location}
              </span>{" "}
              <span>{position}</span>
            </div>
            <div className="details-container__details">
              <span className="material-symbols-rounded details-container__icon details-container__icon--alarm">
                {error}
              </span>{" "}
              <span>{node.alarm_status != 0 ? 1 : 0}</span>
            </div>
          </div>
          {node.solar_id !== null ? (
            <BatteryLevel>
              <TextBox>
                {t("Battery")}
                {/* {value} */} 73%
              </TextBox>
              <input
                type="range"
                min="1"
                max="100"
                //value={value}
                className="battery__progress-bar"
                style={{ background: barColor }}
              />
              <div className="battery__percentages">
                <TextBox textType="caption" color="#A3AED0">
                  {txt.dimming1}
                </TextBox>
                <TextBox textType="caption" color="#A3AED0">
                  {txt.dimming50}
                </TextBox>
                <TextBox textType="caption" color="#A3AED0">
                  {txt.dimming100}
                </TextBox>
              </div>
            </BatteryLevel>
          ) : (
            <></>
          )}
          <div className="popup__status-container">
            <span className="material-symbols-rounded status-container__icon">
              {lightBulb}
            </span>
            <span className="status-container__content">{t("Status")}</span>
          </div>
          <div style={{ paddingRight: "15px", paddingLeft: "18px" }}>
            <div className="popup__onoff-container">
              <button
                onClick={() => handleClick(2)}
                disabled={node.online === 0 ? true : false}
                className={
                  node.online === 0
                    ? "onoff-container__button onoff-container__button--off-disabled"
                    : node.on === 0
                    ? "onoff-container__button onoff-container__button--off"
                    : "onoff-container__button"
                }
              >
                {t("OFF")}
              </button>
              <button
                onClick={() => handleClick(1)}
                disabled={node.online === 0 ? true : false}
                className={
                  node.online === 0
                    ? "onoff-container__button onoff-container__button--on-disabled"
                    : node.on === 1
                    ? "onoff-container__button onoff-container__button--on"
                    : "onoff-container__button"
                }
              >
                {t("ON")}
              </button>
            </div>
            {mqttError && (
              <div className="popup__message-container">
                <span
                  className="material-symbols-rounded details-container__icon"
                  style={{ color: "#CF2A2A", marginRight: "5px" }}
                >
                  {sadFace}
                </span>
                <TextSpan color="#CF2A2A">{t(txt.errorConnection)}</TextSpan>
              </div>
            )}
          </div>
          <div
            className="popup__link-container"
            onClick={() => {
              handleClickViewDetails();
            }}
          >
            <span className="link-container__link">{t("View Details")}</span>
            <span className="material-symbols-rounded">{arrowForward}</span>
          </div>
        </DetailPopupStyled>
      </>
    ) : (
      <DetailPopupHybridViewStyled
        style={{
          position: "absolute",
          height: mqttError ? "238px" : customHeight,
          width: customWidth,
          // left: `${newPosition.x}px`,
          // top: `${newPosition.y}px`,
          right: "2vw",
          top: "30px",
        }}
      >
        <div className="hybridpopup__block">
          <h1 className="hybridpopup__block__title">{node.name}</h1>
          <div className="hybridpopup__block__position">
            <span className="material-symbols-rounded hybridpopup__block__position--icon">
              {location}
            </span>
            <span className="hybridpopup__block__position--text">
              {position}
            </span>
          </div>
          <span
            onClick={handleClose}
            className="material-symbols-rounded hybridpopup__block__close"
          >
            {close}
          </span>
        </div>
        <div
          className="hybridpopup__bulk"
          onClick={() => setBulkActions(!bulkActions)}
        >
          <span className="material-symbols-rounded hybridpopup__bulk__icon">
            {googleIcons.add}
          </span>
        </div>
        {bulkActions && (
          <article className="hybridpopup__bulk__container">
            <span
              onClick={() => (node.online === 1 ? handleClick(1) : <></>)}
              id="on"
              className={
                node.online === 0
                  ? "hybridpopup__bulk__container--action-disabled"
                  : "hybridpopup__bulk__container--action"
              }
            >
              {t("ON")}
            </span>
            <span
              onClick={() => (node.online === 1 ? handleClick(2) : <></>)}
              id="off"
              className={
                node.online === 0
                  ? "hybridpopup__bulk__container--action-disabled"
                  : "hybridpopup__bulk__container--action"
              }
            >
              {t("OFF")}
            </span>
          </article>
        )}
      </DetailPopupHybridViewStyled>
    );
  }
  return <></>;
};
