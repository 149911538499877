import { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LatLng } from "leaflet";
import { useAppSelector, useAppDispatch } from "./redux/hooks";
import {
  filterAlarmDevicesActionCreator,
  filterDisconnectedDevicesActionCreator,
  filterOffDevicesActionCreator,
  filterOnDevicesActionCreator,
  filteredAllDevicesActionCreator,
} from "./redux/slices/devices/filteredDevicesSlice";
import { turnAllFalseActionCreator } from "./redux/slices/graphicsSlice/graphicsSlice";
import { errorToInitialStateActionCreator } from "./redux/slices/mqttSlice/mqttSlice";
import {
  setIsFullViewActionCreator,
  setIsListViewActionCreator,
  setIsHybridViewActionCreator,
  setIsOpenedDetailActionCreator,
  setIsOpenedGroupActionCreator,
  setFirstRenderMapToTrueActionCreator,
  setIsStaticViewActionCreator,
} from "./redux/slices/uiSlice/uiSlice";
import { CustomMap } from "./components/CustomMaps/CustomMap/CustomMap";
import { DataCardList } from "./components/DataCardList/DataCardList";
import { DimmingPopUp } from "./components/DimmingPopUp/DimmingPopUp";
import { Events } from "./components/Events/Events";
import { ForgotPassword } from "./components/ForgotPassword/ForgotPassword";
import { HybridMenuTable } from "./components/Lists/HybridMenu/HybridMenuTable";
import Layout from "./components/Layout/Layout";
import { ListTable } from "./components/Lists/ListMenu/ListTable";
import { LocalWeather } from "./components/LocalWeather/LocalWeather";
import { Login } from "./pages/Login/loginPage";
import { LuminairePage } from "./components/LuminairePage/LuminairePage";
import { ProfilePage } from "./components/ProfilePage/ProfilePage";
import { SillyMap } from "./components/CustomMaps/CustomMap/SillyMap";
import { StrategiesPage } from "./components/StrategiesPage/Strategies";
import Users from "./components/Users";
import { NotFoundPage } from "./pages/ResponsePages/404page";
import { ErrorPage } from "./pages/ResponsePages/errorPage";
import { ComingSoon } from "./pages/ResponsePages/comingSoonPage";
import { ThemeProvider } from "styled-components";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import {
  LayoutGreyShadow,
  LayoutMenu,
  LayoutFirstItem,
  LayoutSecondItem,
  LayoutThirdItem,
} from "./AppStyled";
import { styledMainTheme } from "./styles/shared-styles/styleMainTheme";
import "bootstrap/dist/css/bootstrap.min.css"; // import the Bootstrap CSS file
import "bootstrap/dist/js/bootstrap.js"; // import the Bootstrap JS file
import grid from "./assets/new_icons/grid.svg";
import viewHybrid from "./assets/new_icons/view-hybrid.svg";
import viewList from "./assets/new_icons/view-list.svg";
import Cookies from "js-cookie";
import { useUserService } from "./services/userService";
import { RootState } from "./redux/store";
import UnTaggedNodesTable from "./components/Lists/ListMenu/UnTaggedNodesTable";
import Switchboard from "./components/SwitchBoard";
import useSwitchBoard from "./hooks/useSwitchBoard/useSwitchBoard";

const initialPosition = new LatLng(0, 0);
const App = (): JSX.Element => {
  const darkTheme = false; //This variable will be changed for a ui state variable actualtheme
  const sessionId = localStorage.getItem("sessionId"); // esto habría que implementarlo?
  const theme = darkTheme ? styledMainTheme.dark : styledMainTheme.light;

  const mapFullSize = { width: "100%", height: "100%" };
  const mapHalfSize = { height: "100%" };
  const [nodesLength, setNodesLength] = useState<any>(null);

  const [center, setCenter] = useState(initialPosition);
  const nodes = useAppSelector((state) => state.devices.nodes);
  const devices = useAppSelector((state) => state.filteredDevices);
  const lampData = devices.nodes.filter((lamp) => lamp.latitude);

  const dispatch = useAppDispatch();

  const background = useAppSelector((state) => state.graphics.background);
  const showNotPositionedNodes = useAppSelector(
    (state: RootState) => state.shared.showNotPositionedNodes
  );
  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);
  const listView = useAppSelector((state) => state.ui.isListView);

  const [gridOpacity, setGridOpacity]: any = useState(fullView ? 1 : 0.2);
  const [hybridOpacity, setHybridOpacity] = useState(hybridView ? 1 : 0.2);
  const [listOpacity, setListOpacity] = useState(listView ? 1 : 0.2);
  const disconnectedLuminaires = nodes
    .filter((luminaire: any) => !luminaire.online)
    .length.toString();

  const onLuminaries = nodes
    .filter((luminaire: any) => luminaire.on && luminaire.online)
    .length.toString();

  const offLuminaries = nodes
    .filter((luminaire: any) => !luminaire.on && luminaire.online)
    .length.toString();

  const [disconnected, setDisconnected] = useState<any>(null);
  const [onLumin, setOnLumin] = useState<any>(null);
  const [offLumin, setOffLumin] = useState<any>(null);
  const [lamps, setLamps] = useState<any>();

  const allNodes = useAppSelector((state) => state.devices.nodes);
  const allDevices = useAppSelector((state) => state.devices);
  const filterSelected = useAppSelector((state) => state.actualFilter);
  const { getUserProfile } = useUserService();

  const setStatesToFalse = () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(turnAllFalseActionCreator());
    dispatch(errorToInitialStateActionCreator());
    dispatch(setFirstRenderMapToTrueActionCreator());
  };

  const toggleFullViewVisibility = () => {
    setHybridOpacity(0.2);
    setListOpacity(0.2);
    setStatesToFalse();
    if (filterSelected.all) {
      dispatch(filteredAllDevicesActionCreator(allDevices));
    } else if (filterSelected.on) {
      dispatch(filterOnDevicesActionCreator(allDevices));
    } else if (filterSelected.off) {
      dispatch(filterOffDevicesActionCreator(allDevices));
    } else if (filterSelected.disconnected) {
      dispatch(filterDisconnectedDevicesActionCreator(allDevices));
    } else if (filterSelected.alarmas) {
      dispatch(filterAlarmDevicesActionCreator(allDevices));
    }
    dispatch(setIsFullViewActionCreator());
  };

  const toggleListViewVisibility = () => {
    setHybridOpacity(0.2);
    setGridOpacity(0.2);
    setStatesToFalse();
    dispatch(setIsListViewActionCreator());
  };

  const toggleHybridViewVisibility = () => {
    setGridOpacity(0.2);
    setListOpacity(0.2);
    setStatesToFalse();
    if (filterSelected.all) {
      dispatch(filteredAllDevicesActionCreator(allDevices));
    } else if (filterSelected.on) {
      dispatch(filterOnDevicesActionCreator(allDevices));
    } else if (filterSelected.off) {
      dispatch(filterOffDevicesActionCreator(allDevices));
    } else if (filterSelected.disconnected) {
      dispatch(filterDisconnectedDevicesActionCreator(allDevices));
    } else if (filterSelected.alarmas) {
      dispatch(filterAlarmDevicesActionCreator(allDevices));
    }
    dispatch(setIsHybridViewActionCreator());
  };

  useEffect(() => {
    if (fullView) {
      setGridOpacity(1);
      setHybridOpacity(0.2);
      setListOpacity(0.2);
    }
    navigator.geolocation.getCurrentPosition((position) => {
      const center = new LatLng(
        position.coords.latitude,
        position.coords.longitude
      );

      setCenter(center);
    });
  }, [allNodes, fullView]);

  useEffect(() => {
    if (hybridView) {
      setGridOpacity(0.2);
      setHybridOpacity(1);
      setListOpacity(0.2);
    }
  }, [hybridView]);

  const user = useAppSelector((state: RootState) => state.user);
  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      getUserProfile(token);
      if (user) {
        console.log("Profile", user);
      }
    }
  }, []);
  useEffect(() => {
    setNodesLength(nodes.length.toString());
    setDisconnected(disconnectedLuminaires);
    setOnLumin(onLuminaries);
    setOffLumin(offLuminaries);
  }, []);

  useEffect(() => {
    if (showNotPositionedNodes === true) {
      setLamps(lampData);
    }
  }, [devices]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/dashboard",
      element: (
        <div>
          {background ? (
            <LayoutGreyShadow>
              <DimmingPopUp />
            </LayoutGreyShadow>
          ) : (
            <></>
          )}
          <Layout>
            <div className="main-page">
              <DataCardList
                length={nodesLength}
                onLumin={onLumin}
                disConnected={disconnected}
                offLumin={offLumin}
              />
              <LayoutMenu>
                <LayoutFirstItem>
                  <img
                    src={viewHybrid}
                    style={{ opacity: hybridOpacity }}
                    onMouseEnter={() =>
                      !hybridView ? setHybridOpacity(1) : <></>
                    }
                    onMouseLeave={() =>
                      !hybridView ? setHybridOpacity(0.2) : <></>
                    }
                    width={26}
                    height={26}
                    onClick={toggleHybridViewVisibility}
                  />
                </LayoutFirstItem>
                <LayoutSecondItem>
                  <img
                    src={viewList}
                    style={{ opacity: listOpacity }}
                    onMouseEnter={() => (!listView ? setListOpacity(1) : <></>)}
                    onMouseLeave={() =>
                      !listView ? setListOpacity(0.2) : <></>
                    }
                    width={21}
                    height={21}
                    onClick={toggleListViewVisibility}
                  />
                </LayoutSecondItem>
                <LayoutThirdItem>
                  <img
                    src={grid}
                    style={{ opacity: gridOpacity }}
                    onMouseEnter={() => (!fullView ? setGridOpacity(1) : <></>)}
                    onMouseLeave={() =>
                      !fullView ? setGridOpacity(0.2) : <></>
                    }
                    width={15}
                    height={15}
                    onClick={toggleFullViewVisibility}
                  />
                </LayoutThirdItem>
              </LayoutMenu>
              {allNodes.length > 0 ? (
                <>
                  {fullView && (
                    <>
                      {lamps && (
                        <CustomMap
                          mapSize={mapFullSize}
                          center={center}
                          lamps={lamps}
                        />
                      )}

                      <LocalWeather />
                    </>
                  )}
                  {listView && <>{showNotPositionedNodes && <ListTable />}</>}
                  {hybridView && (
                    <>
                      <div
                        className="row"
                        style={{ minHeight: "80%", position: "relative" }}
                      >
                        <div className="col-6">
                          <CustomMap
                            center={center}
                            mapSize={mapHalfSize}
                            lamps={lamps}
                          />
                        </div>
                        <LocalWeather />

                        <div className="col-6">
                          <HybridMenuTable />
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {fullView && (
                    <>
                      <SillyMap mapSize={mapFullSize} center={center} />
                      <LocalWeather />
                    </>
                  )}
                  {listView && <ListTable />}
                  {hybridView && (
                    <>
                      <div className="row" style={{ minHeight: "84.2%" }}>
                        <div className="col-6">
                          <SillyMap center={center} mapSize={mapHalfSize} />
                          <LocalWeather />
                        </div>
                        <div className="col-6">
                          <HybridMenuTable />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Layout>
        </div>
      ),
    },
    {
      path: "/luminaire/:nodeId",
      element: (
        <Layout>
          <LuminairePage />
        </Layout>
      ),
    },
    {
      path: "/strategies",
      element: (
        <Layout>
          <StrategiesPage />
        </Layout>
      ),
    },
    {
      path: "/profile",
      element: (
        <Layout>
          <ProfilePage />
        </Layout>
      ),
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/users",
      element: (
        <Layout>
          <Users user={user} />
        </Layout>
      ),
    },
    {
      path: "/events",
      element: (
        <Layout>
          <Events />
        </Layout>
      ),
    },
    {
      path: "/404",
      element: (
        <Layout>
          <NotFoundPage />
        </Layout>
      ),
    },
    {
      path: "/error",
      element: (
        <Layout>
          <ErrorPage />
        </Layout>
      ),
    },
    {
      path: "/coming-soon",
      element: (
        <Layout>
          <ComingSoon />
        </Layout>
      ),
    },
    {
      path: "/switchboard/:switchboardId",
      element: (
        <Layout>
          <Switchboard />
        </Layout>
      ),
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;

/*
  //dejo esto comentado para cuando apliquemos lógica a login
  const router = createBrowserRouter([
    {
      path: "/",
      element: login ? (
        <Layout>
          <div className="main-page">
            <DataCardList />
            <div className="row">
              <div className="col-12">
                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    padding: 0,
                    margin: 0,
                    float: "right",
                  }}
                >
                  <li style={{ marginRight: "10px", alignSelf: "center" }}>
                    <img
                      src={viewHybrid}
                      height={24}
                      onClick={toggleGridViewVisibility}
                    />
                  </li>
                  <li style={{ marginRight: "10px", alignSelf: "center" }}>
                    <img
                      src={viewList}
                      height={18}
                      onClick={toggleListViewVisibility}
                    />
                  </li>
                  <li style={{ marginRight: "10px", alignSelf: "center" }}>
                    <img
                      src={grid}
                      height={12}
                      onClick={toggleFullViewVisibility}
                    />
                  </li>
                </ul>
              </div>
            </div>
            {isFullViewVisible && (
              <>
                <CustomMap mapSize={mapFullSize} center={center} />
                <LocalWeather />
              </>
            )}
            {isListViewVisible && <ListMenu />}

            {isGridViewVisible && (
              <>
                <div className="row">
                  <div className="col-6">
                    <CustomMap center={center} mapSize={mapFullSize} />
                    <LocalWeather />
                  </div>
                  <div className="col-6">
                    <HybridMenuTable />
                  </div>
                </div>
              </>
            )}
          </div>
        </Layout>
      ) : (
        <Login
          username={username}
          password={password}
          setlogin={setlogin}
          handleLogin={handleLogin}
          isLoading={isLoading}
        />
      ),
    },

    {
      path: "/luminaries",
      element: (
        <Layout>
          <LuminairePage />
        </Layout>
      ),
    },
    {
      path: "/strategies",
      element: (
        <Layout>
          <StrategiesPage />
        </Layout>
      ),
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};
*/
