import { useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ElectricalParametersChart from "./SavingsChart";
import { languages } from "../../../../utils/languages/languages";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import plusCircle from "../../../../assets/new_icons/plusCircle.svg";
import { useTranslation } from "react-i18next";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface EnergyGenerationProps {}

export const Savings = ({}: EnergyGenerationProps) => {
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();
  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        justifyContent: "space-between",
        height: "100%",
        background: "#14D260",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="white">{t(txt.savings)}</Text>
        </div>
        <div
          style={{ textAlign: "right", marginTop: "-2px", cursor: "pointer" }}
        >
          <img
            src={plusCircle}
            color="white"
            height={16}
            style={{ opacity: iconOpacity }}
            onMouseEnter={() => setIconOpacity(1)}
            onMouseLeave={() => setIconOpacity(0.5)}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ElectricalParametersChart />
      </div>
    </div>
  );
};
