import { useAppSelector } from "../../../../redux/hooks";
import { Details } from "../Step1Details/Details";
import DimmingChart from "./DimmingChart";
import { Review } from "../Step3Review/Review";
import { languages } from "../../../../utils/languages/languages";
import {
  CardContainer,
  CancelButton,
  NextButton,
} from "../StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import { useTranslation } from "react-i18next";

interface DimmingProps {
  setShowDetails?: any;
  showDetails?: any;
  setShowDimming?: any;
  showDimming?: any;
  setShowReview?: any;
  showReview?: any;
  setNewChartData?: any;
  newChartData?: any;
}

export const Dimming = ({
  setShowDetails,
  showDetails,
  setShowDimming,
  showDimming,
  setShowReview,
  showReview,
  setNewChartData,
  newChartData,
}: DimmingProps) => {
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { t } = useTranslation();

  return (
    <>
      <div className="col-12">
        <CardContainer>
          <div
            className="col-12"
            style={{ marginBottom: "16px", textAlign: "left" }}
          >
            <Text fontWeight="600" marginBottom="0px">
              {t(txt.dimmingConfigurationTitle)}
            </Text>
            <Text textType="caption" fontWeight="400">
              {t(txt.dimmingConfigurationSubtitle)}
            </Text>
          </div>
          {/* <div
            className="row"
            style={{ marginBottom: "16px", textAlign: "left" }}
          >
            <div className="col-6">
              <Text textType="caption" color="#475569" marginBottom="0px">
                From
              </Text>
              <InputField type="number" />
            </div>
            <div className="col-6">
              <Text textType="caption" color="#475569" marginBottom="0px">
                To
              </Text>
              <InputField type="number" />
            </div>
          </div> */}
          <DimmingChart
            showDimming={showDimming}
            setNewChartData={setNewChartData}
            newChartData={newChartData}
          />
        </CardContainer>
      </div>
      <div className="row">
        <div className="col-6" style={{ textAlign: "left" }}>
          <CancelButton
            onClick={() => {
              setShowDetails(true);
              setShowDimming(false);
              setShowReview(false);
            }}
          >
            {t(txt.backToName)}
          </CancelButton>
          {showDetails && <Details />}
        </div>
        <div className="col-6" style={{ textAlign: "right" }}>
          <NextButton
            onClick={() => {
              setShowDetails(false);
              setShowDimming(false);
              setShowReview(true);
            }}
          >
            {t(txt.nextReviewCreate)}
          </NextButton>
          {showReview && <Review />}
          {/* <NextButton onClick={()=> {deleteStrategy(24)}}>delete</NextButton>
          <NextButton onClick={()=> {editStrategy(newStrategy.labels, newStrategy.data, 21)}}>edit</NextButton> */}
        </div>
      </div>
    </>
  );
};
