import styled from "styled-components";

export const LocalWeatherStyled = styled.section`
  position: absolute;
  bottom: 30px;
  left: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  width: 230px;
  height: 100px;
  min-width: 200px;
  background: ${(props) => props.theme.mainBackgroundColor};
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;

  .weather-card {
    &__icon {
      display: flex;
      align-items: center;
      border-radius: 50%;
      height: 100%;
      font-size: 50px;
    }

    &__content-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      font-weight: 400;
      font-size: ${(props) => props.theme.secondFontSize};
      color: ${(props) => props.theme.mainTitleColor};
    }

    &__time-container {
      display: flex;
      justify-content: center;
    }

    &__content {
      font-weight: 500;
      font-size: ${(props) => props.theme.weatherTimeFontSize};
      color: ${(props) => props.theme.mainTitleColor};
    }

    &__am-pm {
      display: flex;
      align-items: flex-end;
      font-weight: 400;
      font-size: ${(props) => props.theme.seconFontSize};
      color: ${(props) => props.theme.mainTitleColor};
      margin-bottom: 4px;
    }
  }

  h1,
  h2 {
    margin: 0;
  }
`;
