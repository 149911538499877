import { useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import BatteryChart from "./ProductionChart";
import { languages } from "../../../../utils/languages/languages";
import { CardSimple } from "../../LuminairePageStyled";
import { Text, TextSpan } from "../../../../styles/shared-styles/text-sizes";
import plusCircle from "../../../../assets/new_icons/plusCircle.svg";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ProductionProps {}

export const Production = ({}: ProductionProps) => {
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();
  return (
    <CardSimple>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="#A3AED0">{t(txt.production)}</Text>
        </div>
        <div
          style={{ textAlign: "right", marginTop: "-2px", cursor: "pointer" }}
        >
          <img
            src={plusCircle}
            height={16}
            style={{ opacity: iconOpacity }}
            onMouseEnter={() => setIconOpacity(1)}
            onMouseLeave={() => setIconOpacity(0.5)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          height: 0,
          zIndex: 6,
          justifyContent: "center",
          top: "54px",
          left: 2,
          position: "relative",
        }}
      >
        <Text fontWeight="500">89 kWh</Text>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BatteryChart />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <TextSpan
          textType="subTitle"
          style={{ height: "10px", fontSize: "14px" }}
        >
          {t(txt.solar)} 61kWh
        </TextSpan>
      </div>
    </CardSimple>
  );
};
