import { useAppSelector } from "../../../redux/hooks";
import { useLightingService } from "../../../services/lightingService";
import { languages } from "../../../utils/languages/languages";
import { Text } from "../../../styles/shared-styles/text-sizes";
import plusCircle from "../../../assets/plusCircle.svg";
import { changeFeederStatus } from "../../../services/feeder";
import { useState } from "react";

interface StatusCardProps {
  selectedTab: any;
  onLamp?: number | undefined;
  onlineLamp?: number | null;
  switchBoardData?: any;
}

export const StatusCard = ({
  selectedTab,
  onLamp,
  onlineLamp,
  switchBoardData,
}: StatusCardProps) => {
  const [status, setStatus] = useState<boolean>(switchBoardData?.is_active);
  const { sendCommandToLuminaire } = useLightingService();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const handleClick = async (value: boolean) => {
    try {
      const response = changeFeederStatus({ is_active: value }, selectedTab);
      setStatus(value);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div
      className="card"
      style={{
        padding: "24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        flexGrow: 1,
        marginBottom: "24px",
        justifyContent: "center",
        display: "block",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div className="mb-4">
          <Text color="#A3AED0">{txt.status}</Text>
        </div>
        {/* <div style={{ textAlign: "right", marginTop: "-2px", cursor: "pointer" }}>
          <img src={plusCircle} height={16} />
        </div> */}
      </div>
      <div className="grid grid-cols-12" style={{ marginBottom: "16px" }}>
        <div className="col-span-12">
          {!status && onlineLamp ? (
            <>
              <button
                onClick={() => handleClick(false)}
                style={{
                  width: "50%",
                  background: "#fff",
                  boxShadow: "0px 4px 8px 3px rgb(0 0 0 / 15%)",
                  border: "none",
                  padding: "8px 0px",
                  fontSize: "10px",
                  borderRadius: "4px 0 0 4px",
                }}
              >
                OFF
              </button>
              <button
                onClick={() => handleClick(true)}
                style={{
                  width: "50%",
                  color: "#fff",
                  background: "#56C568",
                  // boxShadow: "0px 4px 8px 3px rgb(0 0 0 / 15%)",
                  border: "none",
                  padding: "8px 0px",
                  fontSize: "10px",
                  borderRadius: "0 4px 4px 0",
                }}
              >
                ON
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => handleClick(false)}
                style={{
                  width: "50%",
                  color: "#ffffff",
                  background: "#525252",
                  boxShadow: "0px 4px 8px 3px rgb(0 0 0 / 15%)",
                  border: "none",
                  padding: "8px 0px",
                  fontSize: "10px",
                  borderRadius: "4px 0 0 4px",
                }}
              >
                OFF
              </button>
              <button
                onClick={() => handleClick(true)}
                style={{
                  width: "50%",
                  color: "#979797",
                  background: "#ffffff",
                  boxShadow: "0px 4px 8px 3px rgb(0 0 0 / 15%)",
                  border: "none",
                  padding: "8px 0px",
                  fontSize: "10px",
                  borderRadius: "0 4px 4px 0",
                }}
              >
                ON
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
