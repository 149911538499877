import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getSingleSwitchBoard,
  getSwitchBoards,
} from "../../services/switchBoardService";
import { T_Response } from "../../types/api.types";
import { ISwitchboard, SwitchBoardPayload } from "../../types/interfaces";
import { useAppSelector } from "../../redux/hooks";

interface IProps {
  organizationDetails?: SwitchBoardPayload;
}

const useSwitchBoard = () => {
  const { switchboardId } = useParams();
  const [switchBoardData, setSwitchBoardData] = useState<ISwitchboard>();
  const [allSwitchBoards, setAllSwitchBoards] = useState<ISwitchboard[]>();

  const getSingleSwitchBoardData = async () => {
    try {
      const response = await getSingleSwitchBoard(switchboardId as string);
      setSwitchBoardData(response);
    } catch (error) {
      console.log(error);
    }
  };
  const getSwitchBoardData = async () => {
    try {
      const payload = {
        organizationId: "1",
        //  selectedOrganizationId.toString(),
        serviceId: "1",
        page: 1,
        page_size: 1000,
      };
      const response = await getSwitchBoards(payload);
      setAllSwitchBoards(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSingleSwitchBoardData();
    getSwitchBoardData();
  }, [switchboardId]);

  return { switchBoardData, allSwitchBoards };
};

export default useSwitchBoard;
