import { ChangeEvent, useState } from "react";
import {
  InputContainer,
  PreffixInputIcon,
  SuffixInputIcon,
  Input,
} from "./InputStyled";
import visible_eye from "../../assets/eye-on.svg";
import invisible_eye from "../../assets/eye-off.svg";

interface InputFormProps {
  inputType?: string;
  preffix_icon?: string;
  suffix_icon?: string;
  placeholder?: string;
  marginBottom?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isError?: boolean; // New prop for error handling
  name?: string;
}

const InputForm: React.FC<InputFormProps> = ({
  inputType = "text",
  preffix_icon = "",
  suffix_icon = "",
  placeholder = "",
  marginBottom = "medium",
  onChange,
  onKeyPress,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const showVisibleEye = visible && inputType === "password";
  const showInvisibleEye = !visible && inputType === "password";

  return (
    <>
      <InputContainer marginBottom={marginBottom}>
        {preffix_icon && (
          <PreffixInputIcon src={preffix_icon} alt="smartec logo" height={16} />
        )}

        {suffix_icon && (
          <SuffixInputIcon
            src={
              showVisibleEye
                ? visible_eye
                : showInvisibleEye
                ? invisible_eye
                : suffix_icon
            }
            alt="smartec logo"
            height={16}
            onClick={() => setVisible(!visible)}
          />
        )}

        <Input
          prefix={preffix_icon}
          placeholder={placeholder}
          type={
            showVisibleEye ? "text" : showInvisibleEye ? "password" : inputType
          }
          className="form-control"
          onChange={onChange}
          onKeyPress={onKeyPress}
          {...props}
        />
      </InputContainer>
    </>
  );
};

export default InputForm;
