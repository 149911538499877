import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { setLanguage } from "../../redux/slices/languageSlice/languageSlice";
import {
  changeSideMenuActionCreator,
  setAlarmsVisibleActionCreator,
  setGroupsVisibleActionCreator,
  setEventsVisibleActionCreator,
  setIsFullViewActionCreator,
  setFirstRenderMapToTrueActionCreator,
} from "../../redux/slices/uiSlice/uiSlice";
import { useUserService } from "../../services/userService";
import { Header } from "../Header/Header";
import { languages } from "../../utils/languages/languages";
import {
  ContainerMain,
  Sidemenu,
  SidemenuLogo,
  SidemenuTopIcon,
  SidemenuIcon,
  SidemenuDownIcon,
  Headertest,
  ContainerChildren,
  ChildrenComponent,
  HeadertestFullView,
  ContainerChildrenFullView,
} from "./LayoutStyled";
import alarm from "../../assets/new_icons/Alarm layout.svg";
import strategies from "../../assets/new_icons/big-point layout.svg";
import chevronLeft from "../../assets/chevron-left.svg";
import chevronRight from "../../assets/arrow_forward_outline.svg";
import defaultProfile from "../../assets/default-profile.png";
import events from "../../assets/new_icons/clock layout.svg";
import esIcon from "../../assets/es.png";
import enIcon from "../../assets/en.png";
import grid from "../../assets/new_icons/grid.svg";
import groups from "../../assets/new_icons/Groups layout.svg";
import moon from "../../assets/moon.svg";
import rwIcon from "../../assets/rw.png";
import SmartecSmallLogo from "../../assets/S-Smartec.svg";
import swIcon from "../../assets/sw.png";
import frIcon from "../../assets/fr.png";

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
  style?: object;
}

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const alerts = false;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSideMenuVisible = useAppSelector(
    (state) => state.ui.isSideMenuVisible
  );
  const isHomeVisible = useAppSelector((state) => state.ui.isHomeVisible);
  const isAlarmsVisible = useAppSelector((state) => state.ui.isAlarmsVisible);
  const isGroupsVisible = useAppSelector((state) => state.ui.isGroupsVisible);
  const isStrategiesVisible = useAppSelector(
    (state) => state.ui.isStrategiesVisible
  );
  const isEventsVisible = useAppSelector((state) => state.ui.isEventsVisible);
  const user = useAppSelector((state: RootState) => state.user);
  const { getUserProfile } = useUserService();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const [dashboardOpacity, setDashboardOpacity]: any = useState(
    isHomeVisible ? 1 : 0.2
  );
  const [alarmsOpacity, setAlarmsOpacity] = useState(isAlarmsVisible ? 1 : 0.2);
  const [groupsOpacity, setGroupsOpacity] = useState(isGroupsVisible ? 1 : 0.2);
  const [strategiesOpacity, setStrategiesOpacity] = useState(
    isStrategiesVisible ? 1 : 0.2
  );
  const [eventsOpacity, setEventsOpacity] = useState(isEventsVisible ? 1 : 0.2);
  const [moonOpacity, setMoonOpacity] = useState(0.2);

  useEffect(() => {
    setDashboardOpacity(isHomeVisible ? 1 : 0.2);
    setAlarmsOpacity(isAlarmsVisible ? 1 : 0.2);
    setGroupsOpacity(isGroupsVisible ? 1 : 0.2);
    setStrategiesOpacity(isStrategiesVisible ? 1 : 0.2);
    setEventsOpacity(isEventsVisible ? 1 : 0.2);
  }, [
    isHomeVisible,
    isAlarmsVisible,
    isGroupsVisible,
    isStrategiesVisible,
    isEventsVisible,
  ]);

  // useEffect(() => {
  //   const token = Cookies.get("token");

  //   if (token) {
  //     getUserProfile(token);
  //     if (user) {
  //       console.log("Header", user);
  //       console.log("user language", user.language_name);
  //     }
  //   } else {
  //     // Redirect the user to the login page
  //     navigate("/");
  //   }
  // }, []);
  // useEffect(() => {
  //   const token = Cookies.get("token");

  //   if (token) {
  //     getUserProfile(token);
  //     if (user) {
  //       console.log("Header", user);
  //       console.log("user language", user.language_name);

  //       // Determine user's preferred language from the fetched data
  //       const userPreferredLanguage = user.language_name; // Replace with the actual property name from your API response

  //       // Set the language in Redux store or local state, if needed
  //       dispatch(setLanguage(userPreferredLanguage));

  //       // Change the language in the i18n instance
  //       i18n.changeLanguage(userPreferredLanguage);

  //       console.log("User Language", userPreferredLanguage);
  //     }
  //   } else {
  //     // Redirect the user to the login page
  //     navigate("/");
  //   }
  // }, []);
  //-----------------------------

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    } else {
      // Default language setting
      localStorage.setItem("selectedLanguage", "en");
    }
  }, [i18n]);

  const items = [
    {
      label: "English",
      key: "en",
      icon: enIcon,
    },
    {
      label: "Spanish",
      key: "es",
      icon: esIcon,
    },
    {
      label: "Kinyarwanda",
      key: "rw",
      icon: rwIcon,
    },
    {
      label: "French",
      key: "fr",
      icon: frIcon,
    },
    {
      label: "Swahili",
      key: "sw",
      icon: swIcon,
    },
  ];

  const changeLanguage = (language: string) => {
    dispatch(setLanguage(language));
    localStorage.setItem("selectedLanguage", language);
    i18n.changeLanguage(language);
  };

  const handleMenuClick = (item: { key: any }) => {
    changeLanguage(item.key);
  };

  return (
    <ContainerMain>
      {isSideMenuVisible ? (
        <>
          <Sidemenu>
            <SidemenuLogo
              title={t(txt.dashboard)}
              onClick={() => {
                dispatch(setIsFullViewActionCreator());
                dispatch(setFirstRenderMapToTrueActionCreator());
                navigate("/dashboard");
              }}
              style={{ cursor: "pointer" }}
              src={SmartecSmallLogo}
            />
            <SidemenuTopIcon>
              <SidemenuIcon
                title={t(txt.dashboard)}
                onClick={() => {
                  dispatch(setIsFullViewActionCreator());
                  dispatch(setFirstRenderMapToTrueActionCreator());
                  navigate("/dashboard");
                }}
                style={{ cursor: "pointer", opacity: dashboardOpacity }}
                src={grid}
                onMouseEnter={() =>
                  !isHomeVisible ? setDashboardOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isHomeVisible ? setDashboardOpacity(0.2) : <></>
                }
              />
              <SidemenuIcon
                title={t(txt.alarmsCapitalLetter)}
                onClick={() => {
                  dispatch(setAlarmsVisibleActionCreator());
                  navigate("/coming-soon");
                }}
                style={{ cursor: "pointer", opacity: alarmsOpacity }}
                src={alarm}
                onMouseEnter={() =>
                  !isAlarmsVisible ? setAlarmsOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isAlarmsVisible ? setAlarmsOpacity(0.2) : <></>
                }
              />
              <SidemenuIcon
                title={t("Groups")}
                onClick={() => {
                  dispatch(setGroupsVisibleActionCreator());
                  navigate("/coming-soon");
                }}
                style={{ cursor: "pointer", opacity: groupsOpacity }}
                src={groups}
                onMouseEnter={() =>
                  !isGroupsVisible ? setGroupsOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isGroupsVisible ? setGroupsOpacity(0.2) : <></>
                }
              />
              <SidemenuIcon
                title={t(txt.strategies)}
                onClick={() => {
                  navigate("/strategies");
                }}
                style={{ cursor: "pointer", opacity: strategiesOpacity }}
                src={strategies}
                onMouseEnter={() =>
                  !isStrategiesVisible ? setStrategiesOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isStrategiesVisible ? setStrategiesOpacity(0.2) : <></>
                }
              />
              <SidemenuIcon
                title={t(txt.events)}
                onClick={() => {
                  dispatch(setEventsVisibleActionCreator());
                  navigate("/coming-soon"); /* navigate("/events") */
                }}
                style={{ cursor: "pointer", opacity: eventsOpacity }}
                src={events}
                onMouseEnter={() =>
                  !isEventsVisible ? setEventsOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isEventsVisible ? setEventsOpacity(0.2) : <></>
                }
              />
            </SidemenuTopIcon>
            <SidemenuDownIcon>
              {/* <SidemenuIcon title={txt.darkLight} src={moon} style={{ cursor: "pointer", opacity: moonOpacity }}
                onMouseEnter={() => moonOpacity === 0.2 ? setMoonOpacity(1) : <></>} onMouseLeave={() => moonOpacity === 1 ? setMoonOpacity(0.2) : <></>}
              /> */}
              <SidemenuIcon
                title={t(txt.expandDecrease)}
                src={chevronLeft}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(changeSideMenuActionCreator());
                }}
              />
            </SidemenuDownIcon>
          </Sidemenu>
          <Headertest>
            <Header
              alerts={alerts}
              profileImg={defaultProfile}
              user={user}
              handleMenuClick={handleMenuClick}
              items={items}
            ></Header>
          </Headertest>
          <ContainerChildren>
            <ChildrenComponent>{children}</ChildrenComponent>
          </ContainerChildren>
        </>
      ) : (
        <>
          <SidemenuDownIcon
            style={{ height: "108px", left: "-18px", zIndex: 2 }}
          >
            <SidemenuIcon
              src={chevronRight}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                background: "white",
                cursor: "pointer",
                padding: "17px",
                boxShadow:
                  "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
              }}
              onClick={() => {
                dispatch(changeSideMenuActionCreator());
              }}
            />
          </SidemenuDownIcon>
          <HeadertestFullView>
            <Header
              alerts={alerts}
              profileImg={defaultProfile}
              user={user}
              handleMenuClick={handleMenuClick}
              items={items}
            ></Header>
          </HeadertestFullView>
          <ContainerChildrenFullView style={{ paddingLeft: "30px" }}>
            <ChildrenComponent>{children}</ChildrenComponent>
          </ContainerChildrenFullView>
        </>
      )}
    </ContainerMain>
  );
};

export default Layout;
