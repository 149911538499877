import styled from "styled-components";

export const FilterBarStyled = styled.section`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  top: 5vh;
  //top: 128px;
  right: 20px;
  left: 30px;
  justify-content: right;
  align-content: center;
  width: 50px;
  cursor: pointer;
`;
