import { Consumption, FeederConsumptionFeeder } from "../types/interfaces";
import moment from "moment";

const getTime = (date: string) => {
  const parsedTime = moment(date, "HH:mm");
  const formattedTime = parsedTime.format("h");
  const amOrPm = parsedTime.format("A");
  return `${formattedTime} ${amOrPm}`;
};
export const formatSwitchBoardFeederGraphData = (
  data: FeederConsumptionFeeder[]
) => {
  const mapData = data.map((consumption: FeederConsumptionFeeder) => {
    return {
      label: consumption.feeder_name,
      borderColor: consumption.color,
      backgroundColor: consumption.color,
      borderWidth: 1,
      pointBorderWidth: 0,
      pointRadius: 0,
      tension: 0.4,
      data: consumption.consumptions.map(
        (item: Consumption) => item.consumption
      ),
    };
  });
  const dataCurrentConsumption = {
    labels: [
      "1 AM",
      "2 AM",
      "3 AM",
      "4 AM",
      "5 AM",
      "6 AM",
      "7 AM",
      "8 AM",
      "9 AM",
      "10 AM",
      "11 AM",
      "12 AM",
      "1 PM",
      "2 PM",
      "3 PM",
      "4 PM",
      "5 PM",
      "6 PM",
      "7 PM",
      "8 PM",
      "9 PM",
      "10 PM",
      "11 PM",
      "12 PM",
    ], // Numbers on the x-axis
    datasets: mapData,
  };
  return dataCurrentConsumption;
};
