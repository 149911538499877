import { TextSpan } from "../../../styles/shared-styles/text-sizes";
import personOutlined from "../../../assets/person_outline.svg";
import moreActions from "../../../assets/more_actions.svg";
import { GetManagerUsers } from "../../../types/interfaces";
import { Empty } from "antd";
import { TableRow } from "../UsersStyled";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface Props {
  users: GetManagerUsers[];
}
interface UserAvatarProps {
  firstName: string;
}
export const UsersTable = ({ users }: Props) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");

  const handleClick = (id: string) => {
    navigate(`${pathname}?id=${id}`);
  };

  console.log({ id });

  const getRandomColor = (): string => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const UserAvatar: React.FC<UserAvatarProps> = ({ firstName }) => {
    const avatarStyle: React.CSSProperties = {
      backgroundColor: getRandomColor(),
      width: "36px",
      height: "36px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#ffffff",
      padding: "16px 0px",
    };
    const firstLetter: string = firstName.charAt(0).toUpperCase();

    return <div style={avatarStyle}>{firstLetter}</div>;
  };
  return !users.length ? (
    <Empty description="No users" />
  ) : (
    <table className="table">
      {users
        ? Object.values(users).map((item) => (
            <TableRow
              key={item.id}
              style={{
                borderBottom: "1px solid #E7E0EC",
                cursor: "pointer",
                background: item.id === id ? "#f1f1f1" : "",
              }}
              onClick={() => handleClick(item.id)}
            >
              <td>
                <UserAvatar firstName={item?.name} />
              </td>
              <td
                style={{
                  fontSize: "14px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <TextSpan color="#475569" fontWeight="700">
                  {item?.name} {item?.surname}
                </TextSpan>
                <br />
                <TextSpan color="#a3aed0" textType="caption">
                  {item?.email}
                </TextSpan>
              </td>
              <td
                style={{
                  textAlign: "right",
                  fontSize: "14px",
                  verticalAlign: "middle",
                }}
              >
                <TextSpan color="#a3aed0" textType="caption">
                  {item?.role} <img src={personOutlined} height={12} />
                </TextSpan>
              </td>
              <td>
                <img src={moreActions} height={30} />
              </td>
            </TableRow>
          ))
        : null}
    </table>
  );
};
