import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import {
  GroupList,
  ListOrganization,
  OrganizationProfile,
  Table,
} from "../UsersStyled";
import { Checkbox, Collapse } from "antd";
import { IconStyled } from "../../ProfilePage/ProfilePageStyled";
import light from "../../../assets/light-bulb-shine.svg";
import groups_numbers from "../../../assets/groups_numbers.svg";
import { GetManagerGroupState } from "../../../services/getManagedGroupService";
import companyFlag from "../../../assets/new_icons/Company.svg";
import { useTranslation } from "react-i18next";

export interface GroupsProps {
  groupsData: GetManagerGroupState[] | null;
}
const { Panel } = Collapse;
export const Groups = ({ groupsData }: GroupsProps) => {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <div className="row">
        <div className="col-12">
          <Text textType="subTitle" fontWeight="700" marginBottom="16px">
            {t("Groups Permits")}
          </Text>
        </div>
      </div>
      <Collapse bordered={false} expandIconPosition="end" size="small">
        {Array.isArray(groupsData) &&
          groupsData.map((item, index) => (
            <Panel
              header={
                <Table className="table table-borderless">
                  <tr>
                    <td style={{ width: "12px" }}>
                      <OrganizationProfile src={companyFlag} />
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <Text fontWeight="500" marginBottom="0px">
                        {item.organization_name}
                      </Text>
                      <ListOrganization>
                        <IconStyled src={light} height={12} />
                        <TextSpan textType="caption" color="#a3aed0">
                          {item.servName}
                        </TextSpan>
                      </ListOrganization>
                      <ListOrganization>
                        <IconStyled src={groups_numbers} height={16} />
                        <TextSpan textType="caption" color="#a3aed0">
                          {item?.groups?.length}
                        </TextSpan>
                      </ListOrganization>
                    </td>
                  </tr>
                </Table>
              }
              key={index}
            >
              <GroupList className="list">
                {" "}
                {Array.isArray(item.groups) && item.groups.length > 0 ? (
                  item.groups.map((data: any) => (
                    <div className="option-container" key={data.id}>
                      <Checkbox checked={data.selected === true} />
                      <TextSpan fontWeight="500" style={{ marginLeft: "8px" }}>
                        {data.groupName}
                      </TextSpan>
                    </div>
                  ))
                ) : (
                  <div>{t("No groups")}</div>
                )}
              </GroupList>
            </Panel>
          ))}
      </Collapse>
    </>
  );
};
