import { Devices, NodeDevice, NodeLumimairePage } from "../types/interfaces";
import {
  loadDevicesActionCreator,
  loadUnPositionedNode,
  updateNodeDeviceStatusGlobalActionCreator,
} from "../redux/slices/devices/devicesSlice";
import {
  filteredAllDevicesActionCreator,
  updateNodeDeviceStatusActionCreator,
} from "../redux/slices/devices/filteredDevicesSlice";
import { addIdsActionCreator } from "../redux/slices/mqttSlice/mqttSlice";
import { useAppDispatch } from "../redux/hooks";
import { saveNodeActionCreator } from "../redux/slices/node/manageNodeSlice";

export const useLightingService = () => {
  //  const webAddressPrefix: string = "https://st.salvilighting.com/api";
  const webAddressPrefix: string = process.env
    .REACT_APP_API_LOCAL_BASE_URL as string;
  const dispatch = useAppDispatch();

  const getAllNodes = async (
    orgId: number,
    serviceId: number,
    organizations: Array<[]>,
    services: Array<[]>,
    is_Positioned: boolean | undefined
  ): Promise<boolean> => {
    try {
      let response: any;
      if (is_Positioned === false) {
        response = await fetch(
          `${webAddressPrefix}/nodes/?orgId=${orgId}&serviceId=${serviceId}&is_positioned=${is_Positioned}`,
          { credentials: "include" }
        );
      } else {
        response = await fetch(
          `${webAddressPrefix}/nodes/?orgId=${orgId}&serviceId=${serviceId}`,
          { credentials: "include" }
        );
      }

      const nodes = await response.json();
      console.log(nodes);
      // Aquí puedes manejar los datos recibidos
      const nodeList: any = [];
      const unpositionedNodeList: any = [];
      nodes?.data.map((node: any) => {
        //Le he puesto any porque el modelo no está definido y cambiarlo implica muchas cosas ahora mismo. Hay que hacerlo, pero en otro momento
        const newDevice: NodeDevice = {
          alarm_status: node.alarm_status,
          bri: Number(node.dimming),
          on: Number(node.is_on), // on-off
          latitude: node.latitude,
          longitude: node.longitude,
          active: node.node_active, // is node active? yes-no
          node_id: node.node_id,
          name: node.node_name,
          orgName: node.organization,
          organizationId: node.organization_id,
          selected: node.selected,
          service: node.service,
          solar_id: node.solar_id, // not solar: no id, is null. Solar: has id
          online: Number(node.wireless_status),
          //SHUNCOM
          addr: node.addr,
          alarm_id: "",
          gateway_addr: node.gateway_addr,
          pEnergy: node.active_power,
          prjName: "",
          projectId: "",
          uid: node.node_id,
        };
        console.log(newDevice.latitude, "latitude");

        if (
          newDevice.latitude === "0.000000" &&
          newDevice.longitude === "0.000000"
        ) {
          unpositionedNodeList.push(newDevice);
        }
        nodeList.push(newDevice);
      });

      const devices: Devices = {
        gateways: [],
        nodes: nodeList,
        unpositionedNodes: unpositionedNodeList,
        organizations: organizations,
        selectedOrganizationId: orgId,
        services: services,
        selectedServiceId: serviceId,
      };

      dispatch(loadDevicesActionCreator(devices));
      dispatch(filteredAllDevicesActionCreator(devices));
      dispatch(loadUnPositionedNode(unpositionedNodeList));

      return true;
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const sendCommandToLuminaire = async (
    luminaires: NodeDevice[],
    commandNumber: number,
    dimming: number
  ) => {
    let luminaireId: String[] = [];
    luminaires.forEach((luminaire) => {
      luminaireId.push(luminaire.node_id);
    });
    dispatch(addIdsActionCreator(luminaireId));
    console.log("commandNumber: ", commandNumber);

    const body =
      commandNumber === 3
        ? {
            uid: luminaireId,
            cmd: {
              msg_type: commandNumber,
              value: dimming,
            },
          }
        : {
            uid: luminaireId,
            cmd: {
              msg_type: commandNumber,
            },
          };

    try {
      fetch(`${webAddressPrefix}/nodes/control-luminaire`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const getNodeInfo = async (nodeId: string): Promise<boolean> => {
    try {
      const response = await fetch(`${webAddressPrefix}/nodes/${nodeId}`, {
        credentials: "include",
      });
      const node = await response.json();

      const nodeInfo: NodeLumimairePage = {
        case_name: node[0].case_name,
        creation_date: node[0].creation_date,
        device_type: node[0].device_name,
        bri: node[0].dimming,
        gateway_id: node[0].gateway_id,
        hops: node[0].hops,
        on: node[0].is_on,
        latitude: node[0].latitude,
        longitude: node[0].longitude,
        manufacturer_name: node[0].manufacturer_name,
        active: node[0].node_active,
        node_id: node[0].node_id,
        node_name: node[0].node_name,
        num_neighbours: node[0].num_neighbours,
        protocol_name: node[0].protocol_name,
        rssi: node[0].rssi,
        solar_id: node[0].solar_id,
        traveltime: node[0].traveltime,
        update_date: node[0].update_date,
        online: node[0].wireless_status,
      };

      //Pendiente de parsearlo con shuncom
      if (nodeInfo.bri === undefined || nodeInfo.bri === null) {
        nodeInfo.bri = node[0].bri;
      }

      dispatch(saveNodeActionCreator(nodeInfo));

      return true;
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  return {
    getAllNodes,
    sendCommandToLuminaire,
    getNodeInfo,
  };
};
