import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { resetStrategyActionCreator } from "../../redux/slices/strategiesSlice/strategiesSlice";
import { setStrategiesVisibleActionCreator } from "../../redux/slices/uiSlice/uiSlice";
import { useStrategiesService } from "../../services/strategiesService";
import { NewStrategyModal } from "./NewStrategyModal/NewStrategyModal";
import { EditStrategyModal } from "./EditStrategyModal/EditStrategyModal";
import StrategiesChart from "./StrategiesChart";
import { googleIcons } from "../../utils/googleFontsIcons/icons";
import { languages } from "../../utils/languages/languages";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import moonStar from "../../assets/moon_star.svg";
import narrowLeft from "../../assets/new_icons/back.svg";
import plusCircle from "../../assets/new_icons/plusCircle.svg";
import plusIcon from "../../assets/new_icons/plus.svg";
import sun from "../../assets/sun.svg";
import { useTranslation } from "react-i18next";

interface StrategiesPageCard {}

export const StrategiesPage = ({}: StrategiesPageCard) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);
  const listView = useAppSelector((state) => state.ui.isListView);
  const strategies = useAppSelector((state) => state.strategies.strategies);
  const newStrategy = useAppSelector((state) => state.strategies);
  const selectedOrgId: any = useAppSelector(
    (state) => state.devices.selectedOrganizationId
  );
  const selectedServiceId: any = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const { getStrategy, deleteStrategy, editStrategyChart } =
    useStrategiesService();
  const txt = languages[currentLanguage];
  const location = useLocation();
  const nodeId = location.state?.nodeId;
  const viewState = location.state?.viewState;

  const { lightBulb } = googleIcons;

  console.log("el length", strategies.length);

  const [showModal, setShowModal] = useState(false);
  const [showActions, setShowActions] = useState({
    index: "",
    state: false,
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [editChart, setEditChart] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [saveId, setSaveId]: any = useState();
  const [saveName, setSaveName]: any = useState("");
  const [name, setName]: any = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const handleShowModal = () => {
    dispatch(resetStrategyActionCreator());
    setShowModal(!showModal);
  };

  const handleShowActions = (index: string) => {
    setShowActions({ index: index, state: !showActions.state });
    setEditDetails(false);
    setEditChart(false);
  };

  const handleShowEditModal = () => {
    dispatch(resetStrategyActionCreator());
    setShowEditModal(!showEditModal);
    setShowActions({ index: showActions.index, state: false });
  };

  // REVISAR ESTO
  /* window.addEventListener('popstate', () => {
    if (nodeId) {
      navigate(`/luminaire/${nodeId}`, {state: {viewState}})
    } else {
      navigate("/dashboard")
    }
  }); */

  const deleteAndGetStrategy = async (id: number) => {
    await deleteStrategy(id);
    resetStates();
    await getStrategy(selectedOrgId, selectedServiceId);
  };

  const resetStates = () => {
    setShowDeletePopup(false);
    setShowActions({ index: "", state: false });
    setSaveId();
    setSaveName("");
    setName("");
    setErrorMessage(false);
  };

  const handleStrategyName = (e: any) => {
    const newName = e.target.value;
    setName(newName);
  };

  useEffect(() => {
    getStrategy(selectedOrgId, selectedServiceId);
    dispatch(setStrategiesVisibleActionCreator());
  }, [showModal, showEditModal, dispatch, selectedOrgId, selectedServiceId]);

  const { t } = useTranslation();
  return (
    <>
      <div
        className="container-fluid"
        style={{
          pointerEvents: showDeletePopup ? "none" : "auto",
          marginTop: "8px",
          paddingRight: "0px",
          paddingLeft: "0px",
          position: "relative",
          zIndex: 2,
        }}
      >
        {!fullView && !hybridView && !listView ? (
          <div
            style={{
              marginBottom: "20px",
              cursor: "pointer",
              display: "inline-flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(`/luminaire/${nodeId}`, { state: { viewState } });
            }}
          >
            <img
              src={narrowLeft}
              height={12}
              alt=""
              style={{ marginRight: "12px" }}
            />
            <TextSpan color="black">{t(txt.backToOverview)}</TextSpan>
          </div>
        ) : (
          <div style={{ marginTop: "20px" }}></div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "18px",
            marginRight: "8px",
          }}
        >
          <div>
            <Text textType="title" fontWeight="700" marginBottom="8px">
              {t(txt.lightStrategies)}
            </Text>
            <Text textType="">{t(txt.shortLongDescription)}</Text>
          </div>
          <div className="col-2" style={{ textAlign: "right" }}>
            <Button
              buttonType={strategies.length === 20 ? "secondary" : "primary"}
              onClick={() => {
                strategies.length !== 20 ? setShowModal(true) : <></>;
              }}
              style={{ padding: "8px", width: "140px" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
              >
                <img
                  src={plusIcon}
                  height={18}
                  alt=""
                  style={{ marginRight: "4px" }}
                />
                <TextSpan
                  color="black"
                  fontWeight="500"
                  style={{ marginRight: "6px" }}
                >
                  {t(txt.newStrategy)}
                </TextSpan>
              </div>
            </Button>
            {strategies.length === 20 ? (
              <Text style={{ marginTop: "6px" }}>{t(txt.strategiesLimit)}</Text>
            ) : (
              <></>
            )}
            {showModal && (
              <NewStrategyModal
                showModal={showModal}
                handleShowModal={handleShowModal}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "21px",
            flexWrap: "wrap",
          }}
        >
          {strategies &&
            strategies.map((item: any, index: any) => (
              <div
                key={item.id}
                className="card"
                style={{
                  padding: "24px",
                  border: "none",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
                  backdropFilter: "blur(2px)",
                  borderRadius: "16px",
                  marginBottom: "12px",
                  width: "24%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <div
                    className="col-6"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "4px",
                    }}
                  >
                    <span
                      className="material-symbols-rounded"
                      style={{ marginLeft: "-4px" }}
                    >
                      {lightBulb}
                    </span>
                    <TextSpan color="#A3AED0" textType="caption">
                      {item.value}
                    </TextSpan>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      marginTop: "-2px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={plusCircle}
                      style={{ cursor: "pointer" }}
                      height={16}
                      alt=""
                      onClick={() => {
                        handleShowActions(index);
                      }}
                    />
                    {showActions.index === index && showActions.state && (
                      <article
                        style={{
                          position: "absolute",
                          top: "40px",
                          right: "26px",
                          width: "190px",
                          maxHeight: "336px",
                          overflowY: "auto",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
                          boxShadow:
                            "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
                          borderRadius: "4px",
                          zIndex: 3,
                        }}
                      >
                        <>
                          <style>
                            {`
                            .org-span:hover {
                              background-color: #1c1b1f11;
                            }
                          `}
                          </style>
                          <span
                            className="org-span"
                            onClick={() => {
                              handleShowEditModal();
                              setEditDetails(true);
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              isolation: "isolate",
                              paddingLeft: "15px",
                              width: "100%",
                              minHeight: "56px",
                              cursor: "pointer",
                              textAlign: "left",
                            }}
                          >
                            {t(txt.editInfo)}
                          </span>
                          <span
                            className="org-span"
                            onClick={() => {
                              handleShowEditModal();
                              setEditChart(true);
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              isolation: "isolate",
                              paddingLeft: "15px",
                              width: "100%",
                              minHeight: "56px",
                              cursor: "pointer",
                              textAlign: "left",
                            }}
                          >
                            {t(txt.editChart)}
                          </span>
                          <span
                            className="org-span"
                            onClick={() => {
                              setShowDeletePopup(true);
                              setSaveId(item.id);
                              setSaveName(item.strgy_name);
                            }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              isolation: "isolate",
                              paddingLeft: "15px",
                              width: "100%",
                              minHeight: "56px",
                              cursor: "pointer",
                              textAlign: "left",
                            }}
                          >
                            {t(txt.delete)}
                          </span>
                        </>
                      </article>
                    )}
                    {showActions.index === index && (
                      <>
                        {showEditModal && (
                          <EditStrategyModal
                            item={item}
                            showEditModal={showEditModal}
                            handleShowEditModal={handleShowEditModal}
                            editDetails={editDetails}
                            setEditDetails={setEditDetails}
                            editChart={editChart}
                            setEditChart={setEditChart}
                          />
                        )}
                      </>
                    )}
                    {/* onClick={()=> {editStrategy(newStrategy.labels, newStrategy.data, item.id)}} */}
                  </div>
                </div>
                <div>
                  <Text fontWeight="700" marginBottom="8px">
                    {item.strgy_name}
                  </Text>
                  <Text color="#A3AED0" textType="caption">
                    {item.description}
                  </Text>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "4px",
                    }}
                  >
                    <img src={moonStar} alt="" height={16} />
                    <img src={sun} alt="" height={16} />
                  </div>
                  <StrategiesChart
                    chartData={[
                      item.dim1,
                      item.dim2,
                      item.dim3,
                      item.dim4,
                      item.dim5,
                      item.dim6,
                      item.dim7,
                      item.time1,
                      item.time2,
                      item.time3,
                      item.time4,
                      item.time5,
                      item.time6,
                      item.time7,
                    ]}
                    borderColor={item.color}
                    backgroundColor={item.color}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "7px",
                    marginTop: "8px",
                    height: "20px",
                  }}
                >
                  <TextSpan color={item.color} fontWeight="500">
                    {item.saving}
                    {t(txt.percentageEnergySaved)}
                  </TextSpan>
                </div>
              </div>
            ))}
        </div>
      </div>
      {showDeletePopup && (
        <div
          style={{
            background: "rgba(255, 255, 255, 0.8)",
            boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)",
            backdropFilter: "blur(2px)",
            borderRadius: "14px",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            top: "400px",
            left: "650px",
            zIndex: 4,
          }}
        >
          <Text>{t(txt.deleteStrategyPopupTitle)}</Text>
          <input
            placeholder={"Strategy's name"}
            value={name}
            onChange={handleStrategyName}
          />
          {errorMessage ? (
            <Text
              marginBottom="0px"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "6px",
              }}
            >
              {t(txt.errorWrongStrategy)}
            </Text>
          ) : (
            <></>
          )}
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button
              style={{
                padding: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "14px",
              }}
              onClick={() => {
                if (name === saveName) {
                  deleteAndGetStrategy(saveId);
                } else {
                  setErrorMessage(true);
                }
              }}
            >
              {txt.accept}
            </button>
            <button
              style={{
                padding: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "14px",
              }}
              onClick={() => {
                resetStates();
              }}
            >
              {t(txt.cancel)}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
