import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import { ServiceCard, ServiceProfile, ServiceList } from "../UsersStyled";
import { Checkbox } from "antd";
import { GetManagerServiceState } from "../../../services/getManagedServiceService";
import countryFlags from "../../../utils/countryFlags/countryFlags";
import companyFlag from "../../../assets/new_icons/Company.svg";
import { useTranslation } from "react-i18next";

export interface servicesProps {
  serviceData: GetManagerServiceState[] | null;
}

export const Services = ({ serviceData }: servicesProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="row" style={{ marginBottom: "" }}>
        <div className="col-12">
          <Text textType="subTitle" fontWeight="700" marginBottom="16px">
            {t("Service Permits")}
          </Text>
        </div>
      </div>
      <div className="row">
        {Array.isArray(serviceData) &&
          serviceData.map((data) => (
            <div className="col-4" key={data.orgId}>
              {" "}
              <ServiceCard>
                <ServiceProfile src={companyFlag} />{" "}
                <TextSpan fontWeight="700" style={{ marginLeft: "8px" }}>
                  {data.organization_name}
                </TextSpan>
                <hr />
                <ServiceList className="list">
                  {Array.isArray(data.services) &&
                    data.services.map((serv: any) => (
                      <div className="option-container" key={serv.id}>
                        <Checkbox checked={serv.selected === true} />
                        <span style={{ fontSize: "12px", marginLeft: "8px" }}>
                          {serv.servName}
                        </span>
                      </div>
                    ))}
                </ServiceList>
              </ServiceCard>
            </div>
          ))}
      </div>
    </>
  );
};
