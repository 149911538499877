import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { DataCard } from "../DataCard/DataCard";
import { languages } from "../../utils/languages/languages";
import { DataCardListStyled } from "./DataCardListStyled";
import lightBulb from "../../assets/new_icons/luminaire detailCard.svg";
import notPositioned from "../../assets/new_icons/Not positioned.svg";
import disconnected from "../../assets/new_icons/Node disconnected.svg";
import ON from "../../assets/new_icons/ON.svg";
import OFF from "../../assets/new_icons/OFF.svg";
import { useTranslation } from "react-i18next";
import { useLightingService } from "../../services/lightingService";
import { toggleShowNotPositionedNodes } from "../../redux/slices/shared-slice";
import {
  setIsHybridViewActionCreator,
  setIsListViewActionCreator,
} from "../../redux/slices/uiSlice/uiSlice";

export const DataCardList = (props: any) => {
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const nodes = useAppSelector((state) => state.devices.nodes);

  const unpositionedNodes = useAppSelector(
    (state) => state.devices.unpositionedNodes
  );

  const disconnectedLuminaires = nodes
    .filter((luminaire) => !luminaire.online)
    .length.toString();

  const onLuminaries = nodes
    .filter((luminaire) => luminaire.on && luminaire.online)
    .length.toString();

  const offLuminaries = nodes
    .filter((luminaire) => !luminaire.on && luminaire.online)
    .length.toString();

  const alarms = nodes
    .filter((luminaire) =>
      luminaire.alarm_status != 0 ? luminaire.alarm_status : 0
    )
    .length.toString();

  const onNodes = nodes.filter((node) => node.online && node.on);

  let totalNodes = nodes.length;

  const { t } = useTranslation();
  const { getAllNodes }: any = useLightingService();
  const selectedServiceId = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const organizations = useAppSelector((state) => state.devices.organizations);
  const services = useAppSelector((state) => state.devices.services);

  const getNotPositionedData = async (notPositioned: boolean) => {
    if (notPositioned) {
      await dispatch(toggleShowNotPositionedNodes(false));
      await getAllNodes("1", selectedServiceId, organizations, services, false);
      await dispatch(setIsHybridViewActionCreator());
    } else {
      await getAllNodes("1", selectedServiceId, organizations, services);
      await dispatch(toggleShowNotPositionedNodes(true));
    }
  };

  return (
    <>
      {nodes && (
        <DataCardListStyled>
          <DataCard
            title={t("Total luminaires")}
            content={props.length}
            onClickFn={getNotPositionedData}
            mainIcon={lightBulb}
            notPositioned={false}
          />
          <DataCard
            title={t("Not positioned")}
            onClickFn={getNotPositionedData}
            content={unpositionedNodes.length.toString()}
            mainIcon={notPositioned}
            notPositioned={true}
          ></DataCard>
          <DataCard
            title={t("Disconnected")}
            content={props.disConnected}
            mainIcon={disconnected}
            notPositioned={false}
          />
          <DataCard
            title={t("ON luminaires")}
            content={props.onLumin}
            mainIcon={ON}
            notPositioned={false}
          />
          <DataCard
            title={t("OFF luminaires")}
            content={props.offLumin}
            mainIcon={OFF}
            notPositioned={false}
          />
          {/* CARD COMENTADA HASTA QUE ARREGLEMOS EL TEMA DE LAS ALARMAS
          COMMENTED CARD UNTIL WE TALK ABOUT ALARMS*/}
          {/* <DataCard
            title={t(txt.alarmsCapitalLetter)}
            content={alarms}
            mainIcon={alarm}
            isAlert
          /> */}
          {/* CARD COMENTADA HASTA QUE ARREGLEMOS EL TEMA DEL P.ENERGY EN EL BACK
          COMMENTED CARD UNTIL WE TALK ABOUT P.ENERGY ISSUE ON THE BACK        */}
          {/* <DataCard
            title={cardInfo.Consumption}
            content={totalConsumption}
            mainIcon={bolt}
          />      */}
        </DataCardListStyled>
      )}
    </>
  );
};
