import { Line } from "react-chartjs-2";
import { Text } from "../../../styles/shared-styles/text-sizes";
import plusCircle from "../../../assets/plusCircle.svg";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { languages } from "../../../utils/languages/languages";
import { ChartData, ChartDataset } from "chart.js";
import { useState } from "react";

const LineGraph = ({ data, options, title, menuItems }: any) => {
  const [openMenu, setOpenMenu] = useState(false);
  const currentLanguage = useAppSelector(
    (state: RootState) => state.ui.actualLanguage
  );
  const txt = languages[currentLanguage];

  const [selectedFilter, setSelectedFilter] = useState(menuItems[0].title);
  const generateRandomNumbersArray = (length: number) => {
    const randomNumbersArray = [];
    for (let i = 0; i < length; i++) {
      const randomNumber = Math.floor(Math.random() * 5) + 1;
      randomNumbersArray.push(randomNumber);
    }
    return randomNumbersArray;
  };

  const generateDate = (days: number) => {
    const currentDate = new Date();
    const daysOfMonthFromToday = [];
    for (let i = 1; i <= days; i++) {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() - i);
      const dayOfMonth = newDate.getDate();
      daysOfMonthFromToday.push(dayOfMonth);
    }
    const ascDate = daysOfMonthFromToday.sort((a, b) => a - b);
    return ascDate;
  };

  const generateCurrentConsumption = () => {
    const length = menuItems.filter(
      (item: any) => selectedFilter === item.title
    )[0].value;
    if (length > 1) {
      const sets = data?.datasets.map((item: any) => {
        return {
          ...item,
          data: generateRandomNumbersArray(length),
        };
      });
      const dataCurrentConsumption = {
        labels: generateDate(length), // Numbers on the x-axis
        datasets: sets,
      };
      return dataCurrentConsumption;
    } else {
      return data;
    }
  };

  function generateMonthsInAscendingOrder(numberOfMonths: number) {
    const today = new Date();
    const currentMonth = today.getMonth();
    const months = [];

    for (let i = 0; i < numberOfMonths; i++) {
      const newMonth = new Date(today.getFullYear(), currentMonth - i, 1);
      months.push(newMonth.toLocaleString("default", { month: "long" }));
    }

    return months.slice().reverse();
  }
  const generateConsumption = () => {
    const length = selectedFilter === "Last 6 Months" ? 6 : 12;
    if (length === 12) {
      const sets = data?.datasets.map((item: any) => {
        return {
          ...item,
          data: generateRandomNumbersArray(length),
        };
      });
      const dataCurrentConsumption = {
        labels: generateMonthsInAscendingOrder(length), // Numbers on the x-axis
        datasets: sets,
      };
      return dataCurrentConsumption;
    }
    return data;
  };
  return (
    <div
      style={{
        padding: 24,
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        position: "relative",
      }}
    >
      {/* <div
        className="mb-4"
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="#A3AED0">{txt.electricParams}</Text>
        </div>
        <div
          style={{ textAlign: "right", marginTop: "-2px", cursor: "pointer" }}
        >
          <img src={plusCircle} height={16} />
        </div>
      </div> */}
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div>
          <Text color="#A3AED0">{title}</Text>
        </div>
        <div className="dropdown-center" style={{ fontSize: "9px" }}>
          <button
            className="btn dropdown-toggle rounded-pill border border-1"
            type="button"
            data-bs-toggle="dropdown"
            style={{ fontSize: "11px", padding: "5px 20px" }}
            aria-expanded="false"
          >
            {selectedFilter}
          </button>
          <ul className="dropdown-menu">
            {menuItems.map((item: any) => {
              return (
                <li onClick={() => setSelectedFilter(item.title)}>
                  <div className="dropdown-item" key={item.title}>
                    {item.title}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {/* <div className="flex">
          {data &&
            data.datasets.map((item: ChartDataset<"line">) => (
              <div className="flex flex-wrap items-center gap-2 px-2">
                <div
                  className="rounded-full h-2 w-2"
                  style={{ backgroundColor: item.backgroundColor as string }}
                ></div>
                <p className="text-xs">{item.label}</p>
              </div>
            ))}
        </div> */}
      </div>
      <div>
        <Line
          data={
            title === "Current Consumption (Amps)"
              ? generateCurrentConsumption()
              : generateConsumption()
          }
          options={options}
        />
      </div>
    </div>
  );
};

export default LineGraph;
