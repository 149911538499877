import { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ElectricalParametersChart from "./ElectricalParametersChart";
import { languages } from "../../../utils/languages/languages";
import { Text } from "../../../styles/shared-styles/text-sizes";
import arrowBack from "../../../assets/new_icons/Arrow left.svg";
import arrowForward from "../../../assets/new_icons/Arrow right.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ElectricalParametersCardProps {}

export const ElectricalParametersCard = ({}: ElectricalParametersCardProps) => {
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();
  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="#A3AED0">{t(txt.electricParams)}</Text>
        </div>
        {/* <div style={{ textAlign: "right", marginTop: "-2px", cursor: "pointer" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} />
        </div> */}
      </div>
      {/* <div
        className="row"
        style={{ textAlign: "center", paddingLeft: "128px", paddingRight: "128px" }}
      >
        <div className="col-12">
          <button
            style={{
              height: "32px",
              padding: "0",
              background: "transparent",
              float: "left",
              border: "none",
            }}
          >
            <img src={arrowBack} height={16} />
          </button>
          <button
            style={{
              padding: "0",
              background: "transparent",
              fontSize: "24px",
              fontWeight: "700",
              color: "#1B2559",
              border: "none",
            }}
          >
            March 6 - March 12
          </button>
          <button
            style={{
              height: "32px",
              padding: "0",
              background: "transparent",
              float: "right",
              border: "none",
            }}
          >
            <img src={arrowForward} height={16} />
          </button>
        </div>
      </div> */}

      <div className="row">
        {/* <div className="col-12">
          <div
            className="nav nav-tabs"
            id="nav-tab"
            role="tablist"
            style={{
              display:"flex", 
              justifyContent:"space-between",
              border: "none"
            }}
          >
            <a
              className="nav-item nav-link"
              id="nav-amperes-tab"
              data-toggle="tab"
              //href="#nav-amperes"
              role="tab"
              aria-controls="nav-amperes"
              aria-selected="false"
              style={{
                border: "none",
              }}
            >
              <span
                style={{
                  border: "1px solid #bdbdbd",
                  fontSize: "12px",
                  borderRadius: "50px",
                  padding: "0px 16px",
                  color: "#475569",
                  cursor: "pointer"
                }}
              >
                Voltage (V)
              </span>
            </a>
            <a
              className="nav-item nav-link"
              id="nav-amperes-tab"
              data-toggle="tab"
              //href="#nav-amperes"
              role="tab"
              aria-controls="nav-amperes"
              aria-selected="false"
              style={{
                border: "none",
              }}
            >
              <span
                style={{
                  border: "1px solid #bdbdbd",
                  fontSize: "12px",
                  borderRadius: "50px",
                  padding: "0px 16px",
                  color: "#475569",
                  cursor: "pointer"
                }}
              >
                Amperes (A)
              </span>
            </a>
            <a
              className="nav-item nav-link active"
              id="nav-power-tab"
              data-toggle="tab"
              //href="#nav-power"
              role="tab"
              aria-controls="nav-power"
              aria-selected="true"
              style={{
                border: "none",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  borderRadius: "50px",
                  padding: "0px 16px",
                  color: "#ffffff",
                  backgroundColor: "#CE2A96",
                  border: "1px solid #CE2A96",
                  cursor: "pointer"
                }}
              >
                Power (P)
              </span>
            </a>
            <a
              className="nav-item nav-link"
              id="nav-consumption-tab"
              data-toggle="tab"
              //href="#nav-consumption"
              role="tab"
              aria-controls="nav-consumption"
              aria-selected="false"
              style={{
                border: "none",
              }}
            >
              <span
                style={{
                  border: "1px solid #bdbdbd",
                  fontSize: "12px",
                  borderRadius: "50px",
                  padding: "0px 16px",
                  color: "#475569",
                  cursor: "pointer"
                }}
              >
                Consumption (KW/h)
              </span>
            </a>
          </div>
          <div
            className="tab-content"
            id="nav-tabContent"
            style={{ padding: "8px" }}
          >
            <div
              className="tab-pane fade show active"
              id="nav-voltage"
              role="tabpanel"
              aria-labelledby="nav-voltage-tab"
            ></div>
          </div>
          <div
            className="tab-pane fade"
            id="nav-amperes"
            role="tabpanel"
            aria-labelledby="nav-amperes-tab"
          ></div>
          <div
            className="tab-pane fade show"
            id="nav-power"
            role="tabpanel"
            aria-labelledby="nav-power-tab"
          ></div>
        </div> */}
        <div
          className="tab-pane fade"
          id="nav-consumption"
          role="tabpanel"
          aria-labelledby="nav-consumption-tab"
        ></div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "98px" }}
      >
        <ElectricalParametersChart />
      </div>
      {/* <div className="row">
        <div className="col-12">
          <div
            className="chart-container"
            style={{ height: "344px", width: "100%" }}
          >
            {" "}
            <ElectricalParametersChart />
          </div>
        </div>
      </div> */}
    </div>
  );
};
