import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { changeOrgsPopUpActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { logout } from "../../redux/slices/logout/logoutSlice";
import {
  setFirstRenderMapToTrueActionCreator,
  setIsOpenedDetailActionCreator,
  setIsOpenedGroupActionCreator,
} from "../../redux/slices/uiSlice/uiSlice";
import { useLightingService } from "../../services/lightingService";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { languages } from "../../utils/languages/languages";
import {
  HeaderStyled,
  HeaderTitleStyled,
  IconsListStyled,
  IconStyled,
  LogoStyled,
  NavStyled,
  SpacerStyled,
  DropdownContainer,
  ProfilePicture,
  DropdownItem,
  IconProfileStyled,
} from "./HeaderStyled";
import { TextBox, TextSpan } from "../../styles/shared-styles/text-sizes";
import company from "../../assets/new_icons/Company.svg";
import help from "../../assets/new_icons/Help.svg";
import logoSalvi from "../../assets/new_icons/logoSalvi.svg";
import logoutIcon from "../../assets/new_icons/Log out.svg";
import manageUsers from "../../assets/new_icons/Manage users.svg";
import organizationsIcon from "../../assets/new_icons/Organizations - Filled.svg";
import question from "../../assets/question.svg";
import userPicture from "../../assets/new_icons/Profile.svg";

interface UserProps {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  role_name: string;
}
interface HeaderProps {
  profileImg: string;
  alerts: boolean;
  user: UserProps;
  handleMenuClick: (item: any) => void;
  items: any[];
}

export const Header = ({
  profileImg,
  alerts,
  user,
  handleMenuClick,
  items,
}: HeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const popUpChangeOrg = useAppSelector(
    (state) => state.graphics.organizationsPopUp
  );
  const organizations = useAppSelector((state) => state.devices.organizations);
  const services = useAppSelector((state) => state.devices.services);
  const selectedServiceId = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const selectedOrgId = useAppSelector(
    (state) => state.devices.selectedOrganizationId
  );
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const { getAllNodes }: any = useLightingService();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [openRefresh, setOpenRefresh] = useState(false);
  const [opacityOrgs, setOpacityOrgs] = useState(0.5);

  const location = useLocation();
  const pathname = location.pathname;
  const isProfilePage = pathname === "/profile";
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const refresh = async () => {
    try {
      let res = await fetch(
        `${process.env.REACT_APP_API_LOCAL_BASE_URL}/gateway/update_software?orgId=1&servId=1`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  useEffect(() => {
    organizations?.map((org: any) => {
      if (selectedOrgId === org.id) {
        setName(org.organization_name);
      }
    });

    const handleClickOutsideOrgs = (event: MouseEvent) => {
      if (
        popUpChangeOrg &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        dispatch(changeOrgsPopUpActionCreator());
      }
    };

    const handleClickOutsideProfile = (event: MouseEvent) => {
      if (
        isOpen &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("picture-class")
      ) {
        setIsOpen(!isOpen);
      }
    };

    const handleClickOutsideRefresh = (event: MouseEvent) => {
      if (
        openRefresh &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setOpenRefresh(!openRefresh);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideOrgs);
    document.addEventListener("mousedown", handleClickOutsideProfile);
    document.addEventListener("mousedown", handleClickOutsideRefresh);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOrgs);
      document.removeEventListener("mousedown", handleClickOutsideProfile);
      document.removeEventListener("mousedown", handleClickOutsideRefresh);
    };
  }, [selectedOrgId, dispatch, popUpChangeOrg, isOpen, openRefresh]);

  const fetchNodeData = async () => {
    await getAllNodes("1", selectedServiceId, organizations, services);
  };

  useEffect(() => {
    fetchNodeData();
  }, []);

  const { t } = useTranslation();

  return (
    <HeaderStyled className="header">
      <NavStyled>
        {!isProfilePage ? (
          <LogoStyled src={company} alt="smartec logo" />
        ) : null}
        <HeaderTitleStyled>
          <TextBox
            textType="subTitle"
            fontWeight="600"
            style={{ cursor: "pointer" }}
            className="icon-class"
            onClick={() => {
              setOpenRefresh(!openRefresh);
            }}
          >
            {t("welcomeTo", { name })}
          </TextBox>
        </HeaderTitleStyled>
        {openRefresh && (
          <article
            style={{
              position: "absolute",
              top: "18px",
              left: "410px",
              width: "190px",
              maxHeight: "336px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
              boxShadow:
                "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
              borderRadius: "4px",
              zIndex: 3,
            }}
          >
            <style>
              {`
                .refresh:hover {
                  background-color: #1c1b1f11;
                }
              `}
            </style>
            <span
              className="refresh"
              onClick={async () => {
                refresh();
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                isolation: "isolate",
                paddingLeft: "15px",
                paddingRight: "6px",
                width: "100%",
                minHeight: "56px",
                cursor: "pointer",
                textAlign: "left",
              }}
            >
              {t(txt.refresh)}
            </span>
          </article>
        )}
        <SpacerStyled></SpacerStyled>
        <IconsListStyled>
          <LanguageSelector handleMenuClick={handleMenuClick} items={items} />
          <IconStyled
            className="icon-class"
            src={organizationsIcon}
            style={{ opacity: opacityOrgs }}
            onMouseEnter={() => setOpacityOrgs(1)}
            onMouseLeave={() => setOpacityOrgs(0.5)}
            onClick={() => {
              dispatch(changeOrgsPopUpActionCreator());
              dispatch(setIsOpenedDetailActionCreator(false));
              dispatch(setIsOpenedGroupActionCreator(false));
            }}
          />
          {popUpChangeOrg && (
            <article
              style={{
                position: "absolute",
                top: "60px",
                right: "98px",
                width: "190px",
                maxHeight: "336px",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
                boxShadow:
                  "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
                borderRadius: "4px",
                zIndex: 3,
              }}
            >
              {organizations?.map((org: any) => (
                <>
                  <style>
                    {`
                      .org-span:hover {
                        background-color: #1c1b1f11;
                      }
                    `}
                  </style>
                  <span
                    className="org-span"
                    key={org.id}
                    onClick={async () => {
                      await getAllNodes(
                        org.id,
                        selectedServiceId,
                        organizations,
                        services
                      );
                      dispatch(changeOrgsPopUpActionCreator());
                      dispatch(setFirstRenderMapToTrueActionCreator());
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      isolation: "isolate",
                      paddingLeft: "15px",
                      paddingRight: "6px",
                      width: "100%",
                      minHeight: "56px",
                      cursor: "pointer",
                      backgroundColor:
                        org.id === selectedOrgId ? "#1c1b1f11" : "",
                      textAlign: "left",
                    }}
                  >
                    {org.organization_name}
                  </span>
                </>
              ))}
            </article>
          )}
          {/* TODO: To be add later */}
          {/* <IconStyled src={globe}></IconStyled> */}
          {/* <IconStyled src={question}></IconStyled> */}
          <DropdownContainer style={{ marginLeft: "18px" }}>
            <ProfilePicture
              className="picture-class"
              src={logoSalvi}
              alt="Profile Picture"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          </DropdownContainer>
          {isOpen && (
            <article
              style={{
                position: "absolute",
                top: "75%",
                left: "92.35%",
                transform: "translateX(-50%)",
                backgroundColor: "#fff",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                borderRadius: "4px",
                paddingBottom: "8px",
                zIndex: 10,
                width: "234px",
              }}
            >
              <div
                className="row"
                style={{
                  cursor: "pointer",
                  paddingLeft: "12px",
                  paddingRight: "24px",
                  paddingTop: "24px",
                  paddingBottom: "4px",
                  fontSize: "14px",
                  textAlign: "left",
                }}
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <div className="col-4">
                  <ProfilePicture src={logoSalvi}></ProfilePicture>
                </div>
                <div className="col-8" style={{ marginLeft: "-16px" }}>
                  <TextSpan marginBottom="0px">
                    {user.first_name} {user.last_name}{" "}
                  </TextSpan>
                  <TextSpan textType="caption" color="#A3AED0">
                    {user.email}
                  </TextSpan>
                </div>
              </div>
              <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                <hr />
              </div>
              <DropdownItem
                onClick={() => {
                  navigate("/profile");
                }}
                style={{ cursor: "pointer" }}
              >
                <IconProfileStyled
                  src={userPicture}
                  style={{ marginRight: "18px" }}
                />
                <TextSpan>{t("Profile Details")}</TextSpan>
              </DropdownItem>
              {/* <DropdownItem onClick={() => {navigate("/users")}} style={{cursor: "pointer"}}>
                <IconProfileStyled src={manageUsers} style={{ height: "20px", marginRight: "14px" }} />
                <TextSpan>{t("Manage Users")}</TextSpan>
              </DropdownItem> */}
              <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                <hr />
              </div>
              {/* <DropdownItem>
                <IconProfileStyled src={help} style={{ marginRight: "18px" }} />
                <TextSpan>{t("Help Center")}</TextSpan>
              </DropdownItem> */}
              <DropdownItem
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              >
                <IconProfileStyled
                  src={logoutIcon}
                  style={{ marginRight: "18px" }}
                />
                <TextSpan>{t("Log Out")}</TextSpan>
              </DropdownItem>
            </article>
          )}
        </IconsListStyled>
      </NavStyled>
    </HeaderStyled>
  );
};
