import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DevicesFilter } from "../../../types/interfaces";

const initialState: DevicesFilter = {
  area: false,
  all: true,
  on: false,
  off: false,
  disconnected: false,
  alarmas: false,
};

const filterSlice = createSlice({
  name: "actualFilter",
  initialState: initialState,
  reducers: {
    setActualFilterWithArea: (previousData, action: PayloadAction<DevicesFilter>) => ({
      ...action.payload,  
      area: previousData.area
    }),

    setFilters: (previousData, action: PayloadAction<DevicesFilter>) => ({
      ...action.payload
    }),

    setManageArea: (previousData, action: PayloadAction<DevicesFilter>) => ({
      ...action.payload,  
      all: previousData.all,
      on: previousData.on,
      off: previousData.off,
      disconnected: previousData.disconnected,
      alarmas: previousData.alarmas

    }),
  },
});

export const filterReducer = filterSlice.reducer;

export const { 
  setActualFilterWithArea: setActualFilterWithAreaActionCreator,
  setFilters: setFiltersActionCreator,
  setManageArea: setManageAreaActionCreator
} =
  filterSlice.actions;
