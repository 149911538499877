import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import { CardStyled } from "./ProfilePageStyled";
import { ButtonOutlined } from "../../styles/shared-styles/buttons-sizes";
import {
  updatePassword,
  updatePasswordStart,
  updatePasswordFailure,
} from "../../redux/slices/profile/passwordSlices";
import { useTranslation } from "react-i18next";

export const PasswordInformation = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleUpdatePassword = async () => {
    setError(null);
    setSuccessMessage(null);

    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("Please fill in all fields.");
      return;
    }

    if (newPassword === currentPassword) {
      setError("New password should be different from the current password.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }

    try {
      dispatch(updatePasswordStart());
      setIsLoading(true);

      await dispatch(
        updatePassword(currentPassword, newPassword, confirmPassword) // Call the updatePassword action with the form values
      );

      // setSuccessMessage("Password Updated Successfully");
      // setCurrentPassword("");
      // setNewPassword("");
      // setConfirmPassword("");
    } catch (error: any) {
      dispatch(updatePasswordFailure(error));
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <CardStyled>
        <Text textType="subTitle" fontWeight="700" color="background: #1B2559;">
          Password
        </Text>

        <div className="row" style={{ marginBottom: "16px" }}>
          <div className="col-6">
            <Text marginBottom="8px">{t("Current Password")}</Text>
            <input
              className="form-control"
              type="text" // Use type="password" to hide the input characters
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ marginBottom: "16px" }}>
          <div className="col-6">
            <TextSpan>{t("New Password")}</TextSpan>
            <input
              className="form-control"
              type="text" // Use type="password" to hide the input characters
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="col-6">
            <TextSpan>{t("Confirm Password")}</TextSpan>
            <input
              className="form-control"
              type="text" // Use type="password" to hide the input characters
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="row" style={{ textAlign: "right" }}>
          {(error || successMessage) && (
            <div className="col">
              {error && <Text color="red">{error}</Text>}
              {successMessage && <Text color="green">{successMessage}</Text>}
            </div>
          )}

          <div className="col">
            {/* Update the button to trigger password update */}
            {/* <ButtonOutlined onClick={handleUpdatePassword} disabled={isLoading}>
              {isLoading ? "Updating..." : t("Update Password")}
            </ButtonOutlined> */}
          </div>
        </div>
      </CardStyled>
    </>
  );
};
