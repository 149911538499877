import styled from "styled-components";

export const IconStyled = styled.img`
  margin-right: 4px;
`;

export const CardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MapContainer = styled.div`
  height: 250px;
  width: 100%;
  margin-bottom: 32px;
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
`;

export const MiniMapLocalWeatherStyled = styled.div`
  position: absolute;
  bottom: 16px;
  left: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  width: 230px;
  height: 100px;
  min-width: 200px;
  background: ${(props) => props.theme.mainBackgroundColor};
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;

  @media (max-width: 1768px) {
    bottom: -220px;
  }
`;
export const DropDownLabels = styled.div`
  padding: 10px;
  &:hover {
    background-color: #f5f5f5;
  }
`;
