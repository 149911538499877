// Quizás un approach sería enviar ambas listas, de nodos posicionados y no posicionado, y que tengan un orden pero no se revuelvan.
// Por ejemplo, si quieres ordenar por orden alfabético, tanto los nodos posicionados como los no posicionados se autoordenarán,
// pero los no posicionados quedarán arriba (de la A a la Z), y justo debajo, los posicionados (de nuevo de la A a la Z)
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setFiltersActionCreator } from "../../../redux/slices/actualFilterSlice/actualFilterSlice";
import { filteredAllDevicesActionCreator } from "../../../redux/slices/devices/filteredDevicesSlice";
import { changeBackgroundActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { errorToInitialStateActionCreator } from "../../../redux/slices/mqttSlice/mqttSlice";
import {
  setIsOpenedDetailActionCreator,
  setIsOpenedGroupActionCreator,
  setIsStaticViewActionCreator,
  setViewsToFalseActionCreator,
} from "../../../redux/slices/uiSlice/uiSlice";
import { useLightingService } from "../../../services/lightingService";
import {
  checkAll,
  onMasterCheck,
  onItemCheck,
  makeMenusVisible,
  makeIconsVisible,
  changeOrder,
  changeState,
} from "../ListsLogic";
import {
  ErrorPopUp,
  Scroll,
  IconStyled,
  SwitchComponent,
  GroupText,
  ListPopUp,
} from "../ListsStyled";
import { ThemeProvider } from "styled-components";
import { styledMainTheme } from "../../../styles/shared-styles/styleMainTheme";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import { googleIcons } from "../../../utils/googleFontsIcons/icons";
import { languages } from "../../../utils/languages/languages";
import Arrow_right_full from "../../../assets/new_icons/arrow_right_full.svg";
import AZ from "../../../assets/new_icons/A-Z.svg";
import clearFilters from "../../../assets/new_icons/Cross.svg";
import clearFiltersLight from "../../../assets/new_icons/Cross lightGrey.svg";
import connectedIcon from "../../../assets/new_icons/Connected.svg";
import dimming from "../../../assets/new_icons/Dimming.svg";
import disconnectedIcon from "../../../assets/new_icons/Node disconnected.svg";
import doubleArrow from "../../../assets/new_icons/Filter double arrow.svg";
import ellipseGreen from "../../../assets/new_icons/ellipse_green.svg";
import ellipseRed from "../../../assets/new_icons/ellipse_red.svg";
import ellipseYellow from "../../../assets/new_icons/ellipse_yellow.svg";
import Options from "../../../assets/new_icons/Options.svg";
import refresh from "../../../assets/new_icons/list_refresh.svg";
import switchOff from "../../../assets/new_icons/Switch OFF.svg";
import switchOn from "../../../assets/new_icons/Switch ON.svg";
import turnOn from "../../../assets/new_icons/Switch ON.svg";
import turnOff from "../../../assets/new_icons/Switch OFF.svg";
import ZA from "../../../assets/new_icons/Z-A.svg";
import { useTranslation } from "react-i18next";
import { Button, Space, message } from "antd";
import { setSelectedNodeToAddLocation, toggleShowNotPositionedNodes } from "../../../redux/slices/shared-slice";
import { RootState } from "../../../redux/store";
import updateLocation from "../../../services/update_Location/updateLocation";

const theme = {
  primaryColor: styledMainTheme?.light?.smartecGreen,
};

export const HybridMenuTable = () => {
  const dispatch = useAppDispatch();
  const { sendCommandToLuminaire } = useLightingService();
  const showNotPositionedNodes = useAppSelector(
    (state: any) => state.shared.showNotPositionedNodes
  );
  const navigate = useNavigate();
  const devices = useAppSelector((state) => state.devices);
  const filteredNodes = useAppSelector((state) => state.filteredDevices.nodes);
  const unNodes = useAppSelector(
    (state) => state.filteredDevices.unpositionedNodes
  );
  const mqttError = useAppSelector((state) => state.mqtt.error);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const { addLocationMarkerLatLng } = useAppSelector(
    (state: RootState) => state.shared
  );
  const selectedServiceId = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const organizations = useAppSelector((state) => state.devices.organizations);
  const services = useAppSelector((state) => state.devices.services);
  const txt = languages[currentLanguage];
  const { close, sadFace } = googleIcons;

  const [list, setList]: any = useState(filteredNodes);
  const [aa, setAa]: any = useState(unNodes);
  const [masterChecked, setMasterChecked] = useState<boolean>(false);

  //esto quizás no?

  // usestates for menus
  const [nameOrder, setNameOrder] = useState(false);
  const [connectionOrder, setConnectionOrder] = useState(false);
  const [statusOrder, setStatusOrder] = useState(false);
  const [alarmOrder, setAlarmOrder] = useState(false);
  const [groupsOrder, setGroupsOrder] = useState(false);

  // usestates for icons
  const [nameAZ, setNameAZ] = useState(false);
  const [nameZA, setNameZA] = useState(false);
  const [updateId, setUpdateId] = useState<any>(null);
  const { getAllNodes }: any = useLightingService();
  const [addLocationDisable, setAddLocationDisable] = useState(false);
  const [connected, setConnected] = useState(false);
  const [disconnected, setDisconnected] = useState(false);
  const [on, setOn] = useState(false);
  const [off, setOff] = useState(false);
  const [clear, setClear] = useState(false);
  const [critical, setCritical] = useState(false);
  const [moderate, setModerate] = useState(false);
  const [groupsAZ, setGroupsAZ] = useState(false);
  const [groupsZA, setGroupsZA] = useState(false);
  const [groups, setGroups] = useState(false);
  const { t } = useTranslation();

  const handleClickSingle = async (node: any, order: number) => {
    dispatch(setIsStaticViewActionCreator(true));
    try {
      let data: any = sendCommandToLuminaire([node], order, 0);
      /* if (data[0][0].result !== 0) {
      } */
    } catch (error) {
      alert(error);
    }
  };

  const handleClickMultiple = async (nodes: any, order: number) => {
    dispatch(setIsStaticViewActionCreator(true));
    let newArr: any = [];
    nodes.find((node: any) => {
      if (node.selected === true) {
        newArr.push(node);
      }
    });

    try {
      if (newArr.length > 0) {
        sendCommandToLuminaire(newArr, order, 0);
      }
    } catch (error) {
      alert(error);
    }
  };

  const changeBackground = (nodes: any) => {
    let newArr: any = [];
    nodes.find((node: any) => {
      if (node.selected === true) {
        newArr.push(node);
      }
    });

    if (newArr.length > 0) {
      dispatch(changeBackgroundActionCreator());
    }
  };

  const addLocation = (id: any) => {
    setAddLocationDisable(true);
    setUpdateId(id);
  };

  const handleClose = () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(setIsStaticViewActionCreator(true));
    //setSelectedMarker(initialLamp.node_id);
  };

  const handleClickViewDetails = (device: any) => {
    handleClose();
    let viewState = "hybrid";
    dispatch(setViewsToFalseActionCreator());
    navigate(`/luminaire/${device.node_id}`, { state: { viewState } });
  };

  const handleLocationCancel = () => {
    setAddLocationDisable(false);
    setUpdateId(null);
    dispatch(setSelectedNodeToAddLocation(null));
  };

  const updateMapLocation = async (id: any, name: string) => {
    if (!addLocationMarkerLatLng) {
      return message.error("Please select a position on the map to continue.");
    }
    await updateLocation.updateNodePosition(
      id,
      name,
      addLocationMarkerLatLng?.lat,
      addLocationMarkerLatLng?.lng
    );
    dispatch(toggleShowNotPositionedNodes(true));
    await getAllNodes("1", selectedServiceId, organizations, services);
    setAddLocationDisable(false);
    setUpdateId(null);
    dispatch(setSelectedNodeToAddLocation(null));
  };

  const resetFilters = () => {
    dispatch(filteredAllDevicesActionCreator(devices));
    makeIconsVisible(
      "",
      setNameAZ,
      setNameZA,
      setConnected,
      setDisconnected,
      setOn,
      setOff,
      setClear,
      setCritical,
      setModerate,
      setGroupsAZ,
      setGroupsZA,
      setGroups
    );
    dispatch(
      setFiltersActionCreator({
        all: true,
        area: false,
        on: false,
        off: false,
        disconnected: false,
        alarmas: false,
      })
    );
  };

  useEffect(() => {
    setList(filteredNodes);
    setAa(unNodes);
    onMasterCheck(list, setMasterChecked);

    const handleClickOutsideMenu = (event: MouseEvent) => {
      if (
        nameOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setNameOrder(!nameOrder);
      }

      if (
        connectionOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setConnectionOrder(!connectionOrder);
      }

      if (
        statusOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setStatusOrder(!statusOrder);
      }

      if (
        alarmOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setAlarmOrder(!alarmOrder);
      }

      if (
        groupsOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setGroupsOrder(!groupsOrder);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, [
    filteredNodes,
    devices,
    list,
    nameOrder,
    connectionOrder,
    statusOrder,
    alarmOrder,
    groupsOrder,
  ]);

  /*
  <td>
    {device.groups.map((group, index) => (
      <GroupText key={index}>{group}</GroupText>
    ))}
  </td>
  */
  //style={{width: "174px"}}

  return (
    <>
      <div
        style={{
          zIndex: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          marginTop: "200px",
        }}
      >
        {mqttError && (
          <ErrorPopUp>
            <div className="errorpopup__button-container">
              <span
                onClick={() => {
                  dispatch(errorToInitialStateActionCreator());
                }}
                className="material-symbols-rounded errorpopup__button-container__icon"
              >
                {close}
              </span>
            </div>
            <div className="errorpopup__message-container">
              <span
                className="material-symbols-rounded errorpopup__message-container__icon"
                style={{ color: "#CF2A2A", marginRight: "5px" }}
              >
                {sadFace}
              </span>
              <TextSpan color="#CF2A2A">{t(txt.errorConnection)}</TextSpan>
            </div>
          </ErrorPopUp>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          zIndex: 2,
          // position: "absolute",
          top: "104px",
          /* right: "613px" */ right: "644px",
          margin: "10px",
        }}
      >
        <input
          type="checkbox"
          className="form-check-input"
          checked={masterChecked}
          id="mastercheck"
          onChange={(e) => checkAll(e, list)}
          style={{ cursor: "pointer", width: "16px", height: "16px" }}
        />
        <IconStyled
          src={refresh}
          style={{
            cursor: "pointer",
            marginRight: "0px",
            marginTop: "5px",
            width: "15px",
            height: "15px",
          }}
          title={t(txt.refresh)}
        />
        <IconStyled
          src={clearFilters}
          onClick={() => {
            resetFilters();
          }}
          style={{
            cursor: "pointer",
            marginRight: "0px",
            marginTop: "5px",
            width: "13px",
            height: "13px",
          }}
          title={t(txt.clearAllFilters)}
        />
        <Text
          marginBottom="0"
          style={{ marginLeft: "2px", marginRight: "7px" }}
        >
          |
        </Text>
        <IconStyled
          src={turnOn}
          onClick={() => {
            handleClickMultiple(list, 1);
          }}
          style={{
            cursor: "pointer",
            marginRight: "12px",
            marginTop: "2px",
            width: "15px",
            height: "15px",
          }}
          title={t(txt.switchON)}
        />
        <IconStyled
          src={turnOff}
          onClick={() => {
            handleClickMultiple(list, 2);
          }}
          style={{
            cursor: "pointer",
            marginRight: "12px",
            marginTop: "2px",
            width: "16px",
            height: "16px",
          }}
          title={t(txt.switchOFF)}
        />
        <IconStyled
          src={dimming}
          onClick={() => {
            changeBackground(list);
          }}
          style={{
            cursor: "pointer",
            marginRight: "12px",
            marginTop: "2px",
            width: "16px",
            height: "16px",
          }}
          title={t(txt.dimming)}
        />
        {/* <IconStyled
          src={groupsIcon}
          style={{ cursor: "pointer", marginRight: "0px", marginTop: "2px", width: "16px", height: "16px" }}
          title={t(txt.newGroup)}
        /> */}
      </div>
      <Scroll style={{ position: "relative", zIndex: 1 }}>
        {/* <Scroll style={{ width: "49.2%", position: "absolute", zIndex: 1 }}> */}
        <div style={{ paddingRight: 3 }}>
          {/* </div><div style={{ paddingRight: 10 }}> */}
          <table
            className="table table-borderless"
            style={{ fontSize: "12px", width: "100%" }}
          >
            <thead
              style={{
                boxShadow:
                  "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
                position: "sticky",
                top: 1,
                zIndex: 1,
                background: "white",
              }}
            >
              <tr style={{ position: "relative" }}>
                <th scope="col"></th>
                <th scope="col" style={{ cursor: "pointer", width: 120 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="icon-class"
                    onClick={() => {
                      setNameOrder(!nameOrder);
                      makeMenusVisible(
                        "name",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">{t("Name")}</TextSpan>
                    {nameAZ ? (
                      <IconStyled
                        style={{
                          marginRight: -11,
                          width: "16px",
                          height: "16px",
                        }}
                        src={AZ}
                      />
                    ) : nameZA ? (
                      <IconStyled
                        style={{
                          marginRight: -11,
                          width: "16px",
                          height: "16px",
                        }}
                        src={ZA}
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} />
                  </div>
                  {nameOrder && (
                    <article>
                      <ListPopUp
                        style={{ right: 729, top: 238, position: "fixed" }}
                      >
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setNameOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 16,
                              marginRight: 10,
                              width: "13px",
                              height: "13px",
                            }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeOrder(list, 1, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "nameAZ",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setNameOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 15,
                              marginRight: 10,
                              width: "16px",
                              height: "16px",
                            }}
                            src={AZ}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.fromAToZ)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{
                            width: 140,
                            paddingBottom: "18px",
                            height: "60px",
                          }}
                          onClick={() => {
                            changeOrder(list, 2, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "nameZA",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setNameOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 15,
                              marginRight: 10,
                              width: "16px",
                              height: "16px",
                            }}
                            src={ZA}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.fromZToA)}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ cursor: "pointer", width: 174 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="icon-class"
                    onClick={() => {
                      setConnectionOrder(!connectionOrder);
                      makeMenusVisible(
                        "connection",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">{t(txt.connection)}</TextSpan>
                    {connected ? (
                      <IconStyled
                        style={{
                          marginRight: -11,
                          width: "16px",
                          height: "16px",
                        }}
                        src={connectedIcon}
                      />
                    ) : disconnected ? (
                      <IconStyled
                        style={{
                          marginRight: -11,
                          width: "16px",
                          height: "16px",
                        }}
                        src={disconnectedIcon}
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} />
                  </div>
                  {connectionOrder && (
                    <article>
                      <ListPopUp
                        style={{ right: 582, top: 238, position: "fixed" }}
                      >
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setConnectionOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 16,
                              marginRight: 10,
                              width: "13px",
                              height: "13px",
                            }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeState("connected", devices);
                            makeIconsVisible(
                              "connected",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setConnectionOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 15,
                              marginRight: 10,
                              width: "16px",
                              height: "16px",
                            }}
                            src={connectedIcon}
                          />
                          <span className="listpopup__block__text">
                            {t("Connected")}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{
                            width: 140,
                            paddingBottom: "18px",
                            height: "60px",
                          }}
                          onClick={() => {
                            changeState("disconnected", devices);
                            makeIconsVisible(
                              "disconnected",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setConnectionOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 15,
                              marginRight: 10,
                              width: "16px",
                              height: "16px",
                            }}
                            src={disconnectedIcon}
                          />
                          <span className="listpopup__block__text">
                            {t("Disconnected")}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ cursor: "pointer" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="icon-class"
                    onClick={() => {
                      setStatusOrder(!statusOrder);
                      makeMenusVisible(
                        "status",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">{t(txt.status)}</TextSpan>
                    {on ? (
                      <IconStyled
                        style={{
                          marginRight: -11,
                          width: "16px",
                          height: "16px",
                        }}
                        src={switchOn}
                      />
                    ) : off ? (
                      <IconStyled
                        style={{
                          marginRight: -11,
                          width: "16px",
                          height: "16px",
                        }}
                        src={switchOff}
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} />
                  </div>
                  {statusOrder && (
                    <article>
                      <ListPopUp
                        style={{ right: 415, top: 238, position: "fixed" }}
                      >
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setStatusOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 16,
                              marginRight: 10,
                              width: "13px",
                              height: "13px",
                            }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeState("on", devices);
                            makeIconsVisible(
                              "on",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setStatusOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 15,
                              marginRight: 10,
                              width: "16px",
                              height: "16px",
                            }}
                            src={switchOn}
                          />
                          <span className="listpopup__block__text">
                            {t("ON")}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{
                            width: 140,
                            paddingBottom: "18px",
                            height: "60px",
                          }}
                          onClick={() => {
                            changeState("off", devices);
                            makeIconsVisible(
                              "off",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setStatusOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 15,
                              marginRight: 10,
                              width: "16px",
                              height: "16px",
                            }}
                            src={switchOff}
                          />
                          <span className="listpopup__block__text">
                            {t("OFF")}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ cursor: "pointer" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="icon-class"
                    onClick={() => {
                      setAlarmOrder(!alarmOrder);
                      makeMenusVisible(
                        "alarm",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">{t(txt.alarm)}</TextSpan>
                    {clear ? (
                      <IconStyled
                        style={{ marginRight: -11 }}
                        src={ellipseGreen}
                      />
                    ) : critical ? (
                      <IconStyled
                        style={{ marginRight: -11 }}
                        src={ellipseRed}
                      />
                    ) : moderate ? (
                      <IconStyled
                        style={{ marginRight: -11 }}
                        src={ellipseYellow}
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} />
                  </div>
                  {alarmOrder && (
                    <article>
                      <ListPopUp
                        style={{ right: 259, top: 238, position: "fixed" }}
                      >
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setAlarmOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 14,
                              marginRight: 10,
                              width: "13px",
                              height: "13px",
                            }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeState("alarm", devices);
                            makeIconsVisible(
                              "clear",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setAlarmOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 16, marginRight: 10 }}
                            src={ellipseGreen}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.clear)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeOrder(list, 1, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "critical",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setAlarmOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10 }}
                            src={ellipseRed}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.critical)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{
                            width: 140,
                            paddingBottom: "18px",
                            height: "60px",
                          }}
                          onClick={() => {
                            changeOrder(list, 1, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "moderate",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setAlarmOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10 }}
                            src={ellipseYellow}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.moderate)}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ cursor: "pointer" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="icon-class"
                    onClick={() => {
                      setGroupsOrder(!groupsOrder);
                      makeMenusVisible(
                        "groups",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">{t("Groups")}</TextSpan>
                    {groupsAZ ? (
                      <IconStyled
                        style={{
                          marginRight: -11,
                          width: "16px",
                          height: "16px",
                        }}
                        src={AZ}
                      />
                    ) : groupsZA ? (
                      <IconStyled
                        style={{
                          marginRight: -11,
                          width: "16px",
                          height: "16px",
                        }}
                        src={ZA}
                      />
                    ) : groups ? (
                      <IconStyled
                        style={{ marginRight: -11 }}
                        src={ellipseYellow}
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} />
                  </div>
                  {groupsOrder && (
                    <article>
                      <ListPopUp
                        style={{ right: 96, top: 238, position: "fixed" }}
                      >
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setGroupsOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 16,
                              marginRight: 10,
                              width: "13px",
                              height: "13px",
                            }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeOrder(list, 1, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "groupsAZ",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setGroupsOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 15,
                              marginRight: 10,
                              width: "16px",
                              height: "16px",
                            }}
                            src={AZ}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.fromAToZ)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeOrder(list, 2, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "groupsZA",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setGroupsOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{
                              marginLeft: 15,
                              marginRight: 10,
                              width: "16px",
                              height: "16px",
                            }}
                            src={ZA}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.fromZToA)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{
                            width: 140,
                            paddingBottom: "18px",
                            height: "60px",
                          }}
                          onClick={() => {
                            changeOrder(list, 1, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "groups",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups
                            );
                            setGroupsOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10 }}
                            src={ellipseYellow}
                          />
                          <span className="listpopup__block__text">
                            {t("Groups")}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  <img
                    src={Options}
                    height={12}
                    style={{ cursor: "pointer" }}
                    alt={"options"}
                  />
                </th>
                {showNotPositionedNodes === false && <th>Location Status</th>}
              </tr>
            </thead>
            <tbody
              style={{
                paddingTop: 20,
              }}
            >
              {/* {aa.map((device: any) => (
                <>
                  <tr>
                    <Space />
                  </tr>
                  <tr key={device.node_id}>
                    <td scope="row">
                      <input
                        type="checkbox"
                        checked={device.selected === true ? true : false}
                        className="form-check-input"
                        id={`rowcheck${device.node_id}`}
                        onChange={(e: any) => onItemCheck(e, device, list)}
                        style={{ cursor: "pointer", width: "16px", height: "16px" }}
                      />
                    </td>      
                    <td>
                      <TextSpan>{device.name}</TextSpan>
                    </td>
                    <td>
                      {device.online === 0 ?
                        <>
                          <IconStyled src={disconnectedIcon} />
                          <TextSpan>{t(txt.disconnected)}</TextSpan>
                        </>
                      :
                        <>
                          <IconStyled src={connectedIcon} />
                          <TextSpan>{t(txt.connected)}</TextSpan>
                        </>
                      }
                    </td>
                    <td>
                      {device.on === 0 ? 
                        device.online === 0 ?
                          <SwitchComponent checkedChildren="On" unCheckedChildren="Off" checked={false} disabled onClick={() => handleClickSingle(device, 1)} />
                        :
                          <SwitchComponent checkedChildren="On" unCheckedChildren="Off" checked={false} onClick={() => handleClickSingle(device, 1)} />
                      : 
                        <ThemeProvider theme={theme}>
                          <SwitchComponent
                            checked
                            checkedChildren="On" 
                            unCheckedChildren="Off"
                            onClick={() => handleClickSingle(device, 2)}
                            />
                        </ThemeProvider>
                      }
                    </td>
                    <td>
                      <>
                        {device.alarm_status === 0 ? (
                          <>
                            <IconStyled src={ellipseGreen} />
                            <TextSpan>{t(txt.clear)}</TextSpan>
                          </>
                        ) : device.alarm_status === 1 ? (
                          <>
                            <IconStyled src={ellipseRed} />
                            <TextSpan>{t(txt.critical)}</TextSpan>
                          </>
                        ) : device.alarm_status === 2 ? (
                          <>
                            <IconStyled src={ellipseYellow} />
                            <TextSpan>{t(txt.moderate)}</TextSpan>
                          </>
                        ) : (
                          <>
                            <IconStyled src={ellipseGreen} />
                            <TextSpan>{t(txt.clear)}</TextSpan>
                          </>
                        )}
                      </>
                    </td>
                    <td>
                      <TextSpan{t(txt.none)}</TextSpan>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <img src={Arrow_right_full} height={12} alt={"arrow right"} style={{ cursor: "pointer" }} onClick={() => {handleClickViewDetails(device)}} />
                    </td>
                  </tr>
                </>
              ))} */}

              {list.map((device: any) => (
                <>
                  {showNotPositionedNodes ? (
                    device?.longitude !== "0.000000" && (
                      <>
                        <tr>
                          <Space />
                        </tr>
                        <tr key={device.node_id}>
                          <td scope="row">
                            <input
                              type="checkbox"
                              checked={device.selected === true ? true : false}
                              className="form-check-input"
                              id={`rowcheck${device.node_id}`}
                              onChange={(e: any) =>
                                onItemCheck(e, device, list)
                              }
                              style={{
                                cursor: "pointer",
                                width: "16px",
                                height: "16px",
                              }}
                            />
                          </td>
                          <td>
                            <TextSpan>{device.name}</TextSpan>
                          </td>
                          <td>
                            {device.online === 0 ? (
                              <>
                                <IconStyled src={disconnectedIcon} />
                                <TextSpan> {t("Disconnected")}</TextSpan>
                              </>
                            ) : (
                              <>
                                <IconStyled src={connectedIcon} />
                                <TextSpan> {t("Connected")}</TextSpan>
                              </>
                            )}
                          </td>
                          <td>
                            {device.on === 0 ? (
                              device.online === 0 ? (
                                <SwitchComponent
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  checked={false}
                                  disabled
                                  onClick={() => handleClickSingle(device, 1)}
                                />
                              ) : (
                                <SwitchComponent
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  checked={false}
                                  onClick={() => handleClickSingle(device, 1)}
                                />
                              )
                            ) : (
                              <ThemeProvider theme={theme}>
                                <SwitchComponent
                                  checked
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  onClick={() => handleClickSingle(device, 2)}
                                />
                              </ThemeProvider>
                            )}
                          </td>
                          <td>
                            <>
                              {device.alarm_status === 0 ? (
                                <>
                                  <IconStyled src={ellipseGreen} />
                                  <TextSpan>{t(txt.clear)}</TextSpan>
                                </>
                              ) : device.alarm_status === 1 ? (
                                <>
                                  <IconStyled src={ellipseRed} />
                                  <TextSpan> {t(txt.critical)}</TextSpan>
                                </>
                              ) : device.alarm_status === 2 ? (
                                <>
                                  <IconStyled src={ellipseYellow} />
                                  <TextSpan>{t(txt.moderate)}</TextSpan>
                                </>
                              ) : (
                                <>
                                  <IconStyled src={ellipseGreen} />
                                  <TextSpan>{t(txt.clear)}</TextSpan>
                                </>
                              )}
                            </>
                          </td>
                          <td>
                            <TextSpan>{t(txt.none)}</TextSpan>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <img
                              src={Arrow_right_full}
                              height={12}
                              alt={"arrow right"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleClickViewDetails(device);
                              }}
                            />
                          </td>
                          {showNotPositionedNodes === false && (
                            <>
                              {updateId === device.node_id ? (
                                <Space size="large">
                                  <Button
                                    onClick={() =>
                                      updateMapLocation(
                                        device.node_id,
                                        device?.name
                                      )
                                    }
                                  >
                                    Update
                                  </Button>
                                  <Button onClick={handleLocationCancel}>
                                    Cancel
                                  </Button>
                                </Space>
                              ) : (
                                <td>
                                  <Button
                                    onClick={() => {
                                      dispatch(
                                        setSelectedNodeToAddLocation(
                                          device.node_id
                                        )
                                      );
                                      addLocation(device.node_id);
                                    }}
                                    disabled={addLocationDisable}
                                  >
                                    Add Location
                                  </Button>
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      </>
                    )
                  ) : (
                    <>
                      <tr>
                        <Space />
                      </tr>
                      <tr key={device.node_id}>
                        <td scope="row">
                          <input
                            type="checkbox"
                            checked={device.selected === true ? true : false}
                            className="form-check-input"
                            id={`rowcheck${device.node_id}`}
                            onChange={(e: any) => onItemCheck(e, device, list)}
                            style={{
                              cursor: "pointer",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </td>
                        <td>
                          <TextSpan>{device.name}</TextSpan>
                        </td>
                        <td>
                          {device.online === 0 ? (
                            <>
                              <IconStyled src={disconnectedIcon} />
                              <TextSpan> {t("Disconnected")}</TextSpan>
                            </>
                          ) : (
                            <>
                              <IconStyled src={connectedIcon} />
                              <TextSpan> {t("Connected")}</TextSpan>
                            </>
                          )}
                        </td>
                        <td>
                          {device.on === 0 ? (
                            device.online === 0 ? (
                              <SwitchComponent
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={false}
                                disabled
                                onClick={() => handleClickSingle(device, 1)}
                              />
                            ) : (
                              <SwitchComponent
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={false}
                                onClick={() => handleClickSingle(device, 1)}
                              />
                            )
                          ) : (
                            <ThemeProvider theme={theme}>
                              <SwitchComponent
                                checked
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                onClick={() => handleClickSingle(device, 2)}
                              />
                            </ThemeProvider>
                          )}
                        </td>
                        <td>
                          <>
                            {device.alarm_status === 0 ? (
                              <>
                                <IconStyled src={ellipseGreen} />
                                <TextSpan>{t(txt.clear)}</TextSpan>
                              </>
                            ) : device.alarm_status === 1 ? (
                              <>
                                <IconStyled src={ellipseRed} />
                                <TextSpan> {t(txt.critical)}</TextSpan>
                              </>
                            ) : device.alarm_status === 2 ? (
                              <>
                                <IconStyled src={ellipseYellow} />
                                <TextSpan>{t(txt.moderate)}</TextSpan>
                              </>
                            ) : (
                              <>
                                <IconStyled src={ellipseGreen} />
                                <TextSpan>{t(txt.clear)}</TextSpan>
                              </>
                            )}
                          </>
                        </td>
                        <td>
                          <TextSpan>{t(txt.none)}</TextSpan>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <img
                            src={Arrow_right_full}
                            height={12}
                            alt={"arrow right"}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleClickViewDetails(device);
                            }}
                          />
                        </td>
                        {showNotPositionedNodes === false && (
                          <>
                            {updateId === device.node_id ? (
                              <Space size="large">
                                <Button
                                  onClick={() =>
                                    updateMapLocation(
                                      device.node_id,
                                      device?.name
                                    )
                                  }
                                >
                                  Update
                                </Button>
                                <Button onClick={handleLocationCancel}>
                                  Cancel
                                </Button>
                              </Space>
                            ) : (
                              <td>
                                <Button
                                  onClick={() => {
                                    dispatch(
                                      setSelectedNodeToAddLocation(
                                        device.node_id
                                      )
                                    );
                                    addLocation(device.node_id);
                                  }}
                                  disabled={addLocationDisable}
                                >
                                  Add Location
                                </Button>
                              </td>
                            )}
                          </>
                        )}
                      </tr>
                    </>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </Scroll>
    </>
  );
};
