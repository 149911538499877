import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import updateManagerUserPersonalInformationService from "../../../services/manager_User/updateManagerUserPersoInfoService";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface UpdateManagerUserPersonalInformationState {
  isLoading: boolean;
  error: string | null;
}

const initialState: UpdateManagerUserPersonalInformationState = {
  isLoading: false,
  error: null,
};

const updateManagerUserPersonalInformationSlice = createSlice({
  name: "updateManagerUserPersonalInformation",
  initialState,
  reducers: {
    updateManagerUserPersonalInformationStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    updateManagerUserPersonalInformationSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    updateManagerUserPersonalInformationFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  updateManagerUserPersonalInformationStart,
  updateManagerUserPersonalInformationSuccess,
  updateManagerUserPersonalInformationFailure,
} = updateManagerUserPersonalInformationSlice.actions;

export const updateManagerUserPersonalInformation =
  (
    userId: string,
    data: any
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(updateManagerUserPersonalInformationStart());

      const token = Cookies.get("token");

      // If the token is undefined, handle the error
      if (!token) {
        throw new Error("Token not found.");
      }

      // Call the updatePersonalInformationService to update the personal information
      await updateManagerUserPersonalInformationService.updateManagerUserPersonalInformation(
        token,
        userId,
        data
      );

      // You can dispatch additional actions or update your personal information state if needed

      dispatch(updateManagerUserPersonalInformationSuccess());
      return "Personal information updated successfully!";
    } catch (error: any) {
      dispatch(
        updateManagerUserPersonalInformationFailure(error.message as string)
      );

      throw error;
    }
  };

export default updateManagerUserPersonalInformationSlice.reducer;
