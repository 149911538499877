import { createSlice } from "@reduxjs/toolkit";
import { Graphics } from "../../../types/interfaces";

const initialState: Graphics = {
  background: false,
  organizationsPopUp: false
};

const graphicsSlice = createSlice({
  name: "graphics",
  initialState: initialState,
  reducers: {
    changeBackground: (previousState: Graphics) => ({
        ...previousState,
        background: !previousState.background
    }),

    changeOrgsPopUp: (previousState: Graphics) => ({
      ...previousState,
      organizationsPopUp: !previousState.organizationsPopUp
    }),

    /* changeOrgsPopUp: (previousState: Graphics, action: any) => ({
      ...previousState,
      ...action.payload,
      background: previousState.background,
      organizationsPopUp: action.payload
    }), */

    turnAllFalse: (previousState: Graphics) => ({
      ...previousState,
      background: false,
      organizationsPopUp: false
  }),
  },
});

export const graphicsReducer = graphicsSlice.reducer;

export const {
  changeBackground: changeBackgroundActionCreator,
  changeOrgsPopUp: changeOrgsPopUpActionCreator,
  turnAllFalse: turnAllFalseActionCreator
} = graphicsSlice.actions;
