import { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { Modal } from "react-bootstrap";
import { Details } from "../StrategiesSteps/Step1Details/Details";
import { Dimming } from "../StrategiesSteps/Step2Dimming/Dimming";
import { Review } from "../StrategiesSteps/Step3Review/Review";
import { languages } from "../../../utils/languages/languages";
import { CenteredTitle } from "../NewStrategyModal/NewStrategyModalStyled";
import styled from "styled-components";
import { Text } from "../../../styles/shared-styles/text-sizes";
import { useTranslation } from "react-i18next";

interface NewStrategyModalProps {
  showModal: boolean;
  handleShowModal: () => void;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 800px;
  }

  .modal-content {
    padding-bottom: 0px;
    background: rgba(236, 236, 236, 1);
    border: 0px;
  }
`;

export const NewStrategyModal = ({
  showModal,
  handleShowModal,
}: NewStrategyModalProps) => {
  const [showPreventPopup, setShowPreventPopup] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [showDimming, setShowDimming] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [newChartData, setNewChartData] = useState();
  const [errorMessage, setErrorMessage] = useState(false);

  const name = useAppSelector((state) => state.strategies.name);
  const selectedOption = useAppSelector(
    (state) => state.strategies.color.value
  );
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const handlePreventPopup = () => {
    setShowPreventPopup(true);
  };

  const goToDimming = async () => {
    if (name === "" || selectedOption === undefined) {
      setErrorMessage(true);
      setShowDetails(true);
      setShowDimming(false);
      setShowReview(false);
    } else {
      setErrorMessage(false);
      setShowDetails(false);
      setShowDimming(true);
      setShowReview(false);
    }
  };

  const goToReview = async () => {
    if (name === "" || selectedOption === "") {
      setShowDetails(true);
      setShowDimming(false);
      setShowReview(false);
    } else {
      setShowDetails(false);
      setShowDimming(false);
      setShowReview(true);
    }
  };
  const { t } = useTranslation();
  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ? (
        <div
          style={{
            background: "rgba(255, 255, 255, 0.8)",
            boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)",
            backdropFilter: "blur(2px)",
            borderRadius: "14px",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            top: "270px",
            left: "177px",
            zIndex: 3,
          }}
        >
          <Text>{t(txt.closeStrategyPopupTitle)}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button
              style={{
                padding: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "14px",
              }}
              onClick={() => {
                handleShowModal();
              }}
            >
              {txt.accept}
            </button>
            <button
              style={{
                padding: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "14px",
              }}
              onClick={() => {
                setShowPreventPopup(false);
              }}
            >
              {t(txt.cancel)}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          padding: "20px 20px 0 20px",
          background: "rgba(236, 236, 236, 0.92)",
          zIndex: 2,
        }}
      ></Modal.Header>
      <Modal.Body
        style={{
          pointerEvents: showPreventPopup ? "none" : "auto",
          background: "rgba(236, 236, 236, 0.92)",
          marginBottom: "10px",
          paddingTop: "0px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <CenteredTitle>
          <Text
            fontWeight="700"
            marginBottom="5px"
            color="#475569"
            style={{ fontSize: "22px" }}
          >
            {t(txt.createStrategy)}
          </Text>
          <Text textType="caption" fontWeight="400" color="#475569">
            {t(txt.followSteps)}
          </Text>
        </CenteredTitle>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "15px",
            marginTop: "50px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              borderTop: showDetails
                ? "2px solid rgb(27, 37, 89)"
                : "2px solid rgb(217, 217, 217)",
            }}
            onClick={() => {
              setShowDetails(true);
              setShowDimming(false);
              setShowReview(false);
            }}
          >
            <Text
              color={showDetails ? "rgb(27, 37, 89)" : "rgb(189, 189, 189)"}
              fontWeight="500"
              style={{
                paddingTop: "16px",
                marginBottom: "2px",
                fontSize: "10px",
              }}
            >
              {t(txt.step1)}
            </Text>
            <Text
              color={showDetails ? "rgb(27, 37, 89)" : "rgb(189, 189, 189)"}
              fontWeight="700"
              style={{ fontSize: "16px" }}
            >
              {t(txt.name)}
            </Text>
          </div>
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              borderTop: showDimming
                ? "2px solid rgb(27, 37, 89)"
                : "2px solid rgb(217, 217, 217)",
            }}
            onClick={() => {
              goToDimming();
            }}
          >
            <Text
              color={showDimming ? "rgb(27, 37, 89)" : "rgb(189, 189, 189)"}
              fontWeight="500"
              style={{
                paddingTop: "16px",
                marginBottom: "2px",
                fontSize: "10px",
              }}
            >
              {t(txt.step2)}
            </Text>
            <Text
              color={showDimming ? "rgb(27, 37, 89)" : "rgb(189, 189, 189)"}
              fontWeight="700"
              style={{ fontSize: "16px" }}
            >
              {t(txt.dimming)}
            </Text>
          </div>
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              borderTop: showReview
                ? "2px solid rgb(27, 37, 89)"
                : "2px solid rgb(217, 217, 217)",
            }}
            onClick={() => {
              goToReview();
            }}
          >
            <Text
              color={showReview ? "rgb(27, 37, 89)" : "rgb(189, 189, 189)"}
              fontWeight="500"
              style={{
                paddingTop: "16px",
                marginBottom: "2px",
                fontSize: "10px",
              }}
            >
              {t(txt.step3)}
            </Text>
            <Text
              color={showReview ? "rgb(27, 37, 89)" : "rgb(189, 189, 189)"}
              fontWeight="700"
              style={{ fontSize: "16px" }}
            >
              {t(txt.step3Title)}
            </Text>
          </div>
        </div>
        <div>
          {showDetails && (
            <Details
              onHide={handleShowModal}
              showDimming={showDimming}
              goToDimming={goToDimming}
              errorMessage={errorMessage}
            />
          )}
          {showDimming && (
            <Dimming
              setShowDetails={setShowDetails}
              setShowDimming={setShowDimming}
              showDimming={showDimming}
              setShowReview={setShowReview}
              showReview={showReview}
              setNewChartData={setNewChartData}
              newChartData={newChartData}
            />
          )}
          {showReview && (
            <Review
              onHide={handleShowModal}
              setShowDetails={setShowDetails}
              setShowDimming={setShowDimming}
              showDimming={showDimming}
              setShowReview={setShowReview}
              setNewChartData={setNewChartData}
              newChartData={newChartData}
            />
          )}
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
