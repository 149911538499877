import { Icon } from "leaflet";
import bulbOn from "../assets/icons/icon-bulb-on.svg";
import bulbOff from "../assets/icons/icon-bulb-off.svg";
import disconnectedSvg from "../assets/icons/baseline-wifi-off.svg";
// import lightBulb from "../assets/icons/lightBulb.svg";
import lightBulb from "../assets/node.svg";
import alarm from "../assets/icons/icon-device-alarm.svg";
import selectedIcon from "../assets/icons/icon-selected.svg";
import switchBoard from "../assets/icons/switchboard.svg";

export type Status =
  | "on"
  | "off"
  | "error"
  | "disconnected"
  | "lightBulb"
  | "switchBoard";

export const getIcon = (status: Status, selected: boolean) => {
  const shadowUrl = selected ? selectedIcon : "";
  const shadowSize = [40, 40];

  const ICONS = {
    on: new Icon({
      iconUrl: bulbOn,
      iconSize: [30, 30],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    off: new Icon({
      iconUrl: bulbOff,
      iconSize: [30, 30],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    error: new Icon({
      iconUrl: alarm,
      iconSize: [20, 20],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    disconnected: new Icon({
      iconUrl: disconnectedSvg,
      shadowUrl,
      iconSize: [25, 25],
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    lightBulb: new Icon({
      iconUrl: lightBulb,
      shadowUrl,
      iconSize: [25, 25],
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    switchBoard: new Icon({
      iconUrl: switchBoard,
      shadowUrl,
      iconSize: [25, 25],
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
  };

  return ICONS[status];
};
