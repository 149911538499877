import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetManagerUsers } from "../../../types/interfaces";

const initialGetManagerUsersState: GetManagerUsers[] = [];

const getManagerUsersSlice = createSlice({
  name: "managerUsers",
  initialState: initialGetManagerUsersState,
  reducers: {
    loadGetManagerUsersActionCreator: (
      state,
      action: PayloadAction<GetManagerUsers[]>
    ) => {
      return action.payload;
    },
  },
});

export const { loadGetManagerUsersActionCreator } =
  getManagerUsersSlice.actions;

export default getManagerUsersSlice.reducer;
