import axios from "axios";

class UpdatePasswordService {
  private webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;

  public async updatePasswordWithToken(
    token: string,
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
  ): Promise<void> {
    try {
      const response = await fetch(
        `${this.webAddressPrefix}/profile/change-password`,
        {
          method: "PATCH",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            current_password: currentPassword,
            new_password: newPassword,
            confirm_password: confirmPassword,
          }),
        }
      );
      if (!response) {
        throw new Error("there is no response data");
      }
    } catch (error) {
      throw new Error("there is an error. Please try again later.");
    }
  }
}

const updatePasswordService = new UpdatePasswordService();
export default updatePasswordService;
