import Cookies from "js-cookie";
import { GetManagedUserOrganisationPayload } from "../types/interfaces";
import { useAppDispatch } from "../redux/hooks";
import { loadGetManagerUsersOrganisationActionCreator } from "../redux/slices/managerUsers/getManagerUsersOrgSlice";

export interface GetManagerOrganisationState {
  id: string;
  organization: string;
  type: string;
  city: string;
  country: string;
  organizationActive: string;
  selected: boolean;
}

export const useGetManagedUserOrganisationService = () => {
  const webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;
  const dispatch = useAppDispatch();

  const saveTokenToStorage = (token: string) => {
    Cookies.set("token", token, { expires: 7 });
  };

  const getManagedUserOrganisation = async (
    token: string,
    userId: string
  ): Promise<GetManagerOrganisationState[] | null> => {
    try {
      const response = await fetch(
        `${webAddressPrefix}/users/user-org?userId=${userId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        console.log("Organisation serv. manager by id", userId);
        const getManagedOrgData: GetManagedUserOrganisationPayload[] =
          await response.json();
        console.log(
          "Organisation serv. manager by id response json",
          response.json
        );
        console.log("Organisation serv. manager by id 1", getManagedOrgData);
        if (Array.isArray(getManagedOrgData) && getManagedOrgData.length > 0) {
          const orgList: GetManagerOrganisationState[] = getManagedOrgData.map(
            (orgData) => ({
              id: orgData.id || "",
              organization: orgData.organization_name || "",
              type: orgData.type_name || "",
              city: orgData.city || "",
              country: orgData.country || "",
              organizationActive: orgData.organization_active || "",
              selected: orgData.selected === true,
            })
          );

          dispatch(loadGetManagerUsersOrganisationActionCreator(orgList));

          return orgList;
        } else {
          console.log("No data found or unexpected response format.");
          return null;
        }
      } else {
        console.log("Failed to fetch user by ID.", response.status);
        // Handle other error cases here
        return null;
      }
    } catch (error) {
      console.log("Error while fetching user by ID:", error);
      // Handle error cases here
      return null;
    }
  };

  return {
    getManagedUserOrganisation,
    saveTokenToStorage,
  };
};
