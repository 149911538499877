import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import updateLanguageService from "../../../services/updateLanguageService";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface UpdateLanguageState {
  isLoading: boolean;
  error: string | null;
}

const initialState: UpdateLanguageState = {
  isLoading: false,
  error: null,
};

const updateLanguageSlice = createSlice({
  name: "updateLanguage",
  initialState,
  reducers: {
    updateLanguageStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    updateLanguageSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    updateLanguageFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  updateLanguageStart,
  updateLanguageSuccess,
  updateLanguageFailure,
} = updateLanguageSlice.actions;

// Thunk to update personal information
export const updateLanguage =
  (
    languageName: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(updateLanguageStart());

      const token = Cookies.get("token");

      // If the token is undefined, handle the error
      if (!token) {
        throw new Error("Token not found.");
      }

      // Call the updateLanguageService to update the personal information
      await updateLanguageService.updateLanguage(token, languageName);

      // You can dispatch additional actions or update your personal information state if needed

      dispatch(updateLanguageSuccess());
      return "Language updated successfully!";
    } catch (error: any) {
      dispatch(updateLanguageFailure(error.message as string));

      throw error;
    }
  };

export default updateLanguageSlice.reducer;
