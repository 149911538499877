import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setActualFilterWithAreaActionCreator, setFiltersActionCreator } from "../../../redux/slices/actualFilterSlice/actualFilterSlice";
import {
  filterAlarmDevicesActionCreator,
  filterDisconnectedDevicesActionCreator,
  filteredAllDevicesActionCreator,
  filterOffDevicesActionCreator,
  filterOnDevicesActionCreator,
} from "../../../redux/slices/devices/filteredDevicesSlice";
import { allowFlyModeActionCreator, setIsStaticViewActionCreator, setIsOpenedDetailActionCreator } from "../../../redux/slices/uiSlice/uiSlice";
import { DevicesFilter } from "../../../types/interfaces";
import { FilterElementStyled } from "./FilterElementStyled";
import ON from "../../../assets/new_icons/ON.svg";
import OFF from "../../../assets/new_icons/OFF.svg";

interface FilterElementProps {
  icon: string;
  title: string;
  type: "ON" | "OFF" | "Disconnected" | "Alarmed" | "All" | "selectArea";
  newDrawSetState?: any;
}

export const FilterElement = ({
  icon,
  title,
  type,
  newDrawSetState,
}: FilterElementProps) => {
  const [isActive, setIsActive] = useState(false);
  const [draw, setDraw] = useState(false);
  const detailPopUp = useAppSelector((state) => state.ui.isOpenedDetail);
  const dispatch = useAppDispatch();
  const filter: DevicesFilter = {
    area: type === "selectArea",
    all: type === "All",
    on: type === "ON",
    off: type === "OFF",
    disconnected: type === "Disconnected",
    alarmas: type === "Alarmed",
  };

  const devices = useAppSelector((state) => state.devices);
  const filteredNodes = useAppSelector((state) => state.filteredDevices.nodes);
  const actualFilter = useAppSelector((state) => state.actualFilter);

  // así, se mantiene area seleccionado + un type más, pero area no queda como true en redux
  /*const selected =
    (type === "selectArea" ) ||
    (type === "All" && actualFilter.all) ||
    (type === "ON" && actualFilter.on) ||
    (type === "OFF" && actualFilter.off) ||
    (type === "Disconnected" && actualFilter.disconnected) ||
    (type === "Alarmed" && actualFilter.alarmas);*/

  const selected =
    (type === "selectArea" && actualFilter.area) ||
    (type === "All" && actualFilter.all) ||
    (type === "ON" && actualFilter.on) ||
    (type === "OFF" && actualFilter.off) ||
    (type === "Disconnected" && actualFilter.disconnected) ||
    (type === "Alarmed" && actualFilter.alarmas);

  const handleClick = () => {
    const action =
      type === "All"
        ? filteredAllDevicesActionCreator(devices)
        : type === "ON"
        ? filterOnDevicesActionCreator(devices)
        : type === "OFF"
        ? filterOffDevicesActionCreator(devices)
        : type === "Disconnected"
        ? filterDisconnectedDevicesActionCreator(devices)
        : type === "Alarmed"
        ? filterAlarmDevicesActionCreator(devices)
        : type === "selectArea"
        ? newDrawSetState(!draw)
        : null;

    if (detailPopUp) {
      dispatch(setIsOpenedDetailActionCreator(false));
    }
    if (action) dispatch(action);

    if (filter.area === true) {
      dispatch(setFiltersActionCreator(filter));
    } else {
      dispatch(setActualFilterWithAreaActionCreator(filter));
    }

    setIsActive(!isActive);
    dispatch(setIsStaticViewActionCreator(false));
    dispatch(allowFlyModeActionCreator(true));
  };

  return (
    <FilterElementStyled
      onClick={filteredNodes.length > 0 ? handleClick : () => {}}
      //es esto
      title={title}
    >
      <div className={selected ? "active" : ""}>
        <img src={icon} style={{ padding: "1px", width: "18px", height: "18px", marginTop: icon === ON || icon === OFF ? "-2px": "" }} />
        {selected && <h1>{title}</h1>}
      </div>
    </FilterElementStyled>
  );
};
