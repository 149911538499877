import axios from "axios";

export interface ManagerUserPersonalInformation {
  first_name: string;
  last_name: string;
  phone_number: string;
  language_name: string;
  role_name: string;
  user_active: "Y" | "N";
}

class UpdateManagerUserPersonalInformationService {
  private webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;

  public async updateManagerUserPersonalInformation(
    token: string,
    userId: string,
    updatedUserData: ManagerUserPersonalInformation
  ): Promise<void> {
    try {
      await axios.put(
        `${this.webAddressPrefix}/users/user-pi?userId=${userId}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            credentials: "include",
          },
        }
      );
    } catch (error) {
      throw new Error("There was an error. Please try again later.");
    }
  }
}

const updateManagerUserPersonalInformationService =
  new UpdateManagerUserPersonalInformationService();
export default updateManagerUserPersonalInformationService;
