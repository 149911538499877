import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  changeStrategyChartActionCreator,
  changeSunsetActionCreator,
  turnSunsetTrueActionCreator,
  changeSunriseActionCreator,
  turnSunriseTrueActionCreator,
  sumCountActionCreator,
} from "../../../redux/slices/strategiesSlice/strategiesSlice";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import dragData from "chartjs-plugin-dragdata";
import { getHoursAndValues, calculateEnergySaving } from "../StrategiesLogic";
import { languages } from "../../../utils/languages/languages";
import { hourRegex, minuteRegex, numericRegex } from "../../../utils/regex";
import { Input } from "../../../components/Input/InputStyled";
import { Text } from "../../../styles/shared-styles/text-sizes";
import moonStar from "../../../assets/moon_star.svg";
import sun from "../../../assets/sun.svg";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  dragData,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface EditChartProps {
  item: any;
}

function EditChart({ item }: EditChartProps) {
  const dispatch = useAppDispatch();
  const count = useAppSelector((state) => state.strategies.count);
  const sunset = useAppSelector((state) => state.strategies.sunset);
  const sunrise = useAppSelector((state) => state.strategies.sunrise);
  const strategy = useAppSelector((state) => state.strategies);
  const value = useAppSelector((state) => state.strategies.saving);
  let newLabels = [...strategy.labels];
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [maxLength, setMaxLength] = useState(2);
  const [reset, setReset] = useState("");
  const [errorMessageHour, setErrorMessageHour] = useState(false);
  const [errorMessageDimmingPoint, setErrorDimmingPoint] = useState(false);
  const [errorMessageNoHour, setErrorMessageNoHour] = useState(false);
  const { t } = useTranslation();

  console.log("el item", item);
  const times = [
    item.time1,
    item.time2,
    item.time3,
    item.time4,
    item.time5,
    item.time6,
    item.time7,
  ];
  let labels: any = [];

  for (let i = 0; i < times.length; i++) {
    if (times[i] === "254:254") {
      if (times[i - 1] === "99:99") {
        labels[labels.length - 1] = t(txt.sunrise);
        //dispatch(sunriseTrueActionCreator());
        break;
      }
      break;
    }

    if (times[i] === "99:99") {
      if (i === times.length - 1) {
        labels.push(t(txt.sunrise));
      } else {
        labels.push(t(txt.sunset));
      }
    } else {
      labels.push(times[i]);
    }
  }

  const dimmings = [
    item.dim1,
    item.dim2,
    item.dim3,
    item.dim4,
    item.dim5,
    item.dim6,
    item.dim7,
  ];
  let newData = dimmings.slice(0, labels.length);

  const [itemData, setItemData] = useState({
    labels: strategy?.labels || labels,
    datasets: strategy?.data || [
      {
        label: "Standard",
        data: newData,
        borderColor: item.color,
        backgroundColor: item.color + "80",
        fill: true,
        stepped: true,
        pointRadius: 5,
        pointBackgroundColor: "white",
        showLine: true,
        spanGaps: true,
      },
    ],
  });

  const optionsDragable = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
      },
      dragData: {
        round: 0,
        onDragEnd: (value: any, datasetIndex: any) => {
          setItemData((data) => {
            data.datasets[0].data[value] = datasetIndex;

            return {
              ...data,
              datasets: data.datasets.map((dataset: any) => ({
                ...dataset,
              })),
            };
          });
        },
      },
    },
    scales: {
      y: {
        display: true,
        max: 100,
        min: 0,
        ticks: {
          stepSize: 25,
        },
      },
      x: {
        display: true,
        ticks: {
          stepSize: 25,
          maxTicks: 3,
        },
      },
    },
    dragData: true,
  };

  const handleData = (value: string, action: string) => {
    let newLabel = [...itemData.labels];
    let newData = [...itemData.datasets];

    if (sunset && sunrise) {
      newLabel[0] = "17:00";
      newLabel[newLabel.length - 1] = "08:00";
    } else if (sunset) {
      newLabel[0] = "17:00";
    } else if (sunrise) {
      newLabel[newLabel.length - 1] = "08:00";
    }

    const [hours, minutes] = value.split(":");
    let numericValue = parseInt(hours) + (minutes ? parseInt(minutes) / 60 : 0);

    if (numericValue < parseInt(newLabel[0])) {
      numericValue += 24;
    }

    let index = 0;
    while (index < newLabel.length) {
      const [currentHours, currentMinutes] = newLabel[index].split(":");
      let currentNumericValue =
        parseInt(currentHours) +
        (currentMinutes ? parseInt(currentMinutes) / 60 : 0);

      if (currentNumericValue < parseInt(newLabel[0])) {
        currentNumericValue += 24;
      }

      if (currentNumericValue > numericValue) {
        break;
      }

      index++;
    }

    if (!minutes) {
      value = value + ":00";
    }

    if (action === "addData") {
      if (value === "17") {
        newData[0].data[index - 1] = 100;
      } else if (value === "08") {
        newData[0].data[index - 1] = 0;
      }

      if (!newLabel.includes(value)) {
        newData[0].data.splice(index, 0, 50);
        newLabel = newLabel.slice();
        newLabel.splice(index, 0, value.toString());
        if (count !== undefined) {
          dispatch(sumCountActionCreator({ count: newLabel.length }));
        }
      }
    }

    if (action === "deleteData") {
      if (value !== "17" && value !== "08") {
        if (
          newLabel.includes(value) &&
          newData[0].data[index - 1] !== undefined
        ) {
          newData[0].data.splice(index - 1, 1);
          let tempNewLabel = newLabel;
          newLabel = tempNewLabel.filter((label: any) => label !== value);
          if (count !== undefined) {
            dispatch(sumCountActionCreator({ count: newLabel.length }));
          }
        } else if (
          newLabel.includes(value) &&
          newData[0].data[index - 1] === undefined
        ) {
          setErrorDimmingPoint(true);
        } else if (!newLabel.includes(value)) {
          setErrorMessageNoHour(true);
        }
      }
    }

    if (sunset && sunrise) {
      newLabel[0] = "Sunset";
      newLabel[newLabel.length - 1] = "Sunrise";
    } else if (sunset) {
      newLabel[0] = "Sunset";
    } else if (sunrise) {
      newLabel[newLabel.length - 1] = "Sunrise";
    }

    setItemData({
      labels: newLabel,
      datasets: newData.map((dataset: any) => ({
        ...dataset,
      })),
    });
  };

  const handleHourInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (numericRegex.test(e.target.value)) {
      if (e.target.value.length === 2 && e.target.value.length === maxLength) {
        if (hourRegex.test(e.target.value)) {
          setHour(e.target.value);
        } else {
          e.target.value = reset;
        }
      } else if (
        e.target.value.length <= 2 &&
        e.target.value.length <= maxLength
      ) {
        setHour(e.target.value);
      } else {
        e.target.value = reset;
      }
    } else {
      e.target.value = reset;
    }

    if (e.target.value === "") {
      setHour("");
    }
  };

  const handleMinuteInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (numericRegex.test(e.target.value)) {
      if (e.target.value.length === 2 && e.target.value.length === maxLength) {
        if (minuteRegex.test(e.target.value)) {
          setMinute(e.target.value);
        } else {
          e.target.value = reset;
        }
      } else if (
        e.target.value.length <= 2 &&
        e.target.value.length <= maxLength
      ) {
        setMinute(e.target.value);
      } else {
        e.target.value = reset;
      }
    } else {
      e.target.value = reset;
    }

    if (e.target.value === "") {
      setMinute("");
    }
  };

  const handleClick = (hour: string, minute: string) => {
    if (hour.length === 2 && (minute.length === 0 || minute.length === 2)) {
      setErrorMessageHour(false);
      setErrorDimmingPoint(false);
      setErrorMessageNoHour(false);

      if (count !== 7) {
        if (minute.length === 0) {
          if (hour === "17" || hour === "08") {
            setErrorMessageHour(true);
          } else {
            handleData(hour, "addData");
          }
        } else if (minute.length === 2) {
          let value = [hour, minute].join(":");
          handleData(value, "addData");
        }
      }
    } else {
      setErrorMessageHour(true);
    }
  };

  const handleDelete = (hour: string, minute: string) => {
    if (hour.length === 2 && (minute.length === 0 || minute.length === 2)) {
      setErrorMessageHour(false);
      setErrorDimmingPoint(false);
      setErrorMessageNoHour(false);

      if (minute.length === 0) {
        if (hour === "17" || hour === "08") {
          setErrorMessageHour(true);
        } else {
          handleData(hour, "deleteData");
        }
      } else if (minute.length === 2) {
        let value = [hour, minute].join(":");
        handleData(value, "deleteData");
      }
    } else {
      setErrorMessageHour(true);
    }
  };

  const handleSunset = () => {
    if (sunset) {
      dispatch(changeSunsetActionCreator());
      newLabels[0] = "17:00";

      setItemData({
        labels: newLabels,
        datasets: itemData.datasets,
      });
    } else if (!sunset) {
      dispatch(changeSunsetActionCreator());
      newLabels[0] = t(txt.sunset);

      setItemData({
        labels: newLabels,
        datasets: itemData.datasets,
      });
    }
  };

  const handleSunrise = () => {
    if (sunrise) {
      dispatch(changeSunriseActionCreator());
      newLabels[newLabels.length - 1] = "08:00";

      setItemData({
        labels: newLabels,
        datasets: itemData.datasets,
      });
    } else if (!sunrise) {
      dispatch(changeSunriseActionCreator());
      newLabels[newLabels.length - 1] = t(txt.sunrise);

      setItemData({
        labels: newLabels,
        datasets: itemData.datasets,
      });
    }
  };

  useEffect(() => {
    if (itemData.labels[0] === t(txt.sunset)) {
      dispatch(turnSunsetTrueActionCreator());
    }

    if (itemData.labels[itemData.labels.length - 1] === t(txt.sunrise)) {
      dispatch(turnSunriseTrueActionCreator());
    }

    let hoursWithValues = getHoursAndValues(
      itemData.labels,
      itemData.datasets[0].data
    );
    calculateEnergySaving(hoursWithValues);

    const newArray = itemData.datasets[0].data.map((element: any) =>
      element === undefined ? "" : element
    );
    dispatch(
      changeStrategyChartActionCreator({
        labels: itemData.labels,
        data: newArray,
      })
    );
  }, [itemData, sunset, sunrise]);

  return (
    <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
      <div className="row" style={{ marginBottom: "6px", textAlign: "left" }}>
        <div className="col-6" style={{ textAlign: "left" }}>
          <img src={moonStar} height={14} />
        </div>
        <div className="col-6" style={{ textAlign: "right" }}>
          <img src={sun} height={14} />
        </div>
      </div>
      <Line options={optionsDragable} data={itemData} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="col-6"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <label style={{ display: "flex", gap: "6px" }}>
              <input
                type="checkbox"
                checked={sunset}
                onChange={() => {
                  handleSunset();
                }}
              />
              <Text marginBottom="2px" style={{ fontSize: "11px" }}>
                {t(txt.setAutomaticSunset)}
              </Text>
            </label>
            <label style={{ display: "flex", gap: "6px" }}>
              <input
                type="checkbox"
                checked={sunrise}
                onChange={() => {
                  handleSunrise();
                }}
              />
              <Text marginBottom="2px" style={{ fontSize: "11px" }}>
                {t(txt.setAutomaticSunrise)}
              </Text>
            </label>
          </div>
          <div
            className="col-6"
            style={{
              display: "flex",
              marginTop: "12px",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "15px",
            }}
          >
            <Input
              style={{ border: "1px solid #ced4da", width: "80px" }}
              placeholder={t(txt.hour)}
              onChange={(e) => {
                handleHourInputChange(e);
              }}
            />
            <div style={{ fontWeight: 700 }}>:</div>
            <Input
              style={{ border: "1px solid #ced4da", width: "80px" }}
              placeholder={t(txt.minute)}
              onChange={(e) => {
                handleMinuteInputChange(e);
              }}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <button
                style={{ padding: "6px", width: "103px" }}
                onClick={() => {
                  hour !== "" ? handleClick(hour, minute) : <></>;
                }}
              >
                {t(txt.addPoint)}
              </button>
              <button
                style={{ padding: "6px" }}
                onClick={() => {
                  hour !== "" ? handleDelete(hour, minute) : <></>;
                }}
              >
                {t(txt.deletePoint)}
              </button>
            </div>
          </div>
        </div>
        <div style={{ alignSelf: "center" }}>
          <Text color={item.color} marginBottom="0px" fontWeight="500">
            {value || item.saving}
            {t(txt.percentageEnergySaved)}
          </Text>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {count === 7 ? (
          <Text marginBottom="0px">{t(txt.errorStrategyLimit)}</Text>
        ) : (
          <></>
        )}
        {errorMessageHour ? (
          <Text marginBottom="0px">{t(txt.errorDimmingHour)}</Text>
        ) : (
          <></>
        )}
        {errorMessageDimmingPoint ? (
          <Text marginBottom="0px">{t(txt.errorDimmingPoint)}</Text>
        ) : (
          <></>
        )}
        {errorMessageNoHour ? (
          <Text marginBottom="0px">{t(txt.errorDimmingNoHour)}</Text>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default EditChart;
