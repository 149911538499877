import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetManagedUserPayload } from "../../../types/interfaces";

const initialGetManagerUsersState: GetManagedUserPayload[] = [];

const getManagedUserByIdSlice = createSlice({
  name: "managerUsers",
  initialState: initialGetManagerUsersState,
  reducers: {
    loadGetManagerUsersByIdActionCreator: (
      state,
      action: PayloadAction<GetManagedUserPayload[]>
    ) => {
      return action.payload;
    },
  },
});

export const { loadGetManagerUsersByIdActionCreator } =
  getManagedUserByIdSlice.actions;

export default getManagedUserByIdSlice.reducer;
