import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setFiltersActionCreator } from "../../redux/slices/actualFilterSlice/actualFilterSlice";
import { turnAllFalseActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import {
  setFirstRenderMapToTrueActionCreator,
  setIsOpenedDetailActionCreator,
  setIsOpenedGroupActionCreator,
  setIsFullViewActionCreator,
} from "../../redux/slices/uiSlice/uiSlice";
import authService from "../../services/authService";
import { useLightingService } from "../../services/lightingService";
import InputForm from "../../components/Input/Input";
import { languages } from "../../utils/languages/languages";
import {
  Container,
  LoginOverlay,
  LoginForm,
  LoginOverlayWhite,
  ButtonLogin,
  ImageLogo,
} from "./LoginStyled";
import { TextBox, TextSpan } from "../../styles/shared-styles/text-sizes";
import { ButtonLoading } from "../../styles/shared-styles/buttons-sizes";
import envelope_icon from "../../assets/mail.svg";
import locker_icon from "../../assets/lock.svg";
import logo from "../../assets/smartec-logo.svg";
import { useTranslation } from "react-i18next";

export const Login = () => {
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const navigate = useNavigate();
  const { getAllNodes }: any = useLightingService();

  const checkEnter = (e: any) => {
    if (e.charCode === 13) {
      handleLogin();
    }
  };

  const backToInitialState = () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(turnAllFalseActionCreator());
    dispatch(
      setFiltersActionCreator({
        all: true,
        area: false,
        on: false,
        off: false,
        disconnected: false,
        alarmas: false,
      })
    );
    dispatch(setIsFullViewActionCreator());
    dispatch(setFirstRenderMapToTrueActionCreator());
  };

  const handleLogin = async () => {
    setIsLoading(true);
    setInvalid(false);

    try {
      const res = await authService.smartecLogin(email, password);
      const token = res.result;
      const organizations = res.organizationsArray;
      // Updated code:
      // Check if the token is not null before proceeding.
      if (token) {
        // Login successful
        localStorage.setItem("token", token);

        const nodesFound = await getAllNodes(
          organizations[0].id,
          organizations[0].services[0].service_id,
          organizations,
          organizations[0].services
        );

        if (nodesFound === true) {
          backToInitialState();
          navigate("/dashboard");
        } else {
          alert("Error");
        }
      } else {
        // Invalid credentials or missing token
        setInvalid(true);
      }
    } catch (error) {
      console.error("Login error:", error);
      // Handle any errors that occurred during login
      setInvalid(true);
    } finally {
      setIsLoading(false);
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <Container className="login_container">
        <div className="row">
          <div className="col-6">
            <LoginOverlay className="overlay" />
          </div>
          <div className="col-6">
            <Container>
              <LoginOverlayWhite className="overlay" />
              <LoginForm padding="large" className="card" borderRadius="large">
                <div className="row">
                  <div className="col-12 ">
                    <ImageLogo src={logo} />
                  </div>
                </div>
                <div className="col-12 text-left">
                  <TextBox
                    textType="title"
                    fontWeight="700"
                    marginBottom="32px"
                  >
                    {t(txt.logInSmartec)}
                  </TextBox>
                </div>
                <InputForm
                  preffix_icon={envelope_icon}
                  inputType="email"
                  placeholder={t(txt.email)}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyPress={(e) => checkEnter(e)}
                />
                <InputForm
                  preffix_icon={locker_icon}
                  suffix_icon={envelope_icon}
                  inputType="password"
                  placeholder={t(txt.password)}
                  marginBottom="small"
                  onChange={(e: any) => setPassword(e.target.value)}
                  onKeyPress={(e: any) => checkEnter(e)}
                />
                <TextSpan
                  marginBottom="medium"
                  style={{ textAlign: "right", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  {txt.forgotPasswordQ}
                </TextSpan>
                {invalid ? (
                  <TextSpan
                    style={{ marginTop: "-20px", width: "200px" }}
                    color="red"
                  >
                    {t(txt.errorLogin)}
                  </TextSpan>
                ) : null}
                <div className="col-12 text-left">
                  {isLoading ? (
                    <ButtonLoading
                      style={{ marginTop: "16px" }}
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {t(txt.loading)}
                    </ButtonLoading>
                  ) : (
                    <ButtonLogin
                      buttonType="primary"
                      onClick={() => handleLogin()}
                    >
                      {t(txt.logIn)}
                    </ButtonLogin>
                  )}
                </div>
              </LoginForm>
            </Container>
          </div>
        </div>
      </Container>
    </>
  );
};
