import {
  DevicesInfo,
  Gateway,
  Lamp,
  NodeDevice,
  Project,
} from "../types/interfaces";

export const calculatePositionRelativeToLine = (
  mbLin: any,
  x: number,
  y: number
) => {
  //We return true if there is in one side of the line. false if it's in the other side
  if (mbLin.m * x + mbLin.b > y) return true;
  return false;
};

export const calculateM_B = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
) => {
  let mLine = 0;
  let bLine = 0;
  mLine = (y2 - y1) / (x2 - x1);
  bLine = y1 - mLine * x1;
  return { m: mLine, b: bLine };
};

export const isMarkerInsidePolygon = (myNode: any, myPolygonSelection: any) => {
  const polyPoints = myPolygonSelection.getLatLngs()[0];
  let lngPositiveCross = 0;
  let lngNegativeCross = 0;
  let latPositiveCross = 0;
  let latNegativeCross = 0;

  if (myNode.latitude !== undefined && myNode.latitude !== "")
    //If my node doesn't have coordinates, we don't go inside
    for (let i = 1; i < polyPoints.length; i++) {
      //We explore all the polyPoints

      //First of all, the latitude
      if (
        (polyPoints[i - 1].lat - myNode.latitude) *
          (polyPoints[i].lat - myNode.latitude) <
          0 ||
        polyPoints[i].lat === myNode.latitude
      ) {
        //If we detect 2 consecutive points that cross the latitude of the markers...
        const mbLine = calculateM_B(
          polyPoints[i - 1].lat,
          polyPoints[i - 1].lng,
          polyPoints[i].lat,
          polyPoints[i].lng
        );
        if (
          calculatePositionRelativeToLine(
            mbLine,
            myNode.latitude,
            myNode.longitude
          ) === true
        )
          lngPositiveCross++;
        else lngNegativeCross++;
      }
      //Now is the turn for longitude
      if (
        (polyPoints[i - 1].lng - myNode.longitude) *
          (polyPoints[i].lng - myNode.longitude) <
          0 ||
        polyPoints[i].lng === myNode.longitude
      ) {
        //If we detect 2 consecutive points that cross the latitude of the markers...
        const mbLine = calculateM_B(
          polyPoints[i - 1].lng,
          polyPoints[i - 1].lat,
          polyPoints[i].lng,
          polyPoints[i].lat
        );
        if (
          calculatePositionRelativeToLine(
            mbLine,
            myNode.longitude,
            myNode.latitude
          ) === true
        )
          latPositiveCross++;
        else latNegativeCross++;
      }
    }

  //Now we need to study the last point and the first one to close the loop

  if (
    (polyPoints[polyPoints.length - 1].lat - myNode.latitude) *
      (polyPoints[0].lat - myNode.latitude) <
      0 ||
    polyPoints[0].lat == myNode.latitude
  ) {
    const mbLine = calculateM_B(
      polyPoints[polyPoints.length - 1].lat,
      polyPoints[polyPoints.length - 1].lng,
      polyPoints[0].lat,
      polyPoints[0].lng
    );
    if (
      calculatePositionRelativeToLine(
        mbLine,
        myNode.latitude,
        myNode.longitude
      ) == true
    )
      lngPositiveCross++;
    else lngNegativeCross++;
  }
  //Now is the turn for longitude
  if (
    (polyPoints[polyPoints.length - 1].lng - myNode.longitude) *
      (polyPoints[0].lng - myNode.longitude) <
      0 ||
    polyPoints[0].lng == myNode.longitude
  ) {
    //If we detect 2 consecutive points that cross the latitude of the markers...
    const mbLine = calculateM_B(
      polyPoints[polyPoints.length - 1].lng,
      polyPoints[polyPoints.length - 1].lat,
      polyPoints[0].lng,
      polyPoints[0].lat
    );
    if (
      calculatePositionRelativeToLine(
        mbLine,
        myNode.longitude,
        myNode.latitude
      ) == true
    )
      latPositiveCross++;
    else latNegativeCross++;
  }

  if (lngNegativeCross * lngPositiveCross * latNegativeCross * latPositiveCross)
    return true;
  return false;
};

export const calculateDevicesInfo = (
  nodes: Lamp[],
  gateways: Gateway[]
): DevicesInfo => {
  const devicesInfo: DevicesInfo = {
    totalNodes: nodes.length.toString(),
    connectedNodes: nodes.filter((node) => node.online).length.toString(),
    onNodes: nodes.filter((node) => node.online && node.on).length.toString(),
    alarmedNodes: nodes.filter((node) => node.alarm_status).length.toString(),
    totalGateways: gateways.length.toString(),
    connectedGateways: gateways
      .filter((gateway) => gateway.online)
      .length.toString(),
    alarmedGateways: gateways
      .filter((gateway) => gateway.alarm_status)
      .length.toString(),
  };

  return devicesInfo;
};

export const getAllProjects = (nodes: Lamp[]): Array<Project> => {
  return nodes
    .filter((node) => node.latitude)
    .map((node) => node.prjName)
    .filter((project, index, projects) => projects.indexOf(project) === index)
    .sort()
    .map((project) => ({
      projectName: project,
      isFavourite: false,
      devices: nodes.filter((node) => node.prjName === project).length,
    }));
};

export const getAllOrganizations = (nodes: Lamp[]) => {
  return nodes
    .map((node) => node.orgName)
    .filter((project, index, projects) => projects.indexOf(project) === index)
    .sort();
};

export const getOneNodePerProject = (nodes: NodeDevice[]) => {
  const filteredByProject: NodeDevice[] = [];

  nodes.forEach((node) => {
    if (
      !getAllProjects(filteredByProject)
        .map((project) => project.projectName)
        .includes(node.prjName)
    ) {
      filteredByProject.push(node);
    }
  });
  return filteredByProject;
};

export const calculateAveragePosition = (
  nodes: Array<Lamp>
): { latitude: number; longitude: number } => {
  const positionedNodes = nodes.filter((node) => node.latitude);
  let totalLatitude: number = positionedNodes
    .map((nodes) => Number.parseFloat(nodes.latitude))
    .reduce((totalLatitude, latitude) => totalLatitude + latitude);
  let totalLongitude: number = positionedNodes
    .map((nodes) => Number.parseFloat(nodes.longitude))
    .reduce((totalLongitude, longitude) => totalLongitude + longitude);

  return {
    latitude: totalLatitude / positionedNodes.length,
    longitude: totalLongitude / positionedNodes.length,
  };
};

export interface Position {
  latitude: number;
  longitude: number;
  id: string;
}

export const getAllpositions = (nodes: Array<Lamp>): Array<Position> => {
  const positions: Array<Position> = nodes
    .filter((node) => node.latitude)
    .map((node) => ({
      id: node.node_id,
      latitude: Number.parseFloat(node.latitude),
      longitude: Number.parseFloat(node.longitude),
    }))
    .sort((a, b) => b.latitude - a.latitude);
  return positions;
};

export const getAveragePosition = (positions: Array<Position>) => {
  return {
    averageLatitude: positions
      .map((position) => position.latitude)
      .reduce((average, latitude) => (average += latitude)),
    averageLongitude: positions
      .map((position) => position.longitude)
      .reduce((average, longitude) => (average += longitude)),
  };
};
