import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createFeeders,
  deleteFeeder,
  getFeederConsumption,
  getFeeders,
  getSingleFeeder,
  getUnassignedNodes,
  updateFeeder,
} from "../../services/feeder";
import { formatSwitchBoardFeederGraphData } from "../../utils/switchBoard.util";
import { SingleFeederDetails } from "../../types/interfaces";
import { useAppSelector } from "../../redux/hooks";

const useFeeder = (selectedTab: string, invalidate: boolean = true) => {
  const { switchboardId }: any = useParams();
  const [feedersNav, setFeedersNav] = useState<any>();
  const [feederConsumption, setFeederConsumption] = useState<any>();
  const [allNodes, setAllNodes] = useState<any>();
  const [singleFeederDetails, setSingleFeederDetails] =
    useState<SingleFeederDetails>();
  const [unAssignedNode, setUnassignedNode] = useState<any>();
  const [isLoadingFeederList, setIsLoadingFeederList] =
    useState<boolean>(false);
  const [isLoadingConsumptionFeeder, setIsLoadingConsumptionFeeder] =
    useState<boolean>(false);
  const [isLoadingUnAssignedFeeder, setIsLoadingUnAssignedFeeder] =
    useState<boolean>(false);
  const [isLoadingSingleFeeder, setIsLoadingSingleFeeder] =
    useState<boolean>(false);
  const [isFeederLoading, setIsFeederLoading] = useState<boolean>(false);
  const getFeederList = async () => {
    try {
      setIsLoadingFeederList(true);
      const response = await getFeeders({ switchboardId });
      setFeedersNav(response.data);
      setIsLoadingFeederList(false);
    } catch (error) {
      setIsLoadingFeederList(false);
      throw error;
    }
  };
  const getConsumptionFeeder = async () => {
    try {
      setIsLoadingConsumptionFeeder(true);
      const response = await getFeederConsumption(switchboardId, selectedTab);
      const lineGraph = formatSwitchBoardFeederGraphData(response.feeders);
      setFeederConsumption(lineGraph);
      setIsLoadingConsumptionFeeder(false);
    } catch (error) {
      setIsLoadingConsumptionFeeder(false);
      throw error;
    }
  };
  const flatArray = () => {
    const feederMap = feedersNav?.map((feed: any) => {
      return feed.nodes.map((node: any) => {
        return { ...node, color: feed.color };
      });
    });
    const flatArrayFeederMap = [].concat.apply([], feederMap);
    setAllNodes(flatArrayFeederMap);
  };
  const getSingleFeederDetails = async () => {
    try {
      setIsLoadingSingleFeeder(true);
      const response = await getSingleFeeder(selectedTab);
      setSingleFeederDetails(response);
      setIsLoadingSingleFeeder(false);
    } catch (error) {
      setIsLoadingSingleFeeder(false);
      throw error;
    }
  };
  const createFeederCall = async (data: any) => {
    try {
      const response = await createFeeders({
        ...data,
        color: "#889656",
        switchboard_id: switchboardId,
        is_active: true,
      });
      return [response, null];
    } catch (error: any) {
      //   toast.error(error.response.data.error);
      // setOpen(false);
      return [null, error];
    }
  };

  const editFeederCall = async (data: any) => {
    try {
      const response = await updateFeeder({
        feeder_id: selectedTab,
        payload: { feeder_id: data.id, feeder_name: data.name },
      });
      return [response, null];
    } catch (error) {
      return [null, error];
    }
  };
  const deleteFeederCall = async () => {
    try {
      const response = await deleteFeeder(selectedTab);
      return [response, null];
    } catch (error) {
      return [null, error];
    }
  };
  const getUnassignedNodesCall = async () => {
    try {
      setIsLoadingUnAssignedFeeder(true);
      const response = await getUnassignedNodes(switchboardId);
      setUnassignedNode(response);
      setIsLoadingUnAssignedFeeder(false);
    } catch (error) {
      setUnassignedNode([]);
      setIsLoadingUnAssignedFeeder(false);
      throw error;
    }
  };
  useEffect(() => {
    flatArray();
  }, [feedersNav]);
  useEffect(() => {
    if (
      isLoadingConsumptionFeeder &&
      isLoadingFeederList &&
      isLoadingSingleFeeder &&
      isLoadingUnAssignedFeeder
    ) {
      setIsFeederLoading(true);
    } else {
      setIsFeederLoading(false);
    }
  }, [
    isLoadingConsumptionFeeder,
    isLoadingFeederList,
    isLoadingSingleFeeder,
    isLoadingUnAssignedFeeder,
  ]);

  useEffect(() => {
    getFeederList();
    getConsumptionFeeder();
    flatArray();
    if (selectedTab !== "General") {
      getSingleFeederDetails();
    }
    getUnassignedNodesCall();
  }, [selectedTab, invalidate]);

  return {
    feedersNav,
    feederConsumption,
    allNodes,
    singleFeederDetails,
    createFeederCall,
    editFeederCall,
    deleteFeederCall,
    unAssignedNode,
    isFeederLoading,
  };
};

export default useFeeder;
