import React, { useState } from "react";
import { SwitchBoardProps } from "../../../types/interfaces";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { languages } from "../../../utils/languages/languages";
import { TextSpan } from "../../../styles/shared-styles/text-sizes";
import ActionButton from "../Actionbutton/Actionbutton";
import { IconStyled } from "../SwitchBoardPageStyled";
import gateway from "../../../assets/gateway.svg";
import lightOFF from "../../../assets/icons/icon-bulb-off.svg";
import lampLocation from "../../../assets/lampLocation.jpg";
import lightON from "../../../assets/light-bulb-shine.svg";
import onlineLamp from "../../../assets/online.svg";
import ID from "../../../assets/ID.svg";
import outlined from "../../../assets/outlined.svg";
import wifiOff from "../../../assets/wifiOff.svg";
import { useDispatch } from "react-redux";
import { setSwitchBoardModalState } from "../../../redux/slices/uiSlice/uiSlice";
import useFeeder from "../../../hooks/useFeeders/useFeeder";
import { Modal } from "react-bootstrap";
import { Button } from "../../../styles/shared-styles/buttons-sizes";

const SwitchBoardDetail = ({
  switchData,
  selectedTab,
  setSelectedTab,
}: SwitchBoardProps) => {
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { deleteFeederCall } = useFeeder(selectedTab);
  const handleClose = () => {
    setOpen((prev) => !prev);
    setErrorMsg(undefined);
  };
  const [errorMsg, setErrorMsg] = useState(undefined);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm();
  const deleteFeeder = async () => {
    const response = await deleteFeederCall();
    if (response[0]) {
      setSelectedTab("General");
      handleClose();
    } else {
      setErrorMsg(response[1].response.data.error);
      throw response[1];
    }
  };
  let deleteDialog = (
    <Modal show={open} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Feeder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure you want to Delete the selected Feeder?</span>

        <div style={{ color: "red", fontSize: "11px" }}>
          <span>{errorMsg}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button buttonType="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button buttonType="primary" onClick={deleteFeeder}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
  const dropDownData =
    selectedTab === "General"
      ? [
          {
            label: "Create Feeder",
            function: () => dispatch(setSwitchBoardModalState(true)),
          },
        ]
      : [
          {
            label: "Edit Feeder",
            function: () => {
              dispatch(setSwitchBoardModalState(true));
            },
          },
          {
            label: "Delete Feeder",
            function: () => handleClose(),
            // function: () => deleteFeeder(),
          },
        ];
  return (
    <div className="row" style={{ marginBottom: "16px", alignItems: "center" }}>
      <div className="col-2 col-lg-1">
        <div
          className="profile-picture-container"
          style={{
            borderRadius: "50%",
            width: "96px",
            height: "96px",
            overflow: "hidden",
          }}
        >
          <img
            src={lampLocation}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="Profile Picture"
            className="img-fluid"
          />
        </div>
      </div>
      <div
        className="col-8 col-lg-10"
        style={{ paddingLeft: "10px", alignItems: "center" }}
      >
        <div style={{ marginBottom: "10px" }}>
          <TextSpan textType="title" fontWeight="700">
            {switchData?.switchboard_name}
          </TextSpan>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            marginBottom: "5px",
          }}
        >
          <div className="flex items-center" style={{ gap: "5px" }}>
            <IconStyled
              src={Number(switchData?.is_active) ? onlineLamp : wifiOff}
              height={16}
            />
            <TextSpan style={{ marginRight: "32px" }}>
              {Number(switchData?.is_active) ? txt.connected : txt.disconnected}
            </TextSpan>
          </div>
          <div className="flex items-center" style={{ gap: "5px" }}>
            <IconStyled
              // src={
              //   Number(switchData?.is_on) && Number(switchData?.is_active)
              //     ? lightON
              //     : lightOFF
              // }
              src={Number(switchData?.is_active) ? lightON : lightOFF}
              height={24}
            />
            <TextSpan style={{ marginRight: "32px" }}>
              {Number(switchData?.is_active) && Number(switchData?.is_active)
                ? txt.ON
                : txt.OFF}
            </TextSpan>
          </div>
          <div className="flex items-center" style={{ gap: "5px" }}>
            <IconStyled src={outlined} height={24} />
            <TextSpan style={{ marginRight: "32px" }}>
              {switchData?.switchboard_name}
            </TextSpan>
          </div>
          <div className="flex items-center" style={{ gap: "5px" }}>
            <IconStyled src={ID} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {switchData?.switchboard_id}
            </TextSpan>
          </div>
          <div className="flex items-center" style={{ gap: "5px" }}>
            <IconStyled src={gateway} height={18} />
            {/* <TextSpan>{switchData?.gateway_address}</TextSpan> */}
          </div>
        </div>
      </div>
      <div className="col-2 col-lg-1" style={{ textAlign: "right" }}>
        <ActionButton dropDownData={dropDownData} />
      </div>
      {deleteDialog}
    </div>
  );
};

export default SwitchBoardDetail;
