import { LatLng } from "leaflet";
import React, { useEffect, useState } from "react";
import { MiniMap } from "../../CustomMaps/CustomMap/MiniMap";
import RemoveIcon from "../../../assets/remove_circle_outline.svg";
import { useParams } from "react-router-dom";
import CustomModal from "../../../shared/Modal/CustomModal";
import { SwitchBoardMiniMap } from "../SwitchBoardMiniMap/SwitchBoardMiniMap";
import { addNodeToFeeder } from "../../../services/feeder";
import useFeeder from "../../../hooks/useFeeders/useFeeder";
import { Modal } from "react-bootstrap";

export interface SingleFeederData {
  feeder: Feeder;
  nodes: Node[];
}

export interface Feeder {
  id: number;
  feeder_id: string;
  feeder_name: string;
  color: string;
  switchboard_id: number;
  is_active: boolean;
}

export interface Node {
  id: number;
  node_id: string;
  node_name: string;
  latitude: string;
  longitude: string;
  organization_id: number;
  manufacturer_id: number;
  service_id: number;
  gateway_id: string;
  wireless_status: boolean;
  alarm_status: string;
  node_active: boolean;
}

interface IProps {
  handleOpen: () => void;
  open: boolean;
  feeders: any;
  selectedTab: string;
  feederData: any;
  switchBoardData: any;
  isSingleFeederDataLoading: boolean;
  setInvalidate: any;
}

const FeederNodesModal = ({
  handleOpen,
  open,
  selectedTab,
  feederData,
  feeders,
  switchBoardData,
  isSingleFeederDataLoading,
  setInvalidate,
}: IProps) => {
  const params = useParams();
  const [nodesList, setNodesList] = useState<any>([]);
  const [unassignedNodesList, setUnassignedNodesList] = useState<any>([]);
  const [otherNodes, setOtherNodes] = useState();
  const mapHalfSize = { height: "100%" };
  const { unAssignedNode } = useFeeder(selectedTab);
  //   const queryClient = useQueryClient();
  //   const { data: unAssignedSwitchBoardNodes } = useQuery(
  //     "getUnassignedNodes",
  //     async () => {
  //       const data = await getUnassignedNodes(params.switchBoardId as string);
  //       return data.data;
  //     }
  //   );
  const mapFeeders = () => {
    const feederMap = feeders?.map((feed: any) => {
      return {
        feeder_id: feed.id,
        nodes: feed.nodes.map((node: any) => {
          return {
            ...node,
            color: feed.color,
          };
        }),
      };
    });
    return feederMap;
  };
  const assignNodes = async () => {
    try {
      const response = await addNodeToFeeder({
        feeder_id: selectedTab as string,
        node_ids: uniqueArray.map((item: any) => item.node_id),
      });
      setInvalidate((prev: boolean) => !prev);
      handleOpen();
    } catch (error) {
      throw error;
    }
  };

  /// if nodelist ma tyo id cha bhane unassigned bata hataunu paryo
  const checkNodeIdForUnassigned = () => {
    const idToRemove = nodesList.map((item: any) => item.node_id);
    const newUnassigned = unassignedNodesList.filter(
      (unassigned: any) => !idToRemove.includes(unassigned.node_id)
    );
    setUnassignedNodesList(newUnassigned);
  };

  const removeNodes = (nodeToRemove: any) => {
    const filteredData = nodesList.filter(
      (item: any) => item.node_id !== nodeToRemove.node_id
    );
    setNodesList(filteredData);
    setUnassignedNodesList([...unassignedNodesList, nodeToRemove]);
  };

  const uniqueArray = Array.from(
    new Set(nodesList.map((item: any) => item.node_id))
  ).map((id) => {
    return nodesList.find((item: any) => item.node_id === id);
  });

  const addUnassignedToNodeList = (node: any) => {
    setNodesList((prev: any[]) => [node, ...prev]);
  };

  let content;
  if (isSingleFeederDataLoading && nodesList === undefined) {
    content = <div className=" ">Loading</div>;
  } else {
    content = (
      <div style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)" }}>
        <div style={{ gridColumn: "span 5", height: "700px" }}>
          {switchBoardData && switchBoardData.latitude && (
            <SwitchBoardMiniMap
              center={
                new LatLng(
                  Number.parseFloat(switchBoardData.latitude),
                  Number.parseFloat(switchBoardData.longitude)
                )
              }
              mapSize={mapHalfSize}
              unAssignedNode={unassignedNodesList} // unassigned nodes which have no assigned feeders
              drawToolOpt={true}
              setNodes={setNodesList}
              nodes={nodesList} // state for storing list of assigned nodes
              switchBoardData={switchBoardData} // for centering to switchBoard
              feederColor={feederData?.feeder?.color}
              addSingleNode={addUnassignedToNodeList}
              otherNodes={otherNodes}
              removeNodes={removeNodes}
            />
          )}
        </div>
        <div style={{ gridColumn: "span 2" }}>
          <div
            style={{
              padding: "20px",
              paddingTop: "12px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "2px" }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "15px" }}
              >
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontSize: "1.25rem",
                      fontWeight: "bold",
                      color: "#475569",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {feederData?.feeder?.feeder_name}
                  </span>
                </div>
                <div
                  style={{
                    borderColor: feederData?.feeder?.color,
                    borderTop: "2px solid",
                    margin: "16px 0",
                    width: "4rem",
                  }}
                ></div>
              </div>
              <span style={{ fontSize: "0.875rem" }}>
                {feederData?.feeder?.feeder_id}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginTop: "30px",
              }}
            >
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                    color: "#475569",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Selected Node
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  height: "400px",
                  overflow: "auto",
                }}
              >
                {uniqueArray?.map((item: any) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "0.875rem",
                      color: "black",
                      fontWeight: "normal",
                      backgroundColor: "#FFF1C8",
                      borderRadius: "0.375rem",
                    }}
                  >
                    <div style={{ padding: "1rem" }}>{item.node_name}</div>
                    <img
                      style={{ padding: "1rem", cursor: "pointer" }}
                      src={RemoveIcon}
                      alt="Your SVG"
                      onClick={() => removeNodes(item)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "50px",
              }}
            >
              <button
                style={{
                  backgroundColor: "#FFD966",
                  color: "black",
                  fontSize: "0.875rem",
                  fontWeight: "medium",
                  borderRadius: "0.375rem",
                  padding: "1rem 2rem", // Adjusted padding to mimic px-8
                  border: "none",
                }}
                onClick={assignNodes}
              >
                Assign Node
              </button>
              <button
                style={{
                  fontSize: "0.875rem", // text-sm
                  fontWeight: "medium",
                  borderRadius: "0.375rem",
                  paddingLeft: "2.5rem", // Adjusted padding to mimic px-10
                  paddingRight: "2.5rem", // Adjusted padding to mimic px-10,
                  background: "none",
                }}
                onClick={() => handleOpen()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const filterUnselectedNodes = () => {
    let filteredArray = feeders?.filter(
      (node: any) => !feederData?.nodes.some((item: any) => item.id === node.id)
    );
    setOtherNodes(filteredArray);
    console.log(filteredArray, "there it is");
    // const otherNodes = feeders.filter()
    // const unselectedNodes = mapFeeders().filter(
    //   (node: any) => selectedTab !== node.feeder_id
    // );
    // const nodeMap = unselectedNodes.map((item: any) => item.nodes);
    // const flatArrayNodeMap: any = [].concat.apply([], nodeMap);
    // setOtherNodes(flatArrayNodeMap);
  };

  useEffect(() => {
    if (unAssignedNode) {
      setUnassignedNodesList(unAssignedNode);
    }
  }, [selectedTab, unAssignedNode, open]);
  useEffect(() => {
    if (feederData) {
      setNodesList(feederData?.nodes);
    }
  }, [selectedTab, feederData, open]);
  useEffect(() => {
    checkNodeIdForUnassigned();
  }, [nodesList]);
  useEffect(() => {
    if (feederData?.nodes) {
      filterUnselectedNodes();
    }
  }, [selectedTab, feederData]);

  return (
    <Modal show={open} handleClose={handleOpen} size={"xl"} centered>
      {content}
    </Modal>
  );
};

export default FeederNodesModal;
