import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Devices, Lamp } from "../../../types/interfaces";

const initialDevices: Devices = {
  gateways: [],
  nodes: [],
  unpositionedNodes: [],
  organizations: [],
  selectedOrganizationId: 0,
  services: [],
  selectedServiceId: 0,
};

const devicesSlice = createSlice({
  name: "devices",
  initialState: initialDevices,
  reducers: {
    loadAllDevices: (previousState, action: PayloadAction<Devices>) => ({
      ...action.payload,
    }),
    loadUnPositionedNode: (state, action) => {
      state.unpositionedNodes = action.payload;
    },

    updateNodeDeviceStatusGlobalRedux: (previousState, action) => {
      const nodeToUpdate = previousState.nodes.find(
        (node) => node.node_id === action.payload.node_id
      );

      console.log("payload redux glob");
      console.log(action.payload);

      previousState.nodes.forEach((node) => {
        if (nodeToUpdate !== undefined) {
          if (node.node_id === nodeToUpdate.node_id) {
            if (action.payload.msg_type === 2) {
              node.on = 0;
              node.online = 1;
            } else if (action.payload.msg_type === 1) {
              node.on = 1;
              node.online = 1;
            } else if (action.payload.msg_type === 3) {
              node.on = 1;
              node.bri = action.payload.value;
              node.online = 1;
            } else if (action.payload.msg_type === 4) {
              node.on = 0;
              node.online = 0;
            }
          }
        }
      });
    },

    updateNodeDeviceStatusGlobal: (
      previousState,
      action: PayloadAction<Lamp>
    ) => {
      const nodeToUpdate = previousState.nodes.findIndex(
        (node) => node.node_id === action.payload.node_id
      );

      previousState.nodes.forEach((node, index) => {
        if (index === nodeToUpdate) {
          let manageNode = node.on === 0 ? 1 : 0;
          node.on = manageNode;
        }
      });
    },
  },
});

export const devicesReducer = devicesSlice.reducer;

export const {
  loadAllDevices: loadDevicesActionCreator,
  loadUnPositionedNode,
  updateNodeDeviceStatusGlobalRedux:
    updateNodeDeviceStatusGlobalReduxActionCreator,
  updateNodeDeviceStatusGlobal: updateNodeDeviceStatusGlobalActionCreator,
} = devicesSlice.actions;
