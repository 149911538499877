import { TextSpan } from "../../../styles/shared-styles/text-sizes";
import {
  Icon,
  InputContainer,
  NavLink,
  TabContent,
  CardContainer,
} from "../UsersStyled";
import searchIcon from "../../../assets/search.svg";
import { useEffect, useState } from "react";
import { Skeleton } from "antd";
import { useGetManagerUsersService } from "../../../services/getManagerUsersService";
import Cookies from "js-cookie";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { UsersTable } from "./UsersTable";
import { GetManagerUsers } from "../../../types/interfaces";
import { useTranslation } from "react-i18next";

export const UsersList = () => {
  const [isLoading, setIsLoading] = useState(false);

  const users = useAppSelector((state: RootState) => state.getManagerUser);
  const { getManagerUsers } = useGetManagerUsersService();

  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      setIsLoading(true);
      getManagerUsers(token)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);
  const { t } = useTranslation();
  return (
    <CardContainer>
      <div className="input-group">
        <InputContainer
          type="text"
          className="form-control"
          placeholder={`${users ? users.length : 0} ${t("Active users")}`}
        />
        <Icon className="input-group-text">
          <img src={searchIcon} alt="Search" />
        </Icon>
      </div>

      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <ul
            className="nav nav-pills"
            style={{
              marginBottom: "16px",
            }}
          >
            <li className="nav-item">
              <NavLink
                className="nav-link active"
                id="users-tab"
                data-bs-toggle="tab"
                data-bs-target="#users"
                type="button"
                role="tab"
                aria-controls="users"
                aria-selected="true"
              >
                {t("Active")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                id="notusers-tab"
                data-bs-toggle="tab"
                data-bs-target="#notusers"
                type="button"
                role="tab"
                aria-controls="notusers"
                aria-selected="false"
              >
                {t("Not Active")}
              </NavLink>
            </li>
          </ul>

          <TabContent className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="users"
              role="tabpanel"
              aria-labelledby="users-tab"
            >
              <UsersTable
                users={users?.filter((user) => user.active === "Y")}
              />
            </div>

            <div
              className="tab-pane fade"
              id="notusers"
              role="tabpanel"
              aria-labelledby="notusers-tab"
            >
              <UsersTable
                users={users?.filter((user) => user.active === "N")}
              />
            </div>
          </TabContent>
        </>
      )}
    </CardContainer>
  );
};
