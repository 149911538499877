import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import InputForm from "../Input/Input";
import { languages } from "../../utils/languages/languages";
import {
  ButtonLogin,
  Container,
  ImageLogo,
  LoginForm,
  LoginOverlay,
  LoginOverlayWhite,
} from "../../pages/Login/LoginStyled";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import chevronLeft from "../../assets/chevron-left.svg";
import envelope_icon from "../../assets/mail.svg";
import logo from "../../assets/smartec-logo.svg";
import { useTranslation } from "react-i18next";
import authService from "../../services/authService";
import { useState } from "react";
import { ButtonLoading } from "../../styles/shared-styles/buttons-sizes";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isFormError, setIsFormError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { t } = useTranslation();

  const handleForgotPassword = async () => {
    setIsFormError(false);
    if (!email) {
      setIsFormError(true);
      return setErrorMessage("Email is required");
    }
    // Email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; // Basic email validation regex
    if (!emailRegex.test(email)) {
      setIsFormError(true);
      return setErrorMessage("Invalid email address");
    }
    setLoading(true);
    const res = await authService.smartecForgotPassword(email);
    if (res?.data) {
      setIsFormError(false);
      setIsSubmitted(true);
      setErrorMessage("");
      setLoading(false);
    } else {
      setErrorMessage(res?.error?.error);
      setIsFormError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Container className="login_container">
        <div className="row">
          <div className="col-6">
            <LoginOverlay className="overlay" />
          </div>
          <div className="col-6">
            <Container>
              <LoginOverlayWhite className="overlay" />
              <LoginForm padding="large" className="card" borderRadius="large">
                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    marginTop: "-100px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={chevronLeft}
                    style={{
                      position: "absolute",
                      top: "-29vh",
                      bottom: "10vh",
                      left: "-21vh",
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      background: "white",
                      padding: "17px",
                      boxShadow:
                        "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
                    }}
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                  <ImageLogo src={logo} />
                </div>
                {!isSubmitted ? (
                  <>
                    <div className="col-span-12 text-left flex flex-col items-center  justify-center h-[calc(100vh-12rem)] w-full">
                      <Text
                        textType="title"
                        fontWeight="700"
                        marginBottom="10px"
                      >
                        {t(txt.forgotPassword)}
                      </Text>
                      <Text
                        fontWeight="400"
                        marginBottom="28px"
                        color="#475569"
                      >
                        {t(txt.forgotPasswordText)}
                      </Text>
                      <InputForm
                        preffix_icon={envelope_icon}
                        onChange={(e) => setEmail(e.target.value)}
                        inputType="email"
                        placeholder={t(txt.email)}
                        isError={isFormError}
                      />
                      {isFormError && (
                        <TextSpan
                          style={{ marginTop: "-40px", width: "200px" }}
                          color="red"
                        >
                          {errorMessage}
                        </TextSpan>
                      )}
                    </div>
                    <div className="col-12 text-left">
                      {loading ? (
                        <ButtonLoading
                          style={{ marginTop: "16px" }}
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {t(txt.resetInstructions)}
                        </ButtonLoading>
                      ) : (
                        <ButtonLogin
                          disabled={loading}
                          buttonType="primary"
                          onClick={handleForgotPassword}
                        >
                          {t(txt.resetInstructions)}
                        </ButtonLogin>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-span-12 text-left flex flex-col items-center  justify-center h-[calc(100vh-12rem)] w-full">
                      <section>
                        <svg
                          width="60"
                          height="60"
                          viewBox="0 0 60 60"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.4999 40.4251L12.0749 30.0001L8.5249 33.5251L22.4999 47.5001L52.4999 17.5001L48.9749 13.9751L22.4999 40.4251Z"
                            fill="#08C146"
                          />
                        </svg>
                        <Text
                          textType="title"
                          fontWeight="600"
                          marginBottom="10px"
                        >
                          Reset Link Sent
                        </Text>
                        <Text textType="caption">
                          Password reset instructions have been sent to your
                          email.
                        </Text>
                      </section>
                    </div>
                  </>
                )}
              </LoginForm>
            </Container>
          </div>
        </div>
      </Container>
    </>
  );
};
