// mqttService.js
import { Client } from 'paho-mqtt';
import { updateNodeDeviceStatusReduxActionCreator } from "../redux/slices/devices/filteredDevicesSlice";
import { updateNodeDeviceStatusGlobalReduxActionCreator } from "../redux/slices/devices/devicesSlice";
import { updateNodeStatusReduxActionCreator } from '../redux/slices/node/manageNodeSlice';
import { store } from '../redux/store';
import { deleteIdsActionCreator } from '../redux/slices/mqttSlice/mqttSlice';
import { setIsStaticViewActionCreator } from '../redux/slices/uiSlice/uiSlice';

function processResponse (orgId: string, nodeId: string, response: string) {
  console.log('La Organización: ', orgId);
  if(nodeId === undefined)  console.log('Te has olvidado de poner el nodeId');
  else console.log('El nodo: ', nodeId);
  try {
    const responseJSON = JSON.parse(response); //responseJSON.r = response responseJSON.o = order responseJSON.n = nodeId
    // Now that we have all the information, it's time to process the response
    // 1. Check if there everything went well
    if (responseJSON.r === 0) 
    {
      // 2. If everything it's ok, update Redux store
    }

    // 3. Find in our calling stack if the nodeId of the response is generated by ours.

  } catch (error) {
    console.log('There is an error in the received information: ', error);    
  }
}

export function connectToMqtt(organizationIds: any[], userId: string) {
  // Crear cliente MQTT
  const client = new Client(process.env.REACT_APP_MQTT_BASE_URL!, parseInt(process.env.REACT_APP_MQTT_PORT!), userId);

  // Manejar el evento 'onConnectionLost'
  client.onConnectionLost = (responseObject: any) => {
    console.log('Connection lost:', responseObject.errorMessage);
  };

  // Manejar el evento 'onMessageArrived'
  client.onMessageArrived = (message: any) => {
    console.log(message);
    const topicSplitted = message.destinationName.split('/');
    processResponse(topicSplitted[1], topicSplitted[2], message.payloadString);

    const luminaireResponse = JSON.parse(message.payloadString);
    if(luminaireResponse.result === 0) {
      // if gw ..... if node .... Los mensajes será distintos y debo pensar en como gestionar esto. Quizás fijándome en el topic?
      //Actualizar redux con el nuevo estado de la luminaria
      console.log('es 0');
      store.dispatch(updateNodeDeviceStatusReduxActionCreator(luminaireResponse));
      store.dispatch(updateNodeDeviceStatusGlobalReduxActionCreator(luminaireResponse));
      store.dispatch(updateNodeStatusReduxActionCreator(luminaireResponse));

    /*   dispatch(loadDevicesActionCreator(devices));
          dispatch(filteredAllDevicesActionCreator(devices)); */


      store.dispatch(setIsStaticViewActionCreator(true));
      const state = store.getState();
      state.mqtt.nodeId.forEach((node: string) => {
        if (node !== "" && node !== undefined) { 
          if (node === luminaireResponse.node_id) {
            store.dispatch(deleteIdsActionCreator(luminaireResponse.node_id));
          }
        }
      })
    } else {
      console.log('no es 0');
      // falta hacer que salte un mensaje de error cuando se borre el nodo de la lista
      store.dispatch(deleteIdsActionCreator([luminaireResponse.node_id]));
    }

    if(luminaireResponse.node_id === 0){
      //Lo borramos de la lista
      if(luminaireResponse.result !== 0)
      console.log('');
        //Muestra alerta por pantalla
    }
    
/*
      if(luminaireResponse.node_id === luminaires[i].node_id) {
        console.log('Lo hemos encontrado!!!');
        let foundNode: NodeDevice = {...luminaires[i]};            
        if(luminaireResponse.msg_type === 2) foundNode.on = 0;
        else if(luminaireResponse.msg_type === 1) foundNode.on = 1;
        else if(luminaireResponse.msg_type === 3) {foundNode.on = 1; foundNode.bri = luminaireResponse.value;}

        dispatch(updateNodeDeviceStatusActionCreator(foundNode));
        dispatch(updateNodeDeviceStatusGlobalActionCreator(foundNode));
      }*/
  };

  // Conectar al broker MQTT
  client.connect({
    userName: process.env.REACT_APP_MQTT_CLIENT_USERNAME,
    password: process.env.REACT_APP_MQTT_CLIENT_PASSWORD,
    useSSL: true,
    onSuccess: () => {
      console.log('Connected to MQTT broker');
      organizationIds.forEach(orgId => {
        console.log(orgId.id)
        // Suscribirse al topic 'front-msg/orgId'
        client.subscribe(`front-msg/${orgId.id}`);    
      });
    }
  });

  return client;
}
