import { Nav } from "react-bootstrap";

interface IProps {
  selected: string;
  setSelected: any;
  tabs: { name: string }[];
}
const SwitchTabs = ({ selected, setSelected, tabs }: IProps) => {
  return (
    <Nav
      variant="underline"
      defaultActiveKey="/home"
      className="border-bottom text-secondary"
      style={{ fontSize: "14px" }}
    >
      <Nav.Item
        className={`py-2 px-3 ${
          selected === "General" ? "border-bottom border-2 border-dark" : ""
        }`}
        onClick={() => setSelected("General")}
      >
        <Nav.Link>
          <span>General</span>{" "}
        </Nav.Link>
      </Nav.Item>
      {tabs?.map((item: any) => {
        if (item.feeder_id === selected) {
          return (
            <Nav.Item
              className={`py-2 px-3 border-bottom border-2 border-dark`}
            >
              <Nav.Link>
                <span>{item.feeder_name}</span>{" "}
              </Nav.Link>
            </Nav.Item>
          );
        }
        return (
          <Nav.Item
            className={`py-2 px-3 `}
            onClick={() => setSelected(item.feeder_id)}
          >
            <Nav.Link>
              <span>{item.feeder_name}</span>{" "}
            </Nav.Link>
          </Nav.Item>
        );
      })}
    </Nav>
  );
};

export default SwitchTabs;
