import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  changeStrategyChartActionCreator,
  changeSunsetActionCreator,
  changeSunriseActionCreator,
  sumCountActionCreator,
} from "../../../../redux/slices/strategiesSlice/strategiesSlice";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import dragData from "chartjs-plugin-dragdata";
import { languages } from "../../../../utils/languages/languages";
import { hourRegex, minuteRegex, numericRegex } from "../../../../utils/regex";
import { Input } from "../../../../components/Input/InputStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import moonStar from "../../../../assets/moon_star.svg";
import sun from "../../../../assets/sun.svg";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  dragData,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface DimmingChartProps {
  showDimming?: any;
  setNewChartData?: any;
  newChartData?: any;
}

function DimmingChart({
  showDimming,
  setNewChartData,
  newChartData,
}: DimmingChartProps) {
  const dispatch = useAppDispatch();
  const color = useAppSelector((state) => state.strategies.color);
  const count = useAppSelector((state) => state.strategies.count);
  const sunset = useAppSelector((state) => state.strategies.sunset);
  const sunrise = useAppSelector((state) => state.strategies.sunrise);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [maxLength, setMaxLength] = useState(2);
  const [reset, setReset] = useState("");
  const [errorMessageHour, setErrorMessageHour] = useState(false);
  const [errorMessageDimmingPoint, setErrorDimmingPoint] = useState(false);
  const [errorMessageNoHour, setErrorMessageNoHour] = useState(false);
  const [chartData, setChartData] = useState({
    //labels: ["17", "18", "19", "20", "21", "22", "23", "00", "01", "02", "03", "04", "05", "06", "07", "08"],
    labels: newChartData?.labels || [
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
    ],
    datasets: newChartData?.datasets || [
      {
        label: "Standard",
        //data: newStrategy.data ? [newStrategy.data] : [100, , , , , , , , , , , , , , , 0],
        data: [100, , , , , , , , , , , , , , , 0],
        //borderColor: "rgba(246, 120, 120)",
        borderColor: color.value,
        //backgroundColor: "#eb9d9d80",
        //backgroundColor: color.value ? color.value+"80" : "#eb9d9d80",
        backgroundColor: color.value + "80",
        fill: true,
        stepped: true,
        pointRadius: 5,
        pointBackgroundColor: "white",
        padding: 10,
        showLine: true,
        spanGaps: true,
      },
    ],
  });

  console.log("el nuevo", newChartData);
  console.log("el inicial", chartData);

  const optionsDragable = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
      },
      dragData: {
        round: 0,
        onDragEnd: (value: any, datasetIndex: any) => {
          setChartData((chartData) => {
            chartData.datasets[0].data[value] = datasetIndex;

            return {
              ...chartData,
              datasets: chartData.datasets.map((dataset: any) => ({
                ...dataset,
              })),
            };
          });

          setNewChartData((newChartData: any) => {
            newChartData.datasets[0].data[value] = datasetIndex;

            return {
              ...newChartData,
              datasets: newChartData.datasets.map((dataset: any) => ({
                ...dataset,
              })),
            };
          });

          /* if (newChartData === undefined) {
            setNewChartData({
              labels: chartData.labels,
              datasets: chartData.datasets
            });
          } else {
            setChartData(newChartData => {
              newChartData.datasets[0].data[value] = datasetIndex;

              return {
                ...newChartData,
                datasets: newChartData.datasets.map((dataset:any) => ({
                  ...dataset,            
                }))
              }
            })
          } */
        },
      },
    },
    scales: {
      /* yAxes: {
        display: false,        
      }, */
      y: {
        display: true,
        max: 100,
        min: 0,
        ticks: {
          stepSize: 25,
          backdropPadding: 10,
        },
      },
      x: {
        display: true,
        ticks: {
          stepSize: 25,
          maxTicks: 3,
        },
      },
    },
    dragData: true,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      /* yAxes: {
        display: false,        
      }, */
      y: {
        display: true,
        max: 100,
        min: 0,
        ticks: {
          stepSize: 25,
          backdropPadding: 10,
        },
      },
      x: {
        display: true,
        ticks: {
          stepSize: 25,
          maxTicks: 3,
        },
      },
    },
    dragData: false,
  };

  const handleData = (value: string, action: string) => {
    const [hours, minutes] = value.split(":");
    let numericValue = parseInt(hours) + (minutes ? parseInt(minutes) / 60 : 0);

    if (numericValue < parseInt(chartData.labels[0])) {
      numericValue += 24;
    }

    let index = 0;
    while (index < chartData.labels.length) {
      const [currentHours, currentMinutes] = chartData.labels[index].split(":");
      let currentNumericValue =
        parseInt(currentHours) +
        (currentMinutes ? parseInt(currentMinutes) / 60 : 0);

      if (currentNumericValue < parseInt(chartData.labels[0])) {
        currentNumericValue += 24;
      }

      if (currentNumericValue > numericValue) {
        break;
      }

      index++;
    }

    let newLabel = chartData.labels;
    let newData = chartData.datasets;

    if (action === "addData") {
      if (value === "17") {
        newData[0].data[index - 1] = 100;
      } else if (value === "08") {
        newData[0].data[index - 1] = 0;
      }

      if (
        chartData.labels.includes(value) &&
        newData[0].data[index - 1] === undefined
      ) {
        newData[0].data[index - 1] = 50;
        if (count !== undefined) {
          dispatch(sumCountActionCreator({ count: count + 1 }));
        }
      } else if (!chartData.labels.includes(value)) {
        newData[0].data.splice(index, 0, 50);
        newLabel = chartData.labels.slice();
        newLabel.splice(index, 0, value.toString());
        if (count !== undefined) {
          dispatch(sumCountActionCreator({ count: count + 1 }));
        }
      }
    }

    if (action === "deleteData") {
      if (value !== "17" && value !== "08") {
        if (
          chartData.labels.includes(value) &&
          newData[0].data[index - 1] !== undefined
        ) {
          newData[0].data.splice(index - 1, 1);
          let tempNewLabel = newLabel;
          newLabel = tempNewLabel.filter((label: any) => label !== value);
          if (count !== undefined) {
            dispatch(sumCountActionCreator({ count: count - 1 }));
          }
        } else if (
          chartData.labels.includes(value) &&
          newData[0].data[index - 1] === undefined
        ) {
          setErrorDimmingPoint(true);
        } else if (!chartData.labels.includes(value)) {
          setErrorMessageNoHour(true);
        }
      }
    }

    setChartData({
      labels: newLabel,
      datasets: newData.map((dataset: any) => ({
        ...dataset,
      })),
    });

    setNewChartData({
      labels: newLabel,
      datasets: newData.map((dataset: any) => ({
        ...dataset,
      })),
    });
  };

  const handleHourInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (numericRegex.test(e.target.value)) {
      if (e.target.value.length === 2 && e.target.value.length === maxLength) {
        if (hourRegex.test(e.target.value)) {
          setHour(e.target.value);
        } else {
          e.target.value = reset;
        }
      } else if (
        e.target.value.length <= 2 &&
        e.target.value.length <= maxLength
      ) {
        setHour(e.target.value);
      } else {
        e.target.value = reset;
      }
    } else {
      e.target.value = reset;
    }

    if (e.target.value === "") {
      setHour("");
    }
  };

  const handleMinuteInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (numericRegex.test(e.target.value)) {
      if (e.target.value.length === 2 && e.target.value.length === maxLength) {
        if (minuteRegex.test(e.target.value)) {
          setMinute(e.target.value);
        } else {
          e.target.value = reset;
        }
      } else if (
        e.target.value.length <= 2 &&
        e.target.value.length <= maxLength
      ) {
        setMinute(e.target.value);
      } else {
        e.target.value = reset;
      }
    } else {
      e.target.value = reset;
    }

    if (e.target.value === "") {
      setMinute("");
    }
  };

  const handleClick = (hour: string, minute: string) => {
    if (hour.length === 2 && (minute.length === 0 || minute.length === 2)) {
      setErrorMessageHour(false);
      setErrorDimmingPoint(false);
      setErrorMessageNoHour(false);

      if (count !== 5) {
        if (minute.length === 0) {
          if (hour === "17" || hour === "08") {
            setErrorMessageHour(true);
          } else {
            handleData(hour, "addData");
          }
        } else if (minute.length === 2) {
          let value = [hour, minute].join(":");
          handleData(value, "addData");
        }
      }
    } else {
      setErrorMessageHour(true);
    }
  };

  const handleDelete = (hour: string, minute: string) => {
    if (hour.length === 2 && (minute.length === 0 || minute.length === 2)) {
      setErrorMessageHour(false);
      setErrorDimmingPoint(false);
      setErrorMessageNoHour(false);

      if (minute.length === 0) {
        if (hour === "17" || hour === "08") {
          setErrorMessageHour(true);
        } else {
          handleData(hour, "deleteData");
        }
      } else if (minute.length === 2) {
        let value = [hour, minute].join(":");
        handleData(value, "deleteData");
      }
    } else {
      setErrorMessageHour(true);
    }
  };

  useEffect(() => {
    if (newChartData === undefined) {
      setNewChartData({
        labels: chartData.labels,
        datasets: chartData.datasets,
      });
    }
    const newArray = chartData.datasets[0].data.map((element: any) =>
      element === undefined ? "" : element
    );
    dispatch(
      changeStrategyChartActionCreator({
        labels: chartData.labels,
        data: newArray,
      })
    );
  }, [chartData, newChartData]);

  const { t } = useTranslation();

  return (
    <>
      <div className="row" style={{ marginBottom: "6px", textAlign: "left" }}>
        <div className="col-6" style={{ textAlign: "left" }}>
          <img
            onClick={() => {
              handleData("17", "addData");
            }}
            src={moonStar}
            height={14}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="col-6" style={{ textAlign: "right" }}>
          <img
            onClick={(e) => {
              handleData("08", "addData");
            }}
            src={sun}
            height={14}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <Line
        options={showDimming ? optionsDragable : options}
        data={chartData}
      />
      {showDimming && (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className="col-6"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
                justifyContent: "center",
              }}
            >
              <label style={{ display: "flex", gap: "6px" }}>
                <input
                  type="checkbox"
                  checked={sunset}
                  onChange={() => {
                    dispatch(changeSunsetActionCreator());
                  }}
                />
                <Text marginBottom="2px" style={{ fontSize: "11px" }}>
                  {t(txt.setAutomaticSunset)}
                </Text>
              </label>
              <label style={{ display: "flex", gap: "6px" }}>
                <input
                  type="checkbox"
                  checked={sunrise}
                  onChange={() => {
                    dispatch(changeSunriseActionCreator());
                  }}
                />
                <Text marginBottom="2px" style={{ fontSize: "11px" }}>
                  {t(txt.setAutomaticSunrise)}
                </Text>
              </label>
            </div>
            <div
              className="col-6"
              style={{
                display: "flex",
                marginTop: "12px",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "15px",
              }}
            >
              <Input
                style={{ border: "1px solid #ced4da", width: "80px" }}
                placeholder={t(txt.hour)}
                onChange={(e) => {
                  handleHourInputChange(e);
                }}
              />
              <div style={{ fontWeight: 700 }}>:</div>
              <Input
                style={{ border: "1px solid #ced4da", width: "80px" }}
                placeholder={t(txt.minute)}
                onChange={(e) => {
                  handleMinuteInputChange(e);
                }}
              />
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <button
                  style={{ padding: "6px", width: "103px" }}
                  onClick={() => {
                    hour !== "" ? handleClick(hour, minute) : <></>;
                  }}
                >
                  {t(txt.addPoint)}
                </button>
                <button
                  style={{ padding: "6px" }}
                  onClick={() => {
                    hour !== "" ? handleDelete(hour, minute) : <></>;
                  }}
                >
                  {t(txt.deletePoint)}
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {count === 5 ? (
              <Text marginBottom="0px">{t(txt.errorStrategyLimit)}</Text>
            ) : (
              <></>
            )}
            {errorMessageHour ? (
              <Text marginBottom="0px">{t(txt.errorDimmingHour)}</Text>
            ) : (
              <></>
            )}
            {errorMessageDimmingPoint ? (
              <Text marginBottom="0px">{t(txt.errorDimmingPoint)}</Text>
            ) : (
              <></>
            )}
            {errorMessageNoHour ? (
              <Text marginBottom="0px">{t(txt.errorDimmingNoHour)}</Text>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default DimmingChart;
