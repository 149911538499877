import axios from 'axios';
import Cookies from 'js-cookie';
import { message } from 'antd';

class UpdateLocation {
  private webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL!;

  public async updateNodePosition(
    nodeId: string,
    node_name: string,
    latitude: string,
    longitude: any
  ): Promise<void> {
    try {
      const res = await axios.put(
        `${this.webAddressPrefix}/nodes/updateNode/${nodeId}`,
        {
          node_name,
          latitude,
          longitude,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "withCredentials": "true",
          },
          withCredentials: true, 
        }
      );
      message.success("Location added successfully");
    } catch (error) {
      message.error("There was an error. Please try again later.");
      throw new Error("There was an error. Please try again later.");
    }
  }
}

const updateLocation = new UpdateLocation();
export default updateLocation;
