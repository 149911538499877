import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import updatePersonalInformationService from "../../../services/updatePersonalInformationService";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface UpdatePersonalInformationState {
  isLoading: boolean;
  error: string | null;
}

const initialState: UpdatePersonalInformationState = {
  isLoading: false,
  error: null,
};

const updatePersonalInformationSlice = createSlice({
  name: "updatePersonalInformation",
  initialState,
  reducers: {
    updatePersonalInformationStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    updatePersonalInformationSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    updatePersonalInformationFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  updatePersonalInformationStart,
  updatePersonalInformationSuccess,
  updatePersonalInformationFailure,
} = updatePersonalInformationSlice.actions;

// Thunk to update personal information
export const updatePersonalInformation =
  (
    firstName: string,
    lastName: string,
    phoneNumber: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      dispatch(updatePersonalInformationStart());

      const token = Cookies.get("token");

      // If the token is undefined, handle the error
      if (!token) {
        throw new Error("Token not found.");
      }

      // Call the updatePersonalInformationService to update the personal information
      await updatePersonalInformationService.updatePersonalInformation(
        token,
        firstName,
        lastName,
        phoneNumber
      );

      // You can dispatch additional actions or update your personal information state if needed

      dispatch(updatePersonalInformationSuccess());
      return "Personal information updated successfully!";
    } catch (error: any) {
      dispatch(updatePersonalInformationFailure(error.message as string));

      throw error;
    }
  };

export default updatePersonalInformationSlice.reducer;
