import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { LatLng, divIcon } from "leaflet";
import { Marker } from "react-leaflet";
import { useAppSelector } from "../../../redux/hooks";
import { getIcon } from "../../../utils/icons";
import "../../../styles/index.css";
import { useEffect, useState } from "react";
import DrawTool from "../../DrawTool/DrawTool";
import LightBulbColor from "../../../utils/lightBulbColor";
import { SVGOverlay } from "react-leaflet";
import { Colors } from "chart.js";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { CustomMapStyled } from "../../CustomMaps/CustomMap/CustomMapStyled";
import { FeederNodes, ISwitchboard } from "../../../types/interfaces";

export interface UnAssignedNode {
  organization_id: number;
  organization: string;
  service: string;
  device: string;
  node_id: string;
  node_name: string;
  longitude: string;
  latitude: string;
  solar_id: any;
  wireless_status: string;
  is_on: string;
  dimming: number;
  alarm_status: string;
  node_active: string;
  selected: string;
}

interface MiniMapProps {
  center?: LatLng;
  mapSize: React.CSSProperties;
  node?: any;
  drawToolOpt?: boolean;
  unAssignedNode?: UnAssignedNode[];
  handleShapeDrawn?: any;
  setNodes?: any;
  nodes?: any;
  switchBoardData?: ISwitchboard;
  feederNodes?: FeederNodes[];
  feederColor?: string;
  generalPageNodes?: any;
  addSingleNode?: any;
  otherNodes?: any;
  removeNodes?: any;
}

export const SwitchBoardMiniMap = ({
  center,
  mapSize,
  node,
  drawToolOpt,
  unAssignedNode,
  setNodes,
  nodes,
  switchBoardData,
  feederNodes,
  feederColor,
  generalPageNodes,
  addSingleNode,
  otherNodes,
  removeNodes,
}: MiniMapProps) => {
  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);
  let latitude = Number.parseFloat(node?.latitude);
  let longitude = Number.parseFloat(node?.longitude);
  const lampStatus = node?.online ? (node?.on ? "on" : "off") : "disconnected";
  // const latlngBounds = L.latLngBounds(
  //   nodes.map((location: any) => [location.latitude, location.longitude])
  // );
  const icon = getIcon(lampStatus, node?.selected);

  const lightBulb = getIcon("lightBulb", false);
  const switchBoard = getIcon("switchBoard", false);

  const getIconWithColor = (element: any) => {
    return divIcon({
      className: "custom-icon",
      html: ReactDOMServer.renderToString(element),
      iconSize: [30, 30],
      shadowSize: [50, 50],
    });
  };
  return (
    <CustomMapStyled
      id="map-container"
      style={
        {
          position: "relative",
          width: "100%",
          height: "100%",
        }
        // !fullView && !hybridView
        //   ? {
        //       position: "absolute",
        //       top: 0,
        //       left: 0,
        //       width: "100%",
        //       height: "100%",
        //     }
        //   : { position: "relative", width: "100%", height: "100%" }
      }
    >
      <MapContainer
        className="my-custom-map"
        center={center}
        zoom={17}
        scrollWheelZoom={switchBoardData ? true : false}
        zoomControl={switchBoardData ? true : false}
      >
        <TileLayer
          key={"map layer"}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {/* this is draw tool */}
        {drawToolOpt && unAssignedNode && !node && (
          <DrawTool
            markers={unAssignedNode}
            nodes={nodes}
            setNodes={setNodes}
          />
        )}
        {otherNodes?.map((node: any) => (
          <Marker
            key={node.node_id}
            position={[node.latitude, node.longitude]}
            riseOnHover
            riseOffset={100}
            icon={getIconWithColor(<LightBulbColor color={node.color} />)}
            eventHandlers={{
              click: () => console.log("this is other nodes"),
            }}
          />
        ))}

        {generalPageNodes &&
          generalPageNodes.map((item: any) => (
            <Marker
              key={item.node_id}
              position={[item.latitude, item.longitude]}
              riseOnHover
              riseOffset={100}
              icon={getIconWithColor(<LightBulbColor color={item.color} />)}
              eventHandlers={{
                click: () => console.log(item),
              }}
            />
          ))}
        {/* this is for switch board feeders node */}
        {nodes &&
          switchBoardData &&
          feederColor &&
          nodes?.map((item: any) => {
            return (
              <Marker
                key={item.node_id}
                position={[item.latitude, item.longitude]}
                riseOnHover
                riseOffset={100}
                icon={getIconWithColor(<LightBulbColor color={feederColor} />)}
                eventHandlers={{
                  click: () => removeNodes(item),
                }}
              />
            );
          })}
        {/* this is for switch board icon */}
        {node && (
          <Marker
            key={node.node_id}
            position={[latitude, longitude]}
            riseOnHover
            riseOffset={100}
            icon={lightBulb}
            eventHandlers={{
              click: () => console.log(node),
            }}
          />
        )}

        {/* this is for the unassigned nodes */}
        {!node &&
          unAssignedNode?.map((item: any) => {
            return (
              <Marker
                key={item.node_id}
                position={[item.latitude, item.longitude]}
                riseOnHover
                riseOffset={100}
                icon={lightBulb}
                eventHandlers={{
                  click: () => addSingleNode(item),
                }}
              />
            );
          })}
        {/* this is for the unassigned nodes */}

        {/* this is for the switchboard nodes */}
        {switchBoardData && (
          <Marker
            key={switchBoardData.id}
            position={[
              Number(switchBoardData.latitude),
              Number(switchBoardData.longitude),
            ]}
            riseOnHover
            riseOffset={100}
            icon={switchBoard}
            // eventHandlers={{
            //   click: () => console.log(item.latitude),
            // }}
          />
        )}
        {/* this is for the switchboard nodes */}
        {/* this is for the feeder nodes nodes */}
        {feederNodes &&
          feederColor &&
          feederNodes?.map((item: any) => (
            <Marker
              key={item.node_id}
              position={[item.latitude, item.longitude]}
              riseOnHover
              riseOffset={100}
              icon={getIconWithColor(<LightBulbColor color={feederColor} />)}
              eventHandlers={{
                click: () => console.log(item),
              }}
            />
          ))}
        {/* this is for the feeder nodes nodes */}
      </MapContainer>
    </CustomMapStyled>
  );
};
