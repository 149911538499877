import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { languages } from "../../../utils/languages/languages";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import alarmTime from "../../../assets/new_icons/alarm-time.svg";
import noAlarm from "../../../assets/noAlarm.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import { useTranslation } from "react-i18next";

interface AlarmsCardProps {}

export const AlarmsCard = ({}: AlarmsCardProps) => {
  const navigate = useNavigate();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();
  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="#A3AED0">{t(txt.alarmsCapitalLetter)}</Text>
        </div>
        {/* <div style={{ textAlign: "right", marginTop: "-2px", cursor: "pointer" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} />
        </div> */}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={noAlarm} style={{ marginBottom: "32px" }} />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={() => {
            navigate("/coming-soon");
          }}
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#ffffff",
            color: "#475569",
            fontWeight: "700",
            fontSize: "14px",
            padding: "6px 16px",
            border: "1px solid #475569",
            borderRadius: "64px",
            cursor: "pointer",
          }}
        >
          <img src={alarmTime} height={12} style={{ marginRight: "12px" }} />
          {t(txt.viewHistoric)}
        </button>
      </div>
    </div>
  );
};
