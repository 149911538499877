import { useState } from "react";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import {
  ButtonFilter,
  DropdownMenu,
  InputFormFiltering,
  SelectFormFilteringSmall,
  ListOrganization,
  OrganizationCard,
  OrganizationProfile,
  SelectFormFiltering,
  Table,
  OptionSelect,
} from "../UsersStyled";
import { IconStyled } from "../../ProfilePage/ProfilePageStyled";
import { ListItem } from "../UsersStyled";
import company from "../../../assets/company.svg";
import radioChekbox from "../../../assets/radio-checkbox.svg";
import maps from "../../../assets/maps.svg";
import search from "../../../assets/search.svg";
import filter from "../../../assets/filter.svg";
import { GetManagerOrganisationState } from "../../../services/getManagedUserOrganisationService";
import countryFlags from "../../../utils/countryFlags/countryFlags";
import { Checkbox } from "antd";
import companyFlag from "../../../assets/new_icons/Company.svg";
import Cookies from "js-cookie";
import {
  ButtonLoading,
  ButtonOutlined,
} from "../../../styles/shared-styles/buttons-sizes";
import { useTranslation } from "react-i18next";

interface OrganizationsProps {
  OrganizationData: GetManagerOrganisationState[] | null;
}
export const Organizations = ({ OrganizationData }: OrganizationsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = async (index: number, userId: string) => {
    if (OrganizationData) {
      // const newData: any =
      //   Array.isArray(OrganizationData) &&
      //   OrganizationData.map((item, i) =>
      //     i === index ? { ...item, selected: !item.selected } : item
      //   );
      const newData = Array.isArray(OrganizationData)
        ? OrganizationData.map((item, i) =>
            i === index ? { ...item, selected: !item.selected } : item
          )
        : [];
      setUpdatedData(newData);
      setSelectedIndex(index);
    }
  };
  const handleUpdateOrganization = async (userId: string) => {
    const token = Cookies.get("token");
    const mainAPIURL = process.env.REACT_APP_API_LOCAL_BASE_URL!;
    try {
      // Update the selected status in the backend API using fetch
      const response = await fetch(
        `${mainAPIURL}/users/user_org?userId=${userId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer  ${token}`,
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            selected: updatedData[selectedIndex].selected,
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        // Update the local state with the updated data
        // (assuming you have a state variable like setOrganizationData)
        // setOrganizationData(updatedData);
        console.log({ updatedData });
      } else {
        // Handle non-OK response status
        console.error("Failed to update selected status:", response.status);
      }
    } catch (error) {
      // Handle fetch error
      console.error("Error updating selected status:", error);
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="row" style={{ marginBottom: "8px" }}>
        <div className="col">
          <Text textType="subTitle" fontWeight="700" marginBottom="8px">
            {t("Organizations Permits")}
          </Text>
        </div>
        <div className="col-auto">
          <ul style={{ display: "inline-flex" }}>
            <ListItem>
              <IconStyled src={search} />
              <IconStyled src={filter} onClick={toggleMenu} />

              {isOpen && (
                <DropdownMenu>
                  <div
                    className="list-container"
                    style={{
                      display: "flex",
                      // justifyContent: "space-around",
                      marginBottom: "16px",
                    }}
                  >
                    <TextSpan textType="caption" color="#A3AED0">
                      Organization name
                    </TextSpan>
                    <InputFormFiltering className="form-control" />
                    <TextSpan textType="caption" color="#A3AED0">
                      Country
                    </TextSpan>
                    <SelectFormFiltering
                      id="inputState"
                      className="form-control"
                    >
                      <OptionSelect selected>Oman</OptionSelect>
                      <OptionSelect>...</OptionSelect>
                    </SelectFormFiltering>
                    <TextSpan textType="caption" color="#A3AED0">
                      City
                    </TextSpan>
                    <SelectFormFiltering className="form-control">
                      <OptionSelect selected>Muscat</OptionSelect>
                      <OptionSelect>...</OptionSelect>
                    </SelectFormFiltering>
                  </div>
                  <div
                    className="list-container"
                    style={{
                      display: "flex",
                      marginBottom: "16px",
                    }}
                  >
                    <TextSpan textType="caption" color="#A3AED0">
                      Organization type
                    </TextSpan>
                    <SelectFormFiltering className="form-control">
                      <OptionSelect selected>City Council</OptionSelect>
                      <OptionSelect>...</OptionSelect>
                    </SelectFormFiltering>

                    <TextSpan textType="caption" color="#A3AED0">
                      {t("Active")}
                    </TextSpan>

                    <SelectFormFilteringSmall className="form-control">
                      <OptionSelect selected>Yes</OptionSelect>
                      <OptionSelect>...</OptionSelect>
                    </SelectFormFilteringSmall>

                    {/* <InputFormFilteringSmall className="form-control" /> */}
                    <TextSpan textType="caption" color="#A3AED0">
                      Selected
                    </TextSpan>
                    <SelectFormFilteringSmall className="form-control">
                      <OptionSelect selected>{t("All")}</OptionSelect>
                      <OptionSelect>...</OptionSelect>
                    </SelectFormFilteringSmall>
                    <div style={{ textAlign: "center" }}>
                      <ButtonFilter>Filter</ButtonFilter>
                    </div>
                  </div>
                </DropdownMenu>
              )}
            </ListItem>

            <ListItem>
              <TextSpan color="#A3AED0">758 Organizations</TextSpan>
            </ListItem>
          </ul>
        </div>
      </div>
      <div className="row" style={{ marginBottom: "32px" }}>
        {Array.isArray(OrganizationData) &&
          OrganizationData.map((item, index) => (
            <div className="col-4" key={index}>
              <OrganizationCard>
                <Table className="table table-borderless">
                  <tr>
                    <td>
                      <OrganizationProfile src={companyFlag} />
                    </td>
                    <td
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      <Text fontWeight="700" marginBottom="0px">
                        {item.organization}
                      </Text>
                      <ListOrganization>
                        <IconStyled src={company} height={12} />
                        <TextSpan textType="caption" color="#a3aed0">
                          {item.type}
                        </TextSpan>
                      </ListOrganization>
                      <ListOrganization>
                        <IconStyled src={maps} height={12} />
                        <TextSpan textType="caption" color="#a3aed0">
                          {item.city} {item.country}
                        </TextSpan>
                      </ListOrganization>
                      <ListOrganization>
                        <IconStyled src={radioChekbox} height={12} />
                        <TextSpan textType="caption" color="#a3aed0">
                          {item.organizationActive === "Y" ? "Yes" : "No"}
                        </TextSpan>
                      </ListOrganization>
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        verticalAlign: "middle",
                      }}
                    >
                      <Checkbox
                        key={index}
                        defaultChecked={item.selected}
                        onChange={() => handleCheckboxChange(index, item.id)}
                      />
                    </td>
                  </tr>
                </Table>
              </OrganizationCard>
            </div>
          ))}
      </div>
      <div className="row" style={{ margin: "16px 0", float: "right" }}>
        <div className="col-3">
          {isLoading ? (
            <ButtonLoading type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading
            </ButtonLoading>
          ) : (
            <ButtonOutlined
            // onClick={() => handleUpdateOrganization(item.id)}
            >
              {t("Update")}
            </ButtonOutlined>
          )}
          {successMessage && (
            <Text color="green" textType="">
              {successMessage}
            </Text>
          )}
          {error && <Text color="red">{error}</Text>}
        </div>
      </div>
    </>
  );
};
