import { Modal } from "react-bootstrap";

const CustomModal = ({ children, show, handleClose, size }: any) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        aria-labelledby="example-modal-sizes-title-lg"
        size={size}
      >
        {children}
      </Modal>
    </>
  );
};

export default CustomModal;
