import React, { useEffect, useState } from "react";
import CustomModal from "../../../shared/Modal/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setSwitchBoardModalState } from "../../../redux/slices/uiSlice/uiSlice";
import { TextBox, TextSpan } from "../../../styles/shared-styles/text-sizes";
import { IconStyled } from "../SwitchBoardPageStyled";
import onlineLamp from "../../../assets/online.svg";
import outlined from "../../../assets/outlined.svg";
import wifiOff from "../../../assets/wifiOff.svg";
import lightON from "../../../assets/light-bulb-shine.svg";
import lightOFF from "../../../assets/icons/icon-bulb-off.svg";
import { languages } from "../../../utils/languages/languages";
import gateway from "../../../assets/gateway.svg";
import { Controller, useForm } from "react-hook-form";
import InputForm from "../../Input/Input";
import useFeeder from "../../../hooks/useFeeders/useFeeder";

const SwitchBoardModal = ({
  selectedTab,
  switchBoardData,
  setSelectedTab,
  feederId,
  feederName,
}: any) => {
  const { isSwitchBoardModalVisible } = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();

  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { createFeederCall, editFeederCall } = useFeeder(selectedTab);
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setError,
  } = useForm();
  const handleModal = () => {
    reset();
    dispatch(setSwitchBoardModalState(!isSwitchBoardModalVisible));
  };
  useEffect(() => {
    reset();
  }, [selectedTab]);

  const CreateFeeder = async (data: any) => {
    console.log("create hanyo");
    const response = await createFeederCall(data);
    if (response[0]) {
      dispatch(setSwitchBoardModalState(!isSwitchBoardModalVisible));
      setSelectedTab(data.id);
      reset();
    }
    if (response[1]) {
      setError("name", {
        type: "server",
        message: response[1].response.data.error,
      });
    }
  };
  const EditFeeder = async (data: any) => {
    console.log("edit hanyo");
    const response = await editFeederCall(data);
    if (response[0]) {
      handleModal();
      setSelectedTab("General");
    }
    if (response[1]) {
      setError("name", {
        type: "server",
        message: response[1].response.data.error,
      });
    }
  };
  console.log(isSwitchBoardModalVisible);
  let content;
  if (selectedTab === "General") {
    content = (
      <div className="container p-5">
        <div className="p-4 rounded" style={{ backgroundColor: "#E8E8E8" }}>
          <h4 style={{ fontWeight: "bold" }}>
            {switchBoardData?.switchboard_name}
          </h4>
          <div className="d-flex flex-row justify-content-between text-dark">
            <div className="flex items-center" style={{ gap: "5px" }}>
              <IconStyled src={outlined} height={24} />
              <TextSpan style={{ marginRight: "32px" }}>
                {switchBoardData?.switchboard_id}
              </TextSpan>
            </div>
            <div className="flex items-center" style={{ gap: "5px" }}>
              <IconStyled
                src={
                  Number(switchBoardData?.wireless_status)
                    ? onlineLamp
                    : wifiOff
                }
                height={16}
              />
              <TextSpan style={{ marginRight: "32px" }}>
                {Number(switchBoardData?.is_active)
                  ? txt.connected
                  : txt.disconnected}
              </TextSpan>
            </div>
            <div className="flex items-center" style={{ gap: "5px" }}>
              <IconStyled src={gateway} height={18} />
              {/* <TextSpan>{LampInfo?.gateway_address}</TextSpan> */}
            </div>
            <div className="flex items-center" style={{ gap: "5px" }}>
              <IconStyled
                src={
                  Number(switchBoardData?.wireless_status) ? lightON : lightOFF
                }
                height={24}
              />
              <TextSpan style={{ marginRight: "32px" }}>
                {Number(switchBoardData?.wireless_status) &&
                Number(switchBoardData?.wireless_status)
                  ? txt.ON
                  : txt.OFF}
              </TextSpan>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit((data) => CreateFeeder(data))}>
          <div className="flex flex-col gap-6 mt-4">
            <h4
              style={{
                fontWeight: "bold",
                color: "#475569",
                margin: "30px 0px",
              }}
            >
              Create Feeder
            </h4>
            <div className="d-flex" style={{ gap: 10, margin: "30px 0px" }}>
              <div style={{ width: "300px" }}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Feeder Name is Required" }}
                  defaultValue={""}
                  render={({ field }) => (
                    <InputForm
                      placeholder={"Please Enter Feeder name"}
                      marginBottom="small"
                      // onChange={(e) => console.log(e.target.value)}
                      {...field}
                      // onKeyPress={(e) => checkEnter(e)}
                    />
                    //   <Input placeholder="Feeder Name" {...field} />
                  )}
                />
                {errors.name && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors.name?.message?.toString()}
                  </span>
                )}
              </div>
              <div style={{ width: "300px" }}>
                <Controller
                  name="id"
                  control={control}
                  rules={{ required: "Feeder Id is required" }}
                  defaultValue={""}
                  render={({ field }) => (
                    <InputForm
                      placeholder={"Feeder ID"}
                      marginBottom="small"
                      // onChange={(e) => console.log(e.target.value)}
                      {...field}
                      // onKeyPress={(e) => checkEnter(e)}
                    />
                    //   <Input
                    //     size="lg"
                    //     error={!!errors?.id}
                    //     label="Feeder ID"
                    //     crossOrigin={undefined}
                    //     {...field}
                    //     disabled={title === "Edit Feeder"}
                    //   />
                  )}
                />
                {errors.id && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors.id?.message?.toString()}
                  </span>
                )}
              </div>
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              <button
                style={{
                  background: "#FFD966",
                  color: "black",
                  borderRadius: "10px",
                  padding: "15px",
                  width: "150px",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  border: "none",
                }}
                type="submit"
              >
                Create feeder
                {/* {title} */}
              </button>
              <button
                //   variant="outlined"
                className="text-sm font-medium rounded-md px-12"
                style={{
                  color: "black",
                  borderRadius: "10px",
                  padding: "15px",
                  width: "150px",
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid",
                  cursor: "pointer",
                  background: "none",
                }}
                type="button"
                onClick={() => handleModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    content = feederId && feederName && (
      <div className="container p-5">
        <div className="p-4 rounded" style={{ backgroundColor: "#E8E8E8" }}>
          <h4 style={{ fontWeight: "bold" }}>
            {switchBoardData?.switchboard_name}
          </h4>
          <div className="d-flex flex-row justify-content-between text-dark">
            <div className="flex items-center" style={{ gap: "5px" }}>
              <IconStyled src={outlined} height={24} />
              <TextSpan style={{ marginRight: "32px" }}>
                {switchBoardData?.switchboard_id}
              </TextSpan>
            </div>

            <div className="flex items-center" style={{ gap: "5px" }}>
              <IconStyled
                src={
                  Number(switchBoardData?.wireless_status)
                    ? onlineLamp
                    : wifiOff
                }
                height={16}
              />
              <TextSpan style={{ marginRight: "32px" }}>
                {Number(switchBoardData?.is_active)
                  ? txt.connected
                  : txt.disconnected}
              </TextSpan>
            </div>
            <div className="flex items-center" style={{ gap: "5px" }}>
              <IconStyled src={gateway} height={18} />
              {/* <TextSpan>{LampInfo?.gateway_address}</TextSpan> */}
            </div>
            <div className="flex items-center" style={{ gap: "5px" }}>
              <IconStyled
                src={
                  Number(switchBoardData?.wireless_status) ? lightON : lightOFF
                }
                height={24}
              />
              <TextSpan style={{ marginRight: "32px" }}>
                {Number(switchBoardData?.wireless_status) &&
                Number(switchBoardData?.wireless_status)
                  ? txt.ON
                  : txt.OFF}
              </TextSpan>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit((data) => EditFeeder(data))}>
          <div className="flex flex-col gap-6 mt-4">
            <h4
              style={{
                fontWeight: "bold",
                color: "#475569",
                margin: "30px 0px",
              }}
            >
              Edit Feeder
            </h4>
            <div className="d-flex" style={{ gap: 10, margin: "30px 0px" }}>
              <div style={{ width: "300px" }}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Feeder Name is Required" }}
                  defaultValue={feederName}
                  render={({ field }) => (
                    <InputForm
                      placeholder={"Please Enter Feeder name"}
                      marginBottom="small"
                      // onChange={(e) => console.log(e.target.value)}
                      {...field}
                      // onKeyPress={(e) => checkEnter(e)}
                    />
                    //   <Input placeholder="Feeder Name" {...field} />
                  )}
                />
                {errors.name && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors.name?.message?.toString()}
                  </span>
                )}
              </div>
              <div style={{ width: "300px" }}>
                <Controller
                  name="id"
                  control={control}
                  rules={{ required: "Feeder Id is required" }}
                  defaultValue={feederId}
                  render={({ field }) => (
                    <InputForm
                      placeholder={"Feeder ID"}
                      marginBottom="small"
                      // onChange={(e) => console.log(e.target.value)}
                      {...field}
                      // onKeyPress={(e) => checkEnter(e)}
                    />
                    //   <Input
                    //     size="lg"
                    //     error={!!errors?.id}
                    //     label="Feeder ID"
                    //     crossOrigin={undefined}
                    //     {...field}
                    //     disabled={title === "Edit Feeder"}
                    //   />
                  )}
                />
                {errors.id && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors.id?.message?.toString()}
                  </span>
                )}
              </div>
            </div>

            <div style={{ display: "flex", gap: 10 }}>
              <button
                style={{
                  background: "#FFD966",
                  color: "black",
                  borderRadius: "10px",
                  padding: "15px",
                  width: "150px",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  border: "none",
                }}
                type="submit"
              >
                Edit feeder
              </button>
              <button
                style={{
                  color: "black",
                  borderRadius: "10px",
                  padding: "15px",
                  width: "150px",
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid",
                  cursor: "pointer",
                  background: "none",
                }}
                type="button"
                onClick={handleModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
  return (
    <CustomModal
      show={isSwitchBoardModalVisible}
      onHide={handleModal}
      size={"lg"}
    >
      {content}
    </CustomModal>
  );
};

export default SwitchBoardModal;
