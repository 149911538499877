import { useState } from "react";
import { TextSpan } from "../../styles/shared-styles/text-sizes";
import { DropdownUsersStyled, UsersContainer } from "./UsersStyled";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import plusIcon from "../../assets/plus.svg";
import { UsersTable } from "./UsersList/UsersTable";
import { UsersList } from "./UsersList";
import { ActiveUsersDeatils } from "./ActiveUsersDetails/ActiveUsersDetails";
import { UserState } from "../../redux/slices/user/userSlice";
import { useTranslation } from "react-i18next";
interface UsersProps {
  user: UserState;
}
const Users = ({ user }: UsersProps) => {
  const [showList, setShowList] = useState(false);
  const { t } = useTranslation();

  return (
    <UsersContainer>
      <div className="row" style={{ marginBottom: "32px" }}>
        <div className="col-2">
          <DropdownUsersStyled
            style={{ paddingBottom: showList ? "20px" : "0" }}
          >
            {" "}
            <div
              onClick={() => setShowList(!showList)}
              className="filter__title-container"
            >
              <span className="title-container__title">{t("All")}</span>

              <span className="material-symbols-rounded title-container__icon">
                {showList ? "expand_less" : "expand_more"}
              </span>
            </div>
            {showList && (
              <>
                <ul className="list">
                  <div className="option-container">
                    <TextSpan>Option 1</TextSpan>
                  </div>
                </ul>
              </>
            )}
          </DropdownUsersStyled>
        </div>

        <div
          className="col-10"
          style={{ alignSelf: "center", textAlign: "right" }}
        >
          <Button buttonType="primary">
            <span>
              <img
                src={plusIcon}
                style={{ height: "16px", marginRight: "8px" }}
              />
            </span>
            {t("New User")}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-auto">
          <UsersList />
        </div>
        <div className="col">
          <ActiveUsersDeatils userRole={user.role_name} />
        </div>
      </div>
    </UsersContainer>
  );
};

export default Users;
